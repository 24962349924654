import React from 'react'
import styles from './Button.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Button,
  IconButton
} from '@mui/material'
import {
  Info
} from '@mui/icons-material'

const ButtonInfo = (props) => {
  const device = useSelector(state => state.device)
  return (device.mobile)
    ? <Button
        color="primary"
        variant="contained"
        className={styles.info}
        {...props}
      >
        <Info fontSize="small"/>
      </Button>
    : <IconButton {...props}>
        <Info color="primary"/>
      </IconButton>
}

export default ButtonInfo
