import React from 'react'
import _range from 'lodash/range'
import styles from './Slider.module.css'
import InputSlider from '../Input/InputSlider'
import {
  Slider as RangeSlider
} from '@mui/material'
import {
  label_heure,
  format_heure
} from '../../../Functions/Heures'
import {
  variables
} from '../../../Variables/Variables'

const step = (variables.heure.step / 60)
const max = format_heure(variables.date.fin)
const min = format_heure(variables.date.debut)

const marks = _range(min, max + step, step).filter(item => Number.isInteger(item)).map(item => {
  return {
    value: item,
    label: item + 'h'
  }
})

const Slider = ({
  input,
  value,
  wrong,
  maxTime,
  minTime,
  children,
  setValue
}) => {
  const Update = (event, array, thumb) => {
    if (!Array.isArray(array)) {
      return
    }
    const maximum = (
      (maxTime !== undefined) &&
      (maxTime <= array[1])
    )
      ? maxTime
      : array[1]
    const minimum = (
      (minTime !== undefined) &&
      (minTime >= array[0])
    )
      ? minTime
      : array[0]
    setValue(
      (thumb === 0)
        ? [Math.min(minimum, value[1] - variables.date.step), value[1]]
        : [value[0], Math.max(maximum, value[0] + variables.date.step)]
    )
  }
  return (
    <div className={styles.slider}>
      <RangeSlider
        min={min}
        max={max}
        size="small"
        marks={marks}
        value={value}
        onChange={Update}
        disableSwap={true}
        valueLabelDisplay="auto"
        step={variables.date.step}
        valueLabelFormat={label_heure}
        className={
          (wrong)
            ? 'wrong-slider'
            : undefined
        }
      />
      {
        (input)
          ? <InputSlider
              value={
                label_heure(value[0], true)
                + ' - ' +
                label_heure(value[1], true)
              }
            />
          : null
      }
      {children}
    </div>
  )
}

export default Slider
