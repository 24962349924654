import clsx from 'clsx'
import React from 'react'
import styles from './PlanningModal.module.css'
import {
  useSelector
} from 'react-redux'
import {
  FormGroup
} from '@mui/material'
import {
  sort_semaine
} from '../../Functions/Sort'
import {
  start_case
} from '../../Functions/Strings'

const PlanningModalSemaineContent = ({
  id,
  setId
}) => {
  const semaines = useSelector(state => state.semaines.semaines)
  return (semaines.length > 0)
    ? <FormGroup
        row={true}
        className="modal-week"
      >
        {
          sort_semaine(semaines).map(item => {
            return (
              <div
                key={item.id}
                onClick={() =>
                  setId(
                    (id === item.id)
                      ? ''
                      : item.id
                  )
                }
                className={
                  clsx({
                    'item-week': true,
                    [styles.selected]: (id === item.id)
                  })
                }
              >
                <span className="item-week-name">{start_case(item.nom)}</span>
              </div>
            )
          })
        }
      </FormGroup>
    : null
}

export default PlanningModalSemaineContent
