import React from 'react'
import Button from './Button'

const ButtonLogout = (props) => {
  return (
    <Button
      color="bar"
      icon="ExitToApp"
      {...props}
    />
  )
}

export default ButtonLogout
