import React from 'react'
import Button from './Button'

const ButtonDuplicate = ({
  button,
  ...others
}) => {
  return (
    <Button
      icon="FileCopy"
      title="Dupliquer"
      style={{
        padding: '14px'
      }}
      button={{
        ...button,
        fontSize: 'small'
      }}
      {...others}
    />
  )
}

export default ButtonDuplicate
