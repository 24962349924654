import React from 'react'
import TableCellRed from './TableCellRed'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellBeta = ({
  item,
  label,
  device
}) => {
  const object = {
    empty: (item.beta !== 1),
    value: <TableCellRed value="Oui"/>
  }
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        {...object}
      />
    : <TableCellDesktop
        max={75}
        {...object}
      />
}

export default TableCellBeta
