import React from 'react'
import ForgotCode from './ForgotCode'
import ForgotMail from './ForgotMail'
import Form from '../Includes/Wrapper/Form'
import Root from '../Includes/Wrapper/Root'
import ForgotPassword from './ForgotPassword'
import Footer from '../Includes/Footer/Footer'
import {
  Paper
} from '@mui/material'

const Forgot = () => {
  const [error, setError] = React.useState({
    code: false,
    mail: false,
    password: false,
    confirmation: false
  })
  const [state, setState] = React.useState({
    step: 1,
    code: '',
    mail: '',
    password: '',
    confirmation: ''
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  return (
    <>
      <Root className="forgot-content">
        <Paper className="forgot-paper">
          <Form fit={true}>
            {
              (state.step === 1)
                ? <ForgotMail
                    error={error}
                    state={state}
                    Update={Update}
                    UpdateError={UpdateError}
                  />
                : (state.step === 2)
                  ? <ForgotCode
                      error={error}
                      state={state}
                      Update={Update}
                      UpdateError={UpdateError}
                    />
                  : (state.step === 3)
                    ? <ForgotPassword
                        error={error}
                        state={state}
                        Update={Update}
                        UpdateError={UpdateError}
                      />
                    : null
            }
          </Form>
        </Paper>
      </Root>
      <Footer mini={true}/>
    </>
  )
}

export default Forgot
