import React from 'react'
import dayjs from '../../../Libraries/dayjs'
import ToolbarComponent from './ToolbarComponent'
import ToolbarRootHeure from './ToolbarRootHeure'
import ToolbarRootShare from './ToolbarRootShare'
import ToolbarRootDelete from './ToolbarRootDelete'
import ToolbarRootAbsence from './ToolbarRootAbsence'
import {
  useSelector
} from 'react-redux'
import {
  ToolbarMobile,
  SwitcherComponent
} from '../Event/Event'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  Toolbar
} from '@devexpress/dx-react-scheduler-material-ui'

const ToolbarRoot = ({ children }) => {
  let data = []
  const device = useSelector(state => state.device)
  const date = useSelector(state => state.planning.date)
  const view = useSelector(state => state.planning.view)
  const equipe = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.filter)
  const absents = useSelector(state => state.planning.absents)
  const planning = useSelector(state => state.planning.planning)
  const creneaux = planning.find(item => item.week === first_day_format(date))
  const checked = equipe.map(item => item.id).filter(item => filter.indexOf(item) < 0)
  const liste = (creneaux)
    ? (view === 'Week')
      ? creneaux.horaires
      : creneaux.horaires.filter(item => dayjs(item.startDate).format('DDMMYYYY') === dayjs(date).format('DDMMYYYY'))
    : []
  data = liste.map(item => {
    const off = absents.filter(object => object.creneau === item.id).map(object => object.id)
    return {
      ...item,
      equipe: item.equipe.filter(item =>
        (checked.includes(item)) &&
        (off.indexOf(item) < 0)
      )
    }
  })
  const something = data.filter(item =>
    (item.equipe.length > 0) &&
    (
      (
        (item.heure) &&
        (item.sens === 0)
      ) || (item.heure !== true)
    )
  )
  const empty = data.filter(item => item.heure !== true)
  const vide = (
    (empty !== undefined) &&
    (empty.length > 0)
  )
  const existe = (
    (something !== undefined) &&
    (something.length > 0)
  )
  return (
    <>
      {
        (device.mobile)
          ? <ToolbarMobile/>
          : null
      }
      <Toolbar.Root className="toolbar toolbar-actions">
        <SwitcherComponent/>
        <ToolbarRootAbsence/>
        <ToolbarRootHeure/>
        <ToolbarRootShare existe={existe}/>
        <ToolbarRootDelete vide={vide}/>
      </Toolbar.Root>
      <ToolbarComponent>
        {children}
      </ToolbarComponent>
    </>
  )
}

export default ToolbarRoot
