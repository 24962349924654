import React from 'react'
import TableCellComptabilite from './TableCellComptabilite'
import {
  convert_heure
} from '../../../Functions/Heures'

const TableCellComptabiliteHeures = ({ item }) => {
  const value = item.heures
  return (
    <TableCellComptabilite
      item={value}
      value={convert_heure(value * 60, false)}
    />
  )
}

export default TableCellComptabiliteHeures
