import React from 'react'
import history from './History'
import {
  useSelector
} from 'react-redux'
import {
  messages
} from '../../Variables/Messages'
import {
  variables
} from '../../Variables/Variables'

const Error = () => {
  const user = useSelector(state => state.login.user)
  return (
    <div className="not-found">
      <div className="not-found-logo">
        <img
          alt="logo"
          src={variables.url.server + variables.path.icon + 'planipo_text.png'}
        />
      </div>
      <div className="not-found-texte">
        <div>{messages.error.die}</div>
      </div>
      <div className="not-found-retour">
        <span
          onClick={() => {
            (user.logged)
              ? history.push('/accueil')
              : history.push('/login')
          }}
        >
          Retour
        </span>
      </div>
    </div>
  )
}

export default Error
