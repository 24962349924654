import clsx from 'clsx'
import React from 'react'
import styles from './AbonnementLight.module.css'

const AbonnementLight = ({
  top,
  bottom,
  mobile,
  favorite,
  abonnement
}) => {
  return (
    <div
      style={{
        background: (
          (!favorite) &&
          (abonnement.css)
        )
      }}
      className={
        clsx({
          [styles.top]: top,
          [styles.light]: true,
          [styles.bottom]: bottom,
          [styles.mobile]: mobile,
          [styles.favorite]: favorite
        })
      }
    />
  )
}

export default AbonnementLight
