import EquipeModalContent from './EquipeModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  CheckMail,
  CheckFloat,
  CheckTexte,
  CheckConges
} from '../../Functions/Check'
import {
  random_color
} from '../../Functions/Colors'
import {
  get_heures
} from '../../Functions/Heures'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  lower_case
} from '../../Functions/Strings'
import {
  get_id
} from '../../Functions/Functions'
import {
  initial
} from '../../Variables/Variables'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  EQUIPE,
  MODAL_EQUIPE
} from '../../Redux/Actions/Equipe'

const EquipeModal = ({ data }) => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.equipe.modal)
  const titulaire = useSelector(state => state.compte.titulaire)
  const settings = useSelector(state => state.settings.variables)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const [error, setError] = React.useState({
    nom: false,
    done: false,
    mail: false,
    temps: false,
    heures: false,
    prenom: false,
    current: false
  })
  const [state, setState] = React.useState({
    nom: '',
    mail: '',
    done: '',
    temps: 0,
    heures: 0,
    color: {},
    prenom: '',
    profil: '',
    current: '',
    fonction: '',
    values: undefined
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (
      (!error.done) &&
      (!error.temps) &&
      (!error.heures) &&
      (!error.current) &&
      CheckTexte(state.nom) &&
      CheckTexte(state.prenom) &&
      (
        (state.mail === '') ||
        (CheckMail(state.mail))
      ) &&
      (
        (
          (state.heures === '') &&
          (state.fonction === titulaire)
        ) || (!error.heures)
      )
    ) {
      UpdateError('nom', false)
      UpdateError('done', false)
      UpdateError('mail', false)
      UpdateError('temps', false)
      UpdateError('heures', false)
      UpdateError('prenom', false)
      UpdateError('current', false)
      const value = {
        id: (modal.open === 'edit')
          ? state.values.id
          : get_id(),
        color: state.color,
        profil: state.profil,
        fonction: state.fonction,
        nom: lower_case(state.nom),
        mail: lower_case(state.mail),
        prenom: lower_case(state.prenom),
        temps: (state.temps !== '') ? parseInt(state.temps) : 0,
        heures: (state.heures !== '') ? parseFloat(state.heures) : 0,
        conges_done: (state.done !== '') ? parseFloat(state.done) : 0,
        conges_current: (state.current !== '') ? parseFloat(state.current) : 0
      }
      const item = {
        ...value,
        commit: (
          (modal.open === 'edit') &&
          (value.temps !== state.values.temps)
        ) ? 1 : 0,
        conges: (
          (modal.open === 'edit') &&
          (
            (value.conges_done !== state.values.conges_done) ||
            (value.conges_current !== state.values.conges_current)
          )
        ) ? 1 : 0
      }
      if (modal.open === 'edit') {
        if (isDifferent([value], [state.values])) {
          dispatch(EQUIPE(
            {
              ...item,
              temps: (value.temps === state.values.temps)
                ? value.temps - get_heures(state.values.id)
                : value.temps
            },
            'edit-user'
          ))
        }
      } else {
        dispatch(EQUIPE(item, 'create-user'))
      }
      dispatch(MODAL_EQUIPE('', ''))
    } else {
      UpdateError('nom', !CheckTexte(state.nom))
      UpdateError('prenom', !CheckTexte(state.prenom))
      UpdateError('mail', (
        (state.mail !== '') &&
        (!CheckMail(state.mail))
      ))
      UpdateError('heures', (
        (parseFloat(state.heures) < settings.minimum.heures) ||
        (parseFloat(state.heures) > settings.maximum.heures) ||
        (
          (state.heures !== '') &&
          (!CheckFloat(state.heures))
        )
      ))
      UpdateError('temps', (
        (parseFloat(state.temps) < settings.minimum.temps) ||
        (parseFloat(state.temps) > settings.maximum.temps) ||
        (
          (state.temps !== '') &&
          (!CheckFloat(state.temps))
        )
      ))
      UpdateError('done', (
        (parseFloat(state.done) < settings.minimum.done) ||
        (parseFloat(state.done) > settings.maximum.done) ||
        (
          (state.done !== '') &&
          (!CheckConges(state.done))
        )
      ))
      UpdateError('current', (
        (parseFloat(state.current) < settings.minimum.current) ||
        (parseFloat(state.current) > settings.maximum.current) ||
        (
          (state.current !== '') &&
          (!CheckConges(state.current))
        )
      ))
    }
  }
  useEffect(() => {
    if (modal.open !== '') {
      UpdateError('nom', false)
      UpdateError('done', false)
      UpdateError('mail', false)
      UpdateError('temps', false)
      UpdateError('heures', false)
      UpdateError('prenom', false)
      UpdateError('current', false)
      if (modal.id === '') {
        Update('color', random_color())
        Update('nom', initial.equipe.nom)
        Update('fonction', fonctions[0].id)
        Update('mail', initial.equipe.mail)
        Update('temps', initial.equipe.temps)
        Update('heures', initial.equipe.heures)
        Update('prenom', initial.equipe.prenom)
        Update('profil', initial.equipe.profil)
        Update('done', initial.equipe.conges.done)
        Update('current', initial.equipe.conges.current)
      } else {
        const equipe = data.find(item => item.id === modal.id)
        const { commit, conges, metier, conges_remaining, ...value } = equipe
        Update('values', value)
      }
    }
    // eslint-disable-next-line
  }, [modal])
  useEffect(() => {
    if (state.values !== undefined) {
      Update('nom', state.values.nom)
      Update('mail', state.values.mail)
      Update('color', state.values.color)
      Update('temps', state.values.temps)
      Update('heures', state.values.heures)
      Update('prenom', state.values.prenom)
      Update('profil', state.values.profil)
      Update('done', state.values.conges_done)
      Update('fonction', state.values.fonction)
      Update('current', state.values.conges_current)
    }
    // eslint-disable-next-line
  }, [state.values])
  return (
    <Dialog
      open={(
        (modal.open === 'create') ||
        (modal.open === 'edit')
      )}
    >
      <DialogTitle>
        {
          (modal.open === 'edit')
            ? "Modifier l'utilisateur"
            : "Nouvel utilisateur"
        }
      </DialogTitle>
      <DialogContent>
        <EquipeModalContent
          error={error}
          state={state}
          Update={Update}
          UpdateError={UpdateError}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => dispatch(MODAL_EQUIPE('', ''))
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default EquipeModal
