import React from 'react'
import LegendeDate from '../Legende/LegendeDate'
import TableCellDesktop from './TableCellDesktop'
import LegendeHeure from '../Legende/LegendeHeure'
import {
  get_user_long
} from '../../../Functions/Strings'

const TableCellLegendeErreurs = ({ item }) => {
  return (
    <TableCellDesktop
      max={200}
      style={{
        paddingLeft: 0
      }}
    >
      {get_user_long(item)}
      <LegendeDate item={item}/>
      {
        item.creneaux.map((item, index) => {
          return (
            <LegendeHeure
              key={index}
              item={item}
            />
          )
        })
      }
    </TableCellDesktop>
  )
}

export default TableCellLegendeErreurs
