import clsx from 'clsx'
import React from 'react'
import styles from './AbonnementSeparate.module.css'

const AbonnementSeparate = ({
  mobile,
  favorite
}) => {
  return (
    <div
      className={
        clsx({
          [styles.mobile]: mobile,
          [styles.separate]: true,
          [styles.favorite]: favorite
        })
      }
    />
  )
}

export default AbonnementSeparate
