import React from 'react'
import Titre from '../Includes/Misc/Titre'
import TableData from '../Includes/Table/TableData'
import styles from './ComptabiliteDownload.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'
import {
  properties
} from '../../Settings/Download'

const ComptabiliteDownload = () => {
  const exports = useSelector(state => state.comptabilite.exports)
  return (exports.length > 0)
    ? <Paper className={styles.root}>
        <Toolbar>
          <Titre>Précédents exports</Titre>
        </Toolbar>
        <TableData
          data={exports}
          {...properties}
        />
      </Paper>
    : null
}

export default ComptabiliteDownload
