import ML from './ML'
import CGV from './CGV'
import PDC from './PDC'
import clsx from 'clsx'
import React from 'react'
import styles from './Privacy.module.css'
import {
  useSelector
} from 'react-redux'

const Privacy = ({ mini }) => {
  const device = useSelector(state => state.device)
  return (
    <span
      className={
        clsx({
          [styles.mini]: mini,
          [styles.container]: true,
          [styles.mobile]: device.mobile
        })
      }
    >
      <span className={styles.link}>
        <CGV/>
      </span>
      <span className={styles.link}>
        <PDC/>
      </span>
      <span className={styles.link}>
        <ML/>
      </span>
    </span>
  )
}

export default Privacy
