import clsx from 'clsx'
import React from 'react'
import PlanningSelect from './PlanningSelect'
import styles from './PlanningModal.module.css'
import PlanningModalSeparation from './PlanningModalSeparation'
import {
  useSelector
} from 'react-redux'
import {
  FormGroup
} from '@mui/material'
import {
  sort_function
} from '../../Functions/Sort'

const PlanningModalFonctionContent = ({
  users,
  setUsers
}) => {
  const equipe = useSelector(state => state.equipe.equipe)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const data = fonctions.filter(item => equipe.map(item => item.fonction).includes(item.id))
  const Toggle = (id, all) => {
    const salaries = equipe.filter(item => item.fonction === id).map(item => item.id)
    const array = users.filter(item => salaries.indexOf(item) < 0)
    setUsers(
      (all)
        ? array
        : [ ...array, ...salaries ]
    )
  }
  return (
    <>
      <PlanningSelect
        None={() => setUsers([])}
        All={() => setUsers(equipe.map(item => item.id))}
      />
      <PlanningModalSeparation/>
      {
        (data.length > 0)
          ? <div className="inline-team">
              <FormGroup
                row={true}
                className="modal-team"
              >
                {
                  sort_function(data).map(item => {
                    const total = equipe.filter(salarie => salarie.fonction === item.id)
                    const salaries = users.filter(user => total.map(item => item.id).includes(user))
                    return (
                      <div
                        key={item.id}
                        onClick={() => Toggle(item.id, (salaries.length === total.length))}
                        className={
                          clsx({
                            'item-team': true,
                            'item-team-fonction': true,
                            [styles.selected]: (salaries.length === total.length)
                          })
                        }
                      >
                        <span className="item-team-nombre">{salaries.length}/{total.length}</span>
                        <span className="item-team-name">{item.fonction}</span>
                      </div>
                    )
                  })
                }
              </FormGroup>
            </div>
          : null
      }
    </>
  )
}

export default PlanningModalFonctionContent
