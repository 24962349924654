import TableCellDateDemande from '../../Components/Includes/Table/TableCellDateDemande'

export const demande = {
  id: "demande",
  sort: true,
  mobile: true,
  padding: true,
  label: "Demande",
  component: TableCellDateDemande
}
