import React from 'react'
import TableCellField from './TableCellField'
import ButtonEdit from '../Button/ButtonEdit'
import ButtonReset from '../Button/ButtonReset'
import ButtonDelete from '../Button/ButtonDelete'
import TableCellContainer from './TableCellContainer'
import ActionSheetUsers from '../Action/ActionSheetUsers'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  get_user_long
} from '../../../Functions/Strings'

const TableCellActionsUsers = ({
  item,
  type,
  action
}) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const name = (type.id === 'offices')
    ? item.mail
    : get_user_long(item)
  return (device.mobile)
    ? <TableCellField>
        {
          (type.id === 'offices')
            ? <ActionSheetUsers
                id={item.id}
                modal={action}
                texte={item.mail}
              />
            : <ActionSheetUsers
                id={item.id}
                modal={action}
                texte={get_user_long(item)}
                remove={(user.unique_id_user !== item.id)}
                reset={(
                  (item.mail !== '') &&
                  (user.unique_id_user !== item.id)
                )}
              />
        }
      </TableCellField>
    : <TableCellContainer width={3}>
        <ButtonEdit
          onClick={() => dispatch(action(item.id, 'edit'))}
        />
        {
          (
            (type.id === 'offices') ||
            (user.unique_id_user !== item.id)
          )
            ? <ButtonDelete
                onClick={() => dispatch(action(item.id, 'delete', name))}
              />
            : null
        }
        {
          (
            (type.id === 'offices') ||
            (
              (item.mail !== '') &&
              (user.unique_id_user !== item.id)
            )
          )
            ? <ButtonReset
                onClick={() => dispatch(action(item.id, 'reset', name))}
              />
            : null
        }
      </TableCellContainer>
}

export default TableCellActionsUsers
