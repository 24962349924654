const initial = {
  target: '',
  open: false,
  selected: [],
  notifications: []
}

function notifications(state = initial, action) {
  switch (action.type) {
    case 'TARGET':
      return {
        ...state,
        target: action.value
      }
    case 'INIT_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.value
      }
    case 'OPEN_NOTIFICATIONS':
      return {
        ...state,
        open: action.value
      }
    case 'READ_NOTIFICATIONS':
      return {
        ...state,
        notifications: state.notifications.map(item => {
          return (action.value.includes(item.id))
            ? {
                ...item,
                read: (action.read)
                  ? [
                      ...item.read,
                      action.user
                    ]
                  : item.read.filter(item => item !== action.user)
              }
            : item
        })
      }
    case 'REMOVE_NOTIFICATIONS':
      return {
        ...state,
        selected: state.selected.filter(item => action.value.indexOf(item) < 0),
        notifications: state.notifications.filter(item => action.value.indexOf(item.id) < 0)
      }
    case 'SELECTED_NOTIFICATIONS':
      return {
        ...state,
        selected: action.value
      }
    case 'REMOVE_NOTIFICATIONS_USER':
      return {
        ...state,
        notifications: state.notifications.filter(item =>
          (item.admin !== action.value) &&
          (item.user !== action.value)
        )
      }
    default:
      return state
  }
}

export default notifications
