import React from 'react'
import dayjs from '../../Libraries/dayjs'
import {
  useSelector
} from 'react-redux'
import {
  Grid
} from '@mui/material'
import {
  start_case
} from '../../Functions/Strings'
import {
  is_not_empty
} from '../../Functions/Ouverture'

const SettingsOuverture = () => {
  const ouverture = useSelector(state => state.ouverture.ouverture)
  const Time = (object) => {
    return (is_not_empty(object))
      ? object.debut.h + 'h' + ((object.debut.m === 0) ? '' : object.debut.m) + ' - ' + object.fin.h + 'h' + ((object.fin.m === 0) ? '' : object.fin.m)
      : '-'
  }
  const coupure = (
    (is_not_empty(ouverture.dimanche.pm)) ||
    (is_not_empty(ouverture.vendredi.pm)) ||
    (is_not_empty(ouverture.mercredi.pm)) ||
    (is_not_empty(ouverture.samedi.pm)) ||
    (is_not_empty(ouverture.jeudi.pm)) ||
    (is_not_empty(ouverture.mardi.pm)) ||
    (is_not_empty(ouverture.lundi.pm))
  )
  return (
    <Grid
      container
      spacing={0}
      columns={5}
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        xs={1}
        item={true}
      />
      {
        (coupure)
          ? <>
              <Grid
                xs={2}
                item={true}
                className="info-grid-moment"
              >
                <span>Matin</span>
              </Grid>
              <Grid
                xs={2}
                item={true}
                className="info-grid-moment"
              >
                <span>Après-midi</span>
              </Grid>
            </>
          : <Grid
              xs={4}
              item={true}
              className="info-grid-moment"
            >
              <span>Journée</span>
            </Grid>
      }
      {
        [...Array(7).keys()].map((item, index) => {
          const day = dayjs().weekday(index).format('dddd')
          const data = ouverture[day]
          return (
            <React.Fragment key={index}>
              <Grid
                xs={1}
                item={true}
                className="info-grid-day"
              >
                {start_case(day)}
              </Grid>
              <Grid
                item={true}
                xs={(coupure) ? 2 : 4}
              >
                {Time(data.am)}
              </Grid>
              {
                (coupure)
                  ? <Grid
                      xs={2}
                      item={true}
                    >
                      {Time(data.pm)}
                    </Grid>
                  : null
              }
            </React.Fragment>
          )
        })
      }
    </Grid>
  )
}

export default SettingsOuverture
