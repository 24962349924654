import Form from '../Includes/Wrapper/Form'
import SettingOuvertureModalContent from './SettingOuvertureModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  FormHelperText
} from '@mui/material'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  setting_ouverture_same,
  setting_ouverture_vide,
  setting_ouverture_valide,
  setting_ouverture_prepare
} from '../../Functions/Settings'
import {
  is_not_empty
} from '../../Functions/Ouverture'
import {
  ouverture as blank
} from '../../Variables/Variables'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  UPDATE_OUVERTURE
} from '../../Redux/Actions/Ouverture'

const SettingOuvertureModal = ({
  open,
  setOpen
}) => {
  const dispatch = useDispatch()
  const [same, setSame] = React.useState(false)
  const [vide, setVide] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [commit, setCommit] = React.useState(false)
  const [valide, setValide] = React.useState(false)
  const [coupure, setCoupure] = React.useState(true)
  const ouverture = useSelector(state => state.ouverture.ouverture)
  const [state, setState] = React.useState({
    lundi: blank,
    mardi: blank,
    jeudi: blank,
    samedi: blank,
    mercredi: blank,
    vendredi: blank,
    dimanche: blank
  })
  const Commit = () => {
    setCommit(true)
    if (
      (valide) &&
      (!error) &&
      (!vide) &&
      (!same)
    ) {
      setOpen()
      setCommit(false)
      const value = {
        lundi: setting_ouverture_prepare(state.lundi, coupure),
        mardi: setting_ouverture_prepare(state.mardi, coupure),
        jeudi: setting_ouverture_prepare(state.jeudi, coupure),
        samedi: setting_ouverture_prepare(state.samedi, coupure),
        mercredi: setting_ouverture_prepare(state.mercredi, coupure),
        vendredi: setting_ouverture_prepare(state.vendredi, coupure),
        dimanche: setting_ouverture_prepare(state.dimanche, coupure)
      }
      if (isDifferent([value], [ouverture])) {
        dispatch(UPDATE_OUVERTURE(value))
      }
    }
  }
  useEffect(() => {
    if (open) {
      setState({
        lundi: ouverture.lundi,
        mardi: ouverture.mardi,
        jeudi: ouverture.jeudi,
        samedi: ouverture.samedi,
        mercredi: ouverture.mercredi,
        vendredi: ouverture.vendredi,
        dimanche: ouverture.dimanche
      })
      setCoupure(
        (is_not_empty(ouverture.dimanche.pm)) ||
        (is_not_empty(ouverture.vendredi.pm)) ||
        (is_not_empty(ouverture.mercredi.pm)) ||
        (is_not_empty(ouverture.samedi.pm)) ||
        (is_not_empty(ouverture.jeudi.pm)) ||
        (is_not_empty(ouverture.mardi.pm)) ||
        (is_not_empty(ouverture.lundi.pm))
      )
    }
    // eslint-disable-next-line
  }, [open])
  useEffect(() => {
    setCommit(false)
    setSame(
      (setting_ouverture_same(state.dimanche)) ||
      (setting_ouverture_same(state.vendredi)) ||
      (setting_ouverture_same(state.mercredi)) ||
      (setting_ouverture_same(state.samedi)) ||
      (setting_ouverture_same(state.jeudi)) ||
      (setting_ouverture_same(state.mardi)) ||
      (setting_ouverture_same(state.lundi))
    )
    setVide(
      (setting_ouverture_vide(state.dimanche, coupure)) &&
      (setting_ouverture_vide(state.vendredi, coupure)) &&
      (setting_ouverture_vide(state.mercredi, coupure)) &&
      (setting_ouverture_vide(state.samedi, coupure)) &&
      (setting_ouverture_vide(state.jeudi, coupure)) &&
      (setting_ouverture_vide(state.mardi, coupure)) &&
      (setting_ouverture_vide(state.lundi, coupure))
    )
    setValide(
      (setting_ouverture_valide(state.dimanche, coupure)) &&
      (setting_ouverture_valide(state.vendredi, coupure)) &&
      (setting_ouverture_valide(state.mercredi, coupure)) &&
      (setting_ouverture_valide(state.samedi, coupure)) &&
      (setting_ouverture_valide(state.jeudi, coupure)) &&
      (setting_ouverture_valide(state.mardi, coupure)) &&
      (setting_ouverture_valide(state.lundi, coupure))
    )
    // eslint-disable-next-line
  }, [state])
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      className="modal-ouverture"
    >
      <DialogTitle>Horaires d'ouverture</DialogTitle>
      <DialogContent>
        <Form
          full={true}
          className="form-ouverture"
        >
          <SettingOuvertureModalContent
            state={state}
            coupure={coupure}
            setError={setError}
            setState={setState}
            setCoupure={setCoupure}
          />
          {
            (commit)
              ? (
                  (!valide) ||
                  (error) ||
                  (vide) ||
                  (same)
                )
                  ? <FormHelperText
                      error={true}
                      className="ouverture-error"
                    >
                      {
                        (error)
                          ? "Votre saisie est invalide"
                          : (vide)
                            ? "Votre saisie est vide"
                            : (same)
                              ? "Vous ne pouvez pas renseigner un début et une fin identiques"
                              : "Vous ne pouvez pas renseigner un début sans une fin et inversement"
                      }
                    </FormHelperText>
                  : null
              : null
          }
        </Form>
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen()
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default SettingOuvertureModal
