import React from 'react'
import TableData from '../Table/TableData'
import LegendeAccordion from './LegendeAccordion'
import {
  sort_date
} from '../../../Functions/Sort'
import {
  properties
} from '../../../Settings/Legende/Vides'

const LegendeVides = ({ data }) => {
  return (
    <LegendeAccordion
      warning={true}
      titre={"Vides (" + data.length + ")"}
    >
      <TableData
        all={true}
        head={false}
        border={true}
        bottom={false}
        pagination={false}
        data={sort_date(data)}
        {...properties}
      />
    </LegendeAccordion>
  )
}

export default LegendeVides
