import React from 'react'
import TableCellDesktop from './TableCellDesktop'

const TableCellSettingsItem = ({ item }) => {
  return (
    <TableCellDesktop normal={true}>
      <span className="setting-explain">
        {item.item}
      </span>
      {
        item.explain.map((item, index) => {
          return (
            <div
              key={index}
              className="td-explain"
            >
              {item}
            </div>
          )
        })
      }
    </TableCellDesktop>
  )
}

export default TableCellSettingsItem
