import clsx from 'clsx'
import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {
  useSelector
} from 'react-redux'
import {
  ToastContainer
} from 'react-toastify'

const Toast = () => {
  const device = useSelector(state => state.device)
  return (
    <ToastContainer
      theme="colored"
      draggable={true}
      closeButton={false}
      closeOnClick={true}
      pauseOnHover={true}
      hideProgressBar={true}
      position="bottom-right"
      bodyClassName="notification-body"
      toastClassName="notification-toast"
      className={
        clsx(
          'notification',
          (device.mobile)
            ? 'notification-mobile'
            : 'notification-desktop'
        )
      }
    />
  )
}

export default Toast
