import clsx from 'clsx'
import React from 'react'
import about from '../../about.json'
import styles from './About.module.css'
import AboutContent from './AboutContent'
import Titre from '../Includes/Misc/Titre'
import Root from '../Includes/Wrapper/Root'
import Privacy from '../Includes/Footer/Privacy'
import {
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'
import {
  Link
} from 'react-router-dom'
import {
  config,
  variables
} from '../../Variables/Variables'

const About = () => {
  const beta = (config.beta === '1') ? 'Beta ' : ''
  const device = useSelector(state => state.device)
  const settings = useSelector(state => state.settings.variables)
  return (
    <Root
      container={true}
      className={
        clsx({
          'page-container-about': true,
          [styles.mobile]: device.mobile,
          'page-container-about-mobile': device.mobile
        })
      }
    >
      <Paper className={styles.root}>
        <Toolbar>
          <Titre>
            <div>Qui sommes-nous ?</div>
          </Titre>
        </Toolbar>
        <AboutContent signature={true}/>
      </Paper>
      <Paper
        className={
          clsx(
            styles.root,
            styles.developper
          )
        }
      >
        <Toolbar>
          <Titre>
            <div>
              {settings.name + ' Version ' + beta + about.version.major + '.' + about.version.minor + '.' + about.version.build}
            </div>
          </Titre>
        </Toolbar>
        <div className={styles.name}>Développé par <span className="bold">{settings.developper.name}</span></div>
        <div className={styles.mail}>
          <Link
            to="#"
            onClick={() => window.location = 'mailto:' + settings.developper.mail}
          >
            {settings.developper.mail}
          </Link>
        </div>
        <img
          alt="logo"
          width="64"
          height="64"
          className={styles.logo}
          src={variables.url.server + variables.path.icon + 'planipo_icon.png'}
        />
      </Paper>
      <Paper
        className={
          clsx(
            styles.root,
            styles.privacy
          )
        }
      >
        <Privacy/>
      </Paper>
    </Root>
  )
}

export default About
