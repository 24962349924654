import React from 'react'
import TableData from '../Includes/Table/TableData'
import {
  properties
} from '../../Settings/Conges'
import {
  absences
} from '../../Variables/Variables'

const AbsenceModalUserContent = ({ salarie }) => {
  const data = [
    {
      id: 0,
      solde: absences.done.year,
      restant: salarie.conges_done
    },
    {
      id: 1,
      solde: absences.current.year,
      restant: salarie.conges_current
    }
  ]
  return (
    <TableData
      data={data}
      size="small"
      desktop={true}
      pagination={false}
      {...properties}
    />
  )
}

export default AbsenceModalUserContent
