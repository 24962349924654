import {
  variables
} from '../../Variables/Variables'

const initial = {
  mail: '',
  mails: [],
  users: [],
  sujet: '',
  select: '',
  companie: '',
  pending: false,
  texte: variables.mail.texte,
  error: {
    mail: false,
    sujet: false,
    texte: false
  }
}

function mail(state = initial, action) {
  switch (action.type) {
    case 'MAIL':
      return {
        ...state,
        [action.item]: action.value
      }
    case 'ADD_MAIL':
      return {
        ...state,
        mails: [ ...state.mails, action.value ]
      }
    case 'DEL_MAIL':
      return {
        ...state,
        mails: state.mails.filter(item => item !== action.value)
      }
    case 'MAIL_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          [action.item]: action.value
        }
      }
    default:
      return state
  }
}

export default mail
