import clsx from 'clsx'
import React from 'react'
import styles from './TableCell.module.css'

const TableCellRed = ({ value }) => {
  return (
    <span
      className={
        clsx(
          styles.red,
          styles.bold
        )
      }
    >
      {value}
    </span>
  )
}

export default TableCellRed
