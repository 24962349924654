import clsx from 'clsx'
import React from 'react'
import {
  useSelector
} from 'react-redux'
import {
  makeStyles
} from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: (props) => (props.fit)
      ? undefined
      : '-8px 0px',
    width: (props) => (
      (props.full !== true) &&
      (props.desktop)
    )
      ? 336
      : '100%',
    '& .MuiFormControl-root': {
      margin: 8,
      width: (props) => (
        (props.mobile) ||
        (
          (props.full) &&
          (props.large !== true)
        )
      )
        ? 'calc(100% - 16px)'
        : (props.large)
          ? '40rem'
          : '20rem'
    }
  }
}))

const Form = ({
  children,
  className,
  ...others
}) => {
  const device = useSelector(state => state.device)
  const classes = useStyles({
    ...device,
    ...others
  })
  return (
    <div
      className={
        clsx(
          className,
          classes.root
        )
      }
    >
      {children}
    </div>
  )
}

export default Form
