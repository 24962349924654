import React from 'react'
import Form from '../Includes/Wrapper/Form'
import InputNom from '../Includes/Input/InputNom'
import {
  CheckTexte
} from '../../Functions/Check'

const SemaineRenameContent = ({
  state,
  Commit,
  Update
}) => {
  const Nom = (value) => {
    Update('nom', value)
    Update('existe', false)
    Update('error', (
      (value !== '') &&
      (!CheckTexte(value))
    ))
  }
  return (
    <Form>
      <InputNom
        setValue={Nom}
        value={state.nom}
        error={state.error}
        existe={state.existe}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            Commit()
          }
        }}
      />
    </Form>
  )
}

export default SemaineRenameContent
