import React from 'react'
import {
  useSelector
} from 'react-redux'

const TableRender = ({
  columns,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (
    <>
      {
        columns.filter(column =>
          (device.desktop) ||
          (device.mobile && column.mobile)
        ).map(column => {
          const Cell = column['component']
          return (
            <Cell
              device={device}
              key={column.id}
              label={column.label}
              action={column.action}
              target={column.target}
              {...others}
            />
          )
        })
      }
    </>
  )
}

export default TableRender
