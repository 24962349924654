import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellEnable = ({
  item,
  label,
  device
}) => {
  const value = (item.enable === 0)
    ? <span className="disabled-user">Désactivé</span>
    : 'Activé'
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={75}
        value={value}
      />
}

export default TableCellEnable
