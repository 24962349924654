import Switch from '../Includes/Misc/Switch'
import Bulle from '../Includes/Wrapper/Bulle'
import SettingOuvertureJour from './SettingOuvertureJour'
import React, {
  useEffect
} from 'react'
import {
  Grid
} from '@mui/material'
import {
  setting_ouverture_vide
} from '../../Functions/Settings'
import {
  ouverture as blank
} from '../../Variables/Variables'

const SettingOuvertureModalContent = ({
  state,
  coupure,
  setError,
  setState,
  setCoupure
}) => {
  const [picker, setPicker] = React.useState({
    am: {
      fin: false,
      debut: false
    },
    pm: {
      fin: false,
      debut: false
    }
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  useEffect(() => {
    setError(
      (picker.am.debut) ||
      (picker.pm.debut) ||
      (picker.am.fin) ||
      (picker.pm.fin)
    )
    // eslint-disable-next-line
  }, [picker])
  return (
    <Grid
      container
      spacing={0}
      columns={10}
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        xs={1}
        item={true}
      />
      <Grid
        xs={1}
        item={true}
      >
        <Bulle
          arrow={true}
          placement="top"
          title="Journée continue"
        >
          <span>
            <Switch
              checked={(!coupure)}
              onChange={() => setCoupure(!coupure)}
            />
          </span>
        </Bulle>
      </Grid>
      {
        (coupure)
          ? <>
              <Grid
                xs={4}
                item={true}
                className="grid-moment grid-am"
              >
                <span>Matin</span>
              </Grid>
              <Grid
                xs={4}
                item={true}
                className="grid-moment grid-pm"
              >
                <span>Après-midi</span>
              </Grid>
            </>
          : <Grid
              xs={8}
              item={true}
              className="grid-moment grid-journee"
            >
              <span>Journée</span>
            </Grid>
      }
      <SettingOuvertureJour
        jour="lundi"
        picker={picker}
        coupure={coupure}
        state={state.lundi}
        setPicker={setPicker}
        Delete={() => Update('lundi', blank)}
        setState={(value) => Update('lundi', value)}
        Duplicate={() => Update('mardi', state.lundi)}
        empty={setting_ouverture_vide(state.lundi, coupure)}
      />
      <SettingOuvertureJour
        jour="mardi"
        picker={picker}
        coupure={coupure}
        state={state.mardi}
        setPicker={setPicker}
        Delete={() => Update('mardi', blank)}
        setState={(value) => Update('mardi', value)}
        Duplicate={() => Update('mercredi', state.mardi)}
        empty={setting_ouverture_vide(state.mardi, coupure)}
      />
      <SettingOuvertureJour
        jour="mercredi"
        picker={picker}
        coupure={coupure}
        setPicker={setPicker}
        state={state.mercredi}
        Delete={() => Update('mercredi', blank)}
        setState={(value) => Update('mercredi', value)}
        Duplicate={() => Update('jeudi', state.mercredi)}
        empty={setting_ouverture_vide(state.mercredi, coupure)}
      />
      <SettingOuvertureJour
        jour="jeudi"
        picker={picker}
        coupure={coupure}
        state={state.jeudi}
        setPicker={setPicker}
        Delete={() => Update('jeudi', blank)}
        setState={(value) => Update('jeudi', value)}
        Duplicate={() => Update('vendredi', state.jeudi)}
        empty={setting_ouverture_vide(state.jeudi, coupure)}
      />
      <SettingOuvertureJour
        jour="vendredi"
        picker={picker}
        coupure={coupure}
        setPicker={setPicker}
        state={state.vendredi}
        Delete={() => Update('vendredi', blank)}
        setState={(value) => Update('vendredi', value)}
        Duplicate={() => Update('samedi', state.vendredi)}
        empty={setting_ouverture_vide(state.vendredi, coupure)}
      />
      <SettingOuvertureJour
        jour="samedi"
        picker={picker}
        coupure={coupure}
        state={state.samedi}
        setPicker={setPicker}
        Delete={() => Update('samedi', blank)}
        setState={(value) => Update('samedi', value)}
        Duplicate={() => Update('dimanche', state.samedi)}
        empty={setting_ouverture_vide(state.samedi, coupure)}
      />
      <SettingOuvertureJour
        jour="dimanche"
        picker={picker}
        coupure={coupure}
        setPicker={setPicker}
        state={state.dimanche}
        Delete={() => Update('dimanche', blank)}
        setState={(value) => Update('dimanche', value)}
        empty={setting_ouverture_vide(state.dimanche, coupure)}
      />
    </Grid>
  )
}

export default SettingOuvertureModalContent
