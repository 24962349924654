import React from 'react'
import TableCellDesktop from './TableCellDesktop'

const TableCellValeur = ({ item }) => {
  const value = (item.percent > 0)
    ? item.percent + '%'
    : (item.reduction > 0)
      ? item.reduction + '€'
      : (item.free > 0)
        ? item.free + ' jours'
        : 0
  return (
    <TableCellDesktop
      max={75}
      value={value}
      empty={(value === 0)}
    />
  )
}

export default TableCellValeur
