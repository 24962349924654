import TableCellNom from '../../Components/Includes/Table/TableCellNom'

export const nom = {
  id: "nom",
  sort: true,
  user: false,
  padding: true,
  label: "Nom",
  component: TableCellNom
}
