import clsx from 'clsx'
import React from 'react'
import styles from './TableCell.module.css'

const TableCellTitreMobile = ({
  red,
  value
}) => {
  return (
    <span className={styles.inline}>
      <span
        className={
          clsx({
            [styles.red]: red,
            [styles.cell]: true,
            [styles.line]: true,
            [styles.titre]: true
          })
        }
      >
        {value}
      </span>
    </span>
  )
}

export default TableCellTitreMobile
