import TableCellRecuperation from '../Components/Includes/Table/TableCellRecuperation'
import TableCellDateHeureFin from '../Components/Includes/Table/TableCellDateHeureFin'
import TableCellDateHeureDebut from '../Components/Includes/Table/TableCellDateHeureDebut'
import TableCellActionsDemandes from '../Components/Includes/Table/TableCellActionsDemandes'
import {
  fin
} from './Includes/Fin'
import {
  nom
} from './Includes/Nom'
import {
  debut
} from './Includes/Debut'
import {
  motif
} from './Includes/Motif'
import {
  nombre
} from './Includes/Nombre'
import {
  prenom
} from './Includes/Prenom'
import {
  status
} from './Includes/Status'
import {
  actions
} from './Includes/Actions'
import {
  demande
} from './Includes/Demande'
import {
  fonction
} from './Includes/Fonction'
import {
  device
} from '../Functions/Device'
import {
  MODAL_HEURES,
  UPDATE_HEURE
} from '../Redux/Actions/Heures'

const target = device()

export const properties = {
  type: {
    id: "heures",
    groupe: "demandes"
  },
  sort: {
    by: "demande",
    order: "desc"
  },
  columns: [
    {
      ...prenom
    },
    {
      ...nom
    },
    {
      ...fonction
    },
    {
      ...demande
    },
    {
      ...debut,
      mobile: true,
      component: (target.mobile)
        ? TableCellDateHeureDebut
        : debut.component
    },
    {
      ...fin,
      mobile: true,
      component: (target.mobile)
        ? TableCellDateHeureFin
        : fin.component
    },
    {
      ...motif,
      target: "heure"
    },
    {
      id: "recuperation",
      sort: true,
      mobile: true,
      padding: true,
      label: "Récupération",
      component: TableCellRecuperation
    },
    {
      ...nombre
    },
    {
      ...status,
      action: UPDATE_HEURE
    },
    {
      ...actions,
      action: MODAL_HEURES,
      component: TableCellActionsDemandes
    }
  ]
}
