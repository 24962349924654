import React from 'react'
import ButtonAdmin from './ButtonAdmin'

const ButtonAdminDisconnect = (props) => {
  return (
    <ButtonAdmin
      color="blue"
      icon="ExitToApp"
      title="Déconnecter"
      {...props}
    />
  )
}

export default ButtonAdminDisconnect
