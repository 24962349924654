import React from 'react'
import ButtonCompte from './ButtonCompte'

const ButtonCompteEdit = (props) => {
  return (
    <ButtonCompte
      icon="Edit"
      color="primary"
      title="Modifier"
      {...props}
    />
  )
}

export default ButtonCompteEdit
