import React from 'react'
import styles from './Badge.module.css'
import {
  makeStyles
} from '@mui/styles'
import {
  Badge as Bubble
} from '@mui/material'

const useStyles = makeStyles(() => ({
  badge: (props) => props
}))

const Badge = ({
  left,
  style,
  content,
  children,
  ...others
}) => {
  const classes = useStyles(style)
  return (
    <Bubble
      max={99}
      color="error"
      badgeContent={content}
      classes={{
        badge: (style)
          ? classes.badge
          : styles.badge
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: (left)
          ? 'left'
          : 'right'
      }}
      {...others}
    >
      {children}
    </Bubble>
  )
}

export default Badge
