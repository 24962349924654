import Slider from './Slider'
import styles from './SliderCreneau.module.css'
import HeurePicker from '../Picker/HeurePicker'
import SliderButtonAdd from './SliderButtonAdd'
import SliderButtonDel from './SliderButtonDel'
import React, {
  useEffect
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  get_day_time,
  get_day_time_max,
  get_day_time_min
} from '../../../Functions/Dates'
import {
  variables
} from '../../../Variables/Variables'

const SliderCreneau = ({
  error,
  state,
  Update,
  buttons,
  UpdateError
}) => {
  const device = useSelector(state => state.device)
  useEffect(() => {
    if (state.total === 2) {
      const intersect = (
        (state.am[1] <= state.pm[0]) ||
        (state.am[0] >= state.pm[1])
      )
      UpdateError('slider', !intersect)
    } else {
      UpdateError('slider', false)
    }
    // eslint-disable-next-line
  }, [state.am, state.pm, state.total])
  return (
    <>
      {
        (device.mobile)
          ? <div className={styles.flex}>
              <HeurePicker
                label="Début"
                onError={(error) => UpdateError('picker', (error !== null))}
                minTime={get_day_time(variables.date.debut.heure, variables.date.debut.minute)}
                value={get_day_time(Math.trunc(state.am[0]), (state.am[0] - Math.floor(state.am[0])) * 60)}
                maxTime={
                  (state.total === 1)
                    ? get_day_time_max(variables.date.fin.heure, variables.date.fin.minute)
                    : get_day_time_max(Math.trunc(state.pm[0]), (state.pm[0] - Math.floor(state.pm[0])) * 60)
                }
                onAccept={(value) => {
                  Update('am', [
                    value.hour() + (value.minute() / 60),
                    state.am[1]
                  ])
                }}
              />
              <HeurePicker
                label="Fin"
                onError={(error) => UpdateError('picker', (error !== null))}
                value={get_day_time(Math.trunc(state.am[1]), (state.am[1] - Math.floor(state.am[1])) * 60)}
                minTime={get_day_time_min(Math.trunc(state.am[0]), (state.am[0] - Math.floor(state.am[0])) * 60)}
                maxTime={
                  (state.total === 1)
                    ? get_day_time(variables.date.fin.heure, variables.date.fin.minute)
                    : get_day_time(Math.trunc(state.pm[0]), (state.pm[0] - Math.floor(state.pm[0])) * 60)
                }
                onAccept={(value) => {
                  Update('am', [
                    state.am[0],
                    value.hour() + (value.minute() / 60)
                  ])
                }}
              />
              {
                (buttons !== false)
                  ? <SliderButtonAdd
                      Update={Update}
                      total={state.total}
                    />
                  : null
              }
            </div>
          : <Slider
              input={true}
              value={state.am}
              wrong={error.slider}
              setValue={(value) => Update('am', value)}
              maxTime={
                (state.total === 2)
                  ? state.pm[0]
                  : undefined
              }
            >
              {
                (buttons !== false)
                  ? <SliderButtonAdd
                      Update={Update}
                      total={state.total}
                    />
                  : null
              }
            </Slider>
      }
      {
        (state.total === 2)
          ? (device.mobile)
            ? <div className={styles.flex}>
                <HeurePicker
                  label="Début"
                  onError={(error) => UpdateError('picker', (error !== null))}
                  maxTime={get_day_time_max(variables.date.fin.heure, variables.date.fin.minute)}
                  minTime={get_day_time(Math.trunc(state.am[1]), (state.am[1] - Math.floor(state.am[1])) * 60)}
                  value={get_day_time(Math.trunc(state.pm[0]), (state.pm[0] - Math.floor(state.pm[0])) * 60)}
                  onAccept={(value) => {
                    Update('pm', [
                      value.hour() + (value.minute() / 60),
                      state.pm[1]
                    ])
                  }}
                />
                <HeurePicker
                  label="Fin"
                  onError={(error) => UpdateError('picker', (error !== null))}
                  maxTime={get_day_time(variables.date.fin.heure, variables.date.fin.minute)}
                  minTime={get_day_time_min(Math.trunc(state.pm[0]), (state.pm[0] - Math.floor(state.pm[0])) * 60)}
                  value={get_day_time(Math.trunc(state.pm[1]), (state.pm[1] - Math.floor(state.pm[1])) * 60)}
                  onAccept={(value) => {
                    Update('pm', [
                      state.pm[0],
                      value.hour() + (value.minute() / 60)
                    ])
                  }}
                />
                {
                  (buttons !== false)
                    ? <SliderButtonDel
                        Update={Update}
                        UpdateError={UpdateError}
                      />
                    : null
                }
              </div>
            : <Slider
                input={true}
                value={state.pm}
                wrong={error.slider}
                minTime={state.am[1]}
                setValue={(value) => Update('pm', value)}
              >
                {
                  (buttons !== false)
                    ? <SliderButtonDel
                        Update={Update}
                        UpdateError={UpdateError}
                      />
                    : null
                }
              </Slider>
          : null
      }
      {
        (error.slider)
          ? <div className={styles.error}>Les deux créneaux ne doivent pas se chevaucher</div>
          : null
      }
    </>
  )
}

export default SliderCreneau
