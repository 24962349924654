import React from 'react'
import OfficesExplain from './OfficesExplain'
import styles from './OfficesTableExplain.module.css'
import {
  Table,
  TableContainer
} from '@mui/material'

const OfficesTableExplain = () => (
  <TableContainer>
    <Table>
      <caption className={styles.caption}>
        <OfficesExplain/>
      </caption>
    </Table>
  </TableContainer>
)

export default OfficesTableExplain
