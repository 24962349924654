import jwt_decode from 'jwt-decode'
import {
  CheckToken
} from '../Functions/Check'
import {
  variables
} from '../Variables/Variables'

const url = variables.url.server + variables.path.api + variables.page.token

export function save_token(item, value) {
  try {
    localStorage.setItem(item, value)
  } catch (error) {}
}

export function delete_token() {
  try {
    localStorage.removeItem('token')
    localStorage.removeItem('token_refresh')
  } catch (error) {}
}

export async function check_token() {
  const token = localStorage.getItem('token')
  if (
    (token === undefined) ||
    (token === null) ||
    (token === '')
  ) {
    return false
  }
  try {
    const { exp } = jwt_decode(token)
    if (Math.round(Date.now() / 1000 + 10) > exp) {
      const expire = await get_token()
      return expire
    } else {
      return true
    }
  } catch (error) {
    return false
  }
}

export async function get_token() {
  let headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', 'Bearer ' + localStorage.getItem('token_refresh'))
  const config = {
    method: 'POST',
    headers: headers
  }
  const response = await fetch(url, config)
  const data = await response.json()
  if (data.status === 'OK') {
    if (CheckToken(data.response.jwt)) {
      try {
        save_token('token', data.response.jwt)
        if (CheckToken(data.response.refresh)) {
          try {
            save_token('token_refresh', data.response.refresh)
          } catch (error) {}
        }
      } catch (error) {}
    }
    return true
  } else {
    return false
  }
}
