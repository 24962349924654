import clsx from 'clsx'
import React from 'react'
import styles from './Titre.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Typography
} from '@mui/material'

const Titre = ({
  children,
  className
}) => {
  const device = useSelector(state => state.device)
  return (
    <Typography
      variant="h6"
      component="div"
      className={
        clsx(
          className,
          styles.titre,
          (device.mobile)
            ? styles.mobile
            : styles.desktop
        )
      }
    >
      {children}
    </Typography>
  )
}

export default Titre
