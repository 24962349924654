import clsx from 'clsx'
import React from 'react'
import styles from './TableCell.module.css'
import {
  useSelector
} from 'react-redux'
import {
  get_user_long,
  get_user_short
} from '../../../Functions/Strings'

const TableCellTitreUser = ({
  item,
  long,
  type
}) => {
  const user = useSelector(state => state.login.user)
  return (
    (user.profil === 'administrateur') ||
    (user.profil === 'gestionnaire')
  )
    ? <span className={styles.inline}>
        <span
          className={
            clsx({
              [styles.cell]: true,
              [styles.line]: true,
              [styles.equipe]: (type.id === 'equipe'),
              [styles.user]: (type.groupe === 'demandes'),
              [styles.red]: (
                (
                  (type.id === 'equipe') &&
                  (item.mail === '')
                ) ||
                (
                  (type.id === 'users') &&
                  (item.enable === 0)
                )
              )
            })
          }
        >
          {
            (long)
              ? get_user_long(item)
              : get_user_short(item)
          }
        </span>
      </span>
    : null
}

export default TableCellTitreUser
