import dayjs from '../../Libraries/dayjs'

const initial = {
  search: '',
  selected: [],
  companies: [],
  loading: true,
  modal: {
    texte: '',
    open: false,
    action: undefined
  }
}

function companies(state = initial, action) {
  switch (action.type) {
    case 'BETA':
      return {
        ...state,
        companies: state.companies.map(item => {
          return (action.companies.includes(item.id))
            ? {
                ...item,
                beta: action.value
              }
            : item
        })
      }
    case 'COMPANIES':
      return {
        ...state,
        selected: [],
        companies: action.value
      }
    case 'COMPANIE_BLOCK':
      return {
        ...state,
        companies: state.companies.map(item => {
          return (action.companies.includes(item.id))
            ? {
                ...item,
                die: (action.value) ? dayjs().unix() : 0
              }
            : item
        })
      }
    case 'MODAL_COMPANIES':
      return {
        ...state,
        modal: {
          open: action.open,
          texte: action.texte,
          action: action.action
        }
      }
    case 'SEARCH_COMPANIE':
      return {
        ...state,
        selected: [],
        search: action.value
      }
    case 'LOADING_COMPANIES':
      return {
        ...state,
        loading: false
      }
    case 'SELECTED_COMPANIES':
      return {
        ...state,
        selected: action.value
      }
    default:
      return state
  }
}

export default companies
