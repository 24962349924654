import React from 'react'
import InputTextField from './InputTextField'
import {
  useSelector
} from 'react-redux'
import {
  CheckFloat
} from '../../../Functions/Check'

const InputHeures = ({
  error,
  value,
  ...others
}) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <InputTextField
      error={error}
      value={value}
      label="Heures"
      helperText={
        (error)
          ? (!CheckFloat(value))
            ? "Le nombre d'heures doit être un entier/décimal positif de la forme 123 ou 123.45"
            : (parseFloat(value) < settings.minimum.heures)
              ? "Le nombre d'heures doit être supérieur à " + settings.minimum.heures
              : (parseFloat(value) > settings.maximum.heures)
                ? "Le nombre d'heures doit être inférieur à " + settings.maximum.heures
                : null
          : null
      }
      {...others}
    />
  )
}

export default InputHeures
