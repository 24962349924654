import React from 'react'
import TableCellCheckbox from './TableCellCheckbox'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Select
} from '../../../Functions/Table'
import {
  SELECTED_USERS
} from '../../../Redux/Actions/Users'

const TableCellCheckboxUsers = ({ item }) => {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.users.selected)
  return (
    <TableCellCheckbox
      checked={selected.includes(item.id)}
      onClick={() => Select(dispatch, SELECTED_USERS, selected, item.id)}
    />
  )
}

export default TableCellCheckboxUsers
