import React from 'react'
import TableCellConges from './TableCellConges'

const TableCellCongesCurrent = ({
  item,
  label
}) => {
  return (
    <TableCellConges
      label={label}
      item={item.conges_current}
    />
  )
}

export default TableCellCongesCurrent
