import React from 'react'
import OfficesToolbarContent from './OfficesToolbarContent'

const OfficesToolbarMobile = () => {
  return (
    <div className="toolbar-actions-mobile">
      <OfficesToolbarContent/>
    </div>
  )
}

export default OfficesToolbarMobile
