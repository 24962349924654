import RoutesUser from './RoutesUser'
import RoutesAdmin from './RoutesAdmin'
import RoutesLogin from './RoutesLogin'
import RoutesOffice from './RoutesOffice'
import history from '../Includes/History'
import RoutesGestion from './RoutesGestion'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  check_token
} from '../../API/token'
import {
  Router
} from 'react-router-dom'
import {
  READY
} from '../../Redux/Actions/Menu'
import {
  USER_BACK
} from '../../Redux/Actions/User'

const Routes = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const ready = useSelector(state => state.menu.ready)
  const Login = async() => {
    const check = await check_token()
    if (check) {
      dispatch(USER_BACK())
    } else {
      dispatch(READY(true))
    }
  }
  useEffect(() => {
    Login()
    // eslint-disable-next-line
  }, [])
  return (ready)
    ? <Router history={history}>
        {
          (user.logged)
            ? (user.profil === 'gestionnaire')
              ? <RoutesGestion/>
              : (user.profil === 'administrateur')
                ? <RoutesAdmin/>
                : (user.profil === 'utilisateur')
                  ? <RoutesUser/>
                  : (user.profil === 'office')
                    ? <RoutesOffice/>
                    : null
            : <RoutesLogin/>
        }
      </Router>
    : null
}

export default Routes
