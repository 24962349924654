import React from 'react'
import MailForm from './MailForm'
import MailUsers from './MailUsers'
import MailToolbar from './MailToolbar'
import styles from '../Styles/Mail.module.css'
import Form from '../../Includes/Wrapper/Form'
import Root from '../../Includes/Wrapper/Root'
import {
  useSelector
} from 'react-redux'

const Mail = () => {
  const device = useSelector(state => state.device)
  return (
    <Root container={true}>
      <Form
        fit={true}
        full={true}
        className={
          (device.mobile)
            ? styles.mobile
            : styles.desktop
        }
      >
        {
          (device.desktop)
            ? <MailToolbar/>
            : null
        }
        <MailUsers/>
        <MailForm/>
        {
          (device.mobile)
            ? <MailToolbar/>
            : null
        }
      </Form>
    </Root>
  )
}

export default Mail
