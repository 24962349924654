import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'
import {
  sort_function
} from '../../../Functions/Sort'
import {
  start_case
} from '../../../Functions/Strings'

const SelectFonction = (props) => {
  const fonctions = useSelector(state => state.fonctions.fonctions)
  return (
    <SelectInput
      label="Fonction"
      options={sort_function(fonctions).map(item => {
        return {
          value: item.id,
          label: start_case(item.fonction)
        }
      })}
      {...props}
    />
  )
}

export default SelectFonction
