import React from 'react'
import EventDrop from './EventDrop'
import EventAppointment from './EventAppointment'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SET_EVENT
} from '../../../Redux/Actions/Planning'

const EventAppointmentComponentAdmin = (props) => {
  const { data } = props
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const target = useSelector(state => state.planning.event)
  return (
    <EventAppointment
      onClick={() => {
        dispatch(SET_EVENT(
          (data.id === target.id)
            ? {
                id: '',
                read: false
              }
            : {
                id: data.id,
                read: false,
                mobile: device.mobile
              }
        ))
      }}
      {...props}
    >
      <EventDrop data={data}/>
    </EventAppointment>
  )
}

export default EventAppointmentComponentAdmin
