import FAQ from './Components/FAQ'
import Demo from './Components/Demo'
import About from './Components/About'
import Tarifs from './Components/Tarifs'
import Explain from './Components/Explain'
import Absences from './Components/Absences'
import Features from './Components/Features'
import Salaries from './Components/Salaries'
import Animation from './Animation/Animation'
import Responsive from './Components/Responsive'
import Comptabilite from './Components/Comptabilite'
import Presentation from './Components/Presentation'
import Root from '../Components/Includes/Wrapper/Root'
import Footer from '../Components/Includes/Footer/Footer'
import React, {
  useEffect
} from 'react'
import {
  useDispatch
} from 'react-redux'
import {
  GET_LANDING
} from '../Redux/Actions/Landing'

const Page = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(GET_LANDING())
    // eslint-disable-next-line
  }, [])
  return (
    <Root>
      <Animation>
        <Presentation/>
        <Responsive/>
        <Demo/>
        <Features/>
        <Salaries/>
        <Absences/>
        <Comptabilite/>
        <Explain/>
        <Tarifs/>
        <About/>
        <FAQ/>
        <Footer mini={false}/>
      </Animation>
    </Root>
  )
}

export default Page
