import clsx from 'clsx'
import React from 'react'
import colors from './TableCellColors.module.css'
import styles from './TableDesktopRow.module.css'
import {
  useSelector
} from 'react-redux'
import {
  TableRow
} from '@mui/material'
import {
  Drag
} from '../Event/Event'

const TableDesktopRow = ({
  item,
  size,
  type,
  hover,
  bottom,
  device,
  pointer,
  children,
  selected,
  draggable
}) => {
  const user = useSelector(state => state.login.user)
  const notification = useSelector(state => state.notifications.target)
  const classes = {
    [styles.row]: true,
    [styles[size]]: true,
    [styles.bottom]: (bottom === false),
    [styles.opacity]: (
      (type.groupe === 'legendes') &&
      (item.selected === 0)
    )
  }
  return (draggable)
    ? <Drag
        id={item.id}
        className={clsx(classes)}
      >
        {children}
      </Drag>
    : <TableRow
        hover={(
          (hover !== false) &&
          (device.desktop)
        )}
        selected={(
          (selected) &&
          (selected.includes(item.id)) &&
          (type.id !== 'fonctions') &&
          (type.id !== 'salaries')
        )}
        className={
          clsx({
            ...classes,
            [styles.pointer]: pointer,
            [styles.mobile]: device.mobile,
            [styles.notification]: (notification !== ''),
            [colors.ko]: (
              (item.status === 0) &&
              (type.groupe === 'demandes')
            ),
            [colors.ok]: (
              (item.status === 1) &&
              (type.groupe === 'demandes')
            ),
            [styles.unread]: (
              (item.read) &&
              (type.id === 'notifications') &&
              (item.read.includes(user.unique_id_user) !== true)
            )
          })
        }
      >
        {children}
      </TableRow>
}

export default TableDesktopRow
