import React from 'react'
import styles from './TablePaginationActions.module.css'
import ButtonPaginationLast from '../Button/ButtonPaginationLast'
import ButtonPaginationNext from '../Button/ButtonPaginationNext'
import ButtonPaginationFirst from '../Button/ButtonPaginationFirst'
import ButtonPaginationPrevious from '../Button/ButtonPaginationPrevious'

const TablePaginationActions = ({
  page,
  count,
  rowsPerPage,
  onPageChange
}) => {
  return (
    <div className={styles.root}>
      <ButtonPaginationFirst
        disabled={(page === 0)}
        onClick={(event) => onPageChange(event, 0)}
      />
      <ButtonPaginationPrevious
        disabled={(page === 0)}
        onClick={(event) => onPageChange(event, page - 1)}
      />
      <ButtonPaginationNext
        onClick={(event) => onPageChange(event, page + 1)}
        disabled={(page >= Math.ceil(count / rowsPerPage) - 1)}
      />
      <ButtonPaginationLast
        disabled={(page >= Math.ceil(count / rowsPerPage) - 1)}
        onClick={(event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
      />
    </div>
  )
}

export default TablePaginationActions
