import React from 'react'
import SelectInput from '../Select/SelectInput'
import {
  useSelector
} from 'react-redux'

const FilterStatut = (props) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <SelectInput
      filter={true}
      label="Statut"
      options={settings.status.map((item, index) => {
        return {
          value: index,
          label: item
        }
      })}
      {...props}
    />
  )
}

export default FilterStatut
