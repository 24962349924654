import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const CODES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'CODES',
      value: value
    })
  }
}

export const OPEN_CODES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'OPEN_CODES',
      value: value
    })
  }
}

export const REMOVE_CODE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_CODE',
      value: value
    })
  }
}

export const SEARCH_CODE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SEARCH_CODE',
      value: value
    })
  }
}

export const SELECTED_CODES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SELECTED_CODES',
      value: value
    })
  }
}

export const GET_CODES = () => {
  return (dispatch) => {
    const data = JSON.stringify({
      action: 'codes'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(CODES(data.response))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const CREATE_CODE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'create-code'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(GET_CODES())
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const DELETE_CODE = (codes) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      codes: codes,
      action: 'delete-code'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(REMOVE_CODE(codes))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
