import React from 'react'
import ButtonAdmin from './ButtonAdmin'

const ButtonAdminDelete = (props) => {
  return (
    <ButtonAdmin
      color="red"
      title="Supprimer"
      icon="DeleteOutline"
      {...props}
    />
  )
}

export default ButtonAdminDelete
