import React from 'react'
import {
  Appointments
} from '@devexpress/dx-react-scheduler-material-ui'

const EventAppointmentBase = ({
  children,
  ...others
}) => {
  return (
    <Appointments.Appointment {...others}>
      {children}
    </Appointments.Appointment>
  )
}

export default EventAppointmentBase
