import React from 'react'
import Button from './Button'

const ButtonPaginationNext = (props) => {
  return (
    <Button
      title="Page suivante"
      icon="KeyboardArrowRight"
      {...props}
    />
  )
}

export default ButtonPaginationNext
