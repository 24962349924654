import React from 'react'
import Alert from '../Includes/Dialog/Alert'
import ButtonIconCheck from '../Includes/Button/ButtonIconCheck'
import {
  useDispatch
} from 'react-redux'
import {
  COMMIT
} from '../../Redux/Actions/Semaines'

const SemainesBarSave = ({
  open,
  Commit,
  setOpen
}) => {
  const dispatch = useDispatch()
  return (
    <>
      <Alert
        open={open}
        titre="Confirmation"
        Commit={() => dispatch(COMMIT(true))}
        Cancel={() => {
          setOpen(false)
          dispatch(COMMIT(false))
        }}
        texte={
          <span>
            <span className="block">Cette semaine présente des conflits.</span>
            <span className="block">Voulez-vous quand même enregistrer cette semaine ?</span>
          </span>
        }
      />
      <ButtonIconCheck
        onClick={() => Commit()}
      />
    </>
  )
}

export default SemainesBarSave
