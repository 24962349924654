import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from './Dialog'

const Alert = ({
  texte,
  titre,
  Cancel,
  Commit,
  ...others
}) => {
  return (
    <Dialog
      alert={true}
      fullScreen={false}
      {...others}
    >
      <DialogTitle>
        {titre}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {texte}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: Cancel,
            hidden: (Cancel === undefined)
          },
          {
            text: 'OK',
            onClick: Commit,
            color: 'primary'
          }
        ]}
      />
    </Dialog>
  )
}

export default Alert
