import React from 'react'
import ButtonCompte from './ButtonCompte'

const ButtonCompteFactures = (props) => {
  return (
    <ButtonCompte
      color="green"
      icon="Receipt"
      title="Factures"
      {...props}
    />
  )
}

export default ButtonCompteFactures
