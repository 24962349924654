import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellUtilisateurs = ({
  item,
  label,
  device
}) => {
  const value = item.users
  const object = {
    value: value,
    empty: (value === 1)
  }
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        {...object}
      />
    : <TableCellDesktop
        max={75}
        {...object}
      />
}

export default TableCellUtilisateurs
