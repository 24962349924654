import React from 'react'
import ToolbarMail from '../../Includes/Toolbar/ToolbarMail'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  MAIL,
  SEND_MAIL
} from '../../../Redux/Actions/Mail'
import {
  variables
} from '../../../Variables/Variables'

const MailToolbar = () => {
  const dispatch = useDispatch()
  const error = useSelector(state => state.mail.error)
  const mails = useSelector(state => state.mail.mails)
  const sujet = useSelector(state => state.mail.sujet)
  const texte = useSelector(state => state.mail.texte)
  const pending = useSelector(state => state.mail.pending)
  const Commit = () => {
    dispatch(MAIL('pending', true))
    dispatch(SEND_MAIL(
      mails,
      sujet,
      texte,
      () => {
        dispatch(MAIL('mail', ''))
        dispatch(MAIL('mails', []))
        dispatch(MAIL('users', []))
        dispatch(MAIL('sujet', ''))
        dispatch(MAIL('select', ''))
        dispatch(MAIL('companie', ''))
        dispatch(MAIL('pending', false))
        dispatch(MAIL('texte', variables.mail.texte))
      }
    ))
  }
  return (
    <ToolbarMail
      mails={mails}
      titre="Envoyer un mail"
      onClick={() => Commit()}
      disabled={(
        (mails.length > variables.mail.maximum) ||
        (mails.length === 0) ||
        (sujet === '') ||
        (texte === '') ||
        (error.sujet) ||
        (error.texte) ||
        (pending)
      )}
    />
  )
}

export default MailToolbar
