import React from 'react'
import Button from './Button'

const ButtonIconSend = (props) => {
  return (
    <Button
      icon="message"
      {...props}
    />
  )
}

export default ButtonIconSend
