import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'

const SelectTypeCompanie = (props) => {
  const types = useSelector(state => state.landing.types)
  return (
    <SelectInput
      label="Qui êtes-vous ?"
      options={Object.entries(types).map(([index, item]) => {
        return {
          value: index,
          label: item
        }
      })}
      {...props}
    />
  )
}

export default SelectTypeCompanie
