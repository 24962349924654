import clsx from 'clsx'
import React from 'react'
import styles from './AbonnementFeatures.module.css'

const AbonnementFeatures = ({
  error,
  modal,
  mobile,
  content,
  favorite,
  abonnement
}) => {
  return (
    <div
      className={
        clsx({
          [styles.box]: true,
          [styles.modal]: modal,
          [styles.mobile]: mobile,
          [styles.favorite]: favorite
        })
      }
    >
      {
        (error)
          ? content
          : <>
              <div
                className={
                  clsx(
                    styles.main,
                    styles.feature
                  )
                }
              >
                {abonnement.target}
              </div>
              {
                abonnement.features.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.feature}
                    >
                      {item}
                    </div>
                  )
                })
              }
            </>
      }
    </div>
  )
}

export default AbonnementFeatures
