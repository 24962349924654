import React from 'react'
import TableCellComptabilite from './TableCellComptabilite'
import {
  convert_heure
} from '../../../Functions/Heures'

const TableCellComptabiliteSupp = ({ item }) => {
  const value = item.supp
  return (
    <TableCellComptabilite
      item={value}
      value={convert_heure(value, (value < 0))}
    />
  )
}

export default TableCellComptabiliteSupp
