import React from 'react'
import styles from './TableHead.module.css'
import TableHeadCell from './TableHeadCell'
import TableCellCheckbox from './TableCellCheckbox'
import {
  TableRow,
  TableSortLabel,
  TableHead as Head
} from '@mui/material'

const TableHead = ({
  by,
  All,
  head,
  count,
  order,
  request,
  selected
}) => {
  return (
    <Head>
      <TableRow>
        {
          (selected !== undefined)
            ? <TableCellCheckbox
                onChange={All}
                checked={(
                  (count > 0) &&
                  (selected.length === count)
                )}
                indeterminate={(
                  (selected.length > 0) &&
                  (selected.length !== count)
                )}
              />
            : null
        }
        {
          head.filter(item => item.id !== 'checkbox').map(item => {
            return (item.sort)
              ? <TableHeadCell
                  item={item}
                  key={item.id}
                  sortDirection={
                    (by === item.id)
                      ? order
                      : false
                  }
                >
                  <TableSortLabel
                    active={(by === item.id)}
                    onClick={() => request(item.id)}
                    direction={
                      (by === item.id)
                        ? order
                        : 'asc'
                    }
                  >
                    {item.label}
                    {
                      (by === item.id)
                        ? <span className={styles.hidden}>
                            {
                              (order === 'desc')
                                ? 'descending'
                                : 'ascending'
                            }
                          </span>
                        : null
                    }
                  </TableSortLabel>
                </TableHeadCell>
              : <TableHeadCell
                  item={item}
                  key={item.id}
                >
                  {item.label}
                </TableHeadCell>
          })
        }
      </TableRow>
    </Head>
  )
}

export default TableHead
