import AbonnementModalContent from './AbonnementModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  config
} from '../../Variables/Variables'
import {
  CHANGE_ABONNEMENT
} from '../../Redux/Actions/Compte'
import {
  SIGNIN_ABONNEMENT
} from '../../Redux/Actions/Signin'

const AbonnementModal = ({
  open,
  setOpen
}) => {
  const dispatch = useDispatch()
  const [error, setError] = React.useState(false)
  const device = useSelector(state => state.device)
  const selected = useSelector(state => state.signin.abonnement)
  const Exit = () => {
    setTimeout(() => {
      dispatch(SIGNIN_ABONNEMENT(''))
    }, 500)
  }
  const Commit = () => {
    dispatch(CHANGE_ABONNEMENT(
      selected.unique_id_abonnement,
      () => dispatch(SIGNIN_ABONNEMENT(''))
    ))
  }
  useEffect(() => {
    if (open) {
      setError(false)
    }
    // eslint-disable-next-line
  }, [open])
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={(device.mobile) ? 'xs' : 'sm'}
      className={
        (device.mobile)
          ? 'abonnement-mobile-modal'
          : undefined
      }
    >
      <DialogTitle>Modifier votre abonnement</DialogTitle>
      <DialogContent>
        <AbonnementModalContent
          error={error}
          setError={setError}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => {
              setOpen(false)
              Exit()
            }
          },
          {
            hidden: config.test,
            onClick: () => {
              if (selected.unique_id_abonnement !== undefined) {
                setOpen(false)
                Commit()
              } else {
                setError(true)
              }
            }
          }
        ]}
      />
    </Dialog>
  )
}

export default AbonnementModal
