import React from 'react'
import Titre from '../../Includes/Misc/Titre'
import Search from '../../Includes/Search/Search'
import CompaniesToolbarButtons from './CompaniesToolbarButtons'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  SEARCH_COMPANIE
} from '../../../Redux/Actions/Companies'

const CompaniesToolbarDesktop = ({ data }) => {
  const dispatch = useDispatch()
  const search = useSelector(state => state.companies.search)
  return (
    <Toolbar>
      <Titre>
        Entreprises {
          (data.length > 0)
            ? '(' + data.length + ')'
            : ''
        }
      </Titre>
      <Search
        search={search}
        setSearch={(value) => dispatch(SEARCH_COMPANIE(value))}
      />
      <CompaniesToolbarButtons/>
    </Toolbar>
  )
}

export default CompaniesToolbarDesktop
