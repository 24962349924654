import clsx from 'clsx'
import React from 'react'
import Titre from '../Includes/Misc/Titre'
import styles from './SemainesBar.module.css'
import SemainesBarSave from './SemainesBarSave'
import SemainesBarClose from './SemainesBarClose'
import InputNom from '../Includes/Input/InputNom'
import SemainesSchedulerDate from './SemainesSchedulerDate'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  CheckTexte
} from '../../Functions/Check'
import {
  lower_case
} from '../../Functions/Strings'
import {
  ToolbarMobile,
  SwitcherComponent
} from '../Includes/Event/Event'
import {
  NOM,
  COMMIT
} from '../../Redux/Actions/Semaines'

const SemainesBar = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const device = useSelector(state => state.device)
  const nom = useSelector(state => state.semaines.nom)
  const view = useSelector(state => state.planning.view)
  const erreurs = useSelector(state => state.semaines.erreurs)
  const working = useSelector(state => state.semaines.working)
  const semaines = useSelector(state => state.semaines.semaines)
  const Nom = (value) => {
    dispatch(NOM({
      value: value,
      error: (
        (value !== '') &&
        (!CheckTexte(value))
      )
    }))
  }
  const Commit = () => {
    const existe = semaines.find(item => lower_case(item.nom) === lower_case(nom.value))
    if (existe === undefined) {
      if (erreurs.length > 0) {
        if (
          (working.edit) ||
          (
            (working.new) &&
            (nom.error === false)
          )
        ) {
          setOpen(true)
        } else {
          dispatch(NOM({
            ...nom,
            error: true
          }))
        }
      } else {
        dispatch(COMMIT(true))
      }
    } else {
      dispatch(NOM({
        ...nom,
        error: true
      }))
    }
  }
  return (
    <>
      {
        (device.mobile)
          ? <ToolbarMobile/>
          : null
      }
      <Toolbar className="paper-toolbar toolbar toolbar-actions toolbar-actions-semaines">
        <span className="paper-toolbar-left">
          <SwitcherComponent/>
          {
            (view === 'Day')
              ? <SemainesSchedulerDate/>
              : null
          }
        </span>
        {
          (working.new)
            ? <InputNom
                size="small"
                clear={true}
                setValue={Nom}
                error={nom.error}
                value={nom.value}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    Commit()
                  }
                }}
              />
            : <Titre
                className={
                  clsx(
                    styles.nom,
                    styles.title
                  )
                }
              >
                {working.nom}
              </Titre>
        }
        <span className="paper-toolbar-right">
          <SemainesBarClose/>
          {
            (
              (working.edit) ||
              (working.new)
            )
              ? <SemainesBarSave
                  open={open}
                  Commit={Commit}
                  setOpen={setOpen}
                />
              : null
          }
        </span>
      </Toolbar>
    </>
  )
}

export default SemainesBar
