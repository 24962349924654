import clsx from 'clsx'
import React from 'react'
import styles from './Dialog.module.css'
import {
  DialogContent
} from '@mui/material'

const Content = ({
  children,
  ...others
}) => {
  return (
    <DialogContent
      className={
        clsx(
          styles.dialog,
          styles.content
        )
      }
      {...others}
    >
      {children}
    </DialogContent>
  )
}

export default Content
