import React from 'react'
import UsersContent from './UsersContent'
import dayjs from '../../../Libraries/dayjs'
import Root from '../../Includes/Wrapper/Root'
import Alert from '../../Includes/Dialog/Alert'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  lower_case,
  sans_accents
} from '../../../Functions/Strings'
import {
  MODAL_USERS,
  SELECTED_USERS
} from '../../../Redux/Actions/Users'

const Users = () => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.users.modal)
  const users = useSelector(state => state.users.users)
  const search = useSelector(state => state.users.search)
  const filters = useSelector(state => state.filters.filters)
  const data = users.filter(item =>
    (
      lower_case(sans_accents(item.companie)).includes(lower_case(sans_accents(search))) ||
      lower_case(sans_accents(item.prenom)).includes(lower_case(sans_accents(search))) ||
      lower_case(sans_accents(item.mail)).includes(lower_case(sans_accents(search))) ||
      lower_case(sans_accents(item.nom)).includes(lower_case(sans_accents(search)))
    ) &&
    (
      ((item.companie === filters.users.companie) || (parseInt(filters.users.companie) === -1)) &&
      ((item.logged === filters.users.logged) || (parseInt(filters.users.logged) === -1)) &&
      ((item.profil === filters.users.profil) || (parseInt(filters.users.profil) === -1)) &&
      ((item.enable === filters.users.status) || (parseInt(filters.users.status) === -1)) &&
      (
        (dayjs.unix(item.login).add(filters.users.login, 'week').isSameOrAfter(dayjs())) ||
        (parseInt(filters.users.login) === -1) ||
        (item.login === filters.users.login)
      )
    )
  )
  return (
    <Root container={true}>
      {
        (users.length > 0)
          ? <Alert
              open={modal.open}
              titre="Confirmation"
              Cancel={() => dispatch(MODAL_USERS(false, '', undefined))}
              texte={<span className="block block-admin">{modal.texte}</span>}
              Commit={() => {
                dispatch(MODAL_USERS(false, '', undefined))
                dispatch(SELECTED_USERS([]))
                modal.action()
              }}
            />
          : null
      }
      <UsersContent data={data}/>
    </Root>
  )
}

export default Users
