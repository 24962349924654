import clsx from 'clsx'
import React from 'react'
import styles from './Input.module.css'
import InputTextField from './InputTextField'

const InputSlider = (props) => {
  return (
    <InputTextField
      disabled={true}
      InputLabelProps={{
        shrink: false
      }}
      className={
        clsx(
          styles.input,
          styles.value,
          styles.slider
        )
      }
      {...props}
    />
  )
}

export default InputSlider
