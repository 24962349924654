import React from 'react'
import styles from '../Styles/Mail.module.css'
import InputMail from '../../Includes/Input/InputMail'
import SelectMail from '../../Includes/Select/SelectMail'
import SelectCompanie from '../../Includes/Select/SelectCompanie'
import ButtonAddCircle from '../../Includes/Button/ButtonAddCircle'
import InputAutocomplete from '../../Includes/Input/InputAutocomplete'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  CheckMail
} from '../../../Functions/Check'
import {
  MAIL,
  ADD_MAIL,
  MAIL_ERROR
} from '../../../Redux/Actions/Mail'

const MailUsers = () => {
  const dispatch = useDispatch()
  const mail = useSelector(state => state.mail.mail)
  const error = useSelector(state => state.mail.error)
  const mails = useSelector(state => state.mail.mails)
  const users = useSelector(state => state.users.users)
  const select = useSelector(state => state.mail.select)
  const companie = useSelector(state => state.mail.companie)
  const utilisateurs = useSelector(state => state.mail.users)
  const liste = users.filter(item => item.mail !== '')
  const disabled = (
    (select === '') ||
    (
      (select === 3) &&
      (companie === '')
    ) ||
    (
      (select === 4) &&
      (utilisateurs.length === 0)
    ) ||
    (
      (select === 5) &&
      (
        (mail === '') ||
        (error.mail)
      )
    )
  )
  const Mail = (value) => {
    dispatch(MAIL('mail', value))
    dispatch(MAIL_ERROR('mail', (
      (value !== '') &&
      (!CheckMail(value))
    )))
  }
  const Add = () => {
    if (!disabled) {
      dispatch(MAIL('mail', ''))
      if (mails.indexOf(mail) < 0) {
        dispatch(ADD_MAIL(mail))
      }
    }
  }
  const Multiple = (array) => {
    dispatch(MAIL('users', []))
    dispatch(MAIL('mails', [
      ...mails,
      ...array.map(item => item.mail).filter(item => mails.indexOf(item) < 0)
    ]))
  }
  return (
    <Paper className={styles.paper}>
      <div className={styles.container}>
        <SelectMail
          value={select}
          setValue={(value) => dispatch(MAIL('select', value))}
        />
        <ButtonAddCircle
          disabled={disabled}
          className={styles.action}
          color={
            (disabled)
              ? "grey"
              : "blue"
          }
          onClick={() => {
            switch (select) {
              case 0:
                Multiple(liste.filter(item => item.titulaire))
              break
              case 1:
                Multiple(liste.filter(item => item.profil === 'administrateur'))
              break
              case 2:
                Multiple(liste)
              break
              case 3:
                Multiple(liste.filter(item => item.unique_id_companie === companie))
              break
              case 4:
                Multiple(utilisateurs)
              break
              case 5:
                Add()
              break
              default:
              break
            }
          }}
        />
      </div>
      {
        (select === 3)
          ? <SelectCompanie
              value={companie}
              setValue={(value) => dispatch(MAIL('companie', value))}
            />
          : (select === 4)
            ? <InputAutocomplete
                value={utilisateurs}
                label="Destinataires"
                onChange={(event, value) => dispatch(MAIL('users', value))}
                options={liste.filter(item => mails.indexOf(item.mail) < 0)}
              />
            : (select === 5)
              ? <InputMail
                  value={mail}
                  setValue={Mail}
                  error={error.mail}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      Add()
                    }
                  }}
                />
              : null
      }
    </Paper>
  )
}

export default MailUsers
