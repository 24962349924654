import React from 'react'
import InputTextField from './InputTextField'

const InputCompanie = ({
  error,
  ...others
}) => {
  return (
    <InputTextField
      error={error}
      label="Nom de l'entreprise"
      helperText={
        (error)
          ? "Ce nom est invalide"
          : null
      }
      {...others}
    />
  )
}

export default InputCompanie
