import React from 'react'
import CodesToolbarButtons from './CodesToolbarButtons'
import FilterAdmin from '../../Includes/Filter/FilterAdmin'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SET_FILTERS_TARGET
} from '../../../Redux/Actions/Filters'

const CodesToolbarMobile = ({ setPage }) => {
  const dispatch = useDispatch()
  const codes = useSelector(state => state.codes.codes)
  const filters = useSelector(state => state.filters.filters)
  return (
    <div className="toolbar-actions-mobile toolbar-actions-mobile-codes">
      <CodesToolbarButtons/>
      <FilterAdmin
        data={codes}
        label="codes"
        target="codes"
        setPage={setPage}
        state={filters.codes}
        setState={(value) => dispatch(SET_FILTERS_TARGET('codes', value))}
      />
    </div>
  )
}

export default CodesToolbarMobile
