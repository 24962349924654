import clsx from 'clsx'
import React from 'react'
import Bulle from '../Wrapper/Bulle'
import ButtonIcon from './ButtonIcon'
import styles from './Button.module.css'
import {
  IconButton
} from '@mui/material'

const Button = React.forwardRef(({
  icon,
  color,
  small,
  title,
  button,
  inherit,
  disabled,
  className,
  placement,
  ...others
}, ref) => {
  return (
    <Bulle
      arrow={true}
      title={title}
      disabled={disabled}
      placement={
        (placement)
          ? placement
          : 'top'
      }
    >
      <IconButton
        ref={ref}
        disabled={disabled}
        color={
          (inherit)
            ? 'inherit'
            : undefined
        }
        className={
          clsx({
            [className]: true,
            [styles.padding]: small
          })
        }
        {...others}
      >
        <ButtonIcon
          icon={icon}
          small={small}
          className={styles[color]}
          {...button}
        />
      </IconButton>
    </Bulle>
  )
})

export default Button
