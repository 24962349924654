import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'

const SelectEngagement = (props) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <SelectInput
      label="Engagement"
      options={settings.codes.engagements}
      {...props}
    />
  )
}

export default SelectEngagement
