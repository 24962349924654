import React from 'react'
import ButtonArrow from '../../Includes/Button/ButtonArrow'
import TableCellField from '../../Includes/Table/TableCellField'
import TableCellTitre from '../../Includes/Table/TableCellTitre'
import TableCellTitreCode from '../../Includes/Table/TableCellTitreCode'
import TableMobileRowTitre from '../../Includes/Table/TableMobileRowTitre'
import TableCellFieldCheckbox from '../../Includes/Table/TableCellFieldCheckbox'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Select
} from '../../../Functions/Table'
import {
  SELECTED_CODES
} from '../../../Redux/Actions/Codes'

const CodesMobileTitre = ({
  item,
  type,
  expanded,
  setExpanded
}) => {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.codes.selected)
  return (
    <TableMobileRowTitre
      item={item}
      type={type}
    >
      <TableCellField>
        <ButtonArrow
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </TableCellField>
      <TableCellTitre
        onClick={() => setExpanded(!expanded)}
      >
        <TableCellTitreCode item={item}/>
        {
          (item.percent > 0)
            ? item.percent + '%'
            : (item.reduction > 0)
              ? item.reduction + '€'
              : (item.free > 0)
                ? item.free + ' jours'
                : null
        }
      </TableCellTitre>
      <TableCellFieldCheckbox
        checked={(selected.includes(item.id))}
        onClick={() => Select(dispatch, SELECTED_CODES, selected, item.id)}
      />
    </TableMobileRowTitre>
  )
}

export default CodesMobileTitre
