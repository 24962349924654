import dayjs from '../../Libraries/dayjs'
import Alert from '../Includes/Dialog/Alert'
import PlanningModalSemaineContent from './PlanningModalSemaineContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_day_format
} from '../../Functions/Dates'
import {
  get_id
} from '../../Functions/Functions'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  ADD_CRENEAU_PLANNING
} from '../../Redux/Actions/Planning'

const PlanningModalSemaine = ({
  open,
  setOpen
}) => {
  const dispatch = useDispatch()
  const [id, setId] = React.useState('')
  const [popup, setPopup] = React.useState(false)
  const [commit, setCommit] = React.useState(false)
  const date = useSelector(state => state.planning.date)
  const planning = useSelector(state => state.planning.planning)
  const semaines = useSelector(state => state.semaines.semaines)
  const week = planning.find(item => item.week === first_day_format(date))
  const horaires = (week !== undefined)
    ? (week.horaires.length === 0)
      ? undefined
      : 0
    : undefined
  useEffect(() => {
    if (open) {
      setId('')
    }
    // eslint-disable-next-line
  }, [open])
  useEffect(() => {
    if (commit) {
      let existe
      let hour = false
      setCommit(false)
      const day = first_day_format(date)
      existe = planning.find(object => object.week === day)
      const semaine = semaines.find(object => object.id === id)
      if (existe !== undefined) {
        hour = (existe.horaires.filter(object => object.heure === true).length > 0)
        existe = (existe.horaires.filter(object => object.heure !== true).length > 0)
      }
      const data = semaine.horaires.map(item => {
        const fin = {
          heure: dayjs(item.endDate).hour(),
          minute: dayjs(item.endDate).minute(),
          jour: dayjs(item.endDate).weekday()
        }
        const debut = {
          heure: dayjs(item.startDate).hour(),
          minute: dayjs(item.startDate).minute(),
          jour: dayjs(item.startDate).weekday()
        }
        return {
          ...item,
          id: get_id(),
          startDate: dayjs(date)
            .add(debut.jour, 'days')
            .add(debut.heure, 'hours')
            .add(debut.minute, 'minutes')
            .toDate(),
          endDate: dayjs(date)
            .add(fin.jour, 'days')
            .add(fin.heure, 'hours')
            .add(fin.minute, 'minutes')
            .toDate()
        }
      })
      const value = (existe)
        ? data
        : {
            week: day,
            id: get_id(),
            horaires: data
          }
      dispatch(ADD_CRENEAU_PLANNING(value, existe, day, hour))
    }
    // eslint-disable-next-line
  }, [commit])
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>Semaines</DialogTitle>
      <DialogContent>
        <Alert
          open={popup}
          titre="Confirmation"
          Cancel={() => setPopup(false)}
          texte="Le planning n'est pas vide, voulez-vous vraiment ajouter une semaine supplémentaire ?"
          Commit={() => {
            setOpen(false)
            setPopup(false)
            setCommit(true)
          }}
        />
        <PlanningModalSemaineContent
          id={id}
          setId={setId}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            onClick: () => {
              if (id === '') {
                setOpen(false)
              } else {
                if (horaires !== undefined) {
                  setPopup(true)
                } else {
                  setOpen(false)
                  setCommit(true)
                }
              }
            }
          }
        ]}
      />
    </Dialog>
  )
}

export default PlanningModalSemaine
