const initial = {
  salaries: {
    by: 'salarie',
    order: 'asc'
  },
  fonctions: {
    by: 'fonction',
    order: 'asc'
  }
}

function sort(state = initial, action) {
  switch (action.type) {
    case 'SORT_SALARIES':
      return {
        ...state,
        salaries: (action.value)
          ? action.value
          : initial.salaries
      }
    case 'SORT_FONCTIONS':
      return {
        ...state,
        fonctions: (action.value)
          ? action.value
          : initial.fonctions
      }
    default:
      return state
  }
}

export default sort
