import clsx from 'clsx'
import React from 'react'
import dayjs from '../../../Libraries/dayjs'
import {
  useSelector
} from 'react-redux'
import {
  MonthView
} from '@devexpress/dx-react-scheduler-material-ui'

const EventMonthCell = ({
  today,
  holidays,
  startDate,
  otherMonth
}) => {
  const date = useSelector(state => state.absences.date)
  const holiday = (
    (dayjs(date).month() === dayjs(startDate).month()) &&
    (holidays[dayjs(startDate).date() - 1] === true)
  )
  return (
    <MonthView.TimeTableCell
      startDate={startDate}
      today={(today || holiday)}
      formatDate={(date) => dayjs(date).format('D')}
      className={
        clsx({
          'cell-month-today': today,
          'cell-month-other': otherMonth,
          'cell-month-today cell-month-ferie': holiday
        })
      }
    />
  )
}

export default EventMonthCell
