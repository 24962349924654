import React from 'react'
import InputTextField from './InputTextField'

const InputNom = ({
  clear,
  error,
  existe,
  uppercase,
  ...others
}) => {
  return (
    <InputTextField
      label="Nom"
      error={error}
      inputProps={{
        style: {
          textTransform: (uppercase)
            ? 'uppercase'
            : 'none'
        }
      }}
      helperText={
        (
          (error) &&
          (clear !== true)
        )
          ? (existe)
            ? "Ce nom est déjà utilisé"
            : "Ce nom est invalide"
          : null
      }
      {...others}
    />
  )
}

export default InputNom
