import React from 'react'
import dayjs from '../../Libraries/dayjs'
import ButtonArrow from '../Includes/Button/ButtonArrow'
import TableCellField from '../Includes/Table/TableCellField'
import TableCellTitre from '../Includes/Table/TableCellTitre'
import TableCellTitreUser from '../Includes/Table/TableCellTitreUser'
import TableMobileRowTitre from '../Includes/Table/TableMobileRowTitre'
import ActionSheetDemandes from '../Includes/Action/ActionSheetDemandes'
import {
  MODAL_INDEMNITES,
  UPDATE_INDEMNITE
} from '../../Redux/Actions/Indemnites'

const IndemnitesMobileTitre = ({
  item,
  type,
  expanded,
  setExpanded
}) => {
  return (
    <TableMobileRowTitre
      item={item}
      type={type}
    >
      <TableCellField>
        <ButtonArrow
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </TableCellField>
      <TableCellTitre
        onClick={() => setExpanded(!expanded)}
      >
        <TableCellTitreUser
          item={item}
          type={type}
        />
        {dayjs.unix(item.date).format('DD/MM/YYYY') + ' - ' + item.nombre + ' km'}
      </TableCellTitre>
      <TableCellField>
        <ActionSheetDemandes
          item={item}
          modal={MODAL_INDEMNITES}
          update={UPDATE_INDEMNITE}
        />
      </TableCellField>
    </TableMobileRowTitre>
  )
}

export default IndemnitesMobileTitre
