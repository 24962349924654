import {
  Box,
  Step,
  Stepper,
  StepLabel
} from '@mui/material'
import {
  steps
} from '../../Variables/Variables'

const SigninProgress = ({ step }) => {
  return (
    <Box className="stepper">
      <Stepper
        activeStep={step - 1}
        alternativeLabel={true}
      >
        {
          steps.map(item => {
            return (
              <Step key={item}>
                <StepLabel>{item}</StepLabel>
              </Step>
            )
          })
        }
      </Stepper>
    </Box>
  )
}

export default SigninProgress
