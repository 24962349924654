import React from 'react'
import Button from './Button'

const ButtonDeny = (props) => {
  return (
    <Button
      icon="Close"
      title="Refuser"
      {...props}
    />
  )
}

export default ButtonDeny
