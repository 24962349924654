import React from 'react'
import Root from '../../Includes/Wrapper/Root'
import Circle from '../../Includes/Accueil/Circle'
import {
  useSelector
} from 'react-redux'
import {
  Grid
} from '@mui/material'

const Accueil = () => {
  let total = 0
  const device = useSelector(state => state.device)
  const users = useSelector(state => state.users.users)
  const companies = useSelector(state => state.companies.companies)
  const spacing = (device.mobile) ? 1 : 2
  const xs = (device.mobile) ? 2 : 1
  companies.forEach(item => {
    total += item.total
  })
  return (
    (users.length > 0) &&
    (companies.length > 0)
  )
    ? <Root container={true}>
        <Grid
          container={true}
          columns={spacing}
          spacing={spacing}
        >
          <Grid
            xs={xs}
            item={true}
          >
            <Circle
              title="Entreprises"
              value={companies.length}
            />
          </Grid>
          <Grid
            xs={xs}
            item={true}
          >
            <Circle
              title="Utilisateurs"
              value={users.length}
            />
          </Grid>
          <Grid
            xs={xs}
            item={true}
          >
            <Circle
              title="Total par mois"
              value={Math.round(total) + '€'}
            />
          </Grid>
          <Grid
            xs={xs}
            item={true}
          >
            <Circle
              title="Salaire individuel net par mois"
              value={Math.round((total * 0.75) / 2) + '€'}
            />
          </Grid>
        </Grid>
      </Root>
    : null
}

export default Accueil
