import React from 'react'
import {
  Checkbox as Input
} from '@mui/material'

const Checkbox = (props) => {
  return (
    <Input
      color="secondary"
      {...props}
    />
  )
}

export default Checkbox
