import React from 'react'
import Factures from '../Factures/Factures'
import styles from './CompteFactures.module.css'
import ButtonCompteFactures from '../Includes/Button/ButtonCompteFactures'

const CompteFactures = () => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <Factures
        open={open}
        setOpen={setOpen}
      />
      <ButtonCompteFactures
        className={styles.button}
        onClick={() => setOpen(true)}
      />
    </>
  )
}

export default CompteFactures
