import React from 'react'
import ButtonArrow from '../Includes/Button/ButtonArrow'
import TableCellField from '../Includes/Table/TableCellField'
import TableCellTitre from '../Includes/Table/TableCellTitre'
import TableMobileRowTitre from '../Includes/Table/TableMobileRowTitre'
import TableCellTitreFacture from '../Includes/Table/TableCellTitreFacture'
import TableCellActionsFactures from '../Includes/Table/TableCellActionsFactures'

const FacturesMobileTitre = ({
  item,
  type,
  expanded,
  setExpanded
}) => {
  return (
    <TableMobileRowTitre
      item={item}
      type={type}
    >
      <TableCellField>
        <ButtonArrow
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </TableCellField>
      <TableCellTitre
        onClick={() => setExpanded(!expanded)}
      >
        <TableCellTitreFacture item={item}/>
      </TableCellTitre>
      <TableCellActionsFactures item={item}/>
    </TableMobileRowTitre>
  )
}

export default FacturesMobileTitre
