import React from 'react'
import Button from './Button'

const ButtonIconUser = (props) => {
  return (
    <Button
      icon="team"
      {...props}
    />
  )
}

export default ButtonIconUser
