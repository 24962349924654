import {
  USER_LOGOUT
} from './Login'
import {
  UPDATE_PLANNING,
  ADD_HEURE_PLANNING,
  MODIFY_HEURE_PLANNING,
  REMOVE_HEURE_PLANNING
} from './Planning'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const ADD_HEURE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_HEURE',
      value: value
    })
  }
}

export const INIT_HEURES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_HEURES',
      value: value
    })
  }
}

export const REMOVE_HEURE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_HEURE',
      value: value
    })
  }
}

export const MODIFY_HEURE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'MODIFY_HEURE',
      value: value
    })
  }
}

export const STATUS_HEURE = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: 'STATUS_HEURE',
      value: value,
      id: id
    })
  }
}

export const REMOVE_HEURES_USER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_HEURES_USER',
      value: value
    })
  }
}

export const EDIT_PLANNING_HEURE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_PLANNING_HEURE',
      value: value
    })
  }
}

export const MODAL_HEURES = (id, open, nombre, data) => {
  return (dispatch) => {
    dispatch({
      type: 'MODAL_HEURES',
      nombre: nombre,
      data: data,
      open: open,
      id: id
    })
  }
}

export const DELETE_HEURE = (id) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    const data = JSON.stringify({
      id: id,
      action: 'delete-heure'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const heures = state().heures.heures
          const ancien = heures.find(item => item.id === id)
          dispatch(REMOVE_HEURE_PLANNING(id, ancien))
          dispatch(REMOVE_HEURE(id))
          if (ancien.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const EDIT_HEURE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    const data = JSON.stringify({
      value: value,
      action: 'edit-heure'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const heures = state().heures.heures
          const ancien = heures.find(item => item.id === value.id)
          dispatch(MODIFY_HEURE_PLANNING(value, ancien))
          dispatch(MODIFY_HEURE(value))
          if (value.status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CREATE_HEURE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'create-heure'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          value.forEach(item => {
            dispatch(ADD_HEURE(item))
            if (item.status === 1) {
              dispatch(ADD_HEURE_PLANNING(item))
            }
          })
          if (value[0].status === 1) {
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_HEURE = (id, value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    const data = JSON.stringify({
      id: id,
      value: value,
      action: 'update-heure'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(STATUS_HEURE(id, value))
          if (value === 1) {
            const heures = state().heures.heures
            const heure = heures.find(item => item.id === id)
            dispatch(ADD_HEURE_PLANNING(heure))
            dispatch(UPDATE_PLANNING())
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
