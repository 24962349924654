import React from 'react'
import TableData from '../Includes/Table/TableData'
import AbsencesMobileTitre from './AbsencesMobileTitre'
import FilterMobile from '../Includes/Filter/FilterMobile'
import AbsencesToolbarMobile from './AbsencesToolbarMobile'
import FilterDesktop from '../Includes/Filter/FilterDesktop'
import AbsencesToolbarDesktop from './AbsencesToolbarDesktop'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  properties
} from '../../Settings/Absences'
import {
  SET_FILTERS_TARGET
} from '../../Redux/Actions/Filters'

const AbsencesContent = ({
  data,
  total
}) => {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const [filter, setFilter] = React.useState(false)
  const device = useSelector(state => state.device)
  const notification = useSelector(state => state.notifications.target)
  return (
    <>
      {
        (device.mobile)
          ? <AbsencesToolbarMobile
              setPage={setPage}
              setFilter={setFilter}
            />
          : null
      }
      <Paper>
        {
          (device.desktop)
            ? <AbsencesToolbarDesktop/>
            : null
        }
        {
          (total > 0)
            ? <>
                {
                  (notification === '')
                    ? (device.mobile)
                      ? <FilterMobile
                          open={filter}
                          page="absences"
                          label="absences"
                          setting="absence"
                          setOpen={setFilter}
                          Commit={(state) => {
                            setPage(0)
                            dispatch(SET_FILTERS_TARGET('absences', state))
                          }}
                        />
                      : <FilterDesktop
                          page="absences"
                          setting="absence"
                          setPage={setPage}
                        />
                    : null
                }
                <TableData
                  data={data}
                  page={page}
                  setPage={setPage}
                  titre={AbsencesMobileTitre}
                  pagination={(
                    (data.length > 0) &&
                    (notification === '')
                  )}
                  {...properties}
                />
              </>
            : null
        }
      </Paper>
    </>
  )
}

export default AbsencesContent
