import React from 'react'
import ButtonAdd from '../Includes/Button/ButtonAdd'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  get_id
} from '../../Functions/Functions'
import {
  get_ouverture,
  get_ouverture_semaine
} from '../../Functions/Ouverture'
import {
  initial
} from '../../Variables/Variables'
import {
  SET_WORKING,
  EDIT_CURRENT
} from '../../Redux/Actions/Semaines'

const SemainesToolbarContent = () => {
  const dispatch = useDispatch()
  const ouverture = useSelector(state => state.ouverture.ouverture)
  const Semaine = (empty) => {
    if (empty) {
      dispatch(EDIT_CURRENT(initial.empty.horaires))
    } else {
      dispatch(EDIT_CURRENT(
        (isDifferent([ouverture], [get_ouverture()]))
          ? get_ouverture_semaine(ouverture)
          : initial.semaine.horaires.map(item => {
              return {
                ...item,
                id: get_id()
              }
            })
      ))
    }
    dispatch(SET_WORKING({
      id: '',
      nom: '',
      new: true,
      open: true,
      edit: false,
      read: false
    }))
  }
  return (
    <>
      <ButtonAdd
        post={true}
        mobile="Pré-remplie"
        title="Semaine pré-remplie"
        onClick={() => Semaine(false)}
      />
      <ButtonAdd
        mobile="Vide"
        title="Semaine vierge"
        onClick={() => Semaine(true)}
      />
    </>
  )
}

export default SemainesToolbarContent
