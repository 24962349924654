import clsx from 'clsx'
import React from 'react'
import PlanningSelect from './PlanningSelect'
import styles from './PlanningModal.module.css'
import PlanningModalSeparation from './PlanningModalSeparation'
import {
  useSelector
} from 'react-redux'
import {
  FormGroup
} from '@mui/material'
import {
  sort_name
} from '../../Functions/Sort'
import {
  get_user_long
} from '../../Functions/Strings'

const PlanningModalSalarieContent = ({
  users,
  setUsers
}) => {
  const equipe = useSelector(state => state.equipe.equipe)
  const Toggle = (id) => {
    setUsers(
      (users.includes(id))
        ? users.filter(item => item !== id)
        : [ ...users, id ]
    )
  }
  return (
    <>
      <PlanningSelect
        None={() => setUsers([])}
        All={() => setUsers(equipe.map(item => item.id))}
      />
      <PlanningModalSeparation/>
      {
        (equipe.length > 0)
          ? <div className="inline-team">
              <FormGroup
                row={true}
                className="modal-team"
              >
                {
                  sort_name(equipe).map(item => {
                    return (
                      <div
                        key={item.id}
                        onClick={() => Toggle(item.id)}
                        className={
                          clsx({
                            'item-team': true,
                            [styles.selected]: users.includes(item.id)
                          })
                        }
                      >
                        {get_user_long(item)}
                      </div>
                    )
                  })
                }
              </FormGroup>
            </div>
          : null
      }
    </>
  )
}

export default PlanningModalSalarieContent
