import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const ADD_MAIL = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_MAIL',
      value: value
    })
  }
}

export const DEL_MAIL = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_MAIL',
      value: value
    })
  }
}

export const MAIL = (item, value) => {
  return (dispatch) => {
    dispatch({
      type: 'MAIL',
      value: value,
      item: item
    })
  }
}

export const MAIL_ERROR = (item, value) => {
  return (dispatch) => {
    dispatch({
      type: 'MAIL_ERROR',
      value: value,
      item: item
    })
  }
}

export const SEND_MAIL = (mails, sujet, texte, callback) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      mails: mails,
      sujet: sujet,
      texte: texte,
      action: 'send-mail'
    })
    api(data)
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}
