import React from 'react'
import styles from './Compte.module.css'
import dayjs from '../../Libraries/dayjs'
import Titre from '../Includes/Misc/Titre'
import CompteFactures from './CompteFactures'
import AbonnementModal from '../Abonnements/AbonnementModal'
import ButtonCompteEdit from '../Includes/Button/ButtonCompteEdit'
import {
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'
import {
  Update,
  LocalOffer
} from '@mui/icons-material'

const CompteAbonnement = ({
  days,
  trial
}) => {
  const [open, setOpen] = React.useState(false)
  const device = useSelector(state => state.device)
  const stripe = useSelector(state => state.stripe.stripe)
  const factures = useSelector(state => state.stripe.factures)
  const abonnement = useSelector(state => state.stripe.abonnement)
  const s = (days > 1) ? 's' : ''
  return (
    <>
      <AbonnementModal
        open={open}
        setOpen={setOpen}
      />
      <Paper
        className={
          (device.mobile)
            ? styles.mobile
            : styles.desktop
        }
      >
        <Toolbar>
          <Titre>Mon abonnement</Titre>
          {
            (
              (factures !== false) &&
              (factures !== '')
            )
              ? (factures.length > 0)
                ? <CompteFactures/>
                : null
              : null
          }
          <ButtonCompteEdit
            onClick={() => setOpen(true)}
          />
        </Toolbar>
        <div className="form-abonnement">
          <div className="abonnement-details">
            <img
              width={50}
              height={50}
              alt={abonnement.name}
              className="abonnement-icone"
              src={require('../../Images/Abonnements/' + abonnement.name + '.png').default}
            />
            <span className="abonnement-month">{stripe.price}€/mois</span>
            {
              (trial)
                ? <div className="trial-abonnement">
                    <div>
                      <LocalOffer className="trial-icone-abonnement"/>
                      <span>{days} jour{s} gratuit{s} restant{s}</span>
                    </div>
                  </div>
                : (
                    (stripe.current_period_end !== undefined) &&
                    (stripe.current_period_end !== null)
                  )
                    ? <div className="period-abonnement">
                        <div>
                          <Update className="period-icone-abonnement"/>
                          <span>
                            {
                              (device.desktop)
                                ? 'Prochain paiement le '
                                : null
                            }
                            {dayjs.unix(stripe.current_period_end).add(1, 'days').format('DD/MM/YYYY')}
                          </span>
                        </div>
                      </div>
                    : null
            }
          </div>
        </div>
      </Paper>
    </>
  )
}

export default CompteAbonnement
