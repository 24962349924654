import React from 'react'
import TableCellDesktop from './TableCellDesktop'

const TableCellField = ({
  children,
  ...others
}) => {
  return (
    <TableCellDesktop
      width={30}
      center={true}
      {...others}
    >
      {children}
    </TableCellDesktop>
  )
}

export default TableCellField
