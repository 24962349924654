import clsx from 'clsx'
import CompteDie from './CompteDie'
import CompteCard from './CompteCard'
import CompteMail from './CompteMail'
import dayjs from '../../Libraries/dayjs'
import CompteDelete from './CompteDelete'
import Root from '../Includes/Wrapper/Root'
import ComptePassword from './ComptePassword'
import CompteAbonnement from './CompteAbonnement'
import CompteEngagement from './CompteEngagement'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  GET_LANDING
} from '../../Redux/Actions/Landing'

const Compte = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const die = useSelector(state => state.login.die)
  const user = useSelector(state => state.login.user)
  const card = useSelector(state => state.stripe.card)
  const stripe = useSelector(state => state.stripe.stripe)
  const abonnement = useSelector(state => state.stripe.abonnement)
  const engagement = useSelector(state => state.compte.engagement)
  const days = dayjs.unix(stripe.trial_end).endOf('day').diff(dayjs(), 'days')
  const trial = (
    (days > 0) &&
    (stripe.trial_end !== '') &&
    (stripe.trial_end >= dayjs().unix())
  )
  useEffect(() => {
    dispatch(GET_LANDING())
    // eslint-disable-next-line
  }, [])
  return (
    <Root
      container={true}
      className={
        clsx({
          'page-container-compte': true,
          'page-container-compte-die': die,
          'page-container-compte-mobile': device.mobile
        })
      }
    >
      {
        (!die)
          ? <>
              <CompteMail/>
              <ComptePassword/>
            </>
          : null
      }
      {
        (
          (card !== false) &&
          (card !== '')
        )
          ? <CompteCard/>
          : null
      }
      {
        (
          (abonnement.name !== null) &&
          (abonnement !== '') &&
          (!die)
        )
          ? <CompteAbonnement
              days={days}
              trial={trial}
            />
          : null
      }
      {
        (
          (engagement !== undefined) &&
          (user.profil === 'administrateur')
        )
          ? (
              (engagement === null) ||
              (
                (dayjs().isAfter(dayjs.unix(engagement.date).endOf('day'))) ||
                (
                  (trial) &&
                  (engagement.essai === 1)
                )
              )
            )
              ? <CompteDelete/>
              : <CompteEngagement/>
          : null
      }
      {
        (die)
          ? <CompteDie/>
          : null
      }
    </Root>
  )
}

export default Compte
