import clsx from 'clsx'
import React from 'react'
import styles from './AbonnementPrix.module.css'

const AbonnementPrix = ({
  mobile,
  favorite,
  promotion,
  abonnement
}) => {
  return (
    <div
      className={
        clsx({
          [styles.mobile]: mobile,
          [styles.container]: true,
          [styles.favorite]: favorite,
          [styles.promotion]: (promotion > 0)
        })
      }
    >
      <div className={styles.name}>
        {abonnement.name}
      </div>
      {
        (promotion > 0)
          ? <div className={styles.prix}>
              <span className={styles.old}>{abonnement.price}€</span>
              <span>{promotion}€</span>
            </div>
          : <div className={styles.prix}>{abonnement.price}€</div>
      }
      <div className={styles.duration}>par mois</div>
    </div>
  )
}

export default AbonnementPrix
