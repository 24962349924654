import Bulle from '../Components/Includes/Wrapper/Bulle'
import SettingsOuverture from '../Components/Settings/SettingsOuverture'
import {
  get_time
} from './Heures'
import {
  isDifferent
} from './Compare'
import {
  get_ouverture,
  get_open_ouverture
} from './Ouverture'

export function setting_ouverture_prepare(object, coupure) {
  return (coupure)
    ? object
    : {
        ...object,
        pm: {
          debut: {
            h: null,
            m: null
          },
          fin: {
            h: null,
            m: null
          }
        }
      }
}

export function setting_ouverture_same(object) {
  return (
    (
      (object.am.debut.h === object.am.fin.h) &&
      (object.am.debut.m === object.am.fin.m) &&
      (object.am.debut.h !== null) &&
      (object.am.debut.m !== null)
    ) ||
    (
      (object.pm.debut.h === object.pm.fin.h) &&
      (object.pm.debut.m === object.pm.fin.m) &&
      (object.pm.debut.h !== null) &&
      (object.pm.debut.m !== null)
    )
  )
}

export function setting_ouverture_vide(object, coupure) {
  const am = (
    (object.am.debut.h === null) &&
    (object.am.debut.m === null) &&
    (object.am.fin.h === null) &&
    (object.am.fin.m === null)
  )
  const pm = (
    (object.pm.debut.h === null) &&
    (object.pm.debut.m === null) &&
    (object.pm.fin.h === null) &&
    (object.pm.fin.m === null)
  )
  return (coupure)
    ? (am && pm)
    : (am)
}

export function setting_ouverture_valide(object, coupure) {
  return (
    (
      (setting_ouverture_vide(object, coupure))
    ) ||
    (
      (object.am.debut.h !== null) &&
      (object.am.debut.m !== null) &&
      (object.pm.debut.h === null) &&
      (object.pm.debut.m === null) &&
      (object.am.fin.h !== null) &&
      (object.am.fin.m !== null) &&
      (object.pm.fin.h === null) &&
      (object.pm.fin.m === null)
    ) ||
    (
      (object.am.debut.h === null) &&
      (object.am.debut.m === null) &&
      (object.pm.debut.h !== null) &&
      (object.pm.debut.m !== null) &&
      (object.am.fin.h === null) &&
      (object.am.fin.m === null) &&
      (object.pm.fin.h !== null) &&
      (object.pm.fin.m !== null)
    ) ||
    (
      (object.am.debut.h !== null) &&
      (object.am.debut.m !== null) &&
      (object.pm.debut.h !== null) &&
      (object.pm.debut.m !== null) &&
      (object.am.fin.h !== null) &&
      (object.am.fin.m !== null) &&
      (object.pm.fin.h !== null) &&
      (object.pm.fin.m !== null)
    )
  )
}

export function get_valeur(item, ouverture) {
  let value
  if (item.regex === 'bool') {
    const e = (item.genre === 0)
      ? 'e'
      : ''
    value = (item.value === 1)
      ? 'Activé' + e
      : 'Désactivé' + e
  } else if (item.id === 'ouverture') {
    value = (isDifferent([ouverture], [get_ouverture()]))
      ? <Bulle
          arrow={true}
          placement="left"
          title={<SettingsOuverture/>}
        >
          <span className="setting-info-ouverture">
            {get_time(get_open_ouverture(ouverture))}
          </span>
        </Bulle>
      : ''
  } else {
    value = item.value
  }
  return value
}
