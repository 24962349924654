import React from 'react'
import OfficesExplain from './OfficesExplain'
import TableData from '../Includes/Table/TableData'
import OfficesTableExplain from './OfficesTableExplain'
import OfficesToolbarMobile from './OfficesToolbarMobile'
import OfficesToolbarDesktop from './OfficesToolbarDesktop'
import {
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  properties
} from '../../Settings/Offices'

const OfficesContent = () => {
  const device = useSelector(state => state.device)
  const offices = useSelector(state => state.offices.offices)
  const maximum = useSelector(state => state.compte.maximum.offices)
  return (
    <>
      {
        (
          (device.mobile) &&
          (maximum !== -1) &&
          (offices.length < maximum)
        )
          ? <OfficesToolbarMobile/>
          : null
      }
      <Paper>
        {
          (device.desktop)
            ? <OfficesToolbarDesktop/>
            : null
        }
        {
          (offices.length > 0)
            ? <TableData
                data={offices}
                pagination={device.desktop}
                size={
                  (device.desktop)
                    ? "large"
                    : "small"
                }
                {...properties}
              >
                <caption>
                  <div>
                    <OfficesExplain/>
                    {
                      (offices.length === maximum)
                        ? <div>Vous avez atteint le nombre maximum de comptes office.</div>
                        : null
                    }
                  </div>
                </caption>
              </TableData>
            : (device.desktop)
              ? <OfficesTableExplain/>
              : null
        }
      </Paper>
    </>
  )
}

export default OfficesContent
