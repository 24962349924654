import React from 'react'
import InputSetting from '../Includes/Input/InputSetting'
import SelectSetting from '../Includes/Select/SelectSetting'

const SettingModalContent = (props) => {
  const { values } = props
  switch (values.type) {
    case 'text':
      return <InputSetting {...props}/>
    case 'select':
      return <SelectSetting {...props}/>
    default:
      return null
  }
}

export default SettingModalContent
