import TableCellMontant from '../../Components/Includes/Table/TableCellMontant'

export const montant = {
  id: "montant",
  sort: true,
  mobile: true,
  padding: true,
  label: "Montant",
  component: TableCellMontant
}
