import React from 'react'
import styles from './NotificationsToolbar.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Button
} from '@mui/material'
import {
  Drafts
} from '@mui/icons-material'
import {
  NOTIFICATIONS,
  SELECTED_NOTIFICATIONS
} from '../../Redux/Actions/Notifications'

const NotificationsToolbarRead = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const selected = useSelector(state => state.notifications.selected)
  return (
    <Button
      color="primary"
      variant="contained"
      className={styles.read}
      disabled={(selected.length === 0)}
      onClick={() => {
        dispatch(NOTIFICATIONS(selected, user.unique_id_user, 1))
        dispatch(SELECTED_NOTIFICATIONS([]))
      }}
      startIcon={
        (device.desktop)
          ? <Drafts/>
          : undefined
      }
    >
      {
        (device.mobile)
          ? <Drafts/>
          : 'Marquer comme lu(s)'
      }
    </Button>
  )
}

export default NotificationsToolbarRead
