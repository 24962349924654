import React from 'react'
import Button from './Button'

const ButtonIconHeure = (props) => {
  return (
    <Button
      icon="heures"
      title="Déclarer une heure"
      {...props}
    />
  )
}

export default ButtonIconHeure
