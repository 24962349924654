import TableCellLegendeVides from '../../Components/Includes/Table/TableCellLegendeVides'

export const properties = {
  type: {
    id: "vides",
    groupe: "legendes"
  },
  sort: {
    by: "",
    order: ""
  },
  columns: [
    {
      id: "vide",
      padding: true,
      component: TableCellLegendeVides
    }
  ]
}
