import React from 'react'
import Button from './Button'

const ButtonIconDay = (props) => {
  return (
    <Button
      icon="day"
      {...props}
    />
  )
}

export default ButtonIconDay
