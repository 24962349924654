import clsx from 'clsx'
import React from 'react'
import UserColor from '../User/UserColor'
import styles from './CreneauLigne.module.css'
import {
  Warning
} from '@mui/icons-material'
import {
  start_case,
  upper_case
} from '../../../Functions/Strings'

const CreneauLigne = ({
  full,
  item,
  empty,
  heure,
  absent,
  content
}) => {
  return (
    <UserColor
      key={item.id}
      color={item.color}
      className={content}
    >
      <>
        {
          (heure)
            ? <i className="icon heures heures-planning"/>
            : (absent)
              ? <Warning
                  className={
                    clsx(
                      'icon-absent',
                      styles.warning
                    )
                  }
                />
              : null
        }
        {
          (empty)
            ? null
            : <>
                <span><span/>{start_case(item.prenom)} </span>
                {
                  (full)
                    ? <span>{upper_case(item.nom)}<span/></span>
                    : <span>{start_case(item.nom[0])}.<span/></span>
                }
              </>
        }
      </>
    </UserColor>
  )
}

export default CreneauLigne
