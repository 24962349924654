import React from 'react'
import AbsencesPlanningToolbarContent from './AbsencesPlanningToolbarContent'

const AbsencesPlanningToolbarMobile = () => {
  return (
    <div className="toolbar-actions-mobile">
      <AbsencesPlanningToolbarContent/>
    </div>
  )
}

export default AbsencesPlanningToolbarMobile
