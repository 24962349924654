import React from 'react'
import Alert from '../Includes/Dialog/Alert'
import ButtonDelete from '../Includes/Button/ButtonDelete'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  get_valeur
} from '../../Functions/Settings'
import {
  UPDATE_SETTING
} from '../../Redux/Actions/Settings'
import {
  DELETE_OUVERTURE
} from '../../Redux/Actions/Ouverture'

const SettingsTableDelete = ({ item }) => {
  const dispatch = useDispatch()
  const [confirm, setConfirm] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const ouverture = useSelector(state => state.ouverture.ouverture)
  return (
    (get_valeur(item, ouverture) !== '') &&
    (
      (item.id === 'comptable') ||
      (item.id === 'ouverture')
    )
  )
    ? <>
        <Alert
          open={confirm}
          setOpen={setConfirm}
          titre="Confirmation"
          Cancel={() => setConfirm(false)}
          texte="Voulez-vous vraiment supprimer l'adresse mail du comptable ?"
          Commit={() => {
            setConfirm(false)
            dispatch(UPDATE_SETTING({
              value: '',
              id: 'comptable',
              action: 'update-comptable'
            }))
          }}
        />
        <Alert
          open={confirmOpen}
          titre="Confirmation"
          setOpen={setConfirmOpen}
          Cancel={() => setConfirmOpen(false)}
          texte="Voulez-vous vraiment supprimer les horaires d'ouverture ?"
          Commit={() => {
            setConfirmOpen(false)
            dispatch(DELETE_OUVERTURE())
          }}
        />
        <ButtonDelete
          onClick={() => {
            if (item.id === 'ouverture') {
              setConfirmOpen(true)
            } else {
              setConfirm(true)
            }
          }}
        />
      </>
    : null
}

export default SettingsTableDelete
