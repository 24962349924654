import React from 'react'
import dayjs from '../../../Libraries/dayjs'
import ButtonIconHeure from '../Button/ButtonIconHeure'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  MODAL_HEURES
} from '../../../Redux/Actions/Heures'

const ToolbarRootHeure = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const date = useSelector(state => state.planning.date)
  const view = useSelector(state => state.planning.view)
  const equipe = useSelector(state => state.equipe.equipe)
  const jour = dayjs(first_day_format(date, view)).startOf('day')
  const access = (
    (user.profil === 'administrateur') ||
    (user.profil === 'utilisateur')
  )
  return (
    (access) &&
    (equipe.length > 0)
  )
    ? <ButtonIconHeure
        onClick={() => {
          dispatch(MODAL_HEURES('', 'create', false, {
            jour: jour
          }))
        }}
      />
    : null
}

export default ToolbarRootHeure
