import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'

const SelectDuree = (props) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <SelectInput
      label="Durée"
      options={settings.absence.conges.map((item, index) => {
        return {
          value: index,
          label: item
        }
      })}
      {...props}
    />
  )
}

export default SelectDuree
