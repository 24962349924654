import {
  USER_LOGOUT
} from './Login'
import {
  INIT_COMPTE
} from './Compte'
import {
  INIT_EQUIPE
} from './Equipe'
import {
  INIT_HEURES
} from './Heures'
import {
  INIT_OFFICES
} from './Offices'
import {
  INIT_ABSENCES
} from './Absences'
import {
  INIT_SETTINGS
} from './Settings'
import {
  INIT_FONCTIONS
} from './Fonctions'
import {
  INIT_OUVERTURE
} from './Ouverture'
import {
  INIT_INDEMNITES
} from './Indemnites'
import {
  api
} from '../../API/api'
import {
  SET_PRIMES,
  INIT_EXPORTS
} from './Comptabilite'
import {
  print_error
} from '../../Functions/Toast'

export const GET_DATA = () => {
  return (dispatch) => {
    const data = JSON.stringify({
      action: 'data'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(INIT_FONCTIONS(data.response.fonctions))
          dispatch(SET_PRIMES(data.response.primes))
          dispatch(INIT_COMPTE(data.response.compte))
          dispatch(INIT_EQUIPE(data.response.equipe))
          dispatch(INIT_HEURES(data.response.heures))
          dispatch(INIT_EXPORTS(data.response.exports))
          dispatch(INIT_OFFICES(data.response.offices))
          dispatch(INIT_ABSENCES(data.response.absences))
          dispatch(INIT_SETTINGS(data.response.settings))
          dispatch(INIT_OUVERTURE(data.response.ouverture))
          dispatch(INIT_INDEMNITES(data.response.indemnites))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}
