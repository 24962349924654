import clsx from 'clsx'
import React from 'react'
import styles from './Inscription.module.css'
import history from '../../Navigation/Includes/History'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  CheckMail
} from '../../Functions/Check'
import {
  SIGNIN_UPDATE
} from '../../Redux/Actions/Signin'

const Inscription = () => {
  const dispatch = useDispatch()
  const [mail, setMail] = React.useState('')
  const [error, setError] = React.useState(false)
  const device = useSelector(state => state.device)
  const Mail = (event) => {
    const value = event.target.value
    setMail(value)
    setError(
      (value !== '') &&
      (!CheckMail(value))
    )
  }
  const Commit = () => {
    if (CheckMail(mail)) {
      setMail('')
      dispatch(SIGNIN_UPDATE('mail', mail))
      history.push('/signin')
    } else {
      setError(!CheckMail(mail))
    }
  }
  return (
    <div
      className={
        clsx({
          [styles.error]: error,
          [styles.container]: true,
          [styles.mobile]: device.mobile
        })
      }
    >
      <input
        type="text"
        value={mail}
        onChange={Mail}
        className={styles.input}
        placeholder="Votre adresse mail"
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            Commit()
          }
        }}
      />
      <button
        onClick={() => Commit()}
        className={styles.button}
      >
        Commencer
      </button>
    </div>
  )
}

export default Inscription
