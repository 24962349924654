import clsx from 'clsx'
import React from 'react'
import styles from './Image.module.css'
import {
  useSelector
} from 'react-redux'

const Image = ({
  alt,
  width,
  mobile,
  shadow,
  ...others
}) => {
  const device = useSelector(state => state.device)
  const value = (device.mobile)
    ? (mobile)
      ? mobile
      : 100
    : width
  return (
    <div
      className={styles.container}
      style={{
        width: value + '%'
      }}
    >
      <img
        alt={alt}
        className={
          clsx({
            [styles.image]: true,
            [styles.shadow]: shadow
          })
        }
        {...others}
      />
    </div>
  )
}

export default Image
