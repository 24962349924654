export const routes = [
  {
    icon: "accueil",
    path: "accueil",
    name: "Accueil",
    hamburger: true,
    gestion: true,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    icon: "companie",
    path: "companies",
    name: "Entreprises",
    hamburger: true,
    gestion: true,
    desktop: true,
    mobile: true,
    admin: false,
    user: false
  },
  {
    icon: "team",
    path: "users",
    name: "Utilisateurs",
    hamburger: true,
    gestion: true,
    desktop: true,
    mobile: true,
    admin: false,
    user: false
  },
  {
    icon: "code",
    path: "codes",
    name: "Codes",
    hamburger: true,
    gestion: true,
    desktop: true,
    mobile: true,
    admin: false,
    user: false
  },
  {
    icon: "message",
    path: "mail",
    name: "Mail",
    hamburger: true,
    gestion: true,
    desktop: true,
    mobile: true,
    admin: false,
    user: false
  },
  {
    icon: "document",
    path: "plaquette",
    name: "Plaquette",
    hamburger: true,
    gestion: true,
    desktop: true,
    mobile: true,
    admin: false,
    user: false
  },
  {
    icon: "planning",
    path: "planning",
    name: "Planning",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    icon: "week",
    path: "semaines",
    name: "Semaines",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: false
  },
  {
    icon: "absences-planning",
    path: "absences-planning",
    name: "Absences Planning",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    icon: "absences",
    path: "absences",
    name: "Absences",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    icon: "heures",
    path: "heures",
    name: "Heures",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    icon: "car",
    path: "indemnites",
    name: "Indemnités",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    icon: "chart",
    path: "comptabilite",
    name: "Comptabilité",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: false,
    admin: true,
    user: false
  },
  {
    icon: "fonction",
    path: "fonctions",
    name: "Fonctions",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: false
  },
  {
    icon: "team",
    path: "equipe",
    name: "Équipe",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: false
  },
  {
    icon: "computer",
    path: "offices",
    name: "Offices",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: false
  },
  {
    icon: "settings",
    path: "parametres",
    name: "Paramètres",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: false,
    admin: true,
    user: false
  },
  {
    icon: "aide",
    path: "aide",
    name: "Aide",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    icon: "about",
    path: "about",
    name: "À Propos",
    hamburger: true,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    path: "notifications",
    name: "Notifications",
    hamburger: false,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    path: "compte",
    name: "Compte",
    hamburger: false,
    gestion: false,
    desktop: true,
    mobile: true,
    admin: true,
    user: true
  },
  {
    path: "login",
    name: "Login"
  },
  {
    path: "signin",
    name: "Signin"
  },
  {
    path: "",
    name: "Accueil"
  }
]
