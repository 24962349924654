const initial = {
  type: 0,
  conges: 6,
  maximum: {},
  profils: [],
  titulaire: '',
  survol: undefined,
  engagement: undefined
}

function compte(state = initial, action) {
  switch (action.type) {
    case 'SET_SURVOL':
      return {
        ...state,
        survol: action.value
      }
    case 'INIT_COMPTE':
      return {
        ...state,
        type: action.value.type,
        conges: action.value.conges,
        survol: action.value.survol,
        profils: action.value.profils,
        titulaire: action.value.titulaire,
        engagement: action.value.engagement,
        maximum: {
          users: action.value.users,
          offices: action.value.offices,
          semaines: action.value.semaines,
          fonctions: action.value.fonctions
        }
      }
    case 'SET_MAXIMUM':
      return {
        ...state,
        maximum: {
          ...state.maximum,
          users: action.value.users,
          semaines: action.value.semaines
        }
      }
    default:
      return state
  }
}

export default compte
