import {
  isMobile as mobile,
  isDesktop as desktop
} from 'react-device-detect'

const breakpoint = 900
const params = new URLSearchParams(window.location.search)

export function device() {
  return {
    size: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    mobile: (
      (params.has('mobile')) ||
      (
        (!params.has('desktop')) &&
        (
          (mobile) ||
          (window.innerWidth <= breakpoint)
        )
      )
    ),
    desktop: (
      (params.has('desktop')) ||
      (
        (!params.has('mobile')) &&
        (
          (desktop) &&
          (window.innerWidth > breakpoint)
        )
      )
    )
  }
}
