import React from 'react'
import styles from './Dialog.module.css'
import {
  Button,
  DialogActions
} from '@mui/material'

const Actions = ({
  buttons,
  children,
  ...others
}) => {
  const items = buttons.filter(item => item.hidden !== true)
  return (
    <DialogActions
      className={styles.actions}
      {...others}
    >
      {
        items.map((item, index) => {
          const { text, color, ...others } = item
          return (
            <Button
              key={index}
              variant="contained"
              color={
                (color)
                  ? color
                  : (index)
                    ? 'primary'
                    : 'secondary'
              }
              {...others}
            >
              {
                (text)
                  ? text
                  : (index)
                    ? 'Valider'
                    : (items.length > 1)
                      ? 'Annuler'
                      : 'Fermer'
              }
            </Button>
          )
        })
      }
      {children}
    </DialogActions>
  )
}

export default Actions
