import TableCellMail from '../../Components/Includes/Table/TableCellMail'

export const mail = {
  id: "mail",
  sort: true,
  mobile: true,
  padding: true,
  label: "Mail",
  component: TableCellMail
}
