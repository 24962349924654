import React from 'react'
import FacturesContent from './FacturesContent'
import {
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'

const Factures = ({
  open,
  setOpen
}) => {
  const factures = useSelector(state => state.stripe.factures)
  return (
    (factures !== false) &&
    (factures !== '')
  )
    ? <Dialog
        open={open}
        maxWidth="md"
      >
        <DialogTitle>Mes factures</DialogTitle>
        <DialogContent>
          <FacturesContent/>
        </DialogContent>
        <DialogActions
          buttons={[
            {
              onClick: () => setOpen(false)
            }
          ]}
        />
      </Dialog>
    : null
}

export default Factures
