import clsx from 'clsx'
import './EquipeColor.css'
import styles from './EquipeColor.module.css'
import UserColor from '../Includes/User/UserColor'
import React, {
  useRef
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  RgbColorPicker
} from 'react-colorful'

const EquipeColor = ({
  color,
  setColor
}) => {
  const ref = useRef()
  const [open, setOpen] = React.useState(false)
  const device = useSelector(state => state.device)
  return (
    <div className={styles.content}>
      <div
        className={styles.swatch}
        onClick={() => setOpen(!open)}
      >
        <UserColor
          color={color}
          className={styles.color}
        />
      </div>
      {
        (open)
          ? <div
              ref={ref}
              className={
                clsx(
                  styles.popover,
                  (device.mobile)
                    ? styles.mobile
                    : styles.desktop
                )
              }
            >
              <div
                className={styles.cover}
                onClick={() => setOpen(false)}
              />
              <div className="react-color-picker">
                <RgbColorPicker
                  color={color}
                  onChange={(color) => setColor(color)}
                />
              </div>
            </div>
          : null
      }
    </div>
  )
}

export default EquipeColor
