import clsx from 'clsx'
import React from 'react'
import CompteModal from './CompteModal'
import styles from './Compte.module.css'
import Titre from '../Includes/Misc/Titre'
import history from '../../Navigation/Includes/History'
import ButtonCompteDelete from '../Includes/Button/ButtonCompteDelete'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'
import {
  Warning
} from '@mui/icons-material'
import {
  DELETE_COMPANIE
} from '../../Redux/Actions/Compte'

const CompteDelete = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const device = useSelector(state => state.device)
  const type = useSelector(state => state.compte.type)
  const [disabled, setDisabled] = React.useState(false)
  const settings = useSelector(state => state.settings.variables)
  return (
    <>
      <CompteModal
        open={open}
        setOpen={setOpen}
        Commit={(texte) => {
          setOpen(false)
          setDisabled(true)
          dispatch(DELETE_COMPANIE(
            texte,
            () => setDisabled(false),
            () => history.push('/login')
          ))
        }}
      />
      <Paper
        className={
          (device.mobile)
            ? styles.mobile
            : styles.desktop
        }
      >
        <Toolbar>
          <Titre
            className={
              clsx(
                styles.titre,
                styles.warning
              )
            }
          >
            Supprimer {settings.companies.libelle[type]}
          </Titre>
          <ButtonCompteDelete
            disabled={disabled}
            onClick={() => setOpen(true)}
          />
        </Toolbar>
        <div className="remove-compte-explain">
          <div>La suppression de votre compte entraînera :</div>
          <div>- l'arrêt de vos paiements</div>
          <div>- la résiliation de votre abonnement</div>
          <div>- la suppression définitive de toutes vos données (pensez à sauvegarder vos exports comptables)</div>
          <span className="remove-compte-icone">
            <Warning fontSize="large"/>
          </span>
        </div>
      </Paper>
    </>
  )
}

export default CompteDelete
