import React from 'react'
import TableCellTitreMobile from './TableCellTitreMobile'

const TableCellTitreFacture = ({ item }) => {
  return (
    <TableCellTitreMobile value={item.id}/>
  )
}

export default TableCellTitreFacture
