import clsx from 'clsx'
import {
  CircularProgress
} from '@mui/material'
import {
  toast
} from 'react-toastify'
import {
  variables
} from '../Variables/Variables'

const white = {
  color: '#fff'
}

const settings = {
  icon: false,
  autoClose: variables.toast.close
}

export function print_info(texte) {
  print_toast(get_content(texte, 'info'))
}

export function print_error(texte = variables.toast.error) {
  print_toast(get_content(texte, 'error'))
}

export function print_toast(content, options) {
  toast(content, {
    ...settings,
    ...options
  })
}

export function update_toast(id, texte = variables.toast.error, type) {
  toast.update(id, {
    ...settings,
    delay: 500,
    render: () => get_content(texte, type)
  })
}

export function print_loading(id, delay = variables.toast.delay) {
  print_toast(get_content(false, 'info', 'notification-loading'), {
    toastId: id,
    autoClose: delay
  })
}

export function get_content(texte, type, classe = undefined) {
  return (
    <div
      className={
        clsx(
          classe,
          'toast-' + type,
          'notification-content'
        )
      }
    >
      {
        (texte === false)
          ? <div className="loading-toast">
              <CircularProgress style={white}/>
            </div>
          : <div className="content-toast">
              {texte}
            </div>
      }
    </div>
  )
}
