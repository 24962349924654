import React from 'react'
import AbsenceModalUserContent from './AbsenceModalUserContent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  MODAL_ABSENCES
} from '../../Redux/Actions/Absences'

const AbsenceModalUser = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const modal = useSelector(state => state.absences.modal)
  const equipe = useSelector(state => state.equipe.equipe)
  const salarie = equipe.find(item => item.id === user.unique_id_user)
  return (salarie !== undefined)
    ? <Dialog
        fullScreen={false}
        open={(modal.open === 'user')}
      >
        <DialogTitle>Mes congés</DialogTitle>
        <DialogContent>
          <AbsenceModalUserContent salarie={salarie}/>
        </DialogContent>
        <DialogActions
          buttons={[
            {
              text: 'OK',
              color: 'primary',
              onClick: () => dispatch(MODAL_ABSENCES('', ''))
            }
          ]}
        />
      </Dialog>
    : null
}

export default AbsenceModalUser
