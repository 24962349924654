import React from 'react'
import Button from './Button'

const ButtonAccept = (props) => {
  return (
    <Button
      icon="Check"
      title="Accepter"
      {...props}
    />
  )
}

export default ButtonAccept
