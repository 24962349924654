import React from 'react'
import styles from './TableCellMobile.module.css'
import {
  variables
} from '../../../Variables/Variables'

const TableCellMobile = ({
  empty,
  label,
  value,
  children
}) => {
  return (
    <div className={styles.card}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>
        {
          (empty)
            ? variables.empty.string
            : (value || children)
        }
      </span>
    </div>
  )
}

export default TableCellMobile
