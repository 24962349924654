import React from 'react'
import EquipeModal from './EquipeModal'
import dayjs from '../../Libraries/dayjs'
import EquipeContent from './EquipeContent'
import Root from '../Includes/Wrapper/Root'
import Alert from '../Includes/Dialog/Alert'
import UserReset from '../Includes/User/UserReset'
import UserDelete from '../Includes/User/UserDelete'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  get_heures
} from '../../Functions/Heures'
import {
  get_range_conges,
  get_conges_number_length
} from '../../Functions/Absences'
import {
  RESET_USER,
  MODAL_EQUIPE,
  REMOVE_EQUIPE
} from '../../Redux/Actions/Equipe'

const range = get_range_conges()

const Equipe = () => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.equipe.modal)
  const equipe = useSelector(state => state.equipe.equipe)
  const absences = useSelector(state => state.absences.absences)
  const maximum = useSelector(state => state.compte.maximum.users)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const data = equipe.map(item => {
    let conges = 0
    absences.filter(object =>
      (dayjs.unix(object.debut).isSameOrBefore(range.done.fin)) &&
      (dayjs.unix(object.fin).isSameOrAfter(range.done.debut)) &&
      (object.user === item.id) &&
      (object.status === 1)
    ).forEach(object => {
      let nombre = object.nombre
      if (
        (dayjs.unix(object.debut).isBefore(range.done.debut)) &&
        (dayjs.unix(object.fin).isAfter(range.done.fin))
      ) {
        nombre = get_conges_number_length(
          range.done.debut,
          range.done.fin,
          object.duree
        )
      } else {
        if (dayjs.unix(object.debut).isBefore(range.done.debut)) {
          nombre = get_conges_number_length(
            range.done.debut,
            dayjs.unix(object.fin),
            object.duree
          )
        }
        if (dayjs.unix(object.fin).isAfter(range.done.fin)) {
          nombre = get_conges_number_length(
            dayjs.unix(object.debut),
            range.done.fin,
            object.duree
          )
        }
      }
      conges += nombre
    })
    return {
      ...item,
      temps: item.temps + get_heures(item.id),
      conges_remaining: item.conges_done - conges,
      metier: fonctions.find(object => object.id === item.fonction).fonction
    }
  })
  return (
    <Root container={true}>
      {
        (maximum !== -1)
          ? <EquipeModal data={data}/>
          : null
      }
      {
        (equipe.length > 0)
          ? <>
              <Alert
                titre="Confirmation"
                open={(modal.open === 'delete')}
                texte={<UserDelete name={modal.name}/>}
                Cancel={() => dispatch(MODAL_EQUIPE('', ''))}
                Commit={() => {
                  dispatch(MODAL_EQUIPE('', ''))
                  dispatch(REMOVE_EQUIPE(modal.id))
                }}
              />
              <Alert
                titre="Confirmation"
                open={(modal.open === 'reset')}
                Cancel={() => dispatch(MODAL_EQUIPE('', ''))}
                texte={
                  <UserReset
                    mot="lui"
                    name={modal.name}
                  />
                }
                Commit={() => {
                  dispatch(MODAL_EQUIPE('', ''))
                  dispatch(RESET_USER(modal.id))
                }}
              />
            </>
          : null
      }
      <EquipeContent data={data}/>
    </Root>
  )
}

export default Equipe
