import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const USERS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'USERS',
      value: value
    })
  }
}

export const SEARCH_USER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SEARCH_USER',
      value: value
    })
  }
}

export const LOADING_USERS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'LOADING_USERS',
      value: value
    })
  }
}

export const SELECTED_USERS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SELECTED_USERS',
      value: value
    })
  }
}

export const DISCONNECT = (users, value) => {
  return (dispatch) => {
    dispatch({
      type: 'DISCONNECT',
      value: value,
      users: users
    })
  }
}

export const USER_BLOCK = (users, value) => {
  return (dispatch) => {
    dispatch({
      type: 'USER_BLOCK',
      value: value,
      users: users
    })
  }
}

export const MODAL_USERS = (open, texte, action) => {
  return (dispatch) => {
    dispatch({
      type: 'MODAL_USERS',
      action: action,
      texte: texte,
      open: open
    })
  }
}

export const GET_USERS = () => {
  return (dispatch) => {
    const data = JSON.stringify({
      action: 'users'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(USERS(data.response))
          dispatch(LOADING_USERS())
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const DISCONNECT_USER = (users) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      users: users,
      action: 'disconnect-user'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(DISCONNECT(users))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const BLOCK_USER = (users, value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      users: users,
      action: 'block-user'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(USER_BLOCK(users, value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
