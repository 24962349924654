import React from 'react'
import Button from './Button'

const ButtonMore = React.forwardRef((props, ref) => {
  return (
    <Button
      ref={ref}
      icon="MoreHoriz"
      {...props}
    />
  )
})

export default ButtonMore
