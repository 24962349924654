import clsx from 'clsx'
import React from 'react'
import styles from './Search.module.css'
import InputSearch from '../Input/InputSearch'
import ButtonClear from '../Button/ButtonClear'
import {
  useSelector
} from 'react-redux'

const Search = ({
  full,
  search,
  setSearch
}) => {
  const device = useSelector(state => state.device)
  return (
    <InputSearch
      value={search}
      setValue={setSearch}
      className={
        (device.mobile)
          ? clsx(
              styles.mobile,
              (full)
                ? styles.full
                : styles.short
            )
          : styles.desktop
      }
      InputProps={{
        endAdornment: (search !== '')
          ? <ButtonClear
              className={styles.button}
              onClick={() => setSearch('')}
            />
          : <ButtonClear
              className={
                clsx(
                  styles.button,
                  styles.hidden
                )
              }
            />
      }}
    />
  )
}

export default Search
