import {
  PENDING
} from './Login'
import {
  user
} from '../../API/api'
import {
  print_info,
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SIGNIN_USER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SIGNIN_USER',
      value: value
    })
  }
}

export const SIGNIN_ABONNEMENT = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SIGNIN_ABONNEMENT',
      value: value
    })
  }
}

export const SIGNIN_UPDATE = (input, value) => {
  return (dispatch) => {
    dispatch({
      type: 'SIGNIN_UPDATE',
      value: value,
      input: input
    })
  }
}

export const USER_FORGOT = (mail, success) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      mail: mail,
      action: 'send-password'
    })
    user(data)
      .then(data => {
        dispatch(PENDING(false))
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'info')
          success()
        } else {
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        dispatch(PENDING(false))
        update_toast(toast, undefined, 'error')
      })
  }
}

export const USER_TOKEN = (mail, code, success) => {
  return (dispatch) => {
    const data = JSON.stringify({
      mail: mail,
      token: code,
      action: 'check-token'
    })
    user(data)
      .then(data => {
        dispatch(PENDING(false))
        if (data.status === 'OK') {
          print_info(data.response)
          success()
        } else {
          print_error(data.response)
        }
      })
      .catch(() => {
        dispatch(PENDING(false))
        print_error()
      })
  }
}

export const CREATE_COMPANIE = (value, success, error) => {
  return () => {
    const data = JSON.stringify({
      value: value,
      action: 'create-companie'
    })
    user(data)
      .then(data => {
        if (data.status === 'OK') {
          success()
        } else {
          error(data.response, data.data)
        }
      })
      .catch(() => {
        error()
      })
  }
}

export const RESET_PASSWORD = (mail, code, password, success) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      mail: mail,
      token: code,
      password: password,
      action: 'reset-password'
    })
    user(data)
      .then(data => {
        dispatch(PENDING(false))
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'info')
          success()
        } else {
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        dispatch(PENDING(false))
        update_toast(toast, undefined, 'error')
      })
  }
}
