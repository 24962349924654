import React from 'react'
import styles from './Dialog.module.css'
import {
  DialogContentText
} from '@mui/material'

const Text = ({
  children,
  ...others
}) => {
  return (
    <DialogContentText
      component="div"
      className={styles.center}
      {...others}
    >
      {children}
    </DialogContentText>
  )
}

export default Text
