import TableCellFacture from '../Components/Includes/Table/TableCellFacture'
import TableCellActionsFactures from '../Components/Includes/Table/TableCellActionsFactures'
import {
  date
} from './Includes/Date'
import {
  actions
} from './Includes/Actions'
import {
  montant
} from './Includes/Montant'

export const properties = {
  type: {
    id: "factures",
    groupe: "divers",
    pagination: "small"
  },
  sort: {
    by: "date",
    order: "desc"
  },
  columns: [
    {
      id: "id",
      sort: true,
      padding: true,
      label: "Facture",
      component: TableCellFacture
    },
    {
      ...date,
      mobile: true
    },
    {
      ...montant
    },
    {
      ...actions,
      component: TableCellActionsFactures
    }
  ]
}
