import React from 'react'
import Image from '../Includes/Image'
import Texte from '../Includes/Texte'
import Container from '../Includes/Container'
import Source from '../../Images/Landing/salaries.png'

const Salaries = () => {
  return (
    <Container>
      <Texte
        left={true}
        titre="Visionnez en un #coup d'oeil.#"
        description="Pour chaque semaine, vous pouvez afficher/masquer chaque salarié. De ce fait, vous avez une vision sur mesure de vos employés."
      />
      <Image
        width={30}
        mobile={70}
        src={Source}
        shadow={true}
        alt="Salariés"
      />
    </Container>
  )
}

export default Salaries
