import React from 'react'
import Titre from '../Includes/Misc/Titre'
import SemainesToolbarContent from './SemainesToolbarContent'
import {
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'

const SemainesToolbarDesktop = () => {
  const semaines = useSelector(state => state.semaines.semaines)
  const maximum = useSelector(state => state.compte.maximum.semaines)
  const access = (
    (maximum !== -1) &&
    (semaines.length < maximum)
  )
  return (
    <Toolbar>
      <Titre>Semaines</Titre>
      {
        (access)
          ? <SemainesToolbarContent/>
          : null
      }
    </Toolbar>
  )
}

export default SemainesToolbarDesktop
