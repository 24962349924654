import clsx from 'clsx'
import React from 'react'
import Planning from '../Hooks/Planning'
import dayjs from '../../../Libraries/dayjs'
import styles from './LegendeCreneauDate.module.css'
import {
  useSelector
} from 'react-redux'
import {
  convert_heure
} from '../../../Functions/Heures'
import {
  start_case
} from '../../../Functions/Strings'

const LegendeCreneauDate = ({ data }) => {
  const planning = Planning()
  const event = useSelector(state => state.planning.event)
  return (
    <>
      <div
        className={
          clsx({
            [styles.content]: true,
            [styles.alone]: event.read
          })
        }
      >
        <i className="icon calendar"/>
        <span>{start_case(dayjs(data.startDate).format('dddd'))}</span>
        {
          (
            (data.heure) ||
            (planning)
          )
            ? ' ' + start_case(dayjs(data.startDate).format('D MMMM YYYY'))
            : null
        }
      </div>
      <div className={styles.content}>
        <i className="icon clock"/>
        <span>{dayjs(data.startDate).format('HH:mm')}</span>
        <span className="heure">-</span>
        <span>{dayjs(data.endDate).format('HH:mm')}</span>
        <span> (<span>{convert_heure((dayjs(data.endDate).unix() - dayjs(data.startDate).unix()) / 60, false)}</span>)</span>
      </div>
    </>
  )
}

export default LegendeCreneauDate
