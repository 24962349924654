import React from 'react'
import dayjs from '../../Libraries/dayjs'

const CardContentPhrase = ({
  prix,
  state
}) => {
  const date = dayjs().add(state.days, 'days').format('DD/MM/YYYY')
  return (
    <div className="stripe-explain">
      <span>Vous payez <b>0.00€</b> aujourd'hui et commencerez à payer </span>
      <span
        className={
          (state.promotion > 0)
            ? 'prime'
            : undefined
        }
      >
        <b>{prix}€</b>
      </span>
      <span>/mois à partir du </span>
      <span
        className={
          (state.free)
            ? 'prime'
            : undefined
        }
      >
        <b>{date}</b>
      </span>
      {
        (state.engagement > 0)
          ? <div className="engaged">
              <span>Ce coupon vous engage </span>
              {
                (state.essai)
                  ? <span>après ce délai </span>
                  : null
              }
              <span>jusqu'au </span>
              <b>{dayjs().add(state.engagement, 'month').endOf('day').format('DD/MM/YYYY')}</b>
            </div>
          : null
      }
    </div>
  )
}

export default CardContentPhrase
