import clsx from 'clsx'
import React from 'react'
import history from '../Includes/History'
import styles from './Hamburger.module.css'
import HamburgerIcone from './HamburgerIcone'
import HamburgerTexte from './HamburgerTexte'
import HamburgerContainer from './HamburgerContainer'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  ListItemButton
} from '@mui/material'
import {
  routes
} from '../../Variables/Routes'
import {
  MENU
} from '../../Redux/Actions/Menu'

const HamburgerGestion = ({ active }) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const Navigate = (path) => {
    dispatch(MENU(false))
    history.push(path)
  }
  return (
    <HamburgerContainer>
      {
        routes.filter(item =>
          (item.hamburger) &&
          (item.gestion) &&
          (
            (
              (device.mobile) &&
              (item.mobile)
            ) ||
            (
              (device.desktop) &&
              (item.desktop)
            )
          )
        ).map(item => {
          return (
            <ListItemButton
              key={item.path}
              onClick={() => Navigate('/' + item.path)}
              className={
                clsx({
                  [styles.item]: true,
                  'active-item': (active === item.path)
                })
              }
            >
              <HamburgerIcone icone={item.icon}/>
              <HamburgerTexte texte={item.name}/>
            </ListItemButton>
          )
        })
      }
    </HamburgerContainer>
  )
}

export default HamburgerGestion
