import React from 'react'
import Button from './Button'

const ButtonClear = (props) => {
  return (
    <Button
      icon="Clear"
      size="small"
      {...props}
    />
  )
}

export default ButtonClear
