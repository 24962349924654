import React from 'react'
import styles from './Compte.module.css'
import Titre from '../Includes/Misc/Titre'
import Stripe from '../Includes/Wrapper/Stripe'
import CompteStripe from '../Compte/CompteStripe'
import ButtonCompteAdd from '../Includes/Button/ButtonCompteAdd'
import ButtonCompteEdit from '../Includes/Button/ButtonCompteEdit'
import {
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'

const URL = (png) => {
  switch (png) {
    case 'amex':
      return 'amex.png'
    case 'diners':
      return 'diners.png'
    case 'discover':
      return 'discover.png'
    case 'jcb':
      return 'jcb.png'
    case 'mastercard':
      return 'mastercard.png'
    case 'unionpay':
      return 'unionpay.png'
    case 'visa':
      return 'visa.png'
    default:
      return 'cb.png'
  }
}

const CompteCard = () => {
  const [open, setOpen] = React.useState(false)
  const device = useSelector(state => state.device)
  const card = useSelector(state => state.stripe.card)
  const carte = (
    (card.brand !== null) &&
    (card.last !== null) &&
    (card.year !== null) &&
    (card.month !== '0')
  )
  return (
    <>
      {
        (card !== '')
          ? <Stripe>
              <CompteStripe
                open={open}
                setOpen={setOpen}
              />
            </Stripe>
          : null
      }
      <Paper
        className={
          (device.mobile)
            ? styles.mobile
            : styles.desktop
        }
      >
        <Toolbar>
          <Titre>Mon moyen de paiement</Titre>
          {
            (carte)
              ? <ButtonCompteEdit
                  onClick={() => setOpen(true)}
                />
              : <ButtonCompteAdd
                  onClick={() => setOpen(true)}
                />
          }
        </Toolbar>
        {
          (carte)
            ? <div className="form-card">
                <div className="card-details">
                  <img
                    width={80}
                    height={50}
                    alt="Brand"
                    className="card-icone"
                    src={require('../../Images/Stripe/' + URL(card.brand)).default}
                  />
                  <span className="card-number">**** **** **** {card.last}</span>
                  <span className="card-month">{card.month}</span>
                  <span>/</span>
                  <span className="card-year">{card.year}</span>
                </div>
              </div>
            : null
        }
      </Paper>
    </>
  )
}

export default CompteCard
