import React from 'react'
import styles from './UserColor.module.css'
import {
  useSelector
} from 'react-redux'
import {
  get_color,
  get_background_color
} from '../../../Functions/Colors'

const UserColor = ({
  color,
  children,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        (device.mobile)
          ? styles.mobile
          : styles.desktop
      }
      style={{
        color: get_color(color),
        background: get_background_color(color)
      }}
      {...others}
    >
      {children}
    </div>
  )
}

export default UserColor
