import TableCellProfil from '../../Components/Includes/Table/TableCellProfil'

export const profil = {
  id: "profil",
  sort: true,
  mobile: true,
  padding: true,
  label: "Profil",
  component: TableCellProfil
}
