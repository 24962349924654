import React from 'react'
import Form from '../Includes/Wrapper/Form'
import SelectAide from '../Includes/Select/SelectAide'
import InputMessage from '../Includes/Input/InputMessage'
import {
  CheckMessage
} from '../../Functions/Check'

const AideContactContent = ({
  error,
  state,
  Update,
  UpdateError
}) => {
  const Sujet = (value) => {
    Update('sujet', value)
    UpdateError('sujet', (value === ''))
  }
  const Texte = (value) => {
    Update('texte', value)
    UpdateError('texte', (
      (value !== '') &&
      (!CheckMessage(value))
    ))
  }
  return (
    <Form full={true}>
      <SelectAide
        setValue={Sujet}
        error={error.sujet}
        value={state.sujet}
      />
      <InputMessage
        setValue={Texte}
        error={error.texte}
        value={state.texte}
      />
    </Form>
  )
}

export default AideContactContent
