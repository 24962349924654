import React from 'react'
import Condition from '../Condition/Demandes'
import ButtonEdit from '../Button/ButtonEdit'
import ButtonDelete from '../Button/ButtonDelete'
import TableCellContainer from './TableCellContainer'
import {
  useDispatch
} from 'react-redux'

const TableCellActionsDemandes = ({
  item,
  action
}) => {
  const dispatch = useDispatch()
  const condition = Condition(item)
  return (
    <TableCellContainer width={2}>
      {
        (condition)
          ? <>
              <ButtonEdit
                color="green"
                onClick={() => dispatch(action(item.id, 'edit', (item.nombre > 0)))}
              />
              <ButtonDelete
                color="red"
                onClick={() => dispatch(action(item.id, 'delete'))}
              />
            </>
          : null
      }
    </TableCellContainer>
  )
}

export default TableCellActionsDemandes
