import dayjs from '../../Libraries/dayjs'
import {
  USER_LOGOUT
} from './Login'
import {
  EDIT_PLANNING_HEURE
} from './Heures'
import {
  base64StringToBlob
} from 'blob-util'
import {
  saveAs
} from 'file-saver'
import {
  api,
  planning
} from '../../API/api'
import {
  first_day_format
} from '../../Functions/Dates'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_creneau_object,
  get_planning_erreurs
} from '../../Functions/Planning'
import {
  get_id
} from '../../Functions/Functions'

export const DAY = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DAY',
      value: value
    })
  }
}

export const VIEW = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'VIEW',
      value: value
    })
  }
}

export const VIEW_DATE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'VIEW_DATE',
      value: value
    })
  }
}

export const SET_EVENT = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_EVENT',
      value: value
    })
  }
}

export const SET_USERS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_USERS',
      value: value
    })
  }
}

export const SHOW = (item, value) => {
  return (dispatch) => {
    dispatch({
      type: 'SHOW',
      value: value,
      item: item
    })
  }
}

export const SET_ERREURS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ERREURS',
      value: value
    })
  }
}

export const SET_ABSENTS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ABSENTS',
      value: value
    })
  }
}

export const DEL_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_PLANNING',
      value: value
    })
  }
}

export const INIT_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_PLANNING',
      value: value
    })
  }
}

export const CLONE_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'CLONE_PLANNING',
      value: value
    })
  }
}

export const STATUS_CRENEAU = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'STATUS_CRENEAU',
      value: value
    })
  }
}

export const DEL_PLANNING_DAY = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_PLANNING_DAY',
      value: value
    })
  }
}

export const SET_PDF_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_PDF_PLANNING',
      value: value
    })
  }
}

export const SET_DATE_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_DATE_PLANNING',
      value: value
    })
    dispatch(UPDATE_PLANNING())
  }
}

export const ADD_HEURE_PLANNING = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_HEURE_PLANNING',
      value: value
    })
  }
}

export const OPEN_PLANNING = (item, value) => {
  return (dispatch) => {
    dispatch({
      type: 'OPEN_PLANNING',
      value: value,
      item: item
    })
  }
}

export const DEL_PLANNING_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_PLANNING_EQUIPE',
      value: value
    })
  }
}

export const DEL_PLANNING_HORAIRE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_PLANNING_HORAIRE',
      value: value
    })
  }
}

export const EDIT_PLANNING_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_PLANNING_EQUIPE',
      value: value
    })
  }
}

export const REMOVE_PLANNING_USER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_PLANNING_USER',
      value: value
    })
  }
}

export const EDIT_PLANNING_HORAIRE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_PLANNING_HORAIRE',
      value: value
    })
  }
}

export const CLEAR_PLANNING_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_PLANNING_EQUIPE',
      value: value
    })
  }
}

export const ADD_PLANNING = (value, item, hour) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_PLANNING',
      value: value,
      item: item,
      hour: hour
    })
  }
}

export const EDIT_PLANNING_HORAIRE_FORM = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_PLANNING_HORAIRE_FORM',
      value: value
    })
  }
}

export const MODIFY_HEURE_PLANNING = (value, ancien) => {
  return (dispatch) => {
    dispatch({
      type: 'MODIFY_HEURE_PLANNING',
      ancien: ancien,
      value: value
    })
  }
}

export const REMOVE_HEURE_PLANNING = (value, ancien) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_HEURE_PLANNING',
      ancien: ancien,
      value: value
    })
  }
}

export const GET_PLANNING = () => {
  return (dispatch) => {
    const data = JSON.stringify({
      action: 'planning'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(INIT_PLANNING(data.response))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const DELETE_PLANNING = (value) => {
  return (dispatch) => {
    dispatch(DEL_PLANNING(value))
    const data = JSON.stringify({
      week: value,
      action: 'del-planning'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const SEND_PLANNING = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'send-planning'
    })
    planning(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
        dispatch(SET_PDF_PLANNING({}))
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
        dispatch(SET_PDF_PLANNING({}))
      })
  }
}

export const DOWNLOAD_PLANNING = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'download-planning'
    })
    planning(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const blob = base64StringToBlob(data.data.file, 'application/pdf')
          saveAs(blob, data.data.name + '.pdf')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const ADD_PLANNING_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch(EDIT_PLANNING_EQUIPE(value))
    const data = JSON.stringify({
      value: value,
      action: 'add-planning-user'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const DELETE_PLANNING_DAY = (value) => {
  return (dispatch) => {
    dispatch(DEL_PLANNING_DAY(value))
    const data = JSON.stringify({
      day: value.day,
      action: 'del-planning-day'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const EMPTY_PLANNING_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch(CLEAR_PLANNING_EQUIPE(value))
    const data = JSON.stringify({
      value: value,
      action: 'empty-creneau'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const DELETE_PLANNING_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch(DEL_PLANNING_EQUIPE(value))
    const data = JSON.stringify({
      value: value,
      action: 'del-planning-user'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const DUPLICATE_PLANNING = (value, day) => {
  return (dispatch) => {
    dispatch(CLONE_PLANNING(value))
    const data = JSON.stringify({
      week: day,
      value: value,
      action: 'clone-creneau'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const MODIFY_PLANNING_HORAIRE = (value) => {
  return (dispatch) => {
    const object = get_creneau_object(value, 'iso')
    dispatch(EDIT_PLANNING_HORAIRE(object))
    const data = JSON.stringify({
      value: object,
      action: 'update-planning'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const MODIFY_PLANNING_HORAIRE_FORM = (value) => {
  return (dispatch) => {
    dispatch(EDIT_PLANNING_HORAIRE_FORM(value))
    const data = JSON.stringify({
      value: value,
      action: 'update-planning-form'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const MODIFY_PLANNING_HORAIRE_HEURE = (value) => {
  return (dispatch) => {
    const object = get_creneau_object(value, 'unix')
    dispatch(EDIT_PLANNING_HORAIRE(object))
    dispatch(EDIT_PLANNING_HEURE(object))
    const data = JSON.stringify({
      value: object,
      action: 'update-planning-heure'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const ADD_CRENEAU_PLANNING = (value, item, week, hour) => {
  return (dispatch) => {
    dispatch(ADD_PLANNING(value, item, hour))
    const data = JSON.stringify({
      week: week,
      value: value,
      action: 'add-planning'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const UPDATE_PLANNING = () => {
  return (dispatch, state) => {
    let array = []
    let creneaux = []
    const date = state().planning.date
    const view = state().planning.view
    const equipe = state().equipe.equipe
    const heures = state().heures.heures
    const absences = state().absences.absences
    const planning = state().planning.planning
    const settings = state().settings.variables
    const fonctions = state().fonctions.fonctions
    const end = dayjs(date).endOf(view.toLowerCase()).unix()
    const start = dayjs(date).startOf(view.toLowerCase()).unix()
    creneaux = planning.filter(item => item.week === first_day_format(date)).map(item => item.horaires).flat()
    if (view === 'Day') {
      creneaux = creneaux.filter(item => dayjs(item.startDate).format('DD/MM/YYYY') === date.format('DD/MM/YYYY'))
    }
    heures.filter(item =>
      (item.debut <= end) &&
      (item.fin >= start) &&
      (item.nombre === 0) &&
      (item.sens === 1) &&
      (
        (item.status === 1) ||
        (item.status === 3)
      )
    ).forEach(item => {
      array.push({
        heure: 1,
        id: item.id,
        user: item.user,
        status: item.status,
        fin: dayjs.unix(item.fin).toDate(),
        debut: dayjs.unix(item.debut).toDate(),
        motif: (item.motif !== 'autre')
          ? settings.heure.motifs[item.motif]
          : item.texte
      })
    })
    const holidays = absences.filter(item =>
      (item.debut <= end) &&
      (item.fin >= start) &&
      (
        (item.status === 1) ||
        (item.status === 3)
      )
    ).map(item => {
      return {
        id: item.id,
        fin: item.fin,
        user: item.user,
        debut: item.debut,
        status: item.status,
        motif: (item.motif !== 'autre')
          ? settings.absence.motifs[item.motif]
          : item.texte
      }
    })
    creneaux.forEach(week => {
      holidays.filter(item =>
        dayjs(week.startDate).isSameOrAfter(dayjs.unix(item.debut)) &&
        dayjs(week.endDate).isSameOrBefore(dayjs.unix(item.fin)) &&
        week.equipe.includes(item.user)
      ).forEach(item => {
        array.push({
          heure: 0,
          id: week.id,
          user: item.user,
          absence: item.id,
          motif: item.motif,
          fin: week.endDate,
          status: item.status,
          debut: week.startDate
        })
      })
    })
    dispatch(SET_ERREURS(get_planning_erreurs(creneaux, true)))
    dispatch(SET_ABSENTS(array.map(item => {
      const user = equipe.find(object => object.id === item.user)
      const fonction = fonctions.find(object => object.id === user.fonction)
      return {
        id: item.user,
        fin: item.fin,
        nom: user.nom,
        creneau: item.id,
        color: user.color,
        debut: item.debut,
        heure: item.heure,
        motif: item.motif,
        prenom: user.prenom,
        status: item.status,
        absence: item.absence,
        fonction: fonction.fonction
      }
    })))
  }
}
