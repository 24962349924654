import clsx from 'clsx'
import React from 'react'
import styles from './Icone.module.css'

const Icone = ({
  icon,
  mini
}) => {
  return (
    <span
      className={
        clsx({
          [styles.mini]: mini,
          [styles.icone]: true
        })
      }
    >
      <i
        className={
          clsx(
            icon,
            'icon'
          )
        }
      />
    </span>
  )
}

export default Icone
