import clsx from 'clsx'
import React from 'react'
import styles from './AbonnementBuy.module.css'
import AbonnementSeparate from './AbonnementSeparate'

const AbonnementBuy = ({
  mobile,
  favorite,
  abonnement,
  ...others
}) => {
  return (
    <>
      <AbonnementSeparate favorite={favorite}/>
      <div
        className={
          clsx({
            [styles.mobile]: mobile,
            [styles.container]: true
          })
        }
      >
        <button
          style={{
            background: (
              (!favorite) &&
              (abonnement.css)
            )
          }}
          className={
            clsx({
              [styles.button]: true,
              [styles.favorite]: favorite
            })
          }
          {...others}
        >
          <i
            className={
              clsx(
                'icon',
                'shop'
              )
            }
          />
        </button>
      </div>
    </>
  )
}

export default AbonnementBuy
