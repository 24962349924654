import React from 'react'
import ButtonCompte from './ButtonCompte'

const ButtonCompteAdd = (props) => {
  return (
    <ButtonCompte
      icon="Add"
      color="green"
      title="Ajouter"
      {...props}
    />
  )
}

export default ButtonCompteAdd
