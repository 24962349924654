import React from 'react'
import Form from '../Wrapper/Form'
import SelectInput from '../Select/SelectInput'
import {
  useSelector
} from 'react-redux'

const FilterCodesContent = ({
  state,
  Update
}) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <Form>
      <SelectInput
        label="Type"
        filter={true}
        value={state.type}
        setValue={(value) => Update('type', value)}
        options={settings.codes.types.map(item => {
          return {
            value: item.id,
            label: item.label
          }
        })}
      />
    </Form>
  )
}

export default FilterCodesContent
