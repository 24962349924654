import React from 'react'
import {
  TableCell
} from '@mui/material'

const TableHeadCell = ({
  item,
  children,
  ...others
}) => {
  const style = (Array.isArray(item.padding))
    ? {
        paddingTop: item.padding[0],
        paddingLeft: item.padding[3],
        paddingRight: item.padding[1],
        paddingBottom: item.padding[2]
      }
    : {
        paddingLeft: (item.padding) ? undefined : 0,
        paddingRight: (item.padding) ? undefined : 0
      }
  return (
    <TableCell
      style={style}
      align={
        (item.center)
          ? 'center'
          : (item.right)
            ? 'right'
            : 'left'
      }
      {...others}
    >
      {children}
    </TableCell>
  )
}

export default TableHeadCell
