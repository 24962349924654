import React from 'react'
import SelectInput from './SelectInput'

const SelectEssai = (props) => {
  return (
    <SelectInput
      label="Essai"
      options={[
        {
          value: 0,
          label: 'Sans'
        },
        {
          value: 1,
          label: 'Avec'
        }
      ]}
      {...props}
    />
  )
}

export default SelectEssai
