import React from 'react'
import Titre from '../../Includes/Misc/Titre'
import Search from '../../Includes/Search/Search'
import UsersToolbarButtons from './UsersToolbarButtons'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  SEARCH_USER
} from '../../../Redux/Actions/Users'

const UsersToolbarDesktop = ({ data }) => {
  const dispatch = useDispatch()
  const search = useSelector(state => state.users.search)
  return (
    <Toolbar>
      <Titre>
        Utilisateurs {
          (data.length > 0)
            ? '(' + data.length + ')'
            : ''
        }
      </Titre>
      <Search
        search={search}
        setSearch={(value) => dispatch(SEARCH_USER(value))}
      />
      <UsersToolbarButtons/>
    </Toolbar>
  )
}

export default UsersToolbarDesktop
