import React from 'react'
import AccueilDate from './AccueilDate'
import Root from '../Includes/Wrapper/Root'
import AccueilConges from './AccueilConges'
import AccueilHeures from './AccueilHeures'
import AccueilTravail from './AccueilTravail'
import AccueilAbsences from './AccueilAbsences'
import AccueilPlanning from './AccueilPlanning'
import {
  useSelector
} from 'react-redux'
import {
  Grid
} from '@mui/material'

const Accueil = () => {
  const device = useSelector(state => state.device)
  const equipe = useSelector(state => state.equipe.equipe)
  const spacing = (device.desktop) ? 2 : 1
  const three = (device.desktop) ? 4 : 12
  const two = (device.desktop) ? 6 : 12
  return (equipe.length > 0)
    ? <Root container={true}>
        <Grid
          container={true}
          spacing={spacing}
        >
          {
            (device.desktop)
              ? <Grid
                  xs={12}
                  item={true}
                >
                  <AccueilDate/>
                </Grid>
              : null
          }
          <Grid
            xs={two}
            item={true}
          >
            <AccueilPlanning/>
          </Grid>
          <Grid
            xs={two}
            item={true}
          >
            <AccueilAbsences/>
          </Grid>
          <Grid
            xs={three}
            item={true}
          >
            <AccueilConges annee="done"/>
          </Grid>
          <Grid
            xs={three}
            item={true}
          >
            <AccueilConges annee="current"/>
          </Grid>
          <Grid
            xs={three}
            item={true}
          >
            <AccueilHeures/>
          </Grid>
          <Grid
            xs={two}
            item={true}
          >
            <AccueilTravail time="week"/>
          </Grid>
          <Grid
            xs={two}
            item={true}
          >
            <AccueilTravail time="month"/>
          </Grid>
        </Grid>
      </Root>
    : null
}

export default Accueil
