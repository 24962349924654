import clsx from 'clsx'
import React from 'react'
import dayjs from '../../../Libraries/dayjs'
import {
  useSelector
} from 'react-redux'
import {
  get_open_cell
} from '../../../Functions/Ouverture'
import {
  DayView
} from '@devexpress/dx-react-scheduler-material-ui'

const EventDayCell = React.memo(({
  open,
  endDate,
  holidays,
  planning,
  startDate
}) => {
  let am = undefined
  let pm = undefined
  const show = useSelector(state => state.planning.show)
  const view = useSelector(state => state.planning.view)
  const ouverture = useSelector(state => state.ouverture.ouverture)
  const horaires = (
    (open) &&
    (show.ouverture)
  )
  if (horaires) {
    const jour = dayjs(startDate).format('dddd')
    am = get_open_cell(ouverture[jour].am, startDate, endDate)
    pm = get_open_cell(ouverture[jour].pm, startDate, endDate)
  }
  return (
    <DayView.TimeTableCell
      endDate={endDate}
      startDate={startDate}
      className={
        clsx({
          [am]: horaires,
          [pm]: horaires,
          'cell-day-week': (view === 'Week'),
          'cell-day-today cell-day-ferie': (
            (planning) &&
            (holidays[dayjs(startDate).weekday()] === true)
          ),
          'cell-day-today': (
            (planning) &&
            (dayjs(startDate).format('DDMMYYYY') === dayjs().format('DDMMYYYY'))
          )
        })
      }
    />
  )
})

export default EventDayCell
