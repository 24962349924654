import clsx from 'clsx'
import CreneauEquipe from './CreneauEquipe'
import dayjs from '../../../Libraries/dayjs'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  useResizeDetector
} from 'react-resize-detector'
import {
  sort_name
} from '../../../Functions/Sort'
import {
  SET_USERS
} from '../../../Redux/Actions/Planning'

const CreneauContent = ({ data }) => {
  const dispatch = useDispatch()
  const [x, setX] = React.useState(false)
  const { ref, width } = useResizeDetector()
  const [alone, setAlone] = React.useState(false)
  const equipe = useSelector(state => state.equipe.equipe)
  const absents = useSelector(state => state.planning.absents)
  const salaries = equipe.filter(item => data.equipe.includes(item.id))
  const heures = dayjs.duration(dayjs(data.endDate).diff(dayjs(data.startDate))).asHours()
  const y = (data.equipe.length > (heures * 2 * 1.25))
  const users = sort_name(salaries).map(item  => {
    return {
      ...item,
      hour: data.heure,
      heure: (data.heure && (data.sens === 1)),
      absent: (absents.filter(item => item.creneau === data.id).map(item => item.id).includes(item.id))
        ? 1
        : 0
    }
  })
  useEffect(() => {
    if (width !== undefined) {
      const value = (
        (width < 50) &&
        (width > 0)
      )
      const seul = (
        (value) &&
        (users.length === 1)
      )
      setX(value)
      setAlone(seul)
    }
    // eslint-disable-next-line
  }, [width, data.equipe.length])
  return (x || y)
    ? <div
        ref={ref}
        onMouseLeave={() => dispatch(SET_USERS([]))}
        onMouseEnter={() => dispatch(SET_USERS(users))}
        className={
          (y)
            ? clsx({
                'appoint-alone': alone,
                'appoint-overflow-y': true
              })
            : clsx({
                'appoint-alone': alone,
                'appoint-overflow-x': true
              })
        }
      >
        {
          (x && y)
            ? <div className="appoint-empty">
                <i className="icon divers"/>
              </div>
            : <div className="appoint">
                <CreneauEquipe
                  empty={true}
                  alone={alone}
                  users={users}
                />
              </div>
        }
      </div>
    : <div ref={ref}>
        <div className="appoint">
          <CreneauEquipe users={users}/>
        </div>
      </div>
}

export default CreneauContent
