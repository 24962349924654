import React from 'react'
import styles from './Container.module.css'
import {
  Paper
} from '@mui/material'

const Container = ({ children }) => {
  return (
    <Paper className={styles.container}>
      {children}
    </Paper>
  )
}

export default Container
