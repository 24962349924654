import React from 'react'
import PlaquetteForm from './PlaquetteForm'
import PlaquetteUsers from './PlaquetteUsers'
import styles from '../Styles/Mail.module.css'
import Form from '../../Includes/Wrapper/Form'
import Root from '../../Includes/Wrapper/Root'
import PlaquetteToolbar from './PlaquetteToolbar'
import {
  useSelector
} from 'react-redux'

const Plaquette = () => {
  const device = useSelector(state => state.device)
  return (
    <Root container={true}>
      <Form
        fit={true}
        full={true}
        className={
          (device.mobile)
            ? styles.mobile
            : styles.desktop
        }
      >
        {
          (device.desktop)
            ? <PlaquetteToolbar/>
            : null
        }
        <PlaquetteUsers/>
        <PlaquetteForm/>
        {
          (device.mobile)
            ? <PlaquetteToolbar/>
            : null
        }
      </Form>
    </Root>
  )
}

export default Plaquette
