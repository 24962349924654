import React from 'react'
import BarTitle from './BarTitle'
import history from '../Includes/History'
import styles from './BarLanding.module.css'
import ButtonLogin from '../../Components/Includes/Button/ButtonLogin'
import ButtonSignin from '../../Components/Includes/Button/ButtonSignin'
import {
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  useLocation
} from 'react-router-dom'
import {
  LocalOffer
} from '@mui/icons-material'
import {
  config,
  variables
} from '../../Variables/Variables'

const BarLanding = () => {
  const location = useLocation()
  const device = useSelector(state => state.device)
  const icone = (device.mobile)
    ? 'planipo.png'
    : 'planipo_text.png'
  return (
    <Toolbar className="bar-menu">
      <div className="bar-content bar-content-left">
        <img
          alt="logo"
          height="50"
          className="pointer"
          width={(device.mobile) ? 31 : 84}
          onClick={() => history.push('/')}
          src={variables.url.server + variables.path.icon + icone}
        />
      </div>
      <BarTitle>
        {
          (location.pathname === '/')
            ? <div className="free-offer-box">
                <div className="free-offer">
                  <LocalOffer className="free-offer-icone"/>
                  <span>1 MOIS D'ESSAI OFFERT !</span>
                </div>
              </div>
            : null
        }
      </BarTitle>
      <div className="bar-content bar-content-right">
        {
          (
            (config.beta !== '1') &&
            (config.demo !== '1')
          )
            ? <ButtonSignin
                edge="start"
                className={styles.button}
                onClick={() => history.push('/signin')}
              />
            : null
        }
        <ButtonLogin
          edge="start"
          className={styles.button}
          onClick={() => history.push('/login')}
        />
      </div>
    </Toolbar>
  )
}

export default BarLanding
