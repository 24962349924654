import React from 'react'
import Switch from '../Misc/Switch'
import styles from './LegendeSettingsItem.module.css'
import TableCellDesktop from '../Table/TableCellDesktop'
import {
  TableRow
} from '@mui/material'

const LegendeSettingsItem = ({
  label,
  ...others
}) => {
  return (
    <TableRow className={styles.row}>
      <TableCellDesktop
        width={90}
        center={true}
        style={{
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        <Switch {...others}/>
      </TableCellDesktop>
      <TableCellDesktop
        max={150}
        style={{
          paddingLeft: 0
        }}
      >
        {label}
      </TableCellDesktop>
    </TableRow>
  )
}

export default LegendeSettingsItem
