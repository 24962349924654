import React from 'react'
import Switch from '../Includes/Misc/Switch'
import {
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  get_ouverture
} from '../../Functions/Ouverture'

const PlanningModalSettingsContent = ({
  state,
  Update
}) => {
  const ouverture = useSelector(state => state.ouverture.ouverture)
  return (
    <Paper
      elevation={2}
      className="form-modal-settings-content"
    >
      {
        (isDifferent([ouverture], [get_ouverture()]))
          ? <div className="inline-settings-select">
              <Switch
                checked={state.ouverture}
                onChange={() => Update('ouverture', !state.ouverture)}
              />
              <div className="table-planning-settings-texte">Afficher les horaires d'ouverture</div>
            </div>
          : null
      }
    </Paper>
  )
}

export default PlanningModalSettingsContent
