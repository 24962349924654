import React from 'react'
import InputTextField from './InputTextField'

const InputMontant = (props) => {
  return (
    <InputTextField
      label="Montant"
      {...props}
    />
  )
}

export default InputMontant
