import React from 'react'
import dayjs from '../../Libraries/dayjs'
import ButtonArrow from '../Includes/Button/ButtonArrow'
import TableCellField from '../Includes/Table/TableCellField'
import TableCellTitre from '../Includes/Table/TableCellTitre'
import TableCellTitreUser from '../Includes/Table/TableCellTitreUser'
import TableMobileRowTitre from '../Includes/Table/TableMobileRowTitre'
import ActionSheetDemandes from '../Includes/Action/ActionSheetDemandes'
import {
  MODAL_ABSENCES,
  UPDATE_ABSENCE
} from '../../Redux/Actions/Absences'

const AbsencesMobileTitre = ({
  item,
  type,
  expanded,
  setExpanded
}) => {
  const fin = dayjs.unix(item.fin).format('DD/MM/YYYY')
  const debut = dayjs.unix(item.debut).format('DD/MM/YYYY')
  const duree = (item.nombre === 0.5)
    ? item.duree
    : item.nombre + 'j'
  return (
    <TableMobileRowTitre
      item={item}
      type={type}
    >
      <TableCellField>
        <ButtonArrow
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </TableCellField>
      <TableCellTitre
        onClick={() => setExpanded(!expanded)}
      >
        <TableCellTitreUser
          item={item}
          type={type}
        />
        {
          (debut === fin)
            ? debut + ' - ' + duree
            : debut + ' - ' + fin + ' - ' + duree
        }
      </TableCellTitre>
      <TableCellField>
        <ActionSheetDemandes
          item={item}
          modal={MODAL_ABSENCES}
          update={UPDATE_ABSENCE}
        />
      </TableCellField>
    </TableMobileRowTitre>
  )
}

export default AbsencesMobileTitre
