import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const INIT_OUVERTURE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_OUVERTURE',
      value: value
    })
  }
}

export const DELETE_OUVERTURE = () => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      action: 'delete-ouverture'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(INIT_OUVERTURE(''))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_OUVERTURE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'update-ouverture'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(INIT_OUVERTURE(value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
