import React from 'react'
import ActionSheet from './ActionSheet'
import {
  useDispatch
} from 'react-redux'

const ActionSheetUsers = ({
  id,
  modal,
  reset,
  texte,
  remove
}) => {
  const dispatch = useDispatch()
  return (
    <ActionSheet
      options={[
        {
          id: 0,
          label: 'Modifier',
          action: () => dispatch(modal(id, 'edit'))
        },
        {
          id: 1,
          label: 'Supprimer',
          condition: (remove !== false),
          action: () => dispatch(modal(id, 'delete', texte))
        },
        {
          id: 2,
          label: 'Réinitialiser',
          condition: (reset !== false),
          action: () => dispatch(modal(id, 'reset', texte))
        }
      ]}
    />
  )
}

export default ActionSheetUsers
