import React from 'react'
import ToolbarSendContent from './ToolbarSendContent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Dialog/Dialog'
import {
  OPEN_PLANNING,
  SEND_PLANNING
} from '../../../Redux/Actions/Planning'

const ToolbarSend = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.planning.base)
  const open = useSelector(state => state.planning.open)
  const [state, setState] = React.useState({
    error: '',
    users: []
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const Reset = () => {
    Update('error', '')
    Update('users', [])
  }
  const Commit = () => {
    if (state.users.length > 0) {
      if (
        (data.week !== undefined) &&
        (data.week !== '')
      ) {
        Reset()
        dispatch(OPEN_PLANNING('share', false))
        dispatch(SEND_PLANNING({
          ...data,
          users: state.users
        }))
      } else {
        Update('error', 'Une erreur est survenue')
      }
    } else {
      Update('error', 'Merci de sélectionner au moins un salarié')
    }
  }
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open.share}
    >
      <DialogTitle>A qui souhaitez-vous envoyer ce planning ?</DialogTitle>
      <DialogContent>
        <ToolbarSendContent
          state={state}
          Update={Update}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => {
              Reset()
              dispatch(OPEN_PLANNING('share', false))
            }
          },
          {
            text: 'Envoyer',
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default ToolbarSend
