import React from 'react'
import TableCellConges from './TableCellConges'

const TableCellCongesDone = ({
  item,
  label
}) => {
  return (
    <TableCellConges
      label={label}
      item={item.conges_done}
    />
  )
}

export default TableCellCongesDone
