import React from 'react'
import Circle from '../Includes/Accueil/Circle'
import {
  useSelector
} from 'react-redux'
import {
  get_heures,
  convert_heure
} from '../../Functions/Heures'

const AccueilHeures = () => {
  const user = useSelector(state => state.login.user)
  const heures = user.temps + get_heures(user.unique_id_user)
  return (
    <Circle
      title="Compteur temps"
      value={
        (heures !== 0)
          ? convert_heure(heures)
          : '0h'
      }
    />
  )
}

export default AccueilHeures
