import React from 'react'
import TableCellCheckbox from './TableCellCheckbox'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SORT_SALARIES,
  SORT_FONCTIONS
} from '../../../Redux/Actions/Sort'
import {
  SET_FILTER_ALL
} from '../../../Redux/Actions/Filters'

const TableCellCheckboxFonctions = ({ item }) => {
  const dispatch = useDispatch()
  const equipe = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.filter)
  const users = equipe.filter(object => object.fonction === item.id)
  const selected = users.filter(object => filter.indexOf(object.id) < 0).length
  const ids = users.map(object => object.id)
  const all = (selected === users.length)
  const Change = () => {
    dispatch(SET_FILTER_ALL(
      (all)
        ? [
            ...filter,
            ...ids
          ]
        : filter.filter(object => ids.indexOf(object) < 0)
    ))
    dispatch(SORT_SALARIES({
      by: 'selected',
      order: 'desc'
    }))
    dispatch(SORT_FONCTIONS({
      by: 'selected',
      order: 'desc'
    }))
  }
  return (
    <TableCellCheckbox
      checked={all}
      onClick={() => Change()}
      indeterminate={(
        (selected > 0) &&
        (selected !== users.length)
      )}
    />
  )
}

export default TableCellCheckboxFonctions
