import clsx from 'clsx'
import React from 'react'
import styles from './TableCell.module.css'
import LegendeDate from '../Legende/LegendeDate'
import TableCellDesktop from './TableCellDesktop'
import LegendeHeure from '../Legende/LegendeHeure'
import {
  get_user_long
} from '../../../Functions/Strings'

const TableCellLegendeAbsents = ({ item }) => {
  return (
    <TableCellDesktop
      max={200}
      style={{
        paddingLeft: 0
      }}
    >
      {get_user_long(item)}
      <div
        className={
          clsx(
            styles.cell,
            styles.motif,
            styles.italic
          )
        }
      >
        {item.motif}
      </div>
      <LegendeDate item={item}/>
      <LegendeHeure item={item}/>
    </TableCellDesktop>
  )
}

export default TableCellLegendeAbsents
