import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'
import {
  FormHelperText
} from '@mui/material'

const SelectAide = ({
  error,
  ...others
}) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <SelectInput
      label="Sujet"
      error={error}
      options={settings.sujets.contact.map(item => {
        return {
          value: item,
          label: item
        }
      })}
      {...others}
    >
      {
        (error)
          ? <FormHelperText error={error}>Le sujet ne doit pas être vide</FormHelperText>
          : null
      }
    </SelectInput>
  )
}

export default SelectAide
