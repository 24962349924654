import React from 'react'
import OfficeModal from './OfficeModal'
import Root from '../Includes/Wrapper/Root'
import Alert from '../Includes/Dialog/Alert'
import OfficesContent from './OfficesContent'
import UserReset from '../Includes/User/UserReset'
import UserDelete from '../Includes/User/UserDelete'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  RESET_OFFICE,
  DELETE_OFFICE,
  MODAL_OFFICES
} from '../../Redux/Actions/Offices'

const Offices = () => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.offices.modal)
  const offices = useSelector(state => state.offices.offices)
  const maximum = useSelector(state => state.compte.maximum.offices)
  return (
    <Root container={true}>
      {
        (maximum !== -1)
          ? <OfficeModal/>
          : null
      }
      {
        (offices.length > 0)
          ? <>
              <Alert
                titre="Confirmation"
                open={(modal.open === 'delete')}
                texte={<UserDelete name={modal.name}/>}
                Cancel={() => dispatch(MODAL_OFFICES('', ''))}
                Commit={() => {
                  dispatch(MODAL_OFFICES('', ''))
                  dispatch(DELETE_OFFICE(modal.id))
                }}
              />
              <Alert
                titre="Confirmation"
                open={(modal.open === 'reset')}
                Cancel={() => dispatch(MODAL_OFFICES('', ''))}
                texte={
                  <UserReset
                    mot="vous"
                    name={modal.name}
                  />
                }
                Commit={() => {
                  dispatch(MODAL_OFFICES('', ''))
                  dispatch(RESET_OFFICE(modal.id))
                }}
              />
            </>
          : null
      }
      <OfficesContent/>
    </Root>
  )
}

export default Offices
