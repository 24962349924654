import TableCellKilometres from '../Components/Includes/Table/TableCellKilometres'
import TableCellActionsDemandes from '../Components/Includes/Table/TableCellActionsDemandes'
import {
  nom
} from './Includes/Nom'
import {
  date
} from './Includes/Date'
import {
  prenom
} from './Includes/Prenom'
import {
  status
} from './Includes/Status'
import {
  actions
} from './Includes/Actions'
import {
  demande
} from './Includes/Demande'
import {
  fonction
} from './Includes/Fonction'
import {
  MODAL_INDEMNITES,
  UPDATE_INDEMNITE
} from '../Redux/Actions/Indemnites'

export const properties = {
  type: {
    id: "indemnites",
    groupe: "demandes"
  },
  sort: {
    by: "demande",
    order: "desc"
  },
  columns: [
    {
      ...prenom
    },
    {
      ...nom
    },
    {
      ...fonction
    },
    {
      ...demande
    },
    {
      ...date
    },
    {
      id: "nombre",
      sort: true,
      padding: true,
      label: "Kilomètres",
      component: TableCellKilometres
    },
    {
      ...status,
      action: UPDATE_INDEMNITE
    },
    {
      ...actions,
      action: MODAL_INDEMNITES,
      component: TableCellActionsDemandes
    }
  ]
}
