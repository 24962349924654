import React from 'react'
import Form from '../Includes/Wrapper/Form'
import InputPassword from '../Includes/Input/InputPassword'
import {
  CheckPassword
} from '../../Functions/Check'

const OfficeModalContent = ({
  error,
  state,
  Update,
  UpdateError
}) => {
  const Password = (value) => {
    Update('password', value)
    UpdateError('confirmation', (value !== state.confirmation))
    UpdateError('password', (
      (value !== '') &&
      (!CheckPassword(value))
    ))
  }
  const Confirmation = (value) => {
    Update('confirmation', value)
    UpdateError('confirmation', (value !== state.password))
  }
  return (
    <Form>
      <InputPassword
        setValue={Password}
        confirmation={false}
        label="Mot de passe"
        error={error.password}
        value={state.password}
      />
      <InputPassword
        confirmation={true}
        label="Confirmation"
        setValue={Confirmation}
        error={error.confirmation}
        value={state.confirmation}
      />
    </Form>
  )
}

export default OfficeModalContent
