import React from 'react'
import Button from './Button'

const ButtonAccount = (props) => {
  return (
    <Button
      color="bar"
      icon="AccountCircle"
      {...props}
    />
  )
}

export default ButtonAccount
