import React from 'react'
import ButtonAdd from '../Includes/Button/ButtonAdd'
import {
  useDispatch
} from 'react-redux'
import {
  MODAL_INDEMNITES
} from '../../Redux/Actions/Indemnites'

const IndemnitesToolbarContent = () => {
  const dispatch = useDispatch()
  return (
    <ButtonAdd
      mobile="Nouvelle indemnité"
      onClick={() => dispatch(MODAL_INDEMNITES('', 'create'))}
    />
  )
}

export default IndemnitesToolbarContent
