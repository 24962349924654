import 'jspdf-autotable'
import jsPDF from 'jspdf'
import dayjs from '../Libraries/dayjs'
import {
  get_weeks
} from './Dates'
import {
  get_time,
  convert_heure
} from './Heures'
import {
  wrap_text,
  get_user_pdf
} from './Strings'
import {
  colonnes
} from '../Variables/Colonnes'

export function PDF(data, debut, fin, comptable = false, detail = false, primes = []) {
  let page
  let final
  let ik = 0
  let supp = 0
  let hours = 0
  let conges = 0
  let rows = []
  let calendar = []
  const last = dayjs(fin)
  const first = dayjs(debut)
  const doc = new jsPDF('l')
  const weeks = get_weeks(first, last)
  for (let i = 0; i < weeks.length; i++) {
    let end = dayjs(weeks[i], 'DD/MM/YYYY').endOf('week')
    let start = dayjs(weeks[i], 'DD/MM/YYYY').startOf('week')
    if (start.isBefore(first)) {
      start = first
    }
    if (end.isAfter(last)) {
      end = last
    }
    calendar.push({
      'fin': end,
      'debut': start
    })
  }
  if (data.length > 1) {
    rows = []
    data.forEach(item => {
      rows.push({
        user: get_user_pdf(item),
        fonction: wrap_text(item.fonction),
        heures: get_time(item.heures * 60),
        conges: (item.conges > 0) ? item.conges : '',
        supp: convert_heure(item.supp, (item.supp < 0)),
        ik: (item.indemnites > 0) ? item.indemnites : ''
      })
      ik += item.indemnites
      conges += item.conges
    })
    doc.text('Bilan du ' + debut.format('DD/MM/YYYY') + ' au ' + fin.format('DD/MM/YYYY'), 14, 15)
    doc.autoTable({
      startY: 20,
      body: rows,
      head: [colonnes[0]]
    })
  }
  if (primes.length > 0) {
    rows = []
    if (data.length > 1) {
      page = doc.internal.getNumberOfPages()
      doc.setPage(page)
      doc.addPage()
    }
    doc.text('Primes', 14, 15)
    primes.forEach(item => {
      item.users.forEach(user => {
        rows.push({
          motif: item.motif,
          user: get_user_pdf(user),
          montant: item.montant + '€'
        })
      })
    })
    doc.autoTable({
      startY: 20,
      body: rows,
      pageBreak: 'auto',
      head: [colonnes[6]]
    })
  }
  data.forEach(item => {
    rows = []
    if (
      (primes.length > 0) ||
      (data.length > 1)
    ) {
      page = doc.internal.getNumberOfPages()
      doc.setPage(page)
      doc.addPage()
    }
    doc.text(item.prenom + ' ' + item.nom + ' (' + get_time((item.heures * 60) + item.supp) + ')', 14, 15)
    calendar.forEach(week => {
      supp = 0
      hours = 0
      item.details.filter(object =>
        (dayjs(object.debut, 'DD/MM/YYYY HH:mm').isSameOrAfter(week.debut)) &&
        (dayjs(object.fin, 'DD/MM/YYYY HH:mm').isSameOrBefore(week.fin))
      ).forEach(line => {
        if (
          (line.heures > 0) &&
          (line.type === 'heures')
        ) {
          hours += line.heures
        }
        if (
          (line.nombre !== 0) &&
          (line.type === 'supp')
        ) {
          supp += line.nombre
        }
      })
      rows.push({
        heures: (hours > 0) ? get_time(hours * 60) : '',
        supp: (supp !== 0) ? convert_heure(supp, (supp < 0)) : '',
        week: week.debut.format('DD/MM/YYYY') + ' - ' + week.fin.format('DD/MM/YYYY')
      })
    })
    doc.autoTable({
      startY: 20,
      body: rows,
      pageBreak: 'auto',
      head: [colonnes[1]]
    })
    rows = []
    item.details.filter(item => item.type === 'conges').forEach(line => {
      rows.push({
        fin: line.fin,
        debut: line.debut,
        duree: line.duree,
        motif: line.motif,
        nombre: line.nombre,
        user: get_user_pdf(item),
        start: dayjs(line.debut, 'DD/MM/YYYY').unix()
      })
    })
    if (rows.length > 0) {
      final = doc.lastAutoTable.finalY
      doc.text('Absences', 14, 15 + final)
      doc.autoTable({
        startY: final + 20,
        head: [colonnes[2]],
        body: rows.sort((a, b) => parseInt(a.start) - parseInt(b.start))
      })
    }
    rows = []
    item.details.filter(item => item.type === 'ik').forEach(line => {
      rows.push({
        date: line.debut,
        user: get_user_pdf(item),
        nombre: line.nombre + 'km',
        start: dayjs(line.debut, 'DD/MM/YYYY HH:mm').unix()
      })
    })
    if (rows.length > 0) {
      final = doc.lastAutoTable.finalY
      doc.text('IK', 14, 15 + final)
      doc.autoTable({
        startY: final + 20,
        head: [colonnes[3]],
        body: rows.sort((a, b) => parseInt(a.start) - parseInt(b.start))
      })
    }
    page = doc.internal.getNumberOfPages()
    doc.setPage(page)
  })
  if (
    (conges > 0) &&
    (data.length > 1)
  ) {
    rows = []
    doc.addPage()
    doc.text('Absences du ' + debut.format('DD/MM/YYYY') + ' au ' + fin.format('DD/MM/YYYY'), 14, 15)
    data.forEach(item => {
      item.details.filter(item => item.type === 'conges').forEach(line => {
        rows.push({
          fin: line.fin,
          debut: line.debut,
          duree: line.duree,
          motif: line.motif,
          nombre: line.nombre,
          user: get_user_pdf(item),
          start: dayjs(line.debut, 'DD/MM/YYYY').unix()
        })
      })
    })
    doc.autoTable({
      startY: 20,
      pageBreak: 'auto',
      head: [{ ...colonnes[4], ...colonnes[2] }],
      body: rows.sort((a, b) => parseInt(a.start) - parseInt(b.start))
    })
    page = doc.internal.getNumberOfPages()
    doc.setPage(page)
  }
  if (
    (ik > 0) &&
    (data.length > 1)
  ) {
    rows = []
    doc.addPage()
    doc.text('Indemnités kilométriques du ' + debut.format('DD/MM/YYYY') + ' au ' + fin.format('DD/MM/YYYY'), 14, 15)
    data.forEach(item => {
      item.details.filter(item => item.type === 'ik').forEach(line => {
        rows.push({
          date: line.debut,
          user: get_user_pdf(item),
          nombre: line.nombre + 'km',
          start: dayjs(line.debut, 'DD/MM/YYYY HH:mm').unix()
        })
      })
    })
    doc.autoTable({
      startY: 20,
      pageBreak: 'auto',
      head: [{ ...colonnes[4], ...colonnes[3] }],
      body: rows.sort((a, b) => parseInt(a.start) - parseInt(b.start))
    })
    page = doc.internal.getNumberOfPages()
    doc.setPage(page)
  }
  if (
    (detail) &&
    (data.length > 0)
  ) {
    rows = []
    data.forEach(item => {
      rows = []
      if (item.details.length > 0) {
        item.details.forEach(line => {
          switch (line.type) {
            case 'ik':
              rows.push({
                type: 'IK',
                fin: line.fin,
                debut: line.debut,
                nombre: line.nombre + 'km',
                start: dayjs(line.debut, 'DD/MM/YYYY HH:mm').unix()
              })
            break
            case 'heures':
              rows.push({
                fin: line.fin,
                debut: line.debut,
                motif: line.motif,
                start: dayjs(line.debut, 'DD/MM/YYYY HH:mm').unix(),
                duree: (line.heures === 0)
                  ? ''
                  : get_time(line.heures * 60),
                type: (
                  (line.heures === 0) ||
                  (line.motif === 'Formation')
                )
                  ? 'Absence'
                  : 'Heure'
              })
            break
            case 'supp':
              rows.push({
                type: 'Supp',
                motif: line.motif,
                fin: line.fin.replace(' 00:00', ''),
                debut: line.debut.replace(' 00:00', ''),
                start: dayjs(line.debut, 'DD/MM/YYYY HH:mm').unix(),
                nombre: (line.nombre !== 0) ? convert_heure(line.nombre, (line.nombre < 0)) : ''
              })
            break
            default:
            break
          }
        })
        doc.addPage()
        doc.text(item.prenom + ' ' + item.nom + ' (' + get_time(item.heures * 60) + ')', 14, 15)
        doc.autoTable({
          startY: 20,
          pageBreak: 'auto',
          head: [colonnes[5]],
          body: rows.sort((a, b) => parseInt(a.start) - parseInt(b.start))
        })
        page = doc.internal.getNumberOfPages()
        doc.setPage(page)
      }
    })
  }
  if (comptable) {
    return doc.output('datauristring')
  } else {
    doc.save(debut.format('DDMMYYYY') + '_' + fin.format('DDMMYYYY') + '.pdf')
  }
}
