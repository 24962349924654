import React from 'react'
import InputTextField from './InputTextField'
import {
  variables
} from '../../../Variables/Variables'

const InputMessage = ({
  error,
  value,
  ...others
}) => {
  return (
    <InputTextField
      rows={10}
      label="Texte"
      error={error}
      value={value}
      multiline={true}
      helperText={
        (error)
          ? (value.length < variables.taille.message.minimum)
            ? "Le texte doit contenir au moins " + variables.taille.message.minimum + " caractères"
            : (value.length > variables.taille.message.maximum)
              ? "Le texte ne doit pas dépasser " + variables.taille.message.maximum + " caractères"
              : "Le texte est invalide"
          : null
      }
      {...others}
    />
  )
}

export default InputMessage
