import React from 'react'
import Form from '../Wrapper/Form'
import Slider from '../Slider/Slider'
import DatePicker from '../Picker/DatePicker'
import InputAutocomplete from '../Input/InputAutocomplete'
import {
  useSelector
} from 'react-redux'

const LegendeCreneauModalContent = ({
  state,
  Update
}) => {
  const equipe = useSelector(state => state.equipe.equipe)
  return (
    <Form full={true}>
      {
        (equipe.length > 0)
          ? <InputAutocomplete
              label="Salariés"
              options={equipe}
              value={equipe.filter(item => state.users.includes(item.id))}
              onChange={(event, value) => Update('users', value.map(item => item.id))}
            />
          : null
      }
      <div className="datepicker-planning">
        <DatePicker
          value={state.day}
          open={state.picker}
          onClose={() => Update('picker', false)}
          onChange={(value) => Update('day', value)}
          slotProps={{
            textField: {
              onClick: () => Update('picker', true)
            }
          }}
        />
      </div>
      <Slider
        input={true}
        wrong={false}
        value={state.heures}
        setValue={(value) => Update('heures', value)}
      />
    </Form>
  )
}

export default LegendeCreneauModalContent
