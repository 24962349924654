import React from 'react'
import Bulle from '../Wrapper/Bulle'
import TableCellMobile from './TableCellMobile'
import styles from './TableCellMotif.module.css'
import TableCellDesktop from './TableCellDesktop'
import {
  Info
} from '@mui/icons-material'

const TableCellMotif = ({
  item,
  label,
  device
}) => {
  const other = (item.motif === 'autre')
  return (device.mobile)
    ? <TableCellMobile label={label}>
        {
          (other)
            ? item.texte
            : item.cause
        }
      </TableCellMobile>
    : <TableCellDesktop max={150}>
        {
          (other)
            ? <>
                {item.cause}
                <Bulle
                  arrow={true}
                  placement="top"
                  title={item.texte}
                >
                  <Info className={styles.motif}/>
                </Bulle>
              </>
            : item.cause
        }
      </TableCellDesktop>
}

export default TableCellMotif
