const initial = {
  open: '',
  codes: [],
  search: '',
  selected: []
}

function codes(state = initial, action) {
  switch (action.type) {
    case 'CODES':
      return {
        ...state,
        selected: [],
        codes: action.value
      }
    case 'OPEN_CODES':
      return {
        ...state,
        open: action.value
      }
    case 'REMOVE_CODE':
      return {
        ...state,
        codes: state.codes.filter(item => action.value.indexOf(item.id) < 0)
      }
    case 'SEARCH_CODE':
      return {
        ...state,
        selected: [],
        search: action.value
      }
    case 'SELECTED_CODES':
      return {
        ...state,
        selected: action.value
      }
    default:
      return state
  }
}

export default codes
