import clsx from 'clsx'
import React from 'react'
import dayjs from '../../Libraries/dayjs'
import Card from '../Includes/Accueil/Card'
import styles from './AccueilPlanning.module.css'
import {
  useSelector
} from 'react-redux'
import {
  sort_date
} from '../../Functions/Sort'
import {
  start_case
} from '../../Functions/Strings'
import {
  variables
} from '../../Variables/Variables'

const AccueilPlanning = () => {
  const user = useSelector(state => state.login.user)
  const absences = useSelector(state => state.absences.absences)
  const planning = useSelector(state => state.planning.planning)
  const data = sort_date(planning.map(item => item.horaires).flat().filter(item =>
    (dayjs(item.startDate).isSameOrAfter(dayjs().add(1, 'days'), 'day')) &&
    (item.equipe.includes(user.unique_id_user)) &&
    (
      (item.sens === undefined) ||
      (item.sens === 0)
    )
  )).slice(0, variables.event.accueil)
  return (
    <Card title={"Mes " + variables.event.accueil + " prochains créneaux de travail"}>
      {
        (data.length > 0)
          ? <div className="accueil-planning">
              {
                data.map((item, index) => {
                  const end = dayjs(item.endDate)
                  const start = dayjs(item.startDate)
                  const absence = absences.filter(object =>
                    (object.user === user.unique_id_user) &&
                    (start.unix() >= object.debut) &&
                    (end.unix() <= object.fin) &&
                    (object.status === 1)
                  )
                  return (
                    <div
                      key={index}
                      className="accueil-planning-item"
                    >
                      <span>{start_case(start.format('dd. D MMM'))}</span>
                      <div
                        className={
                          clsx(
                            {
                              'accueil-planning-content': true,
                              'accueil-planning-content-absent': (absence.length > 0)
                            },
                            (item.heure)
                              ? styles.heure
                              : styles.event
                          )
                        }
                      >
                        <div>{start.format('HH:mm')}</div>
                        <div>{end.format('HH:mm')}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          : <div className="accueil-empty">Vous n'avez aucun jour de travail de programmé</div>
      }
    </Card>
  )
}

export default AccueilPlanning
