import React from 'react'
import TableCellCheckbox from './TableCellCheckbox'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SORT_SALARIES,
  SORT_FONCTIONS
} from '../../../Redux/Actions/Sort'
import {
  SET_FILTER
} from '../../../Redux/Actions/Filters'

const TableCellCheckboxSalaries = ({ item }) => {
  const dispatch = useDispatch()
  const equipe = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.filter)
  const selected = equipe.map(item => item.id).filter(item => filter.indexOf(item) < 0)
  const Change = () => {
    dispatch(SET_FILTER(item.id))
    dispatch(SORT_SALARIES({
      by: 'selected',
      order: 'desc'
    }))
    dispatch(SORT_FONCTIONS({
      by: 'selected',
      order: 'desc'
    }))
  }
  return (
    <TableCellCheckbox
      checked={selected.includes(item.id)}
      onClick={(event) => {
        event.stopPropagation()
        Change()
      }}
    />
  )
}

export default TableCellCheckboxSalaries
