import clsx from 'clsx'
import React from 'react'
import styles from './Root.module.css'
import {
  useSelector
} from 'react-redux'

const Root = ({
  children,
  className,
  container
}) => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        clsx(
          className,
          styles.offset,
          (container)
            ? clsx(
                styles.container,
                (device.mobile)
                  ? styles.mobile
                  : styles.desktop
              )
            : undefined
        )
      }
    >
      {children}
    </div>
  )
}

export default Root
