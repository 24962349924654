import clsx from 'clsx'
import React from 'react'
import styles from './TableCell.module.css'
import {
  TableCell
} from '@mui/material'
import {
  variables
} from '../../../Variables/Variables'

const TableCellDesktop = ({
  max,
  min,
  empty,
  right,
  style,
  value,
  width,
  center,
  normal,
  children,
  ...others
}) => {
  return (
    <TableCell
      className={
        clsx({
          [styles.cell]: true,
          [styles.right]: right,
          [styles.center]: center,
          [styles.normal]: normal,
          [styles.line]: (normal === undefined)
        })
      }
      style={{
        width: width,
        maxWidth: max,
        minWidth: min,
        ...style
      }}
      {...others}
    >
      {
        (empty)
          ? variables.empty.string
          : (value !== undefined)
            ? value
            : children
      }
    </TableCell>
  )
}

export default TableCellDesktop
