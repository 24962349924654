import store from '../Redux/Store/Store'

export function format_heure(value) {
  return parseInt(value.heure) + (value.minute / 60)
}

export function format_minute(value) {
  return parseInt(value.heure * 60) + parseInt(value.minute)
}

export function get_heure_object(value) {
  return {
    heure: Math.trunc(value),
    minute: (value - Math.floor(value)) * 60
  }
}

export function format_nombre(nombre, type) {
  const value = Math.abs(nombre)
  if (type === 'heure') {
    return parseInt(Math.floor(value / 60))
  } else if (type === 'minute') {
    return parseInt(value % 60)
  }
}

export function get_time(time) {
  const signe = (time < 0) ? '-' : ''
  const value = (time < 0) ? Math.abs(time) : time
  const minutes = value % 60
  const hours = Math.floor(value / 60)
  return (minutes === 0)
    ? signe + hours + 'h'
    : signe + hours + 'h' + minutes
}

export function label_heure(value, input = false) {
  const hour = Math.trunc(value)
  const minute = value - Math.floor(value)
  const heure = (
    (input) &&
    (hour < 10)
  )
    ? '0' + hour
    : hour
  return (minute > 0)
    ? heure + 'h' + (minute * 60)
    : (input)
      ? heure + 'h00'
      : heure + 'h'
}

export function get_heures(unique_id_user) {
  let compteur = 0
  const heures = store.getState().heures.heures
  heures.filter(item =>
    (item.user === unique_id_user) &&
    (item.recuperation === 1) &&
    (item.status === 1)
  ).forEach(item => {
    const count = (item.nombre === 0)
      ? parseFloat(item.fin - item.debut)
      : (item.nombre * 60)
    return (item.sens === 0)
      ? compteur += count
      : compteur -= count
  })
  return (compteur === 0)
    ? 0
    : (compteur / 60)
}

export function convert_heure(time, affiche = true) {
  const signe = (affiche)
    ? (time > 0)
      ? '+'
      : '-'
    : ''
  const number = Math.abs(time)
  const heure = (Math.floor(number / 60) > 0)
    ? Math.floor(number / 60) + 'h'
    : ''
  const minute = ((number % 60) > 0)
    ? (number < 60)
      ? (number % 60) + 'm'
      : (number % 60)
    : ''
  const zero = (
    (heure !== '') &&
    (minute < 10)
  )
    ? '0'
    : ''
  return ((number % 60) === 0)
    ? signe + heure
    : signe + heure + zero + minute
}
