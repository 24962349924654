import React from 'react'
import UserColor from '../User/UserColor'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellColor = ({
  item,
  type,
  label,
  device
}) => {
  return (device.mobile)
    ? <TableCellMobile label={label}>
        <UserColor color={item.color}/>
      </TableCellMobile>
    : <TableCellDesktop
        style={{
          paddingLeft: (type.groupe === 'legendes')
            ? (type.id === 'salaries') ? 24 : 0
            : undefined,
          paddingRight: (type.groupe === 'legendes')
            ? (type.id === 'salaries') ? 12 : 0
            : undefined
        }}
        width={
          (
            (type.id === 'absents') ||
            (type.id === 'erreurs')
          )
            ? 90
            : (type.id === 'salaries') ? 35 : 50
        }
      >
        <UserColor color={item.color}/>
      </TableCellDesktop>
}

export default TableCellColor
