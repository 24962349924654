import React from 'react'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'
import {
  get_user_long
} from '../../../Functions/Strings'

const TableCellMessage = ({ item }) => {
  const user = useSelector(state => state.login.user)
  const equipe = useSelector(state => state.equipe.equipe)
  const settings = useSelector(state => state.settings.variables)
  const Message = (item) => {
    let utilisateur
    let administrateur
    const array = item.notification.split('-')
    const type = settings['notifications']['messages'][array[1]]
    const action = settings['notifications']['actions'][array[0]]
    utilisateur = equipe.find(object => object.id === item.user)
    utilisateur = get_user_long(utilisateur)
    if (item.admin !== '') {
      administrateur = equipe.find(object => object.id === item.admin)
      administrateur = get_user_long(administrateur)
    }
    if (item.user === user.unique_id_user) {
      if (item.admin === '') {
        return 'Vous avez ' + action + ' une ' + type
      } else if (item.admin !== user.unique_id_user) {
        return administrateur + ' a ' + action + ' votre ' + type
      }
    } else {
      if (item.admin === '') {
        return utilisateur + ' a ' + action + ' une ' + type
      } else if (item.admin === user.unique_id_user) {
        return 'Vous avez ' + action + ' une ' + type + ' pour ' + utilisateur
      } else {
        return administrateur + ' a ' + action + ' une ' + type + ' pour ' + utilisateur
      }
    }
  }
  return (
    <TableCellDesktop
      normal={true}
      value={Message(item)}
    />
  )
}

export default TableCellMessage
