import React from 'react'
import TableData from '../Table/TableData'
import {
  useSelector
} from 'react-redux'
import {
  sort_function
} from '../../../Functions/Sort'
import {
  properties
} from '../../../Settings/Legende/Creneau'

const CreneauFonctions = ({ liste }) => {
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const array = liste.map(item => {
    return {
      id: item.fonction,
      value: liste.filter(object => object.fonction === item.fonction).length,
      fonction: fonctions.find(object => object.id === item.fonction).fonction
    }
  })
  const data = sort_function(array)
    .filter(item => item.value > 0)
    .filter((value, index, array) => array.findIndex(item => item.id === value.id) === index)
  return (data.length > 0)
    ? <TableData
        all={true}
        data={data}
        head={false}
        size="medium"
        hover={false}
        desktop={true}
        pagination={false}
        {...properties}
      />
    : null
}

export default CreneauFonctions
