import React from 'react'
import TableData from '../Includes/Table/TableData'
import EquipeMobileTitre from './EquipeMobileTitre'
import EquipeToolbarMobile from './EquipeToolbarMobile'
import EquipeToolbarDesktop from './EquipeToolbarDesktop'
import {
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  properties
} from '../../Settings/Equipe'

const EquipeContent = ({ data }) => {
  const device = useSelector(state => state.device)
  const equipe = useSelector(state => state.equipe.equipe)
  const maximum = useSelector(state => state.compte.maximum.users)
  return (
    <>
      {
        (
          (device.mobile) &&
          (maximum !== -1) &&
          (equipe.length < maximum)
        )
          ? <EquipeToolbarMobile/>
          : null
      }
      <Paper>
        {
          (device.desktop)
            ? <EquipeToolbarDesktop/>
            : null
        }
        {
          (equipe.length > 0)
            ? <TableData
                data={data}
                titre={EquipeMobileTitre}
                pagination={device.desktop}
                {...properties}
              >
                {
                  (equipe.length === maximum)
                    ? <caption>Vous avez atteint le nombre maximum d'utilisateurs, pour en créer de nouveaux, vous devez modifier votre abonnement.</caption>
                    : null
                }
              </TableData>
            : null
        }
      </Paper>
    </>
  )
}

export default EquipeContent
