import TableCellComptabiliteSupp from '../Components/Includes/Table/TableCellComptabiliteSupp'
import TableCellComptabiliteConges from '../Components/Includes/Table/TableCellComptabiliteConges'
import TableCellComptabiliteHeures from '../Components/Includes/Table/TableCellComptabiliteHeures'
import TableCellCheckboxComptabilite from '../Components/Includes/Table/TableCellCheckboxComptabilite'
import TableCellComptabiliteIndemnites from '../Components/Includes/Table/TableCellComptabiliteIndemnites'
import {
  nom
} from './Includes/Nom'
import {
  color
} from './Includes/Color'
import {
  heures
} from './Includes/Heures'
import {
  prenom
} from './Includes/Prenom'
import {
  fonction
} from './Includes/Fonction'

export const properties = {
  type: {
    id: "comptabilite",
    groupe: "comptabilite"
  },
  sort: {
    by: "nom",
    order: "asc"
  },
  columns: [
    {
      id: "checkbox",
      component: TableCellCheckboxComptabilite
    },
    {
      ...prenom
    },
    {
      ...nom
    },
    {
      ...fonction
    },
    {
      ...heures,
      component: TableCellComptabiliteHeures
    },
    {
      id: "supp",
      sort: true,
      padding: true,
      label: "Supp",
      component: TableCellComptabiliteSupp
    },
    {
      id: "conges",
      sort: true,
      padding: true,
      label: "Absences",
      component: TableCellComptabiliteConges
    },
    {
      id: "indemnites",
      sort: true,
      padding: true,
      label: "IK",
      component: TableCellComptabiliteIndemnites
    },
    {
      ...color
    }
  ]
}
