import Provider from './Provider'
import React, {
  useRef,
  useEffect
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  DateRangePicker as PickerDateRange
} from '@mui/x-date-pickers-pro/DateRangePicker'
import {
  MobileDateRangePicker as PickerDateRangeMobile
} from '@mui/x-date-pickers-pro/MobileDateRangePicker'

const DateRangePicker = ({
  mobile,
  slotProps,
  ...others
}) => {
  const ref = useRef()
  const [popper, setPopper] = React.useState({})
  const device = useSelector(state => state.device)
  const object = {
    format: 'DD/MM/YYYY'
  }
  useEffect(() => {
    if (ref.current) {
      setPopper({
        placement: 'bottom',
        anchorEl: ref.current
      })
    }
  }, [])
  return (
    <Provider>
      {
        (mobile || device.mobile)
          ? <PickerDateRangeMobile
              className="date-range-picker-mobile"
              slotProps={{
                ...slotProps,
                fieldSeparator: {
                  className: 'picker-separator'
                }
              }}
              {...object}
              {...others}
            />
          : <PickerDateRange
              ref={ref}
              className="date-range-picker"
              slotProps={{
                ...slotProps,
                popper: popper
              }}
              {...object}
              {...others}
            />
      }
    </Provider>
  )
}

export default DateRangePicker
