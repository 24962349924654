import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'

const TableCellAbonnements = ({
  item,
  label,
  device
}) => {
  const abonnements = useSelector(state => state.landing.abonnements)
  const value = abonnements.filter(object => item.abonnements.includes(object.unique_id_abonnement)).map(object => object.name).join(', ')
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={75}
        value={value}
      />
}

export default TableCellAbonnements
