import React from 'react'
import styles from './Video.module.css'
import Player from '../../Components/Includes/Misc/Video'
import {
  useSelector
} from 'react-redux'

const Video = ({
  width,
  ...others
}) => {
  const device = useSelector(state => state.device)
  const value = (device.mobile)
    ? 100
    : width
  return (
    <div
      style={{
        width: value + '%'
      }}
    >
      <Player
        className={styles.video}
        {...others}
      />
    </div>
  )
}

export default Video
