import React from 'react'
import dayjs from '../../Libraries/dayjs'
import Root from '../Includes/Wrapper/Root'
import TableData from '../Includes/Table/TableData'
import ComptabiliteModal from './ComptabiliteModal'
import ComptabiliteExplain from './ComptabiliteExplain'
import ComptabiliteToolbar from './ComptabiliteToolbar'
import ComptabiliteDownload from './ComptabiliteDownload'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  All
} from '../../Functions/Table'
import {
  get_days
} from '../../Functions/Absences'
import {
  properties
} from '../../Settings/Comptabilite'
import {
  get_data
} from '../../Functions/Comptabilite'
import {
  SELECTED_COMPTABILITE
} from '../../Redux/Actions/Comptabilite'

const Comptabilite = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const equipe = useSelector(state => state.equipe.equipe)
  const dates = useSelector(state => state.comptabilite.dates)
  const files = useSelector(state => state.comptabilite.exports)
  const planning = useSelector(state => state.planning.planning)
  const selected = useSelector(state => state.comptabilite.selected)
  const exports = files.filter(item =>
    dayjs(dates[1]).isSameOrAfter(dayjs.unix(item.debut)) &&
    dayjs(dates[0]).isSameOrBefore(dayjs.unix(item.fin).endOf('day'))
  ).map(item => {
    return get_days(
      dayjs.unix(item.debut),
      dayjs.unix(item.fin)
    )
  }).flat()
  const array = planning.map(item => item.horaires).flat()
  const intersection = array.filter(item => exports.includes(dayjs(item.startDate).format('DD/MM/YYYY')))
  const creneaux = array.filter(item =>
    dayjs(item.startDate).isAfter(dayjs(dates[0])) &&
    dayjs(item.endDate).isBefore(dates[1]) &&
    (item.heure !== true)
  )
  const data = get_data(dates, creneaux)
  return (
    <Root container={true}>
      <ComptabiliteModal
        open={open}
        setOpen={setOpen}
        ik={data.ik.filter(item => selected.includes(item.user))}
        hours={data.hours.filter(item => selected.includes(item.user))}
        cutter={data.cutter.filter(item => selected.includes(item.user))}
        holidays={data.holidays.filter(item => selected.includes(item.user))}
      />
      {
        (equipe.length > 0)
          ? <Paper>
              <ComptabiliteToolbar
                data={data.data}
                setOpen={setOpen}
              />
              <TableData
                data={data.data}
                selected={selected}
                count={equipe.length}
                All={(event) => All(dispatch, SELECTED_COMPTABILITE, equipe.map(item => item.id), event)}
                {...properties}
              >
                <caption>
                  <ComptabiliteExplain intersection={(intersection.length > 0)}/>
                </caption>
              </TableData>
            </Paper>
          : null
      }
      <ComptabiliteDownload/>
    </Root>
  )
}

export default Comptabilite
