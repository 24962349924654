import React from 'react'
import Bar from '../Bar/Bar'
import Empty from '../Includes/Empty'
import Error from '../Includes/Error'
import Aide from '../../Components/Aide/Aide'
import FirstLogin from '../Includes/FirstLogin'
import About from '../../Components/About/About'
import Compte from '../../Components/Compte/Compte'
import Heures from '../../Components/Heures/Heures'
import Accueil from '../../Components/Accueil/Accueil'
import HamburgerUser from '../Hamburger/HamburgerUser'
import Absences from '../../Components/Absences/Absences'
import Planning from '../../Components/Planning/Planning'
import Indemnites from '../../Components/Indemnites/Indemnites'
import Notifications from '../../Components/Notifications/Notifications'
import AbsencesPlanning from '../../Components/AbsencesPlanning/AbsencesPlanning'
import {
  useSelector
} from 'react-redux'
import {
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom'
import {
  routes
} from '../../Variables/Routes'

const RoutesUser = () => {
  const location = useLocation()
  const die = useSelector(state => state.login.die)
  const device = useSelector(state => state.device)
  const Check = (path) => (
    routes.filter(item =>
      (item.user) &&
      (
        (
          (device.mobile) &&
          (item.mobile)
        ) ||
        (
          (device.desktop) &&
          (item.desktop)
        )
      )
    ).find(item => item.path === path)
  )
  return (location.pathname === '/error')
    ? <Error/>
    : (die)
      ? <>
          <Bar/>
          <Switch>
            <Route exact path="/compte">
              <div className="flex-container">
                <Compte/>
              </div>
            </Route>
            <Route path="*">
              <Redirect to="/compte"/>
            </Route>
          </Switch>
        </>
      : <>
          <Bar/>
          <Switch>
            <Route exact path="/">
              <div className="flex-container">
                <HamburgerUser active="accueil"/>
                {
                  (Check('accueil'))
                    ? <Accueil/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/accueil">
              <div className="flex-container">
                <HamburgerUser active="accueil"/>
                {
                  (Check('accueil'))
                    ? <Accueil/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/planning">
              <div className="flex-container">
                <HamburgerUser active="planning"/>
                {
                  (Check('planning'))
                    ? <Planning/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/absences">
              <div className="flex-container">
                <HamburgerUser active="absences"/>
                {
                  (Check('absences'))
                    ? <Absences/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/absences-planning">
              <div className="flex-container">
                <HamburgerUser active="absences-planning"/>
                {
                  (Check('absences-planning'))
                    ? <AbsencesPlanning/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/heures">
              <div className="flex-container">
                <HamburgerUser active="heures"/>
                {
                  (Check('heures'))
                    ? <Heures/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/indemnites">
              <div className="flex-container">
                <HamburgerUser active="indemnites"/>
                {
                  (Check('indemnites'))
                    ? <Indemnites/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/aide">
              <div className="flex-container">
                <HamburgerUser active="aide"/>
                {
                  (Check('aide'))
                    ? <Aide/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/about">
              <div className="flex-container">
                <HamburgerUser active="about"/>
                {
                  (Check('about'))
                    ? <About/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/notifications">
              <div className="flex-container">
                <HamburgerUser active="notifications"/>
                {
                  (Check('notifications'))
                    ? <Notifications/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route exact path="/compte">
              <div className="flex-container">
                <HamburgerUser active="compte"/>
                {
                  (Check('compte'))
                    ? <Compte/>
                    : <Empty/>
                }
              </div>
            </Route>
            <Route path="*">
              <Redirect to="/error"/>
            </Route>
          </Switch>
          <FirstLogin/>
        </>
}

export default RoutesUser
