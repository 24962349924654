import React from 'react'
import styles from './FAQ.module.css'
import Texte from '../Includes/Texte'
import Question from '../Includes/Question'
import Container from '../Includes/Container'
import {
  Link
} from 'react-router-dom'
import {
  landing
} from '../../Variables/Landing'
import {
  variables
} from '../../Variables/Variables'

const FAQ = () => {
  return (
    <Container column={true}>
      <Texte
        full={true}
        titre="Vous avez #des questions ?#"
        description="Nous vous répondons..."
      >
        <Link
          to="#"
          className={styles.link}
          onClick={() => window.location = 'mailto:' + variables.mail.contact}
        >
          {variables.mail.contact}
        </Link>
      </Texte>
      <div className={styles.content}>
        {
          landing.faq.map((item, index) => {
            return (
              <Question
                key={index}
                item={item}
              />
            )
          })
        }
      </div>
    </Container>
  )
}

export default FAQ
