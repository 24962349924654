import React from 'react'
import ButtonDelete from '../Includes/Button/ButtonDelete'
import SettingOuverturePicker from './SettingOuverturePicker'
import ButtonDuplicate from '../Includes/Button/ButtonDuplicate'
import {
  Grid
} from '@mui/material'
import {
  get_day_time,
  get_day_time_max,
  get_day_time_min,
  get_day_time_max_max,
  get_day_time_min_min
} from '../../Functions/Dates'
import {
  start_case
} from '../../Functions/Strings'
import {
  is_valid
} from '../../Functions/Ouverture'
import {
  variables
} from '../../Variables/Variables'

const SettingOuvertureJour = ({
  jour,
  empty,
  state,
  Delete,
  picker,
  coupure,
  setState,
  Duplicate,
  setPicker
}) => {
  const Update = (moment, time, values) => {
    setState({
      ...state,
      [moment]: {
        ...state[moment],
        [time]: {
          h: values[0],
          m: values[1]
        }
      }
    })
  }
  const UpdatePicker = (moment, time, value) => {
    setPicker({
      ...picker,
      [moment]: {
        ...picker[moment],
        [time]: value
      }
    })
  }
  const Reset = (moment) => {
    setState({
      ...state,
      [moment]: {
        fin: {
          h: null,
          m: null
        },
        debut: {
          h: null,
          m: null
        }
      }
    })
  }
  return (
    <>
      <Grid
        xs={1}
        item={true}
        className="ouverture-day"
      >
        <div className="grid-day">{start_case(jour)}</div>
      </Grid>
      <Grid
        xs={1}
        item={true}
        className={
          (jour === 'dimanche')
            ? "ouverture-actions-dimanche"
            : "ouverture-actions"
        }
      >
        {
          (!empty)
            ? <>
                {
                  (jour !== 'dimanche')
                    ? <ButtonDuplicate
                        onClick={Duplicate}
                        className="ouverture-duplicate"
                        title="Recopier la ligne sur le jour suivant"
                        button={{
                          color: 'primary'
                        }}
                      />
                    : null
                }
                <ButtonDelete
                  color="red"
                  onClick={Delete}
                  title="Vider la ligne"
                  className="ouverture-remove"
                />
              </>
            : null
        }
      </Grid>
      <Grid
        item={true}
        xs={(coupure) ? 2 : 4}
      >
        <SettingOuverturePicker
          label="Début"
          Reset={() => Reset('am')}
          heures={state.am.debut.h}
          minutes={state.am.debut.m}
          onError={(error) => UpdatePicker('am', 'debut', (error !== null))}
          onAccept={(value) => Update('am', 'debut', [value.hour(), value.minute()])}
          minTime={get_day_time(variables.date.debut.heure, variables.date.debut.minute)}
          maxTime={
            (
              (coupure) &&
              (is_valid(state.pm.debut))
            )
              ? get_day_time_max_max(state.pm.debut.h, state.pm.debut.m)
              : get_day_time_max(variables.date.fin.heure, variables.date.fin.minute)
          }
        />
      </Grid>
      <Grid
        item={true}
        xs={(coupure) ? 2 : 4}
      >
        <SettingOuverturePicker
          label="Fin"
          heures={state.am.fin.h}
          minutes={state.am.fin.m}
          Reset={() => Reset('am')}
          disabled={!is_valid(state.am.debut)}
          minTime={get_day_time_min(state.am.debut.h, state.am.debut.m)}
          onError={(error) => UpdatePicker('am', 'fin', (error !== null))}
          onAccept={(value) => Update('am', 'fin', [value.hour(), value.minute()])}
          maxTime={
            (
              (coupure) &&
              (is_valid(state.pm.debut))
            )
              ? get_day_time_max(state.pm.debut.h, state.pm.debut.m)
              : get_day_time(variables.date.fin.heure, variables.date.fin.minute)
          }
        />
      </Grid>
      {
        (coupure)
          ? <>
              <Grid
                xs={2}
                item={true}
              >
                <SettingOuverturePicker
                  label="Début"
                  Reset={() => Reset('pm')}
                  heures={state.pm.debut.h}
                  minutes={state.pm.debut.m}
                  onError={(error) => UpdatePicker('pm', 'debut', (error !== null))}
                  onAccept={(value) => Update('pm', 'debut', [value.hour(), value.minute()])}
                  maxTime={get_day_time_max(variables.date.fin.heure, variables.date.fin.minute)}
                  minTime={
                    (is_valid(state.am.fin))
                      ? get_day_time_min(state.am.fin.h, state.am.fin.m)
                      : (is_valid(state.am.debut))
                        ? get_day_time_min_min(state.am.debut.h, state.am.debut.m)
                        : get_day_time(variables.date.debut.heure, variables.date.debut.minute)
                  }
                />
              </Grid>
              <Grid
                xs={2}
                item={true}
              >
                <SettingOuverturePicker
                  label="Fin"
                  heures={state.pm.fin.h}
                  minutes={state.pm.fin.m}
                  Reset={() => Reset('pm')}
                  disabled={!is_valid(state.pm.debut)}
                  minTime={get_day_time_min(state.pm.debut.h, state.pm.debut.m)}
                  onError={(error) => UpdatePicker('pm', 'fin', (error !== null))}
                  onAccept={(value) => Update('pm', 'fin', [value.hour(), value.minute()])}
                  maxTime={get_day_time(variables.date.fin.heure, variables.date.fin.minute)}
                />
              </Grid>
            </>
          : null
      }
    </>
  )
}

export default SettingOuvertureJour
