import clsx from 'clsx'
import React from 'react'
import styles from './Button.module.css'
import ButtonCircleClose from '../Button/ButtonCircleClose'
import {
  useSelector
} from 'react-redux'

const ButtonSliderDel = (props) => {
  const device = useSelector(state => state.device)
  return (
    <ButtonCircleClose
      color="red"
      className={
        clsx({
          [styles.left]: device.desktop,
          [styles.right]: device.mobile
        })
      }
      {...props}
    />
  )
}

export default ButtonSliderDel
