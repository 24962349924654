import TableCellSalarie from '../../Components/Includes/Table/TableCellSalarie'
import TableCellLegendeHeures from '../../Components/Includes/Table/TableCellLegendeHeures'
import TableCellCheckboxSalaries from '../../Components/Includes/Table/TableCellCheckboxSalaries'
import {
  color
} from '../Includes/Color'
import {
  heures
} from '../Includes/Heures'

export const properties = {
  type: {
    id: "salaries",
    groupe: "legendes"
  },
  columns: [
    {
      id: "checkbox",
      component: TableCellCheckboxSalaries
    },
    {
      ...color,
      label: "",
      padding: false
    },
    {
      id: "salarie",
      sort: true,
      padding: false,
      label: "Salarié",
      component: TableCellSalarie
    },
    {
      ...heures,
      right: true,
      padding: [undefined, undefined, undefined, 0],
      component: TableCellLegendeHeures
    }
  ]
}
