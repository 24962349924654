import React from 'react'
import TableCellContainer from './TableCellContainer'
import SettingsTableEdit from '../../Settings/SettingsTableEdit'
import SettingsTableDelete from '../../Settings/SettingsTableDelete'

const TableCellActionsSettings = ({ item }) => {
  return (
    <TableCellContainer width={2}>
      <SettingsTableEdit item={item}/>
      <SettingsTableDelete item={item}/>
    </TableCellContainer>
  )
}

export default TableCellActionsSettings
