import clsx from 'clsx'
import React from 'react'
import ButtonClose from '../Includes/Button/ButtonClose'
import HeurePicker from '../Includes/Picker/HeurePicker'
import {
  get_day_time
} from '../../Functions/Dates'

const SettingOuverturePicker = ({
  Reset,
  heures,
  minutes,
  disabled,
  onAccept,
  ...others
}) => {
  const open = (
    (minutes !== null) &&
    (heures !== null)
  )
  return (
    <>
      <HeurePicker
        disabled={disabled}
        onChange={onAccept}
        value={
          (open)
            ? get_day_time(heures, minutes)
            : null
        }
        className={
          clsx({
            'disabled': disabled,
            'closed': (open !== true),
            'heure-ouverture-picker': true
          })
        }
        {...others}
      />
      {
        (
          (open) &&
          (disabled !== true)
        )
          ? <ButtonClose
              title=""
              onClick={Reset}
              className="delete-ouverture-button"
            />
          : null
      }
    </>
  )
}

export default SettingOuverturePicker
