import React from 'react'
import ButtonAdd from '../Includes/Button/ButtonAdd'
import {
  useDispatch
} from 'react-redux'
import {
  MODAL_EQUIPE
} from '../../Redux/Actions/Equipe'

const EquipeToolbarContent = () => {
  const dispatch = useDispatch()
  return (
    <ButtonAdd
      mobile="Nouvel utilisateur"
      onClick={() => dispatch(MODAL_EQUIPE('', 'create'))}
    />
  )
}

export default EquipeToolbarContent
