import React from 'react'
import Button from './Button'

const ButtonEdit = React.forwardRef((props, ref) => {
  return (
    <Button
      ref={ref}
      icon="Edit"
      title="Modifier"
      {...props}
    />
  )
})

export default ButtonEdit
