import TableCellLegendeAbsents from '../../Components/Includes/Table/TableCellLegendeAbsents'
import {
  color
} from '../Includes/Color'

export const properties = {
  type: {
    id: "absents",
    groupe: "legendes"
  },
  sort: {
    by: "",
    order: ""
  },
  columns: [
    {
      ...color
    },
    {
      id: "absent",
      padding: true,
      component: TableCellLegendeAbsents
    }
  ]
}
