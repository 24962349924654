import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateHeureDebut = ({
  item,
  label
}) => {
  return (item.nombre === 0)
    ? <TableCellDate
        time={true}
        label={label}
        value={item.debut}
      />
    : null
}

export default TableCellDateHeureDebut
