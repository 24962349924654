import React from 'react'
import Form from '../Wrapper/Form'
import InputAutocomplete from '../Input/InputAutocomplete'
import {
  useSelector
} from 'react-redux'

const ToolbarSendContent = ({
  state,
  Update
}) => {
  const team = useSelector(state => state.equipe.equipe)
  const equipe = team.filter(item => item.mail !== '')
  return (
    <Form full={true}>
      {
        (equipe.length > 0)
          ? <InputAutocomplete
              label="Salariés"
              options={equipe}
              error={(state.error !== '')}
              value={equipe.filter(item => state.users.includes(item.id))}
              onChange={(event, value) => {
                if (value.length > 0) {
                  Update('error', '')
                }
                Update('users', value.map(item => item.id))
              }}
            />
          : null
      }
      {
        (state.error !== '')
          ? <div className="error-checkbox">{state.error}</div>
          : null
      }
    </Form>
  )
}

export default ToolbarSendContent
