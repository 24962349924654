import React from 'react'
import TableCellCheckbox from './TableCellCheckbox'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Select
} from '../../../Functions/Table'
import {
  SELECTED_CODES
} from '../../../Redux/Actions/Codes'

const TableCellCheckboxCodes = ({ item }) => {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.codes.selected)
  return (
    <TableCellCheckbox
      enable={(item.companie === '')}
      checked={selected.includes(item.id)}
      onClick={() => Select(dispatch, SELECTED_CODES, selected, item.id)}
    />
  )
}

export default TableCellCheckboxCodes
