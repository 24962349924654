import React from 'react'
import SelectInput from './SelectInput'

const SelectMail = (props) => {
  return (
    <SelectInput
      label="Destinataires"
      options={[
        {
          value: 0,
          label: 'Tous les titulaires'
        },
        {
          value: 1,
          label: 'Tous les administrateurs'
        },
        {
          value: 2,
          label: 'Tous les utilisateurs'
        },
        {
          value: 3,
          label: 'Par entreprise'
        },
        {
          value: 4,
          label: 'Par nom'
        },
        {
          value: 5,
          label: 'Par mail'
        }
      ]}
      {...props}
    />
  )
}

export default SelectMail
