import clsx from 'clsx'
import React from 'react'
import styles from './Icon.module.css'

const FooterIcon = ({
  children,
  ...others
}) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className={
        clsx({
          [styles.link]: true,
          [styles.hidden]: (children === undefined)
        })
      }
      {...others}
    >
      {children}
    </a>
  )
}

export default FooterIcon
