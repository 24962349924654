import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'

const TableCellRecuperation = ({
  item,
  label,
  device
}) => {
  const settings = useSelector(state => state.settings.variables)
  const value = settings.heure.recuperation[item.recuperation]
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={150}
        value={value}
      />
}

export default TableCellRecuperation
