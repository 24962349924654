import React from 'react'
import Alert from '../Includes/Dialog/Alert'
import {
  variables
} from '../../Variables/Variables'

const LogoModal = ({
  open,
  setOpen
}) => {
  return (
    <Alert
      open={open}
      titre="Cartes de crédit"
      Commit={() => setOpen(false)}
      texte={
        <table className="card-table">
          <tbody>
            <tr>
              <td className="bold">Paiement avec succès</td>
              <td className="card-point">:</td>
              <td>{variables.stripe.card.succeeded}</td>
            </tr>
            <tr>
              <td className="bold">Paiement en échec</td>
              <td className="card-point">:</td>
              <td>{variables.stripe.card.failed}</td>
            </tr>
            <tr>
              <td className="bold">Paiement avec action</td>
              <td className="card-point">:</td>
              <td>{variables.stripe.card.action}</td>
            </tr>
          </tbody>
        </table>
      }
    />
  )
}

export default LogoModal
