import React from 'react'
import UsersToolbarButtons from './UsersToolbarButtons'
import FilterAdmin from '../../Includes/Filter/FilterAdmin'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SET_FILTERS_TARGET
} from '../../../Redux/Actions/Filters'

const UsersToolbarMobile = ({ setPage }) => {
  const dispatch = useDispatch()
  const users = useSelector(state => state.users.users)
  const filters = useSelector(state => state.filters.filters)
  return (
    <div className="toolbar-actions-mobile toolbar-actions-mobile-users">
      <UsersToolbarButtons/>
      <FilterAdmin
        data={users}
        target="users"
        setPage={setPage}
        label="utilisateurs"
        state={filters.users}
        setState={(value) => dispatch(SET_FILTERS_TARGET('users', value))}
      />
    </div>
  )
}

export default UsersToolbarMobile
