import clsx from 'clsx'
import React from 'react'
import styles from './AbonnementOffer.module.css'
import AbonnementSeparate from './AbonnementSeparate'
import {
  School,
  LocalOffer
} from '@mui/icons-material'
import {
  get_time
} from '../../../Functions/Heures'
import {
  offer_time
} from '../../../Functions/Functions'

const AbonnementOffer = ({
  days,
  free,
  time,
  mobile,
  favorite,
  abonnement
}) => {
  return (
    <>
      <AbonnementSeparate
        mobile={mobile}
        favorite={favorite}
      />
      <div
        className={
          clsx({
            [styles.mobile]: mobile,
            [styles.container]: true,
            [styles.favorite]: favorite
          })
        }
      >
        {
          (time !== undefined)
            ? <>
                <School className={styles.icone}/>
                <span className={styles.span}>{get_time(time)} formation</span>
              </>
            : <>
                <LocalOffer className={styles.icone}/>
                {
                  (abonnement.free_days > 0)
                    ? (free)
                      ? (mobile)
                        ? <span className={styles.span}>{offer_time(days)}</span>
                        : <>
                            <span
                              className={
                                clsx(
                                  styles.old,
                                  styles.span
                                )
                              }
                            >
                              {offer_time(abonnement.free_days)}
                            </span>
                            <span
                              className={
                                clsx(
                                  styles.new,
                                  styles.span
                                )
                              }
                            >
                              {offer_time(days)}
                            </span>
                          </>
                      : <span className={styles.span}>{offer_time(abonnement.free_days)}</span>
                    : (free)
                      ? <span className={styles.span}>{offer_time(days)}</span>
                      : null
                }
              </>
        }
      </div>
    </>
  )
}

export default AbonnementOffer
