import TableCellColor from '../../Components/Includes/Table/TableCellColor'

export const color = {
  id: "color",
  sort: false,
  mobile: true,
  padding: true,
  label: "Couleur",
  component: TableCellColor
}
