import React from 'react'
import styles from './Description.module.css'
import Word from '../../Components/Includes/Misc/Word'

const Description = ({ children }) => {
  return (
    <p className={styles.description}>
      <Word bold={true}>
        {children}
      </Word>
    </p>
  )
}

export default Description
