import React from 'react'
import styles from './Compte.module.css'
import dayjs from '../../Libraries/dayjs'
import Titre from '../Includes/Misc/Titre'
import {
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'

const CompteEngagement = () => {
  const device = useSelector(state => state.device)
  const engagement = useSelector(state => state.compte.engagement)
  return (
    <Paper
      className={
        (device.mobile)
          ? styles.mobile
          : styles.desktop
      }
    >
      <Toolbar>
        <Titre>Mon engagement</Titre>
      </Toolbar>
      <div className="engagement-compte-explain">
        <div>Vous êtes engagé jusqu'au : <b>{dayjs.unix(engagement.date).endOf('day').format('DD/MM/YYYY')}</b></div>
      </div>
    </Paper>
  )
}

export default CompteEngagement
