import React from 'react'
import ButtonAdmin from './ButtonAdmin'

const ButtonAdminAdd = (props) => {
  return (
    <ButtonAdmin
      icon="Add"
      color="blue"
      title="Créer"
      {...props}
    />
  )
}

export default ButtonAdminAdd
