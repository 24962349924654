import React from 'react'
import InputTextField from './InputTextField'
import {
  useSelector
} from 'react-redux'
import {
  CheckConges
} from '../../../Functions/Check'
import {
  absences
} from '../../../Variables/Variables'

const InputConges = ({
  year,
  error,
  value,
  ...others
}) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <InputTextField
      error={error}
      value={value}
      label={"Congés " + absences[year].year}
      helperText={
        (error)
          ? (!CheckConges(value))
            ? "Le nombre de congés " + absences[year].year + " doit être un entier/décimal de la forme -12, 12, 12.5"
            : (parseFloat(value) < settings.minimum[year])
              ? "Le nombre de congés " + absences[year].year + " doit être supérieur à " + settings.minimum[year]
              : (parseFloat(value) > settings.maximum[year])
                ? "Le nombre de congés " + absences[year].year + " doit être inférieur à " + settings.maximum[year]
                : null
          : null
      }
      {...others}
    />
  )
}

export default InputConges
