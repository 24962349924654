import {
  USER_LOGOUT
} from './Login'
import {
  DEL_PLANNING_HORAIRE
} from './Planning'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const NOM = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'NOM',
      value: value
    })
  }
}

export const DATA = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DATA',
      value: value
    })
  }
}

export const COMMIT = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'COMMIT',
      value: value
    })
  }
}

export const ERREURS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ERREURS',
      value: value
    })
  }
}

export const SET_RENAME = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_RENAME',
      value: value
    })
  }
}

export const ADD_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_SEMAINE',
      value: value
    })
  }
}

export const DEL_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_SEMAINE',
      value: value
    })
  }
}

export const ADD_CURRENT = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_CURRENT',
      value: value
    })
  }
}

export const DEL_CURRENT = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_CURRENT',
      value: value
    })
  }
}

export const SET_WORKING = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_WORKING',
      value: value
    })
  }
}

export const EDIT_CURRENT = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_CURRENT',
      value: value
    })
  }
}

export const EDIT_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_SEMAINE',
      value: value
    })
  }
}

export const INIT_SEMAINES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_SEMAINES',
      value: value
    })
  }
}

export const CLONE_CURRENT = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'CLONE_CURRENT',
      value: value
    })
  }
}

export const CLONE_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'CLONE_SEMAINE',
      value: value
    })
  }
}

export const RENAME_SEMAINE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'RENAME_SEMAINE',
      value: value
    })
  }
}

export const SET_DATE_SEMAINES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_DATE_SEMAINES',
      value: value
    })
  }
}

export const DEL_CURRENT_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_CURRENT_EQUIPE',
      value: value
    })
  }
}

export const SET_CURRENT_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CURRENT_EQUIPE',
      value: value
    })
  }
}

export const DEL_CURRENT_HORAIRE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_CURRENT_HORAIRE',
      value: value
    })
  }
}

export const EDIT_CURRENT_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_CURRENT_EQUIPE',
      value: value
    })
  }
}

export const EDIT_CURRENT_HORAIRE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_CURRENT_HORAIRE',
      value: value
    })
  }
}

export const CLEAR_CURRENT_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_CURRENT_EQUIPE',
      value: value
    })
  }
}

export const GET_SEMAINES = () => {
  return (dispatch) => {
    const data = JSON.stringify({
      action: 'semaines'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(INIT_SEMAINES(data.response))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const CLONE_WEEK = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'add-semaine'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(CLONE_SEMAINE(value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const RENAME_WEEK = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'rename-semaine'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(RENAME_SEMAINE(value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CREATE_SEMAINE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'add-semaine'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(ADD_SEMAINE(value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const MODIFY_SEMAINE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'edit-semaine'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(EDIT_SEMAINE(value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const REMOVE_SEMAINE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'del-semaine'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(DEL_SEMAINE(value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const DELETE_PLANNING_HORAIRE = (value) => {
  return (dispatch) => {
    dispatch(DEL_PLANNING_HORAIRE(value))
    const data = JSON.stringify({
      value: value,
      action: 'del-planning-creneau'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}
