import clsx from 'clsx'
import React from 'react'
import Titre from '../Includes/Titre'
import styles from './About.module.css'
import Container from '../Includes/Container'
import AboutContent from '../../Components/About/AboutContent'
import {
  useSelector
} from 'react-redux'

const About = () => {
  const device = useSelector(state => state.device)
  return (
    <Container column={true}>
      <Titre
        full={true}
        className={
          clsx({
            [styles.titre]: true,
            [styles.mobile]: device.mobile
          })
        }
      >
        Qui #sommes-nous ?#
      </Titre>
      <AboutContent/>
    </Container>
  )
}

export default About
