import clsx from 'clsx'
import React from 'react'
import {
  useSelector
} from 'react-redux'
import {
  Dialog
} from '@mui/material'

const Popup = ({
  alert,
  children,
  className,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (
    <Dialog
      transitionDuration={0}
      fullScreen={device.mobile}
      className={
        clsx(
          className,
          (alert)
            ? (device.mobile)
              ? 'alert-mobile'
              : 'alert-desktop'
            : (device.mobile)
              ? 'dialog-mobile'
              : 'dialog-desktop'
        )
      }
      {...others}
    >
      {children}
    </Dialog>
  )
}

export default Popup
