import React from 'react'
import styles from './TableMobileRowContent.module.css'
import {
  Box,
  Collapse,
  TableRow,
  TableCell
} from '@mui/material'

const TableMobileRowContent = ({
  children,
  expanded,
  ...others
}) => {
  return (
    <TableRow {...others}>
      <TableCell colSpan={3}>
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit={true}
        >
          <Box className={styles.box}>
            {children}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default TableMobileRowContent
