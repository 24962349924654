import {
  get_ouverture
} from '../../Functions/Ouverture'

const initial = {
  ouverture: get_ouverture()
}

function ouverture(state = initial, action) {
  switch (action.type) {
    case 'INIT_OUVERTURE':
      return {
        ...state,
        ouverture: (action.value === '')
          ? initial.ouverture
          : action.value
      }
    default:
      return state
  }
}

export default ouverture
