import clsx from 'clsx'
import React from 'react'
import Bulle from '../Wrapper/Bulle'
import Absences from '../Hooks/Absences'
import styles from './Toolbar.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Button
} from '@mui/material'

const ToolbarOpen = ({
  text,
  onVisibilityToggle
}) => {
  const absences = Absences()
  const view = useSelector(state => state.planning.view)
  return (
    <Bulle
      arrow={true}
      placement="top"
      title={
        (absences)
          ? 'Modifier le mois'
          : (view === 'Week')
            ? 'Modifier la semaine'
            : 'Modifier le jour'
      }
    >
      <Button
        onClick={() => onVisibilityToggle(true)}
        className={
          clsx(
            styles.color,
            styles.button
          )
        }
      >
        {text}
      </Button>
    </Bulle>
  )
}

export default ToolbarOpen
