import React from 'react'
import TableData from '../Table/TableData'
import LegendeAccordion from './LegendeAccordion'
import {
  useSelector
} from 'react-redux'
import {
  sort_timestamp
} from '../../../Functions/Sort'
import {
  properties
} from '../../../Settings/Legende/Absents'

const LegendeAbsents = () => {
  const absents = useSelector(state => state.planning.absents)
  return (
    <LegendeAccordion
      warning={true}
      titre={"Absents (" + absents.length + ")"}
    >
      <TableData
        all={true}
        head={false}
        border={true}
        random={true}
        bottom={false}
        pagination={false}
        data={sort_timestamp(absents)}
        {...properties}
      />
    </LegendeAccordion>
  )
}

export default LegendeAbsents
