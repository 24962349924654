import React from 'react'
import Texte from '../Includes/Texte'
import Video from '../Includes/Video'
import Container from '../Includes/Container'
import {
  useSelector
} from 'react-redux'

const Demo = () => {
  const device = useSelector(state => state.device)
  return (
    <Container reverse={device.mobile}>
      <Video
        width={60}
        video="demo"
      />
      <Texte
        right={true}
        titre="Petit tour #d'horizon !#"
        description="Parce qu'une vidéo vaut bien mieux qu'un long discours... voyez par vous-même !"
      />
    </Container>
  )
}

export default Demo
