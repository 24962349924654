import React from 'react'
import InputTextField from './InputTextField'

const InputFonction = ({
  error,
  existe,
  ...others
}) => {
  return (
    <InputTextField
      error={error}
      label="Fonction"
      inputProps={{
        style: {
          textTransform: 'capitalize'
        }
      }}
      helperText={
        (error)
          ? (existe)
            ? "Cette fonction existe déjà"
            : "Cette fonction est invalide"
          : null
      }
      {...others}
    />
  )
}

export default InputFonction
