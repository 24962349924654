import React from 'react'
import dayjs from '../../../Libraries/dayjs'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'
import {
  CheckTimestamp
} from '../../../Functions/Check'

const TableCellDate = ({
  full,
  time,
  label,
  value,
  desktop,
  ...others
}) => {
  const device = useSelector(state => state.device)
  const date = (CheckTimestamp(value))
    ? dayjs.unix(value)
    : dayjs(value)
  const format = (time)
    ? 'HH:mm'
    : (full)
      ? 'DD/MM/YYYY HH:mm'
      : 'DD/MM/YYYY'
  const empty = (
    (value === null) ||
    (value === 0)
  )
  const object = {
    empty: empty,
    value: date.format(format)
  }
  return (
    (desktop !== true) &&
    (device.mobile)
  )
    ? <TableCellMobile
        label={label}
        {...object}
        {...others}
      />
    : <TableCellDesktop
        max={200}
        {...object}
        {...others}
      />
}

export default TableCellDate
