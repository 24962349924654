import React from 'react'
import InputTextField from './InputTextField'

const InputCommentaire = ({
  error,
  ...others
}) => {
  return (
    <InputTextField
      rows={8}
      error={error}
      multiline={true}
      label="Commentaire"
      helperText={
        (error)
          ? "Ce commentaire est invalide"
          : null
      }
      {...others}
    />
  )
}

export default InputCommentaire
