import clsx from 'clsx'
import React from 'react'
import styles from './Destinataires.module.css'
import {
  Chip,
  FormHelperText
} from '@mui/material'
import {
  variables
} from '../../../Variables/Variables'

const Destinataires = ({
  mails,
  Delete
}) => {
  const error = (mails.length > variables.mail.maximum)
  return (
    <div className={styles.container}>
      <div
        className={
          clsx({
            [styles.error]: error,
            [styles.content]: true
          })
        }
      >
        {
          (mails.length > 0)
            ? mails.sort().map(item => {
                return (
                  <Chip
                    key={item}
                    label={item}
                    className={styles.chip}
                    onDelete={() => Delete(item)}
                  />
                )
              })
            : <div className={styles.empty}>Aucun destinataire</div>
        }
      </div>
      {
        (error)
          ? <FormHelperText
              error={true}
              className={styles.helper}
            >
              Vous ne pouvez pas envoyer un mail à plus de {variables.mail.maximum} destinataires.
            </FormHelperText>
          : null
      }
    </div>
  )
}

export default Destinataires
