import React from 'react'
import TableCellField from './TableCellField'
import ButtonEdit from '../Button/ButtonEdit'
import ActionSheet from '../Action/ActionSheet'
import ButtonDelete from '../Button/ButtonDelete'
import TableCellContainer from './TableCellContainer'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  start_case
} from '../../../Functions/Strings'
import {
  MODAL_FONCTIONS
} from '../../../Redux/Actions/Fonctions'

const TableCellActionsFonctions = ({
  item,
  device
}) => {
  const dispatch = useDispatch()
  const equipe = useSelector(state => state.equipe.equipe)
  const condition = (equipe.map(item => item.fonction).find(object => object === item.id) === undefined)
  const Edit = (id) => {
    dispatch(MODAL_FONCTIONS(id, 'edit'))
  }
  const Delete = (id) => {
    dispatch(MODAL_FONCTIONS(id, 'delete', start_case(item.fonction)))
  }
  return (device.mobile)
    ? <TableCellField>
        <ActionSheet
          options={[
            {
              id: 0,
              label: 'Modifier',
              action: () => Edit(item.id)
            },
            {
              id: 1,
              label: 'Supprimer',
              condition: condition,
              action: () => Delete(item.id)
            }
          ]}
        />
      </TableCellField>
    : <TableCellContainer width={2}>
        <ButtonEdit
          onClick={() => Edit(item.id)}
        />
        {
          (condition)
            ? <ButtonDelete
                onClick={() => Delete(item.id)}
              />
            : null
        }
      </TableCellContainer>
}

export default TableCellActionsFonctions
