import dayjs from '../Libraries/dayjs'
import store from '../Redux/Store/Store'
import {
  variables
} from '../Variables/Variables'

export const detail = {
  jour: 1,
  mois: 0,
  annee: 2018
}

export const week_date = dayjs()
  .month(detail.mois)
  .year(detail.annee)
  .date(detail.jour)
  .startOf('day')

export function first_day(date) {
  return dayjs(date).startOf('day')
}

export function first_week(date) {
  return dayjs(date).startOf('week')
}

export function is_last_day(day) {
  return (dayjs(day).weekday() === 6)
}

export function is_first_day(day) {
  return (dayjs(day).weekday() === 0)
}

export function format_export(date) {
  return dayjs.unix(date).format('DDMMYYYY')
}

export function format_week_date(day) {
  return week_date.add(day, 'days')
}

export function first_day_format(date, format = 'Week') {
  return (format === 'Week')
    ? first_week(date).format('YYYYMMDD')
    : first_day(date).format('YYYYMMDD')
}

export function get_day_time(heures, minutes, date = dayjs()) {
  return date
    .startOf('day')
    .add(heures, 'hours')
    .add(minutes, 'minutes')
}

export function get_day_time_min(heures, minutes, date = dayjs()) {
  return get_day_time(heures, minutes, date).add(variables.heure.step, 'minutes')
}

export function get_day_time_min_min(heures, minutes, date = dayjs()) {
  return get_day_time(heures, minutes, date).add(variables.heure.step * 2, 'minutes')
}

export function get_day_time_max(heures, minutes, date = dayjs()) {
  return get_day_time(heures, minutes, date).subtract(variables.heure.step, 'minutes')
}

export function get_day_time_max_max(heures, minutes, date = dayjs()) {
  return get_day_time(heures, minutes, date).subtract(variables.heure.step * 2, 'minutes')
}

export function get_weeks(start, end) {
  let weeks = []
  end = end.endOf('week')
  start = start.startOf('week')
  while (start.isSameOrBefore(end)) {
    const day = start.weekday()
    if (day === 0) {
      weeks.push(start.format('DD/MM/YYYY'))
    }
    start = start.add(1, 'days')
  }
  return weeks
}

export function format_date(value) {
  return {
    endDate: week_date
      .add(value.jour, 'days')
      .add(value.fin.heure, 'hours')
      .add(value.fin.minute, 'minutes')
      .toDate(),
    startDate: week_date
      .add(value.jour, 'days')
      .add(value.debut.heure, 'hours')
      .add(value.debut.minute, 'minutes')
      .toDate()
  }
}

export function format_date_creneau(jour, value) {
  const date = store.getState().planning.date
  const view = store.getState().planning.view
  return {
    endDate: (view === 'Week')
      ? dayjs(date)
        .startOf('week')
        .add(jour, 'days')
        .add(value[1], 'hours')
        .toDate()
      : dayjs(date)
        .startOf('day')
        .add(value[1], 'hours')
        .toDate(),
    startDate: (view === 'Week')
      ? dayjs(date)
        .startOf('week')
        .add(jour, 'days')
        .add(value[0], 'hours')
        .toDate()
      : dayjs(date)
        .startOf('day')
        .add(value[0], 'hours')
        .toDate()
  }
}
