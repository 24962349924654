import React from 'react'
import dayjs from '../../Libraries/dayjs'
import Titre from '../Includes/Misc/Titre'
import styles from './ComptabiliteToolbar.module.css'
import DateRangePicker from '../Includes/Picker/DateRangePicker'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Button,
  Toolbar
} from '@mui/material'
import {
  Save,
  Send
} from '@mui/icons-material'
import {
  PDF
} from '../../Functions/PDF'
import {
  sort_name
} from '../../Functions/Sort'
import {
  SET_PDF,
  COMPTABILITE
} from '../../Redux/Actions/Comptabilite'

const ComptabiliteToolbar = ({
  data,
  setOpen
}) => {
  const dispatch = useDispatch()
  const equipe = useSelector(state => state.equipe.equipe)
  const dates = useSelector(state => state.comptabilite.dates)
  const settings = useSelector(state => state.settings.settings)
  const selected = useSelector(state => state.comptabilite.selected)
  const comptable = settings.find(item => item.id === 'comptable')
  const detail = settings.find(item => item.id === 'detail')
  const enable = (data.filter(item =>
    (selected.includes(item.id)) &&
    (
      (item.indemnites > 0) ||
      (item.conges > 0) ||
      (item.heures > 0) ||
      (item.supp > 0)
    )
  ).length > 0)
  return (settings.length > 0)
    ? <Toolbar>
        <Titre>
          Comptabilité
          {
            (selected.length > 0)
              ? <span className="table-compteur">
                  ({selected.length}/{equipe.length})
                </span>
              : null
          }
        </Titre>
        <DateRangePicker
          value={dates}
          className="date-range-picker-comptabilite"
          slotProps={{
            textField: {
              size: 'small'
            }
          }}
          onChange={(value) => {
            const date = (value[1] !== null)
              ? value[1].endOf('day')
              : null
            dispatch(COMPTABILITE([value[0], date]))
          }}
        />
        <Button
          color="primary"
          variant="contained"
          startIcon={<Save/>}
          className={styles.button}
          disabled={
            (dates[0] === null) ||
            (dates[1] === null) ||
            (!enable)
          }
          onClick={() => {
            PDF(
              sort_name(data).filter(item => selected.includes(item.id)),
              dayjs(dates[0]),
              dayjs(dates[1]),
              false,
              detail.value
            )
          }}
        >
          Exporter
        </Button>
        <Button
          color="green"
          variant="contained"
          startIcon={<Send/>}
          className={styles.button}
          disabled={
            (comptable.value === '') ||
            (dates[0] === null) ||
            (dates[1] === null) ||
            (!enable)
          }
          onClick={() => {
            dispatch(SET_PDF(sort_name(data).filter(item => selected.includes(item.id))))
            setOpen(true)
          }}
        >
          Comptable
        </Button>
      </Toolbar>
    : null
}

export default ComptabiliteToolbar
