import React from 'react'
import Form from '../Wrapper/Form'
import DatePicker from '../Picker/DatePicker'
import SelectInput from '../Select/SelectInput'
import {
  useSelector
} from 'react-redux'

const FilterCompaniesContent = ({
  state,
  Update
}) => {
  const types = useSelector(state => state.landing.types)
  const abonnements = useSelector(state => state.landing.abonnements)
  return (
    <Form>
      <SelectInput
        filter={true}
        label="Abonnement"
        value={state.abonnement}
        setValue={(value) => Update('abonnement', value)}
        options={abonnements.map(item => {
          return {
            value: item.name,
            label: item.name
          }
        })}
      />
      <SelectInput
        label="Code"
        filter={true}
        value={state.code}
        setValue={(value) => Update('code', value)}
        options={[
          {
            value: 1,
            label: 'Avec'
          },
          {
            value: 0,
            label: 'Sans'
          }
        ]}
      />
      <SelectInput
        label="Type"
        filter={true}
        value={state.type}
        setValue={(value) => Update('type', value)}
        options={Object.values(types).map(item => {
          return {
            value: item,
            label: item
          }
        })}
      />
      <SelectInput
        filter={true}
        label="Engagement"
        value={state.engagement}
        setValue={(value) => Update('engagement', value)}
        options={[
          {
            value: 1,
            label: 'Oui'
          },
          {
            value: 0,
            label: 'Non'
          }
        ]}
      />
      <SelectInput
        filter={true}
        label="Statut"
        value={state.status}
        setValue={(value) => Update('status', value)}
        options={[
          {
            value: 0,
            label: 'Activée'
          },
          {
            value: 1,
            label: 'Désactivée'
          }
        ]}
      />
      <SelectInput
        label="Beta"
        filter={true}
        value={state.beta}
        setValue={(value) => Update('beta', value)}
        options={[
          {
            value: 1,
            label: 'Oui'
          },
          {
            value: 0,
            label: 'Non'
          }
        ]}
      />
      <SelectInput
        filter={true}
        value={state.last}
        label="Dernier paiement"
        setValue={(value) => Update('last', value)}
        options={[
          {
            value: 1,
            label: 'Succès'
          },
          {
            value: 0,
            label: 'Erreur'
          }
        ]}
      />
      <div className="planning-picker">
        <SelectInput
          filter={true}
          label="Planning"
          value={state.planning}
          setValue={(value) => {
            Update('planning', value)
            if (value <= 0) {
              Update('date', null)
              Update('error', false)
            }
          }}
          options={[
            {
              value: 0,
              label: 'Aucune date'
            },
            {
              value: 1,
              label: 'Après le'
            },
            {
              value: 2,
              label: 'Avant le'
            }
          ]}
        />
        {
          (state.planning > 0)
            ? <DatePicker
                value={state.date}
                onChange={(value) => {
                  Update('date', value)
                  Update('error', false)
                }}
                slotProps={{
                  textField: {
                    error: state.error
                  }
                }}
              />
            : null
        }
      </div>
    </Form>
  )
}

export default FilterCompaniesContent
