import {
  landing
} from './Landing'
import {
  variables
} from './Variables'

export const messages = {
  error: {
    die: "Cette page n'existe pas",
    beta: "Cette fonction n'est pas accessible en mode beta",
    demo: "Cette fonction n'est pas accessible en mode démo",
    authorized: "Vous n'êtes pas autorisé à accéder à la ressource",
    access: {
      "data": "die",
      "status": "KO",
      "response": "Votre session a expiré"
    }
  },
  stripe: {
    success: "Félicitations, votre paiement a bien été accepté !",
    error: "Votre paiement n'a pas pu aboutir, veuillez vérifier votre saisie. Si le problème persiste, merci de nous contacter : " + variables.mail.contact,
    empty: {
      card: "Votre numéro de carte ne peut pas être vide.",
      cvc: "Le code de sécurité de votre carte ne peut pas être vide.",
      expire: "L'année d'expiration de votre carte ne peut pas être vide."
    },
    card: {
      error: "Nous n'avons pas pu valider votre méthode de paiement, veuillez vérifier votre saisie. Si le problème persiste, merci de nous contacter : " + variables.mail.contact
    },
    die: {
      admin: [
        "Votre moyen de paiement est expiré, pour pouvoir continuer à utiliser " + landing.nom + ", vous devez le mettre à jour.",
        "L'accès de vos utilisateurs est également suspendu."
      ],
      user: [
        "Votre compte a été bloqué suite à un défaut de paiement.",
        "Veuillez vous rapprocher de vos administrateurs pour être débloqué."
      ]
    }
  }
}
