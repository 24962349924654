import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'

const SelectType = (props) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <SelectInput
      label="Type"
      options={settings.codes.types.map((item, index) => {
        return {
          value: index,
          label: item.label
        }
      })}
      {...props}
    />
  )
}

export default SelectType
