import React from 'react'
import TableCellComptabilite from './TableCellComptabilite'

const TableCellComptabiliteIndemnites = ({ item }) => {
  return (
    <TableCellComptabilite item={item.indemnites}/>
  )
}

export default TableCellComptabiliteIndemnites
