import clsx from 'clsx'
import React from 'react'
import UserColor from '../Includes/User/UserColor'
import {
  useSelector
} from 'react-redux'

const AbsencesPlanningAppointment = ({ data }) => {
  const equipe = useSelector(state => state.equipe.equipe)
  const user = equipe.find(item => item.id === data.user)
  return (
    <div
      className={
        clsx(
          'appoint',
          'appoint-absence',
          (data.duree === 0)
            ? 'absence-am'
            : (data.duree === 1)
              ? 'absence-pm'
              : 'absence-day'
        )
      }
    >
      {
        [data].map((item, index) => {
          return (
            <UserColor
              key={index}
              color={user.color}
              className="appoint-div"
            />
          )
        })
      }
    </div>
  )
}

export default AbsencesPlanningAppointment
