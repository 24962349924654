import React from 'react'

const InputStripe = React.forwardRef(({
  component: Component,
  ...others
}, ref) => {
  React.useImperativeHandle(ref, () => ({
    focus: () => ref.current.focus
  }))
  return <Component {...others}/>
})

export default InputStripe
