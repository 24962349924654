const initial = {
  users: [],
  search: '',
  selected: [],
  loading: true,
  modal: {
    texte: '',
    open: false,
    action: undefined
  }
}

function users(state = initial, action) {
  switch (action.type) {
    case 'USERS':
      return {
        ...state,
        selected: [],
        users: action.value
      }
    case 'DISCONNECT':
      return {
        ...state,
        users: state.users.map(item => {
          return (action.users.includes(item.id))
            ? {
                ...item,
                logged: 0
              }
            : item
        })
      }
    case 'USER_BLOCK':
      return {
        ...state,
        users: state.users.map(item => {
          return (action.users.includes(item.id))
            ? {
                ...item,
                enable: (action.value) ? 0 : 1
              }
            : item
        })
      }
    case 'MODAL_USERS':
      return {
        ...state,
        modal: {
          open: action.open,
          texte: action.texte,
          action: action.action
        }
      }
    case 'SEARCH_USER':
      return {
        ...state,
        selected: [],
        search: action.value
      }
    case 'LOADING_USERS':
      return {
        ...state,
        loading: false
      }
    case 'SELECTED_USERS':
      return {
        ...state,
        selected: action.value
      }
    default:
      return state
  }
}

export default users
