import React from 'react'
import Form from '../Includes/Wrapper/Form'
import InputCommentaire from '../Includes/Input/InputCommentaire'
import {
  Warning
} from '@mui/icons-material'
import {
  CheckComment
} from '../../Functions/Check'

const ComptabiliteModalCommentaire = ({
  error,
  state,
  Update,
  UpdateError
}) => {
  const Commentaire = (value) => {
    Update('commentaire', value)
    UpdateError('commentaire', !CheckComment(value))
  }
  return (
    <>
      <div className="inline-block-description">Voulez-vous ajouter des remarques pour le comptable ?</div>
      <Form
        full={true}
        large={true}
      >
        <div className="inline-block-texte">
          <InputCommentaire
            setValue={Commentaire}
            error={error.commentaire}
            value={state.commentaire}
          />
        </div>
      </Form>
      <div className="inline-block-explain">
        <div><Warning className="danger-comptable"/>L'export comptable engendre certains actions irréversibles :</div>
        <div><span/><span>- les indemnités kilométriques comprises entre les dates de l'export seront passées à l'état terminé</span></div>
        <div><span/><span>- les heures supplémentaires (récupération en salaire) comprises entre les dates de l'export seront passées à l'état terminé</span></div>
        <div><span/><span>- les absences comprises entre les dates de l'export seront passées à l'état terminé et déduites des compteurs congés des salariés (si des absences chevauchent les dates de l'export, ces dernières seront "séparées" en deux)</span></div>
      </div>
    </>
  )
}

export default ComptabiliteModalCommentaire
