import clsx from 'clsx'
import dayjs from '../../Libraries/dayjs'
import Planning from '../Includes/Hooks/Planning'
import PlanningUserMobile from './PlanningUserMobile'
import ButtonEdit from '../Includes/Button/ButtonEdit'
import DatePicker from '../Includes/Picker/DatePicker'
import Condition from '../Includes/Condition/Fonctions'
import HeurePicker from '../Includes/Picker/HeurePicker'
import SelectInput from '../Includes/Select/SelectInput'
import styles from './PlanningModalCreneauPaper.module.css'
import CreneauFonctions from '../Includes/Creneau/CreneauFonctions'
import React, {
  useRef
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  sort_name,
  sort_absent
} from '../../Functions/Sort'
import {
  get_day_time,
  get_day_time_max,
  get_day_time_min
} from '../../Functions/Dates'
import {
  start_case
} from '../../Functions/Strings'
import {
  variables
} from '../../Variables/Variables'

const PlanningModalCreneauPaper = ({
  data,
  state,
  Update
}) => {
  const ref = useRef()
  const planning = Planning()
  const condition = Condition()
  const [day, setDay] = React.useState(false)
  const [fin, setFin] = React.useState(false)
  const [debut, setDebut] = React.useState(false)
  const event = useSelector(state => state.planning.event)
  const absents = useSelector(state => state.planning.absents)
  const classes = {
    [styles.content]: true,
    'mobile-read-content': event.read
  }
  const liste = sort_name(state.users).map(item  => {
    return {
      ...item,
      heure: (data.heure && (data.sens === 1)),
      absent: (absents.filter(item => item.creneau === data.id).map(item => item.id).includes(item.id))
        ? 1
        : 0
    }
  })
  return (
    <Paper
      elevation={2}
      className={styles.inner}
    >
      {
        (planning)
          ? <DatePicker
              open={day}
              onClose={() => setDay(false)}
              className="modal-mobile-pickers"
              value={state.dates[0].startOf('day')}
              onAccept={(value) => {
                setDay(false)
                Update('dates', [
                  get_day_time(state.dates[0].hour(), state.dates[0].minute(), value),
                  get_day_time(state.dates[1].hour(), state.dates[1].minute(), value)
                ])
              }}
            />
          : null
      }
      <HeurePicker
        open={debut}
        value={state.dates[0]}
        onClose={() => setDebut(false)}
        className="modal-mobile-pickers"
        onError={(error) => Update('error', (error !== null))}
        maxTime={get_day_time_max(variables.date.fin.heure, variables.date.fin.minute)}
        minTime={get_day_time(variables.date.debut.heure, variables.date.debut.minute)}
        onAccept={(value) => {
          setDebut(false)
          Update('dates', [
            get_day_time(value.hour(), value.minute(), state.dates[0]),
            state.dates[1]
          ])
        }}
      />
      <HeurePicker
        open={fin}
        value={state.dates[1]}
        onClose={() => setFin(false)}
        className="modal-mobile-pickers"
        onError={(error) => Update('error', (error !== null))}
        maxTime={get_day_time(variables.date.fin.heure, variables.date.fin.minute)}
        minTime={get_day_time_min(state.dates[0].hour(), state.dates[0].minute())}
        onAccept={(value) => {
          setFin(false)
          Update('dates', [
            state.dates[0],
            get_day_time(value.hour(), value.minute(), state.dates[1])
          ])
        }}
      />
      <div className="modal-mobile-content">
        <div className={clsx(classes)}>
          <div>
            <i className="icon calendar"/>
            <span>{start_case(state.dates[0].format('dddd'))}</span>
            {
              (
                (
                  (!event.read) &&
                  (planning)
                ) || (data.heure)
              )
                ? ' ' + start_case(state.dates[0].format('D MMMM YYYY'))
                : null
            }
          </div>
          {
            <>
              {
                (!event.read)
                  ? <ButtonEdit
                      ref={ref}
                      onClick={() => setDay(true)}
                    />
                  : null
              }
              {
                (!planning)
                  ? <SelectInput
                      open={day}
                      hidden={true}
                      onClose={() => setDay(false)}
                      value={state.dates[0].weekday()}
                      MenuProps={{
                        anchorEl: ref.current,
                        anchorReference: 'anchorEl'
                      }}
                      options={[...Array(7).keys()].map((item, index) => {
                        return {
                          value: index,
                          label: start_case(dayjs().weekday(index).format('dddd'))
                        }
                      })}
                      setValue={(value) => {
                        const jour = parseInt(value) + 1
                        setDay(false)
                        Update('dates', [
                          get_day_time(state.dates[0].hour(), state.dates[0].minute(), dayjs('0' + jour + '/01/2018', 'DD/MM/YYYY')),
                          get_day_time(state.dates[1].hour(), state.dates[1].minute(), dayjs('0' + jour + '/01/2018', 'DD/MM/YYYY'))
                        ])
                      }}
                    />
                  : null
              }
            </>
          }
        </div>
        <div
          className={
            clsx({
              ...classes,
              [state.classe]: true
            })
          }
        >
          <div>
            <i className="icon absence-from"/>
            <span>{state.dates[0].format('HH:mm')}</span>
          </div>
          {
            (!event.read)
              ? <ButtonEdit
                  onClick={() => setDebut(true)}
                />
              : null
          }
        </div>
        <div
          className={
            clsx({
              ...classes,
              [state.classe]: true
            })
          }
        >
          <div>
            <i className="icon absence-to"/>
            <span>{state.dates[1].format('HH:mm')}</span>
          </div>
          {
            (!event.read)
              ? <ButtonEdit
                  onClick={() => setFin(true)}
                />
              : null
          }
        </div>
      </div>
      {
        (state.users.length > 0)
          ? <>
              {
                (condition)
                  ? <CreneauFonctions liste={liste}/>
                  : <div className={styles.empty}/>
              }
              <div className="semaines-form-equipe">
                {
                  sort_absent(liste).map(item => {
                    return (
                      <PlanningUserMobile
                        data={data}
                        item={item}
                        inner={true}
                        state={state}
                        key={item.id}
                        Update={Update}
                      />
                    )
                  })
                }
              </div>
            </>
          : null
      }
    </Paper>
  )
}

export default PlanningModalCreneauPaper
