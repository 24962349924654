import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'

const SelectNombre = (props) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <SelectInput
      label="Nombre"
      options={settings.codes.nombres.map(item => {
        return {
          value: item,
          label: item
        }
      })}
      {...props}
    />
  )
}

export default SelectNombre
