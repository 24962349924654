import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateDemande = ({
  item,
  label
}) => {
  return (
    <TableCellDate
      full={true}
      label={label}
      value={item.demande}
    />
  )
}

export default TableCellDateDemande
