import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateDebut = ({ item }) => {
  return (
    <TableCellDate
      value={item.debut}
      full={(item.nombre === 0)}
    />
  )
}

export default TableCellDateDebut
