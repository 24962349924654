import clsx from 'clsx'
import dayjs from '../../../Libraries/dayjs'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  TableCell
} from '@mui/material'
import {
  start_case
} from '../../../Functions/Strings'
import {
  DAY,
  VIEW
} from '../../../Redux/Actions/Planning'
import {
  SET_DATE_SEMAINES
} from '../../../Redux/Actions/Semaines'

const EventScaleCell = ({
  month,
  startDate
}) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const date = useSelector(state => state.semaines.date)
  const view = useSelector(state => state.planning.view)
  useEffect(() => {
    if (date !== undefined) {
      dispatch(DAY(dayjs(date).weekday()))
    }
    // eslint-disable-next-line
  }, [date])
  return (
    <TableCell className="table-cell">
      {
        (month)
          ? <div
              className={
                clsx(
                  'cell-day',
                  'text-center'
                )
              }
            >
              {
                (device.mobile)
                  ? start_case(dayjs(startDate).format('dd.'))
                  : start_case(dayjs(startDate).format('dddd'))
              }
            </div>
          : <div
              onClick={() => {
                if (view === 'Week') {
                  dispatch(VIEW('Day'))
                  dispatch(SET_DATE_SEMAINES(startDate))
                }
              }}
              className={
                clsx({
                  'cell-day': true,
                  'text-center': true,
                  'pointer': (view === 'Week'),
                  'cell-day-hover': (view === 'Week')
                })
              }
            >
              {
                (
                  (view === 'Week') &&
                  (device.mobile)
                )
                  ? start_case(dayjs(startDate).format('dd.'))
                  : start_case(dayjs(startDate).format('dddd'))
              }
            </div>
      }
    </TableCell>
  )
}

export default EventScaleCell
