import clsx from 'clsx'
import React from 'react'
import Titre from '../Includes/Misc/Titre'
import Checkbox from '../Includes/Misc/Checkbox'
import styles from './NotificationsToolbar.module.css'
import NotificationsToolbarRead from './NotificationsToolbarRead'
import NotificationsToolbarDelete from './NotificationsToolbarDelete'
import NotificationsToolbarUnread from './NotificationsToolbarUnread'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  All
} from '../../Functions/Table'
import {
  SELECTED_NOTIFICATIONS
} from '../../Redux/Actions/Notifications'

const NotificationsToolbar = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const equipe = useSelector(state => state.equipe.equipe)
  const selected = useSelector(state => state.notifications.selected)
  const notifications = useSelector(state => state.notifications.notifications)
  return (
    <Toolbar
      className={
        clsx({
          [styles.toolbar]: (
            (device.mobile) &&
            (equipe.length > 0) &&
            (notifications.length > 0)
          )
        })
      }
    >
      <Titre className="notifications-title">
        {
          (device.mobile)
            ? <Checkbox
                className={styles.checkbox}
                onChange={(event) => All(dispatch, SELECTED_NOTIFICATIONS, notifications.map(item => item.id), event)}
                indeterminate={(
                  (selected.length > 0) &&
                  (selected.length < notifications.length)
                )}
                checked={(
                  (notifications.length > 0) &&
                  (selected.length === notifications.length)
                )}
              />
            : 'Notifications'
        }
      </Titre>
      <div className="notifications-actions">
        <NotificationsToolbarRead/>
        <NotificationsToolbarUnread/>
        <NotificationsToolbarDelete/>
      </div>
    </Toolbar>
  )
}

export default NotificationsToolbar
