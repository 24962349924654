import React from 'react'

const UserDelete = ({ name }) => {
  return (
    <span>
      <span className="block">Voulez-vous vraiment supprimer <span className="delete-name">{name}</span> ?</span>
      <span className="block">Cette action est irréversible...</span>
    </span>
  )
}

export default UserDelete
