import React from 'react'
import Button from './Button'
import styles from './Button.module.css'

const ButtonArrow = ({
  expanded,
  setExpanded,
  ...others
}) => {
  return (
    <Button
      className={styles.arrow}
      onClick={() => setExpanded(!expanded)}
      button={{
        size: 'small'
      }}
      icon={
        (expanded)
          ? 'KeyboardArrowUp'
          : 'KeyboardArrowDown'
      }
      {...others}
    />
  )
}

export default ButtonArrow
