import TableCellSemaine from '../Components/Includes/Table/TableCellSemaine'
import TableCellActionsSemaines from '../Components/Includes/Table/TableCellActionsSemaines'
import {
  nom
} from './Includes/Nom'
import {
  actions
} from './Includes/Actions'

export const properties = {
  type: {
    id: "semaines",
    groupe: "divers"
  },
  sort: {
    by: "nom",
    order: "asc"
  },
  columns: [
    {
      ...nom,
      mobile: true,
      component: TableCellSemaine
    },
    {
      ...actions,
      mobile: true,
      component: TableCellActionsSemaines
    }
  ]
}
