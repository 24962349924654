import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const ADD_OFFICE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_OFFICE',
      value: value
    })
  }
}

export const INIT_OFFICES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_OFFICES',
      value: value
    })
  }
}

export const SET_DISABLED = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_DISABLED',
      value: value
    })
  }
}

export const REMOVE_OFFICE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_OFFICE',
      value: value
    })
  }
}

export const MODAL_OFFICES = (id, open, name) => {
  return (dispatch) => {
    dispatch({
      type: 'MODAL_OFFICES',
      name: name,
      open: open,
      id: id
    })
  }
}

export const CREATE_OFFICE = () => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    dispatch(SET_DISABLED(true))
    const data = JSON.stringify({
      action: 'create-office'
    })
    api(data)
      .then(data => {
        dispatch(SET_DISABLED(false))
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(ADD_OFFICE(data.data))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
        dispatch(SET_DISABLED(false))
      })
  }
}

export const RESET_OFFICE = (id) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    dispatch(SET_DISABLED(true))
    const data = JSON.stringify({
      id: id,
      action: 'reset-office'
    })
    api(data)
      .then(data => {
        dispatch(SET_DISABLED(false))
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
        dispatch(SET_DISABLED(false))
      })
  }
}

export const DELETE_OFFICE = (id) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      id: id,
      action: 'delete-office'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(REMOVE_OFFICE(id))
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_OFFICE = (id, password) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      id: id,
      password: password,
      action: 'update-office'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
