import React from 'react'
import InputTextField from './InputTextField'
import {
  useSelector
} from 'react-redux'
import {
  CheckInt
} from '../../../Functions/Check'
import {
  convert_heure
} from '../../../Functions/Heures'

const InputTime = ({
  error,
  state,
  setHeures,
  setMinutes,
  ...others
}) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <>
      <div className="block-flex">
        <InputTextField
          error={error}
          label="Heures"
          value={state.heures}
          setValue={setHeures}
          className="block-flex-input"
          {...others}
        />
        <InputTextField
          error={error}
          label="Minutes"
          value={state.minutes}
          setValue={setMinutes}
          className="block-flex-input"
          {...others}
        />
      </div>
      {
        (error)
          ? (
              (!CheckInt(state.minutes)) ||
              (!CheckInt(state.heures))
            )
              ? <div className="error-heure">Le nombre doit être un entier positif de la forme 123</div>
              : (parseInt(state.nombre) < settings.minimum.supp)
                ? <div className="error-heure">Le nombre doit être supérieur à {convert_heure(settings.minimum.supp, false)}</div>
                : (parseInt(state.nombre) > settings.maximum.supp)
                  ? <div className="error-heure">Le nombre doit être inférieur à {convert_heure(settings.maximum.supp, false)}</div>
                  : (parseInt(state.minutes) >= 60)
                    ? <div className="error-heure">Le nombre de minutes doit être inférieur à 60</div>
                    : ((parseInt(state.minutes) % 15) !== 0)
                      ? <div className="error-heure">Le nombre de minutes doit être un multiple de 15</div>
                      : null
          : null
      }
    </>
  )
}

export default InputTime
