import TableCellPrenom from '../../Components/Includes/Table/TableCellPrenom'

export const prenom = {
  id: "prenom",
  sort: true,
  user: false,
  padding: true,
  label: "Prénom",
  component: TableCellPrenom
}
