import TableCellSettingsItem from '../Components/Includes/Table/TableCellSettingsItem'
import TableCellSettingsValeur from '../Components/Includes/Table/TableCellSettingsValeur'
import TableCellActionsSettings from '../Components/Includes/Table/TableCellActionsSettings'
import {
  valeur
} from './Includes/Valeur'
import {
  actions
} from './Includes/Actions'

export const properties = {
  type: {
    id: "settings",
    groupe: "divers"
  },
  sort: {
    by: "nom",
    order: "asc"
  },
  columns: [
    {
      id: "item",
      sort: true,
      padding: true,
      label: "Item",
      component: TableCellSettingsItem
    },
    {
      ...valeur,
      component: TableCellSettingsValeur
    },
    {
      ...actions,
      component: TableCellActionsSettings
    }
  ]
}
