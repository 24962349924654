import React from 'react'
import styles from './LoginButton.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Button
} from '@mui/material'

const LoginButton = ({
  label,
  ...others
}) => {
  const pending = useSelector(state => state.login.pending)
  return (
    <div className={styles.button}>
      <Button
        color="primary"
        disabled={pending}
        variant="contained"
        {...others}
      >
        {label}
      </Button>
    </div>
  )
}

export default LoginButton
