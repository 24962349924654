import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateEngagement = ({
  item,
  label
}) => {
  return (
    <TableCellDate
      full={true}
      label={label}
      value={item.engagement}
    />
  )
}

export default TableCellDateEngagement
