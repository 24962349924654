const initial = {
  profils: [],
  abonnements: [],
  types: {
    '1': 'Pharmacie'
  }
}

function landing(state = initial, action) {
  switch (action.type) {
    case 'SET_TYPES':
      return {
        ...state,
        types: action.value
      }
    case 'SET_PROFILS':
      return {
        ...state,
        profils: action.value
      }
    case 'SET_ABONNEMENTS':
      return {
        ...state,
        abonnements: action.value
      }
    default:
      return state
  }
}

export default landing
