import {
  USER_LOGOUT
} from './Login'
import {
  SET_SURVOL
} from './Compte'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const EDIT_SETTING = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_SETTING',
      value: value
    })
  }
}

export const SET_COMPANIE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_COMPANIE',
      value: value
    })
  }
}

export const INIT_SETTINGS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_SETTINGS',
      value: value
    })
  }
}

export const SET_VARIABLES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_VARIABLES',
      value: value
    })
  }
}

export const UPDATE_SETTING = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value.value,
      action: value.action
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(EDIT_SETTING(value))
          if (value.id === 'survol') {
            dispatch(SET_SURVOL(value.value))
          }
          if (value.id === 'companie') {
            dispatch(SET_COMPANIE(value.value))
          }
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
