import Text from './Text'
import Popup from './Popup'
import Title from './Title'
import Actions from './Actions'
import Content from './Content'

export const Dialog = (props) => <Popup {...props}/>
export const DialogTitle = (props) => <Title {...props}/>
export const DialogActions = (props) => <Actions {...props}/>
export const DialogContent = (props) => <Content {...props}/>
export const DialogContentText = (props) => <Text {...props}/>
