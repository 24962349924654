import React from 'react'
import Button from './Button'
import styles from './Button.module.css'
import {
  useSelector
} from 'react-redux'

const ButtonFilter = ({
  off,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (device.mobile)
    ? <Button
        variant="contained"
        className={styles.filter}
        icon={
          (off)
            ? 'FilterListOff'
            : 'FilterList'
        }
        {...others}
      />
    : <Button
        placement="left"
        variant="contained"
        icon="FilterListOff"
        className={styles.desktop}
        title="Supprimer les filtres"
        {...others}
      />
}

export default ButtonFilter
