import {
  USER_BACK
} from './User'
import {
  DIE,
  USER_LOGOUT
} from './Login'
import {
  api,
  user
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SET_CARD = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CARD',
      value: value
    })
  }
}

export const SET_STRIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_STRIPE',
      value: value
    })
  }
}

export const SET_FACTURES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_FACTURES',
      value: value
    })
  }
}

export const SET_ABONNEMENT = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ABONNEMENT',
      value: value
    })
  }
}

export const UPDATE_CARD = (payment) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      payment: payment,
      action: 'update-card'
    })
    api(data, undefined, true)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(DIE(false))
          dispatch(USER_BACK())
          dispatch(SET_CARD(data.data))
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CODE = (code, abonnement, success, error) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      abonnement: abonnement,
      code: code,
      action: 'code'
    })
    user(data)
      .then(data => {
        if (data.status === 'OK') {
          success(data.data)
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
          error()
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
        error()
      })
  }
}
