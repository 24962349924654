import {
  filtres
} from '../../Variables/Variables'

const initial = {
  filter: [],
  filters: filtres
}

function filters(state = initial, action) {
  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        filter: (state.filter.includes(action.value))
          ? state.filter.filter(item => item !== action.value)
          : [ ...state.filter, action.value ]
      }
    case 'SET_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.page]: {
            ...state.filters[action.page],
            [action.filtre]: action.value
          }
        }
      }
    case 'SET_FILTER_ALL':
      return {
        ...state,
        filter: action.value
      }
    case 'SET_FILTERS_TARGET':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.page]: action.value
        }
      }
    case 'SET_FILTERS_TARGET_RESET':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.page]: initial.filters[action.page]
        }
      }
    default:
      return state
  }
}

export default filters
