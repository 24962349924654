import React from 'react'
import {
  frFR
} from '@mui/x-date-pickers/locales'
import {
  AdapterDayjs
} from '@mui/x-date-pickers/AdapterDayjs'
import {
  LocalizationProvider
} from '@mui/x-date-pickers/LocalizationProvider'

const Provider = ({ children }) => {
  return (
    <LocalizationProvider
      adapterLocale="fr"
      dateAdapter={AdapterDayjs}
      localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      {children}
    </LocalizationProvider>
  )
}

export default Provider
