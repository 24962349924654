import clsx from 'clsx'
import React from 'react'
import BarDie from './BarDie'
import BarMobile from './BarMobile'
import BarDesktop from './BarDesktop'
import BarLanding from './BarLanding'
import styles from './Bar.module.css'
import {
  useSelector
} from 'react-redux'
import {
  AppBar
} from '@mui/material'

const Bar = () => {
  const device = useSelector(state => state.device)
  const die = useSelector(state => state.login.die)
  const menu = useSelector(state => state.menu.menu)
  const user = useSelector(state => state.login.user)
  return (user.logged)
    ? <AppBar
        position="fixed"
        className={
          clsx({
            [styles.bar]: true,
            [styles.root]: true,
            [styles.shift]: menu
          })
        }
      >
        {
          (die)
            ? <BarDie/>
            : (device.mobile)
              ? <BarMobile/>
              : <BarDesktop/>
        }
      </AppBar>
    : <AppBar
        position="fixed"
        className={styles.root}
      >
        <BarLanding/>
      </AppBar>
}

export default Bar
