import dayjs from '../../Libraries/dayjs'
import {
  week_date
} from '../../Functions/Dates'
import {
  get_creneau_object
} from '../../Functions/Planning'
import {
  get_id
} from '../../Functions/Functions'

const initial = {
  data: [],
  current: [],
  erreurs: [],
  semaines: [],
  commit: false,
  date: week_date,
  nom: {
    value: '',
    error: false
  },
  rename: {
    id: '',
    nom: '',
    open: false,
    duplicate: false
  },
  working: {
    id: '',
    nom: '',
    new: false,
    edit: false,
    open: false,
    read: false
  }
}

function semaines(state = initial, action) {
  switch (action.type) {
    case 'NOM':
      return {
        ...state,
        nom: action.value
      }
    case 'DATA':
      return {
        ...state,
        data: action.value
      }
    case 'COMMIT':
      return {
        ...state,
        commit: action.value
      }
    case 'ERREURS':
      return {
        ...state,
        erreurs: action.value
      }
    case 'SET_RENAME':
      return {
        ...state,
        rename: action.value
      }
    case 'ADD_CURRENT':
      return {
        ...state,
        current: [
          ...state.current,
          ...action.value
        ]
      }
    case 'ADD_SEMAINE':
      return {
        ...state,
        semaines: [
          ...state.semaines,
          {
            ...action.value,
            horaires: state.current
          }
        ]
      }
    case 'DEL_CURRENT':
      return {
        ...state,
        current: state.current.filter(item => item.id !== action.value)
      }
    case 'DEL_SEMAINE':
      return {
        ...state,
        semaines: state.semaines.filter(item => item.id !== action.value)
      }
    case 'SET_WORKING':
      return {
        ...state,
        working: action.value
      }
    case 'EDIT_CURRENT':
      return {
        ...state,
        current: action.value
      }
    case 'EDIT_SEMAINE':
      return {
        ...state,
        semaines: state.semaines.map(item => {
          return (item.id === action.value.id)
            ? action.value
            : item
        })
      }
    case 'CLONE_CURRENT':
      return {
        ...state,
        current: [
          ...state.current,
          {
            ...state.current.find(item => item.id === action.value),
            id: get_id()
          }
        ]
      }
    case 'CLONE_SEMAINE':
      return {
        ...state,
        semaines: [
          ...state.semaines,
          {
            ...action.value
          }
        ]
      }
    case 'INIT_SEMAINES':
      return {
        ...state,
        semaines: action.value
      }
    case 'RENAME_SEMAINE':
      return {
        ...state,
        semaines: state.semaines.map(item => {
          return (item.id === action.value.id)
            ? {
                ...item,
                nom: action.value.nom
              }
            : item
        })
      }
    case 'SET_DATE_SEMAINES':
      return {
        ...state,
        date: dayjs(action.value)
      }
    case 'DEL_CURRENT_EQUIPE':
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === action.value.horaire)
            ? {
                ...item,
                equipe: item.equipe.filter(item => item !== action.value.user)
              }
            : item
        })
      }
    case 'SET_CURRENT_EQUIPE':
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === action.value.horaire)
            ? {
                ...item,
                equipe: action.value.equipe
              }
            : item
        })
      }
    case 'DEL_CURRENT_HORAIRE':
      return {
        ...state,
        current: state.current.filter(item => item.id !== action.value)
      }
    case 'EDIT_CURRENT_EQUIPE':
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === action.value.horaire)
            ? {
                ...item,
                equipe: (item.equipe.includes(action.value.user))
                  ? item.equipe
                  : [
                      ...item.equipe,
                      action.value.user
                    ]
              }
            : item
        })
      }
    case 'CLEAR_CURRENT_EQUIPE':
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === action.value)
            ? {
                ...item,
                equipe: []
              }
            : item
        })
      }
    case 'EDIT_CURRENT_HORAIRE':
      const value = get_creneau_object(action.value, 'iso')
      return {
        ...state,
        current: state.current.map(item => {
          return (item.id === value.id)
            ? {
                ...item,
                endDate: value.endDate,
                startDate: value.startDate
              }
            : item
        })
      }
    default:
      return state
  }
}

export default semaines
