import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellCode = ({
  item,
  type,
  label,
  device
}) => {
  const value = (type.id === 'companies')
    ? item.code
    : item.id
  const object = {
    value: value,
    empty: (value === '')
  }
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        {...object}
      />
    : <TableCellDesktop
        max={150}
        {...object}
      />
}

export default TableCellCode
