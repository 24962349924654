import React from 'react'
import styles from './Link.module.css'
import {
  open_link
} from '../../../Functions/Functions'
import {
  variables
} from '../../../Variables/Variables'

const CGV = () => (
  <span
    className={styles.link}
    onClick={() => open_link(variables.url.server + variables.path.link + 'conditions_generales.pdf')}
  >
    Conditions générales de vente
  </span>
)

export default CGV
