import React from 'react'
import styles from './EventEmptyCell.module.css'
import ButtonAddCircle from '../Button/ButtonAddCircle'
import {
  useDispatch
} from 'react-redux'
import {
  OPEN_PLANNING
} from '../../../Redux/Actions/Planning'

const EventEmptyCell = ({ enable }) => {
  const dispatch = useDispatch()
  return (enable)
    ? <div className="add-button">
        <ButtonAddCircle
          title="Nouveau créneau"
          className={styles.button}
          onClick={() => dispatch(OPEN_PLANNING('planning', true))}
        />
      </div>
    : null
}

export default EventEmptyCell
