const initial = {
  input: '',
  mails: [],
  error: false,
  pending: false
}

function plaquette(state = initial, action) {
  switch (action.type) {
    case 'PLAQUETTE':
      return {
        ...state,
        [action.item]: action.value
      }
    case 'DEL_PLAQUETTE':
      return {
        ...state,
        mails: state.mails.filter(item => item !== action.value)
      }
    default:
      return state
  }
}

export default plaquette
