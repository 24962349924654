import React from 'react'
import Form from '../Includes/Wrapper/Form'
import InputCommentaire from '../Includes/Input/InputCommentaire'
import {
  CheckComment
} from '../../Functions/Check'

const CompteModalContent = ({
  state,
  Update
}) => {
  const Commentaire = (value) => {
    Update('commentaire', value)
    Update('error', !CheckComment(value))
  }
  return (
    <>
      <div className="inline-block-description">
        <div>Nous regrettons de vous voir partir...</div>
        <div>Pouvez-vous prendre quelques secondes pour nous expliquer la raison de votre départ ?</div>
        <div>Merci d'avance</div>
      </div>
      <Form
        full={true}
        large={true}
      >
        <div className="inline-block-texte">
          <InputCommentaire
            error={state.error}
            setValue={Commentaire}
            value={state.commentaire}
          />
        </div>
      </Form>
    </>
  )
}

export default CompteModalContent
