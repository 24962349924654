export const MENU = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'MENU',
      value: value
    })
  }
}

export const READY = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'READY',
      value: value
    })
  }
}
