import TableCellStatus from '../../Components/Includes/Table/TableCellStatus'

export const status = {
  id: "status",
  sort: false,
  center: true,
  mobile: true,
  padding: true,
  label: "Statut",
  component: TableCellStatus
}
