import React from 'react'
import styles from './Icon.module.css'
import {
  variables
} from '../../../Variables/Variables'

const FooterLogo = () => {
  return (
    <button className={styles.link}>
      <img
        alt="Logo"
        onClick={() => window.location.reload()}
        src={variables.url.server + variables.path.icon + 'planipo_icon.png'}
      />
    </button>
  )
}

export default FooterLogo
