import React from 'react'
import InputTextField from './InputTextField'
import {
  useSelector
} from 'react-redux'
import {
  InputAdornment
} from '@mui/material'
import {
  CheckInt
} from '../../../Functions/Check'
import {
  convert_heure
} from '../../../Functions/Heures'

const InputTemps = ({
  error,
  value,
  ...others
}) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <InputTextField
      error={error}
      value={value}
      label="Compteur temps"
      InputProps={
        (error)
          ? null
          : {
              endAdornment: <InputAdornment position="end">{convert_heure(value, (value < 0))}</InputAdornment>
            }
      }
      helperText={
        (error)
          ? (!CheckInt(value, false))
            ? "Le compteur temps doit être un entier de la forme -123 ou 123"
            : (parseInt(value) < settings.minimum.temps)
              ? "Le compteur temps doit être supérieur à " + settings.minimum.temps
              : (parseInt(value) > settings.maximum.temps)
                ? "Le compteur temps doit être inférieur à " + settings.maximum.temps
                : null
          : null
      }
      {...others}
    />
  )
}

export default InputTemps
