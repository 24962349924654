import React from 'react'
import Condition from '../Includes/Condition/Demandes'
import ButtonEdit from '../Includes/Button/ButtonEdit'
import styles from './AbsencesPlanningModal.module.css'
import ButtonDelete from '../Includes/Button/ButtonDelete'
import LegendeAbsenceContent from '../Includes/Legende/LegendeAbsenceContent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  MODAL_ABSENCES
} from '../../Redux/Actions/Absences'

const AbsencesPlanningModal = ({
  open,
  setOpen
}) => {
  const dispatch = useDispatch()
  const absence = useSelector(state => state.absences.absence)
  const absences = useSelector(state => state.absences.absences)
  const data = absences.find(item => item.id === absence)
  const condition = Condition(data)
  return (
    (data !== undefined) &&
    (data !== '')
  )
    ? <Dialog
        open={open}
        maxWidth="md"
        fullWidth={true}
        className="modal-mobile-absence-planning"
      >
        <DialogTitle>
          Absence
          {
            (condition)
              ? <>
                  <ButtonEdit
                    className={styles.edit}
                    onClick={() => dispatch(MODAL_ABSENCES(data.id, 'edit'))}
                  />
                  <ButtonDelete
                    className={styles.delete}
                    onClick={() => dispatch(MODAL_ABSENCES(data.id, 'delete'))}
                  />
                </>
              : null
          }
        </DialogTitle>
        <DialogContent>
          <Paper
            elevation={2}
            className={styles.container}
          >
            <LegendeAbsenceContent absence={data}/>
          </Paper>
        </DialogContent>
        <DialogActions
          buttons={[
            {
              onClick: () => setOpen()
            }
          ]}
        />
      </Dialog>
    : null
}

export default AbsencesPlanningModal
