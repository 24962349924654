import React from 'react'
import Titre from '../Includes/Misc/Titre'
import OfficesToolbarContent from './OfficesToolbarContent'
import {
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'

const OfficesToolbarDesktop = () => {
  const offices = useSelector(state => state.offices.offices)
  const maximum = useSelector(state => state.compte.maximum.offices)
  return (
    <Toolbar>
      <Titre>Offices</Titre>
      {
        (
          (maximum !== -1) &&
          (offices.length < maximum)
        )
          ? <OfficesToolbarContent/>
          : null
      }
    </Toolbar>
  )
}

export default OfficesToolbarDesktop
