import clsx from 'clsx'
import React from 'react'
import styles from './LegendeAbsenceContentLigne.module.css'
import {
  useSelector
} from 'react-redux'

const LegendeAbsenceContentLigne = ({
  children,
  className
}) => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        clsx(
          className,
          styles.content,
          (device.mobile)
            ? styles.mobile
            : styles.desktop
        )
      }
    >
      {children}
    </div>
  )
}

export default LegendeAbsenceContentLigne
