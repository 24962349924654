import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const COMPANIES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'COMPANIES',
      value: value
    })
  }
}

export const BETA = (companies, value) => {
  return (dispatch) => {
    dispatch({
      type: 'BETA',
      value: value,
      companies: companies
    })
  }
}

export const SEARCH_COMPANIE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SEARCH_COMPANIE',
      value: value
    })
  }
}

export const LOADING_COMPANIES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'LOADING_COMPANIES',
      value: value
    })
  }
}

export const SELECTED_COMPANIES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SELECTED_COMPANIES',
      value: value
    })
  }
}

export const COMPANIE_BLOCK = (companies, value) => {
  return (dispatch) => {
    dispatch({
      type: 'COMPANIE_BLOCK',
      companies: companies,
      value: value
    })
  }
}

export const MODAL_COMPANIES = (open, texte, action) => {
  return (dispatch) => {
    dispatch({
      type: 'MODAL_COMPANIES',
      action: action,
      texte: texte,
      open: open
    })
  }
}

export const GET_COMPANIES = () => {
  return (dispatch) => {
    const data = JSON.stringify({
      action: 'companies'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(COMPANIES(data.response))
          dispatch(LOADING_COMPANIES())
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const BETA_COMPANIE = (companies, value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      companies: companies,
      action: 'beta-companie'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(BETA(companies, value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const BLOCK_COMPANIE = (companies, value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      companies: companies,
      action: 'block-companie'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(COMPANIE_BLOCK(companies, value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
