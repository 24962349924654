import React from 'react'
import styles from './Select.module.css'
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material'

const SelectInput = ({
  error,
  label,
  filter,
  hidden,
  select,
  options,
  children,
  setValue,
  className,
  ...others
}) => {
  return (
    <FormControl
      variant="outlined"
      className={
        (hidden)
          ? styles.hidden
          : className
      }
    >
      <InputLabel error={error}>
        {label}
      </InputLabel>
      <Select
        error={error}
        label={label}
        onChange={(event) => setValue(event.target.value)}
        {...others}
      >
        {
          (filter)
            ? <MenuItem value={-1}>Tous</MenuItem>
            : null
        }
        {
          options.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item.value}
              >
                {
                  (item.content)
                    ? item.content
                    : null
                }
                {item.label}
              </MenuItem>
            )
          })
        }
      </Select>
      {children}
    </FormControl>
  )
}

export default SelectInput
