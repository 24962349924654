import clsx from 'clsx'
import React from 'react'
import CGV from '../Includes/Footer/CGV'
import PDC from '../Includes/Footer/PDC'
import Checkbox from '../Includes/Misc/Checkbox'
import {
  Button
} from '@mui/material'
import {
  landing
} from '../../Variables/Landing'

const CardContentBottom = ({
  error,
  state,
  Update,
  setStep,
  UpdateError
}) => {
  return (
    <div className="signin-content-bottom">
      <div className="signin-conditions">
        <Checkbox
          checked={state.conditions}
          onChange={(event) => {
            Update('conditions', event.target.checked)
            if (event.target.checked) {
              UpdateError('conditions', false)
            }
          }}
        />
        <div
          className={
            clsx({
              'error-conditions': error.conditions
            })
          }
        >
          J'accepte les <CGV/>, et je renonce à mon droit légal de rétractation de 14 jours afin de bénéficier immédiatemment des services <b>{landing.nom}</b>.
        </div>
      </div>
      <div className="signin-confidentialite">
        <Checkbox
          checked={state.confidentialite}
          onChange={(event) => {
            Update('confidentialite', event.target.checked)
            if (event.target.checked) {
              UpdateError('confidentialite', false)
            }
          }}
        />
        <div
          className={
            clsx({
              'error-confidentialite': error.confidentialite
            })
          }
        >
          J'accepte la <PDC/>.
        </div>
      </div>
      <div className="signin-button signin-card signin-bottom">
        <Button
          color="secondary"
          variant="contained"
          className="button-back"
          disabled={state.pending}
          onClick={() => setStep(2)}
        >
          Retour
        </Button>
        <Button
          type="submit"
          color="primary"
          form="stripe-form"
          variant="contained"
          disabled={state.pending}
        >
          Terminer
        </Button>
      </div>
    </div>
  )
}

export default CardContentBottom
