import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import {
  start_case
} from '../../../Functions/Strings'

const TableCellProfil = ({
  item,
  label,
  device
}) => {
  const value = start_case(item.profil)
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={100}
        value={value}
      />
}

export default TableCellProfil
