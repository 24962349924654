import React from 'react'
import Image from '../Includes/Image'
import Texte from '../Includes/Texte'
import Container from '../Includes/Container'
import Source from '../../Images/Landing/comptabilite.png'

const Comptabilite = () => {
  return (
    <Container>
      <Texte
        left={true}
        titre="Synthétisez vos #plannings.#"
        description="Exportez vos plannings, générez vos rapports détaillés, envoyez les directement à votre comptable."
      />
      <Image
        width={60}
        src={Source}
        shadow={true}
        alt="Comptabilité"
      />
    </Container>
  )
}

export default Comptabilite
