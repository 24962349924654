import React from 'react'
import TableData from '../Includes/Table/TableData'
import {
  useSelector
} from 'react-redux'
import {
  properties
} from '../../Settings/Semaines'

const SemainesTable = () => {
  const device = useSelector(state => state.device)
  const semaines = useSelector(state => state.semaines.semaines)
  const maximum = useSelector(state => state.compte.maximum.semaines)
  return (semaines.length > 0)
    ? <TableData
        data={semaines}
        pagination={device.desktop}
        size={
          (device.desktop)
            ? "large"
            : "small"
        }
        {...properties}
      >
        {
          (semaines.length === maximum)
            ? <caption>Vous avez atteint le nombre maximum de semaines types, pour en créer de nouvelles, vous devez modifier votre abonnement.</caption>
            : null
        }
      </TableData>
    : null
}

export default SemainesTable
