import React from 'react'
import SelectInput from './SelectInput'
import {
  aide
} from '../../../Variables/Variables'

const SelectVideo = (props) => {
  return (
    <SelectInput
      label="Vidéo"
      options={aide.map((item, index) => {
        return {
          value: index,
          label: item.label
        }
      })}
      {...props}
    />
  )
}

export default SelectVideo
