import React from 'react'
import Titre from '../Misc/Titre'
import Container from './Container'
import {
  Toolbar
} from '@mui/material'

const Circle = ({
  title,
  value
}) => {
  return (
    <Container>
      <Toolbar>
        <Titre>
          {title}
        </Titre>
      </Toolbar>
      {
        <div className="accueil-content">
          <div className="accueil-item">
            <span>
              {value}
            </span>
          </div>
        </div>
      }
    </Container>
  )
}

export default Circle
