import React from 'react'
import styles from './Legende.module.css'
import dayjs from '../../../Libraries/dayjs'
import {
  Schedule,
  ArrowRight
} from '@mui/icons-material'

const LegendeHeure = ({ item }) => {
  const debut = (item.start)
    ? item.start
    : (item.debut)
      ? item.debut
      : item.startDate
  const fin = (item.end)
    ? item.end
    : (item.fin)
      ? item.fin
      : item.endDate
  return (
    <div className={styles.row}>
      <Schedule
        fontSize="small"
        className={styles.icone}
      />
      {dayjs(debut).format('HH:mm')}
      <ArrowRight/>
      {dayjs(fin).format('HH:mm')}
    </div>
  )
}

export default LegendeHeure
