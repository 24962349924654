import clsx from 'clsx'
import React from 'react'
import Bulle from '../Wrapper/Bulle'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import styles from './TableCellMailEquipe.module.css'
import {
  Warning
} from '@mui/icons-material'

const TableCellMailEquipe = ({
  item,
  label,
  device
}) => {
  const value = item.mail
  return (device.mobile)
    ? <TableCellMobile label={label}>
        {
          (value === '')
            ? <Warning
                fontSize="small"
                className={
                  clsx(
                    styles.color,
                    styles.mobile
                  )
                }
              />
            : value
        }
      </TableCellMobile>
    : <TableCellDesktop max={250}>
        {
          (value === '')
            ? <Bulle
                arrow={true}
                placement="left"
                title="Pour que votre salarié puisse se connecter, il faut renseigner une adresse mail valide"
              >
                <Warning
                  className={
                    clsx(
                      styles.color,
                      styles.desktop
                    )
                  }
                />
              </Bulle>
            : value
        }
      </TableCellDesktop>
}

export default TableCellMailEquipe
