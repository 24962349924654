import {
  random_number
} from './Functions'

export function random_color() {
  return {
    r: random_number(0, 255),
    g: random_number(0, 255),
    b: random_number(0, 255)
  }
}

export function get_color(color) {
  const value = (
    (color.r * 299) +
    (color.g * 587) +
    (color.b * 114)
  ) / 1000
  return (value >= 128)
    ? 'black'
    : 'white'
}

export function get_background_color(color) {
  return 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')'
}
