import React from 'react'
import Absences from '../Hooks/Absences'
import Planning from '../Hooks/Planning'
import Semaines from '../Hooks/Semaines'
import LegendeVides from './LegendeVides'
import LegendeSurvol from './LegendeSurvol'
import LegendeAbsence from './LegendeAbsence'
import LegendeAbsents from './LegendeAbsents'
import LegendeCreneau from './LegendeCreneau'
import LegendeErreurs from './LegendeErreurs'
import Condition from '../Condition/Fonctions'
import LegendeSalaries from './LegendeSalaries'
import LegendeSemaines from './LegendeSemaines'
import LegendeSettings from './LegendeSettings'
import LegendeFonctions from './LegendeFonctions'
import styles from './LegendePlanning.module.css'
import {
  useSelector
} from 'react-redux'

const LegendePlanning = ({
  liste,
  vides,
  absence
}) => {
  const absences = Absences()
  const planning = Planning()
  const semaines = Semaines()
  const condition = Condition()
  const user = useSelector(state => state.login.user)
  const view = useSelector(state => state.planning.view)
  const event = useSelector(state => state.planning.event)
  const equipe = useSelector(state => state.equipe.equipe)
  const weeks = useSelector(state => state.semaines.semaines)
  const absents = useSelector(state => state.planning.absents)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const erreurs = {
    planning: useSelector(state => state.planning.erreurs),
    semaines: useSelector(state => state.semaines.erreurs)
  }
  const data = (planning)
    ? erreurs.planning
    : erreurs.semaines
  const enable = (
    (fonctions.length > 0) &&
    (equipe.length > 0)
  )
  return (
    <div className={styles.container}>
      {
        (absences)
          ? <>
              {
                (enable)
                  ? <LegendeSalaries
                      liste={liste}
                      draggable={false}
                    />
                  : null
              }
              {
                (absence)
                  ? <LegendeAbsence/>
                  : null
              }
            </>
          : <>
              {
                (
                  (event.id !== '') &&
                  (fonctions.length > 0)
                )
                  ? <LegendeCreneau fonctions={condition}/>
                  : null
              }
              {
                (
                  (!semaines) &&
                  (view === 'Week') &&
                  (weeks.length > 0) &&
                  (user.profil === 'administrateur')
                )
                  ? <LegendeSemaines/>
                  : null
              }
              {
                (enable)
                  ? <LegendeSalaries draggable={condition}/>
                  : null
              }
              {
                (
                  (enable) &&
                  (user.profil === 'administrateur')
                )
                  ? <LegendeFonctions/>
                  : null
              }
              {
                (
                  (planning) &&
                  (absents.length > 0)
                )
                  ? <LegendeAbsents/>
                  : null
              }
              {
                (
                  (user.profil === 'administrateur') &&
                  (fonctions.length > 0) &&
                  (data.length > 0)
                )
                  ? <LegendeErreurs data={data}/>
                  : null
              }
              {
                (
                  (vides.length > 0) &&
                  (user.profil === 'administrateur')
                )
                  ? <LegendeVides data={vides}/>
                  : null
              }
              <LegendeSettings/>
              <LegendeSurvol/>
            </>
      }
    </div>
  )
}

export default LegendePlanning
