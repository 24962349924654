import React from 'react'
import Circle from '../Includes/Accueil/Circle'
import {
  useSelector
} from 'react-redux'
import {
  absences
} from '../../Variables/Variables'

const AccueilConges = ({ annee }) => {
  const user = useSelector(state => state.login.user)
  const equipe = useSelector(state => state.equipe.equipe)
  const conges = equipe.find(item => item.id === user.unique_id_user)
  return (
    <Circle
      value={
        (annee === 'done')
          ? conges.conges_done + 'j'
          : conges.conges_current + 'j'
      }
      title={
        (annee === 'done')
          ? 'Solde congés ' + absences.done.year
          : 'Solde congés ' + absences.current.year
      }
    />
  )
}

export default AccueilConges
