import clsx from 'clsx'
import React from 'react'
import Form from '../Wrapper/Form'
import dayjs from '../../../Libraries/dayjs'
import SliderCreneau from '../Slider/SliderCreneau'
import styles from './CreneauModalContent.module.css'
import InputAutocomplete from '../Input/InputAutocomplete'
import {
  useSelector
} from 'react-redux'
import {
  FormGroup
} from '@mui/material'
import {
  start_case
} from '../../../Functions/Strings'

const CreneauModalContent = (props) => {
  const { error, state, Update, UpdateError } = props
  const device = useSelector(state => state.device)
  const view = useSelector(state => state.planning.view)
  const equipe = useSelector(state => state.equipe.equipe)
  const Jour = (value) => {
    const array = (state.jours.includes(value))
      ? state.jours.filter(item => item !== value)
      : [ ...state.jours, value ]
    Update('jours', array)
    if (array.length > 0) {
      UpdateError('jours', false)
    }
  }
  return (
    <Form full={true}>
      {
        (equipe.length > 0)
          ? <InputAutocomplete
              label="Salariés"
              options={equipe}
              value={equipe.filter(item => state.users.includes(item.id))}
              onChange={(event, value) => Update('users', value.map(item => item.id))}
            />
          : null
      }
      {
        (view === 'Week')
          ? <>
              <div className="inline-block inline-jours">
                <FormGroup
                  row={true}
                  className="semaines-jours"
                >
                  {
                    dayjs.weekdays(true).map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => Jour(index)}
                          className={
                            clsx({
                              'jour-lettre': true,
                              'jour-lettre-mobile': device.mobile,
                              [styles.selected]: state.jours.includes(index)
                            })
                          }
                        >
                          {start_case(item)}
                        </div>
                      )
                    })
                  }
                </FormGroup>
              </div>
              {
                (error.jours)
                  ? <div className="error-checkbox">Merci de sélectionner au moins un jour</div>
                  : null
              }
            </>
          : null
      }
      <SliderCreneau {...props}/>
    </Form>
  )
}

export default CreneauModalContent
