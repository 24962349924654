import React from 'react'
import useInView from '@owaiswiz/use-in-view'
import {
  motion
} from 'framer-motion'

const Slide = ({
  children,
  direction
}) => {
  const [ref, setRef] = useInView()
  const x = {
    target: '0%',
    initial: (direction === 'left')
      ? '-150%'
      : '150%'
  }
  return (
    <motion.section
      ref={ref}
      initial={{
        x: x.initial
      }}
      transition={{
        damping: 19,
        type: 'spring'
      }}
      animate={{
        x: setRef && x.target,
        transition: {
          duration: 0.5
        },
        transitionEnd:{
          x: setRef && 0
        }
      }}
    >
      {children}
    </motion.section>
  )
}

export default Slide
