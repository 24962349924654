import {
  device
} from './Device'
import {
  order_comparator
} from './Strings'
import {
  variables
} from '../Variables/Variables'

export function Slice(array, page, number) {
  return array.slice(
    (page * number),
    (page * number) + number
  )
}

export function Comparator(order, by) {
  return (order === 'desc')
    ? (a, b) => order_comparator(a, b, by)
    : (a, b) => -order_comparator(a, b, by)
}

export function Sort(array, comparator) {
  const data = array.map((item, index) => [item, index])
  data.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return data.map(item => item[0])
}

export function All(dispatch, action, value, event) {
  let array
  if (event.target.checked) {
    array = value
  } else {
    array = []
  }
  dispatch(action(array))
}

export function Select(dispatch, action, selected, id) {
  let array
  if (selected.includes(id)) {
    array = selected.filter(item => item !== id)
  } else {
    array = [ ...selected, id ]
  }
  dispatch(action(array))
}

export function Pagination(type) {
  const target = device()
  return (target.mobile)
    ? variables.table.mobile
    : (type === undefined)
      ? variables.table.normal
      : (type === 'large')
        ? [
            ...variables.table.normal,
            ...variables.table.large
          ]
        : variables.table[type]
}
