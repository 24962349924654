import TableCellMessage from '../Components/Includes/Table/TableCellMessage'
import TableCellDateNotification from '../Components/Includes/Table/TableCellDateNotification'
import TableCellActionsNotifications from '../Components/Includes/Table/TableCellActionsNotifications'
import TableCellCheckboxNotifications from '../Components/Includes/Table/TableCellCheckboxNotifications'
import {
  date
} from './Includes/Date'
import {
  actions
} from './Includes/Actions'

export const properties = {
  type: {
    id: "notifications",
    groupe: "divers"
  },
  sort: {
    by: "date",
    order: "desc"
  },
  columns: [
    {
      id: "checkbox",
      mobile: true,
      component: TableCellCheckboxNotifications
    },
    {
      id: "message",
      sort: false,
      mobile: true,
      padding: true,
      label: "Message",
      component: TableCellMessage
    },
    {
      ...date,
      mobile: true,
      component: TableCellDateNotification
    },
    {
      ...actions,
      mobile: true,
      component: TableCellActionsNotifications
    }
  ]
}
