import clsx from 'clsx'
import React from 'react'
import Titre from './Titre'
import styles from './Texte.module.css'
import Description from './Description'
import {
  useSelector
} from 'react-redux'

const Texte = ({
  full,
  left,
  right,
  titre,
  children,
  description
}) => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        clsx({
          [styles.full]: (device.mobile || full),
          [styles.left]: (device.desktop && left),
          [styles.right]: (device.desktop && right)
        })
      }
    >
      <Titre>
        {titre}
      </Titre>
      <Description>
        {description}
      </Description>
      {children}
    </div>
  )
}

export default Texte
