import React from 'react'
import TableCellDesktop from './TableCellDesktop'

const TableCellRestant = ({ item }) => {
  return (
    <TableCellDesktop
      center={true}
      value={item.restant}
    />
  )
}

export default TableCellRestant
