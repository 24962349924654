import React from 'react'
import Titre from '../../Includes/Misc/Titre'
import Search from '../../Includes/Search/Search'
import CodesToolbarButtons from './CodesToolbarButtons'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  SEARCH_CODE
} from '../../../Redux/Actions/Codes'

const CodesToolbarDesktop = ({ data }) => {
  const dispatch = useDispatch()
  const codes = useSelector(state => state.codes.codes)
  const search = useSelector(state => state.codes.search)
  return (
    <Toolbar>
      <Titre>
        Codes {
          (data.length > 0)
            ? '(' + data.length + ')'
            : ''
        }
      </Titre>
      {
        (codes.length > 0)
          ? <Search
              search={search}
              setSearch={(value) => dispatch(SEARCH_CODE(value))}
            />
          : null
      }
      <CodesToolbarButtons/>
    </Toolbar>
  )
}

export default CodesToolbarDesktop
