import React from 'react'
import {
  TextField
} from '@mui/material'

const InputTextField = ({
  setValue,
  ...others
}) => {
  return (
    <TextField
      variant="outlined"
      onChange={(event) => {
        if (setValue) {
          setValue(event.target.value)
        }
      }}
      {...others}
    />
  )
}

export default InputTextField
