import React from 'react'
import Button from './Button'

const ButtonIconFonction = (props) => {
  return (
    <Button
      icon="fonction"
      {...props}
    />
  )
}

export default ButtonIconFonction
