import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import {
  Check,
  Close
} from '@mui/icons-material'

const TableCellPaiement = ({
  item,
  label,
  device
}) => {
  const value = (item.last === 1)
    ? <Check className="check-icon payment-icon"/>
    : <Close className="close-icon payment-icon"/>
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={200}
        min={140}
        value={value}
      />
}

export default TableCellPaiement
