import React from 'react'
import CreneauLigne from './CreneauLigne'
import {
  sort_absent
} from '../../../Functions/Sort'

const CreneauEquipe = ({
  full,
  alone,
  empty,
  users
}) => {
  return (
    <>
      {
        sort_absent(users).map(item => {
          const classe = (
            (item.absent) ||
            (item.heure)
          )
            ? 'absent-content'
            : (alone)
              ? 'alone-content'
              : undefined
          return (
            <CreneauLigne
              item={item}
              full={full}
              key={item.id}
              empty={empty}
              content={classe}
              absent={item.absent}
              heure={(
                (item.heure) ||
                (item.hour)
              )}
            />
          )
        })
      }
    </>
  )
}

export default CreneauEquipe
