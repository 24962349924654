import React from 'react'
import {
  Grid
} from '@mui/material'

const CardFormContainer = ({ children }) => {
  return (
    <Grid
      sm={6}
      xs={6}
      item={true}
    >
      {children}
    </Grid>
  )
}

export default CardFormContainer
