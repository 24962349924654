import React from 'react'
import TableCellCheckbox from './TableCellCheckbox'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Select
} from '../../../Functions/Table'
import {
  SELECTED_COMPANIES
} from '../../../Redux/Actions/Companies'

const TableCellCheckboxCompanies = ({ item }) => {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.companies.selected)
  return (
    <TableCellCheckbox
      checked={selected.includes(item.id)}
      onClick={() => Select(dispatch, SELECTED_COMPANIES, selected, item.id)}
    />
  )
}

export default TableCellCheckboxCompanies
