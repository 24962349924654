import React from 'react'
import FonctionsToolbarContent from './FonctionsToolbarContent'

const FonctionsToolbarMobile = () => {
  return (
    <div className="toolbar-actions-mobile">
      <FonctionsToolbarContent/>
    </div>
  )
}

export default FonctionsToolbarMobile
