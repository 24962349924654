import clsx from 'clsx'
import React from 'react'
import Texte from '../Includes/Texte'
import styles from './Tarifs.module.css'
import Container from '../Includes/Container'
import history from '../../Navigation/Includes/History'
import Abonnements from '../../Components/Abonnements/Abonnements'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SIGNIN_ABONNEMENT
} from '../../Redux/Actions/Signin'

const Tarifs = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const abonnements = useSelector(state => state.landing.abonnements)
  return (
    <Container column={true}>
      <Texte
        full={true}
        titre="Une offre #sur mesure.#"
        description="Nous vous proposons plusieurs forfaits, afin d'ajuster au mieux votre besoin. Vous ne payez que ce que vous utilisez et #sans engagement# !"
      />
      <div className={styles.container}>
        <Abonnements
          mobile={false}
          landing={true}
          abonnements={abonnements}
          Buy={(abonnement) => {
            dispatch(SIGNIN_ABONNEMENT(abonnement))
            history.push('/signin')
          }}
          favorite={(
            (abonnements.length > 0) &&
            (abonnements.find(item => item.favorite).unique_id_abonnement)
          )}
          className={
            clsx({
              [styles.abonnements]: true,
              [styles.mobile]: device.mobile
            })
          }
        />
      </div>
    </Container>
  )
}

export default Tarifs
