import React from 'react'
import FilterMotif from './FilterMotif'
import FilterReset from './FilterReset'
import FilterStatut from './FilterStatut'
import FilterSalarie from './FilterSalarie'
import styles from './FilterDesktop.module.css'
import DateRangePicker from '../Picker/DateRangePicker'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  isDifferent
} from '../../../Functions/Compare'
import {
  filtres
} from '../../../Variables/Variables'
import {
  SET_FILTERS
} from '../../../Redux/Actions/Filters'

const FilterDesktop = ({
  page,
  setPage,
  setting
}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const filters = useSelector(state => state.filters.filters)
  return (
    <Toolbar className={styles.toolbar}>
      <div className={styles.content}>
        {
          (user.profil === 'administrateur')
            ? <FilterSalarie
                className={styles.filter}
                value={filters[page].salarie}
                setValue={(value) => {
                  setPage(0)
                  dispatch(SET_FILTERS(page, 'salarie', value))
                }}
              />
            : null
        }
        {
          (page !== 'indemnites')
            ? <FilterMotif
                setting={setting}
                className={styles.filter}
                value={filters[page].motif}
                setValue={(value) => {
                  setPage(0)
                  dispatch(SET_FILTERS(page, 'motif', value))
                }}
              />
            : null
        }
        <FilterStatut
          className={styles.filter}
          value={filters[page].status}
          setValue={(value) => {
            setPage(0)
            dispatch(SET_FILTERS(page, 'status', value))
          }}
        />
        <DateRangePicker
          value={filters[page].dates}
          slotProps={{
            textField: {
              className: styles.filter
            }
          }}
          onChange={(value) => {
            setPage(0)
            const date = (value[1] !== null)
              ? value[1].endOf('day')
              : null
            dispatch(SET_FILTERS(page, 'dates', [value[0], date]))
          }}
        />
      </div>
      {
        (isDifferent([filters[page]], [filtres[page]]))
          ? <FilterReset
              target={page}
              callback={() => setPage(0)}
            />
          : null
      }
    </Toolbar>
  )
}

export default FilterDesktop
