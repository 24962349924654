import React from 'react'
import BarTitle from './BarTitle'
import {
  useSelector
} from 'react-redux'
import {
  get_user_long
} from '../../Functions/Strings'

const BarTitleDesktop = () => {
  const user = useSelector(state => state.login.user)
  const companie = useSelector(state => state.settings.companie)
  return (
    <>
      {
        (companie !== '')
          ? <span className="navbar-user">
              <span className="navbar-name">{companie}</span>
            </span>
          : null
      }
      <BarTitle/>
      <span
        className={
          (user.profil !== 'office')
            ? "navbar-user"
            : "navbar-mail"
        }
      >
        {
          (user.profil !== 'office')
            ? <span className="navbar-name">{get_user_long(user)}</span>
            : <span className="navbar-name">{user.mail}</span>
        }
        <span className="navbar-divider"><div/></span>
      </span>
    </>
  )
}

export default BarTitleDesktop
