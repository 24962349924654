import React from 'react'
import TableMobileRow from './TableMobileRow'

const TableMobile = ({
  titre,
  columns,
  ...others
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const TableTitre = titre
  return (
    <>
      <TableTitre
        expanded={expanded}
        setExpanded={setExpanded}
        {...others}
      />
      <TableMobileRow
        columns={columns}
        expanded={expanded}
        {...others}
      />
    </>
  )
}

export default TableMobile
