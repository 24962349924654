import React from 'react'
import Form from '../Wrapper/Form'
import SelectInput from '../Select/SelectInput'
import {
  useSelector
} from 'react-redux'
import {
  start_case
} from '../../../Functions/Strings'

const FilterUsersContent = ({
  state,
  Update
}) => {
  const profils = useSelector(state => state.landing.profils)
  const companies = useSelector(state => state.companies.companies)
  return (
    <Form>
      {
        (companies.length > 0)
          ? <SelectInput
              filter={true}
              label="Entreprise"
              value={state.companie}
              setValue={(value) => Update('companie', value)}
              options={companies.map(item => {
                return {
                  value: item.companie,
                  label: item.companie
                }
              })}
            />
          : null
      }
      <SelectInput
        filter={true}
        label="Profil"
        value={state.profil}
        setValue={(value) => Update('profil', value)}
        options={profils.map(item => {
          return {
            value: item,
            label: start_case(item)
          }
        })}
      />
      <SelectInput
        filter={true}
        label="Statut"
        value={state.status}
        setValue={(value) => Update('status', value)}
        options={[
          {
            value: 1,
            label: 'Activé'
          },
          {
            value: 0,
            label: 'Désactivé'
          }
        ]}
      />
      <SelectInput
        filter={true}
        label="Connecté"
        value={state.logged}
        setValue={(value) => Update('logged', value)}
        options={[
          {
            value: 1,
            label: 'Oui'
          },
          {
            value: 0,
            label: 'Non'
          }
        ]}
      />
      <SelectInput
        filter={true}
        value={state.login}
        label="Dernière connexion"
        setValue={(value) => Update('login', value)}
        options={[
          {
            value: 0,
            label: 'Jamais'
          },
          {
            value: 1,
            label: '1 semaine'
          },
          {
            value: 4,
            label: '1 mois'
          },
          {
            value: 12,
            label: '3 mois'
          }
        ]}
      />
    </Form>
  )
}

export default FilterUsersContent
