import React from 'react'
import Icone from './Icone'
import styles from './Card.module.css'

const Card = ({ item }) => {
  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <div className={styles.titre}>
          {item.title}
        </div>
        <Icone
          mini={true}
          icon={item.icon}
        />
      </div>
      <div className={styles.description}>
        {item.description}
      </div>
    </div>
  )
}

export default Card
