import clsx from 'clsx'
import React from 'react'
import styles from './HamburgerIcone.module.css'
import {
  ListItemIcon
} from '@mui/material'

const HamburgerIcone = React.memo(({ icone }) => {
  return (
    <ListItemIcon className={styles.icone}>
      {
        (typeof(icone) === 'string')
          ? <i
              className={
                clsx(
                  icone,
                  'icon'
                )
              }
            />
          : <span>{icone}</span>
      }
    </ListItemIcon>
  )
})

export default HamburgerIcone
