import Form from '../Includes/Wrapper/Form'
import SettingModalContent from './SettingModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch
} from 'react-redux'
import {
  regex
} from '../../Variables/Regex'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  UPDATE_SETTING
} from '../../Redux/Actions/Settings'

const SettingModal = ({
  open,
  values,
  setOpen
}) => {
  const dispatch = useDispatch()
  const [error, setError] = React.useState('')
  const [value, setValue] = React.useState('')
  const condition = new RegExp(regex[values.regex], 'g')
  const Update = (value) => {
    setValue(value)
    setError(
      (value !== '') &&
      (!condition.test(value))
    )
  }
  const Commit = () => {
    if (condition.test(value)) {
      setError(false)
      const object = {
        value: value,
        id: values.id,
        action: values.action
      }
      if (isDifferent([value], [values.value])) {
        dispatch(UPDATE_SETTING(object))
      }
      setOpen()
    } else {
      setError(!condition.test(value))
    }
  }
  useEffect(() => {
    if (open) {
      setValue(values.value)
      setError(false)
    }
  }, [open, values])
  return (
    <Dialog open={open}>
      <DialogTitle>Modifier le paramètre</DialogTitle>
      <DialogContent>
        <Form>
          <SettingModalContent
            error={error}
            value={value}
            values={values}
            Commit={Commit}
            Update={Update}
          />
        </Form>
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen()
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default SettingModal
