import {
  regex
} from '../Variables/Regex'

export const CheckInt = (texte, positive = true) => {
  const exp = (positive)
    ? regex.int.positive
    : regex.int.negative
  const condition = new RegExp(exp, 'g')
  return condition.test(texte)
}

export const CheckFloat = (texte, positive = true) => {
  const exp = (positive)
    ? regex.float.positive
    : regex.float.negative
  const condition = new RegExp(exp, 'g')
  return condition.test(texte)
}

export const CheckCode = (code) => {
  const condition = new RegExp(regex.code, 'g')
  return condition.test(code)
}

export const CheckMail = (mail) => {
  const condition = new RegExp(regex.mail, 'g')
  return condition.test(mail)
}

export const CheckLong = (texte) => {
  const condition = new RegExp(regex.long, 'g')
  return condition.test(texte)
}

export const CheckToken = (token) => {
  const condition = new RegExp(regex.jwt, 'g')
  return condition.test(token)
}

export const CheckTexte = (texte) => {
  const condition = new RegExp(regex.texte, 'g')
  return condition.test(texte)
}

export const CheckConges = (texte) => {
  const condition = new RegExp(regex.conges, 'g')
  return condition.test(texte)
}

export const CheckStripe = (texte) => {
  const condition = new RegExp(regex.stripe, 'g')
  return condition.test(texte)
}

export const CheckMessage = (texte) => {
  const condition = new RegExp(regex.message, 'gs')
  return condition.test(texte)
}

export const CheckComment = (texte) => {
  const condition = new RegExp(regex.commentaire, 'gs')
  return condition.test(texte)
}

export const CheckPassword = (password) => {
  const condition = new RegExp(regex.password.password, 'g')
  return condition.test(password)
}

export const CheckTimestamp = (timestamp) => {
  const condition = new RegExp(regex.timestamp, 'g')
  return condition.test(timestamp)
}

export const CheckFloatNegative = (texte) => {
  const condition = new RegExp(regex.float, 'g')
  return condition.test(texte)
}

export const CheckPasswordChiffre = (password) => {
  const condition = new RegExp(regex.password.chiffre, 'g')
  return condition.test(password)
}

export const CheckPasswordSpecial = (password) => {
  const condition = new RegExp(regex.password.special, 'g')
  return condition.test(password)
}

export const CheckPasswordMinuscule = (password) => {
  const condition = new RegExp(regex.password.minuscule, 'g')
  return condition.test(password)
}

export const CheckPasswordMajuscule = (password) => {
  const condition = new RegExp(regex.password.majuscule, 'g')
  return condition.test(password)
}
