import React from 'react'
import styles from '../Styles/Mail.module.css'
import ButtonDelete from '../../Includes/Button/ButtonDelete'
import Destinataires from '../../Includes/Misc/Destinataires'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  PLAQUETTE,
  DEL_PLAQUETTE
} from '../../../Redux/Actions/Plaquette'

const PlaquetteForm = () => {
  const dispatch = useDispatch()
  const mails = useSelector(state => state.plaquette.mails)
  return (
    <Paper className={styles.paper}>
      <div className={styles.container}>
        <Destinataires
          mails={mails}
          Delete={(item) => dispatch(DEL_PLAQUETTE(item))}
        />
        <ButtonDelete
          title=""
          className={styles.action}
          disabled={(mails.length === 0)}
          onClick={() => dispatch(PLAQUETTE('mails', []))}
          color={
            (mails.length === 0)
              ? "grey"
              : "red"
          }
        />
      </div>
    </Paper>
  )
}

export default PlaquetteForm
