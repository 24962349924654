import dayjs from '../../Libraries/dayjs'
import {
  USER_LOGOUT
} from './Login'
import {
  REMOVE_HEURES_USER
} from './Heures'
import {
  SET_FILTERS
} from './Filters'
import {
  REMOVE_ABSENCES_USER
} from './Absences'
import {
  UPDATE_PLANNING,
  REMOVE_PLANNING_USER
} from './Planning'
import {
  REMOVE_INDEMNITES_USER
} from './Indemnites'
import {
  api
} from '../../API/api'
import {
  UPDATE_CLOSE,
  REMOVE_PRIMES_USER
} from './Comptabilite'
import {
  REMOVE_NOTIFICATIONS_USER
} from './Notifications'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const ADD_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_EQUIPE',
      value: value
    })
  }
}

export const DEL_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_EQUIPE',
      value: value
    })
  }
}

export const EDIT_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_EQUIPE',
      value: value
    })
  }
}

export const INIT_EQUIPE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_EQUIPE',
      value: value
    })
  }
}

export const SET_MAIL = (value, user) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_MAIL',
      value: value,
      user: user
    })
  }
}

export const UPDATE_CONGES_USER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_CONGES_USER',
      value: value
    })
  }
}

export const MODAL_EQUIPE = (id, open, name) => {
  return (dispatch) => {
    dispatch({
      type: 'MODAL_EQUIPE',
      name: name,
      open: open,
      id: id
    })
  }
}

export const RESET_USER = (id) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      id: id,
      action: 'reset-user'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const REMOVE_EQUIPE = (id) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    const data = JSON.stringify({
      id: id,
      action: 'delete-user'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(DEL_EQUIPE(id))
          dispatch(UPDATE_PLANNING())
          dispatch(REMOVE_HEURES_USER(id))
          dispatch(REMOVE_PRIMES_USER(id))
          dispatch(REMOVE_ABSENCES_USER(id))
          dispatch(REMOVE_PLANNING_USER(id))
          dispatch(REMOVE_INDEMNITES_USER(id))
          dispatch(REMOVE_NOTIFICATIONS_USER(id))
          const filters = state().filters.filters
          if (filters.heures.salarie === id) {
            dispatch(SET_FILTERS('heures', 'salarie', -1))
          }
          if (filters.absences.salarie === id) {
            dispatch(SET_FILTERS('absences', 'salarie', -1))
          }
          if (filters.indemnites.salarie === id) {
            dispatch(SET_FILTERS('indemnites', 'salarie', -1))
          }
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const EQUIPE = (value, action) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    const data = JSON.stringify({
      value: value,
      action: action
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          if (action === 'create-user') {
            dispatch(ADD_EQUIPE(value))
          } else {
            let array = []
            if (value.commit === 1) {
              const heures = state().heures.heures
              array = heures.filter(item =>
                (item.recuperation === 1) &&
                (item.user === value.id) &&
                (item.status === 1)
              ).map(item => item.id)
              dispatch(UPDATE_CLOSE('heure', array, value))
            } else if (value.conges === 1) {
              const absences = state().absences.absences
              array = absences.filter(item =>
                (dayjs.unix(item.fin).isSameOrBefore(dayjs())) &&
                (item.user === value.id) &&
                (item.motif === 'conge') &&
                (item.status === 1)
              ).map(item => item.id)
              dispatch(UPDATE_CLOSE('absence', array, value))
            } else {
              dispatch(EDIT_EQUIPE(value))
            }
          }
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
