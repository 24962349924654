import React from 'react'
import ButtonArrow from '../../Includes/Button/ButtonArrow'
import TableCellField from '../../Includes/Table/TableCellField'
import TableCellTitre from '../../Includes/Table/TableCellTitre'
import TableMobileRowTitre from '../../Includes/Table/TableMobileRowTitre'
import TableCellFieldCheckbox from '../../Includes/Table/TableCellFieldCheckbox'
import TableCellTitreCompanie from '../../Includes/Table/TableCellTitreCompanie'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Select
} from '../../../Functions/Table'
import {
  SELECTED_COMPANIES
} from '../../../Redux/Actions/Companies'

const CompaniesMobileTitre = ({
  item,
  type,
  expanded,
  setExpanded
}) => {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.companies.selected)
  return (
    <TableMobileRowTitre
      item={item}
      type={type}
    >
      <TableCellField>
        <ButtonArrow
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </TableCellField>
      <TableCellTitre
        onClick={() => setExpanded(!expanded)}
      >
        <TableCellTitreCompanie item={item}/>
        {item.abonnement + ' - ' + item.reel + '€'}
      </TableCellTitre>
      <TableCellFieldCheckbox
        checked={(selected.includes(item.id))}
        onClick={() => Select(dispatch, SELECTED_COMPANIES, selected, item.id)}
      />
    </TableMobileRowTitre>
  )
}

export default CompaniesMobileTitre
