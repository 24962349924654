import React from 'react'
import history from '../../Navigation/Includes/History'
import {
  Button
} from '@mui/material'
import {
  landing
} from '../../Variables/Landing'

const SigninSuccess = () => (
  <div className="signin-success">
    <div className="signin-texte">
      <div>Félicitations, tout est prêt !</div>
      <div>
        <span>Vous pouvez dès à présent vous connecter et commencer à découvrir </span>
        <span className="signin-success-name">{landing.nom}</span>
        <span> !</span>
      </div>
    </div>
    <div className="signin-button">
      <Button
        color="primary"
        variant="contained"
        onClick={() => history.push('/login')}
      >
        Se connecter
      </Button>
    </div>
  </div>
)

export default SigninSuccess
