import React from 'react'
import ButtonCompte from './ButtonCompte'

const ButtonCompteDelete = (props) => {
  return (
    <ButtonCompte
      icon="Delete"
      color="secondary"
      title="Supprimer"
      {...props}
    />
  )
}

export default ButtonCompteDelete
