import {
  useSelector
} from 'react-redux'

const Condition = (item) => {
  const user = useSelector(state => state.login.user)
  return (
    (item !== undefined) &&
    (item.status !== 3) &&
    (
      (user.profil === 'administrateur') ||
      (
        (item.status === 2) &&
        (item.user === user.unique_id_user)
      )
    )
  )
}

export default Condition
