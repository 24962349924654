import Planning from '../Hooks/Planning'
import Semaines from '../Hooks/Semaines'
import {
  useSelector
} from 'react-redux'

const Condition = () => {
  const planning = Planning()
  const semaines = Semaines()
  const user = useSelector(state => state.login.user)
  const working = useSelector(state => state.semaines.working)
  return (
    (
      (semaines) &&
      (
        (working.edit) ||
        (working.new)
      )
    ) ||
    (
      (planning) &&
      (user.profil === 'administrateur')
    )
  )
}

export default Condition
