import React from 'react'
import Alert from '../Dialog/Alert'
import Condition from '../Condition/Demandes'
import styles from './LegendeCreneauHeure.module.css'
import ButtonIconEdit from '../Button/ButtonIconEdit'
import ButtonIconTrash from '../Button/ButtonIconTrash'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  DELETE_HEURE,
  MODAL_HEURES
} from '../../../Redux/Actions/Heures'
import {
  SET_EVENT
} from '../../../Redux/Actions/Planning'

const LegendeCreneauHeure = ({ data }) => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.heures.modal)
  const heures = useSelector(state => state.heures.heures)
  const heure = heures.find(item => item.id === data.id)
  const condition = Condition(heure)
  return (condition)
    ? <>
        <Alert
          titre="Confirmation"
          open={(modal.open === 'delete')}
          Cancel={() => dispatch(MODAL_HEURES('', ''))}
          texte="Voulez-vous vraiment supprimer cette heure ?"
          Commit={() => {
            dispatch(DELETE_HEURE(modal.id))
            dispatch(MODAL_HEURES('', ''))
            dispatch(SET_EVENT({
              id: '',
              read: false
            }))
          }}
        />
        <span className={styles.action}>
          <ButtonIconEdit
            small={true}
            inherit={true}
            title="Modifier l'heure"
            onClick={() => dispatch(MODAL_HEURES(data.id, 'edit', false))}
          />
        </span>
        <span className={styles.action}>
          <ButtonIconTrash
            small={true}
            inherit={true}
            title="Supprimer l'heure"
            onClick={() => dispatch(MODAL_HEURES(data.id, 'delete'))}
          />
        </span>
      </>
    : null
}

export default LegendeCreneauHeure
