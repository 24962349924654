import React from 'react'
import Button from './Button'

const ButtonMenu = (props) => {
  return (
    <Button
      icon="Menu"
      color="bar"
      {...props}
    />
  )
}

export default ButtonMenu
