import React from 'react'
import InputTextField from './InputTextField'
import {
  useSelector
} from 'react-redux'
import {
  CheckInt
} from '../../../Functions/Check'

const InputNombre = ({
  error,
  value,
  ...others
}) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <InputTextField
      error={error}
      value={value}
      label="Kilomètres"
      helperText={
        (error)
          ? (!CheckInt(value))
            ? "Le nombre de kilomètres doit être un entier positif de la forme 123"
            : (parseInt(value) < settings.minimum.indemnite)
              ? "Le nombre de kilomètres doit être supérieur à " + settings.minimum.indemnite
              : (parseInt(value) > settings.maximum.indemnite)
                ? "Le nombre de kilomètres doit être inférieur à " + settings.maximum.indemnite
                : null
          : null
      }
      {...others}
    />
  )
}

export default InputNombre
