import SemaineRenameContent from './SemaineRenameContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  CheckTexte
} from '../../Functions/Check'
import {
  lower_case
} from '../../Functions/Strings'
import {
  get_id
} from '../../Functions/Functions'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  SET_RENAME,
  CLONE_WEEK,
  RENAME_WEEK
} from '../../Redux/Actions/Semaines'

const SemaineRename = () => {
  const dispatch = useDispatch()
  const rename = useSelector(state => state.semaines.rename)
  const semaines = useSelector(state => state.semaines.semaines)
  const [state, setState] = React.useState({
    nom: '',
    error: false,
    existe: false
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (CheckTexte(state.nom)) {
      const existe = semaines.find(item => lower_case(item.nom) === lower_case(state.nom))
      if (existe === undefined) {
        const semaine = {
          id: rename.id,
          nom: state.nom
        }
        Update('error', false)
        Update('existe', false)
        if (rename.duplicate) {
          const week = semaines.find(item => item.id === semaine.id).horaires
          const object = {
            id: get_id(),
            nom: semaine.nom,
            horaires: week.map(item => {
              return {
                ...item,
                id: get_id()
              }
            })
          }
          dispatch(CLONE_WEEK(object))
        } else {
          dispatch(RENAME_WEEK(semaine))
        }
        dispatch(SET_RENAME({
          ...rename,
          open: false
        }))
      } else {
        Update('error', true)
        Update('existe', true)
      }
    } else {
      Update('error', !CheckTexte(state.nom))
    }
  }
  useEffect(() => {
    if (rename.open) {
      Update('error', false)
      Update('existe', false)
      Update('nom', rename.nom)
    }
  }, [rename])
  return (
    <Dialog open={rename.open}>
      <DialogTitle>
        {
          (rename.duplicate)
            ? 'Dupliquer la semaine'
            : 'Renommer la semaine'
        }
      </DialogTitle>
      <DialogContent>
        <SemaineRenameContent
          state={state}
          Commit={Commit}
          Update={Update}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => {
              dispatch(SET_RENAME({
                ...rename,
                open: false
              }))
            }
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default SemaineRename
