import clsx from 'clsx'
import React from 'react'

const PlanningSelect = ({
  All,
  None,
  empty,
  planning
}) => {
  return (
    <div
      className={
        clsx({
          'inline-team-select': true,
          'inline-team-select-empty': empty,
          'inline-team-select-planning': planning
        })
      }
    >
      <div
        onClick={All}
        className="item-team"
      >
        Tous
      </div>
      <div
        onClick={None}
        className="item-team"
      >
        Aucun
      </div>
    </div>
  )
}

export default PlanningSelect
