import React from 'react'
import dayjs from '../../Libraries/dayjs'
import Card from '../Includes/Accueil/Card'
import {
  useSelector
} from 'react-redux'
import {
  ArrowRightAlt
} from '@mui/icons-material'
import {
  sort_debut
} from '../../Functions/Sort'
import {
  start_case
} from '../../Functions/Strings'

const AccueilAbsences = () => {
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const date = useSelector(state => state.absences.date)
  const absences = useSelector(state => state.absences.absences)
  const variables = useSelector(state => state.settings.variables)
  const data = absences.filter(item =>
    (item.status === 1) &&
    (item.user === user.unique_id_user) &&
    (dayjs.unix(item.debut).isSameOrAfter(dayjs(date)))
  )
  const Format = (date) => {
    return (device.mobile)
      ? start_case(dayjs.unix(date).format('D MMMM YYYY'))
      : start_case(dayjs.unix(date).format('dddd D MMMM YYYY'))
  }
  return (
    <Card title="Mes futures absences">
      {
        (data.length > 0)
          ? <div className="accueil-content accueil-asbences">
              {
                sort_debut(data).map((item, index) => {
                  const duree = dayjs.unix(item.debut).isSame(dayjs.unix(item.fin), 'day')
                  return (duree)
                    ? <div key={index} className="accueil-div accueil-div-absence">
                        <div>{Format(item.debut)}</div>
                        <div>{variables.absence.conges[item.duree]}</div>
                      </div>
                    : <div key={index} className="accueil-div accueil-div-absence">
                        <span className="left">{Format(item.debut)}</span>
                        <ArrowRightAlt/>
                        <span className="right">{Format(item.fin)}</span>
                      </div>
                })
              }
            </div>
          : <div className="accueil-empty">Vous n'avez aucune absence programmée</div>
      }
    </Card>
  )
}

export default AccueilAbsences
