import React from 'react'
import TableData from '../Includes/Table/TableData'
import FacturesMobileTitre from './FacturesMobileTitre'
import {
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  properties
} from '../../Settings/Factures'

const FacturesContent = () => {
  const device = useSelector(state => state.device)
  const factures = useSelector(state => state.stripe.factures)
  return (
    <Paper elevation={2}>
      <TableData
        data={factures}
        titre={FacturesMobileTitre}
        pagination={device.desktop}
        {...properties}
      />
    </Paper>
  )
}

export default FacturesContent
