import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateHeureFin = ({
  item,
  label
}) => {
  return (item.nombre === 0)
    ? <TableCellDate
        time={true}
        label={label}
        value={item.fin}
      />
    : null
}

export default TableCellDateHeureFin
