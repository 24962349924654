import React from 'react'
import ButtonAdmin from './ButtonAdmin'

const ButtonAdminUnblock = (props) => {
  return (
    <ButtonAdmin
      color="green"
      icon="LockOpen"
      title="Débloquer"
      {...props}
    />
  )
}

export default ButtonAdminUnblock
