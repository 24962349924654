import React from 'react'
import TableCellDesktop from './TableCellDesktop'
import {
  start_case
} from '../../../Functions/Strings'

const TableCellLegendeSemaines = ({
  item,
  functions
}) => {
  return (
    <TableCellDesktop
      max={200}
      value={start_case(item.nom)}
      onClick={() => functions.click(item.id)}
    />
  )
}

export default TableCellLegendeSemaines
