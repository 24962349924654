import React from 'react'
import ButtonDeny from '../Button/ButtonDeny'
import TableCellMobile from './TableCellMobile'
import ButtonAccept from '../Button/ButtonAccept'
import TableCellContainer from './TableCellContainer'
import {
  useDispatch,
  useSelector
} from 'react-redux'

const TableCellStatus = ({
  item,
  label,
  action,
  device
}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const settings = useSelector(state => state.settings.variables)
  const value = settings.status[item.status]
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellContainer width={2}>
        {
          (
            (item.status === 2) &&
            (user.profil === 'administrateur')
          )
            ? <>
                <ButtonDeny
                  color="red"
                  onClick={() => dispatch(action(item.id, 0))}
                />
                <ButtonAccept
                  color="green"
                  onClick={() => dispatch(action(item.id, 1))}
                />
              </>
            : value
        }
      </TableCellContainer>
}

export default TableCellStatus
