import clsx from 'clsx'
import React from 'react'
import styles from './Planning.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'

const Planning = ({ children }) => {
  const device = useSelector(state => state.device)
  return (
    <Paper
      className={
        clsx({
          [styles.planning]: true,
          [styles.desktop]: device.desktop
        })
      }
    >
      {children}
    </Paper>
  )
}

export default Planning
