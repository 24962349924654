import clsx from 'clsx'
import React from 'react'
import Privacy from './Privacy'
import Copyright from './Copyright'
import FooterIcon from './FooterIcon'
import FooterLogo from './FooterLogo'
import styles from './Footer.module.css'
import {
  useSelector
} from 'react-redux'
import {
  landing
} from '../../../Variables/Landing'
import {
  ReactComponent as DiscordIcon
} from '../../../Images/Social/discord.svg'
import {
  ReactComponent as FacebookIcon
} from '../../../Images/Social/facebook.svg'

const Footer = ({ mini }) => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        clsx({
          [styles.mini]: mini,
          [styles.container]: true,
          [styles.mobile]: device.mobile
        })
      }
    >
      {
        (mini)
          ? <Privacy mini={mini}/>
          : <div className={styles.content}>
              {
                (
                  (landing.social.facebook !== '') ||
                  (landing.social.discord !== '')
                )
                  ? <div
                      className={
                        clsx(
                          styles.icones,
                          styles.landing
                        )
                      }
                    >
                      <FooterLogo/>
                      {
                        (
                          (landing.social.facebook !== '') &&
                          (landing.social.discord !== '')
                        )
                          ? <FooterIcon/>
                          : null
                      }
                    </div>
                  : null
              }
              <div className={styles.copyright}>
                <Copyright/>
                <Privacy/>
              </div>
              {
                (
                  (landing.social.facebook !== '') ||
                  (landing.social.discord !== '')
                )
                  ? <div className={styles.icones}>
                      {
                        (landing.social.facebook !== '')
                          ? <FooterIcon href={landing.social.facebook}>
                              <FacebookIcon/>
                            </FooterIcon>
                          : null
                      }
                      {
                        (landing.social.discord !== '')
                          ? <FooterIcon href={landing.social.discord}>
                              <DiscordIcon/>
                            </FooterIcon>
                          : null
                      }
                    </div>
                  : null
              }
            </div>
      }
    </div>
  )
}

export default Footer
