import React from 'react'
import Form from '../Includes/Wrapper/Form'
import DatePicker from '../Includes/Picker/DatePicker'
import InputNombre from '../Includes/Input/InputNombre'
import SelectSalarie from '../Includes/Select/SelectSalarie'
import {
  useSelector
} from 'react-redux'
import {
  CheckInt
} from '../../Functions/Check'

const IndemniteModalContent = ({
  state,
  Commit,
  Update
}) => {
  const user = useSelector(state => state.login.user)
  const settings = useSelector(state => state.settings.variables)
  const Nombre = (value) => {
    Update('nombre', value)
    Update('error', (
      (parseInt(value) < settings.minimum.indemnite) ||
      (parseInt(value) > settings.maximum.indemnite) ||
      (!CheckInt(value))
    ))
  }
  return (
    <Form>
      {
        (user.profil === 'administrateur')
          ? <SelectSalarie
              value={state.salarie}
              setValue={(value) => Update('salarie', value)}
            />
          : null
      }
      <DatePicker
        value={state.date}
        open={state.picker}
        onClose={() => Update('picker', false)}
        onChange={(value) => Update('date', value)}
        slotProps={{
          textField: {
            onClick: () => Update('picker', true)
          }
        }}
      />
      <InputNombre
        setValue={Nombre}
        error={state.error}
        value={state.nombre}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            Commit()
          }
        }}
      />
    </Form>
  )
}

export default IndemniteModalContent
