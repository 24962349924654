import React from 'react'
import Button from './Button'

const ButtonMailSend = (props) => {
  return (
    <Button
      icon="mail"
      size="small"
      color="blue"
      title="Envoyer"
      style={{
        width: 48,
        height: 48
      }}
      {...props}
    />
  )
}

export default ButtonMailSend
