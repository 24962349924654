import TableCellSalaries from '../Components/Includes/Table/TableCellSalaries'
import TableCellActionsPrimes from '../Components/Includes/Table/TableCellActionsPrimes'
import TableCellComptabiliteMotif from '../Components/Includes/Table/TableCellComptabiliteMotif'
import {
  motif
} from './Includes/Motif'
import {
  actions
} from './Includes/Actions'
import {
  montant
} from './Includes/Montant'

export const properties = {
  type: {
    id: "primes",
    groupe: "comptabilite",
    pagination: "small"
  },
  sort: {
    by: "montant",
    order: "desc"
  },
  columns: [
    {
      id: "salaries",
      sort: false,
      padding: true,
      label: "Salariés",
      component: TableCellSalaries
    },
    {
      ...montant
    },
    {
      ...motif,
      component: TableCellComptabiliteMotif
    },
    {
      ...actions,
      component: TableCellActionsPrimes
    }
  ]
}
