import dayjs from '../../Libraries/dayjs'
import {
  CheckTimestamp
} from '../../Functions/Check'
import {
  first_day,
  first_week,
  first_day_format
} from '../../Functions/Dates'
import {
  device
} from '../../Functions/Device'
import {
  get_id
} from '../../Functions/Functions'

let week
let empty
const target = device()
const initial = {
  day: 0,
  base: {},
  users: [],
  absents: [],
  erreurs: [],
  planning: [],
  show: {
    ouverture: true
  },
  event: {
    id: '',
    read: false
  },
  open: {
    share: false,
    planning: false
  },
  view: (target.mobile)
    ? 'Day'
    : 'Week',
  date: (target.mobile)
    ? dayjs().startOf('day')
    : first_week()
}

function planning(state = initial, action) {
  switch (action.type) {
    case 'DAY':
      return {
        ...state,
        day: action.value
      }
    case 'SHOW':
      return {
        ...state,
        show: {
          ...state.show,
          [action.item]: action.value
        }
      }
    case 'VIEW':
      return {
        ...state,
        view: action.value,
        event: {
          id: '',
          read: false
        }
      }
    case 'SET_EVENT':
      return {
        ...state,
        users: [],
        event: action.value
      }
    case 'SET_USERS':
      return {
        ...state,
        users: action.value
      }
    case 'VIEW_DATE':
      return {
        ...state,
        view: 'Day',
        date: dayjs(action.value),
        event: {
          id: '',
          read: false
        }
      }
    case 'SET_ABSENTS':
      return {
        ...state,
        absents: action.value
      }
    case 'SET_ERREURS':
      return {
        ...state,
        erreurs: action.value
      }
    case 'ADD_PLANNING':
      if (action.item) {
        return {
          ...state,
          planning: state.planning.map(item => {
            return (item.week === first_day_format(state.date))
              ? {
                  ...item,
                  horaires: [
                    ...item.horaires,
                    ...action.value
                  ]
                }
              : item
          })
        }
      } else if (action.hour) {
        return {
          ...state,
          planning: state.planning.map(item => {
            return (item.week === first_day_format(state.date))
              ? {
                  ...item,
                  horaires: [
                    ...item.horaires,
                    ...action.value.horaires
                  ]
                }
              : item
          })
        }
      } else {
        return {
          ...state,
          planning: [
            ...state.planning,
            action.value
          ]
        }
      }
    case 'DEL_PLANNING':
      week = state.planning.find(item => item.week === action.value)
      empty = week.horaires.filter(item => item.heure === true)
      return {
        ...state,
        planning: (empty.length === 0)
          ? state.planning.filter(item => item.week !== action.value)
          : state.planning.map(item => {
              return (item.week === action.value)
                ? {
                    ...item,
                    horaires: item.horaires.filter(item => item.heure === true)
                  }
                : item
            })
      }
    case 'INIT_PLANNING':
      return {
        ...state,
        planning: action.value
      }
    case 'OPEN_PLANNING':
      return {
        ...state,
        open: {
          ...state.open,
          [action.item]: action.value
        }
      }
    case 'CLONE_PLANNING':
      return {
        ...state,
        planning: state.planning.map(item => {
          return (item.week === first_day_format(state.date))
            ? {
                ...item,
                horaires: [
                  ...item.horaires,
                  action.value
                ]
              }
            : item
        })
      }
    case 'STATUS_CRENEAU':
      return {
        ...state,
        planning: state.planning.map(item => {
          return (item.week === first_day_format(dayjs.unix(action.value.debut)))
            ? {
                ...item,
                horaires: item.horaires.map(item => {
                  return (item.id === action.value.id)
                    ? {
                        ...item,
                        status: 3
                      }
                    : item
                })
              }
            : item
        })
      }
    case 'DEL_PLANNING_DAY':
      week = state.planning.find(item => item.week === action.value.week)
      empty = week.horaires.filter(item =>
        (item.heure === true) ||
        (dayjs(item.startDate).format('YYYY-MM-DD') !== action.value.day)
      )
      return {
        ...state,
        planning: (empty.length === 0)
          ? state.planning.filter(item => item.week !== action.value.week)
          : state.planning.map(item => {
              return (item.week === action.value.week)
                ? {
                    ...item,
                    horaires: item.horaires.filter(item =>
                      (item.heure === true) ||
                      (dayjs(item.startDate).format('YYYY-MM-DD') !== action.value.day)
                    )
                  }
                : item
            })
      }
    case 'SET_PDF_PLANNING':
      return {
        ...state,
        base: action.value
      }
    case 'SET_DATE_PLANNING':
      return {
        ...state,
        date: (state.view === 'Week')
          ? first_week(action.value)
          : first_day(action.value)
      }
    case 'ADD_HEURE_PLANNING':
      return {
        ...state,
        planning: (action.value.nombre === 0)
          ? (state.planning.find(item => item.week === first_day_format(dayjs.unix(action.value.debut))) !== undefined)
            ? state.planning.map(item => {
                return (item.week === first_day_format(dayjs.unix(action.value.debut)))
                  ? {
                      ...item,
                      horaires: [
                        ...item.horaires,
                        {
                          heure: true,
                          id: action.value.id,
                          sens: action.value.sens,
                          equipe: [action.value.user],
                          status: action.value.status,
                          endDate: dayjs.unix(action.value.fin).toDate(),
                          startDate: dayjs.unix(action.value.debut).toDate()
                        }
                      ]
                    }
                  : item
              })
            : [
                ...state.planning,
                {
                  id: get_id(),
                  week: first_day_format(dayjs.unix(action.value.debut)),
                  horaires: [
                    {
                      heure: true,
                      id: action.value.id,
                      sens: action.value.sens,
                      equipe: [action.value.user],
                      status: action.value.status,
                      endDate: dayjs.unix(action.value.fin).toDate(),
                      startDate: dayjs.unix(action.value.debut).toDate()
                    }
                  ]
                }
              ]
          : state.planning
      }
    case 'DEL_PLANNING_EQUIPE':
      return {
        ...state,
        planning: state.planning.map(item => {
          return (item.week === first_day_format(state.date))
            ? {
                ...item,
                horaires: item.horaires.map(item => {
                  return (item.id === action.value.horaire)
                    ? {
                        ...item,
                        equipe: item.equipe.filter(item => item !== action.value.user)
                      }
                    : item
                })
              }
            : item
        })
      }
    case 'DEL_PLANNING_HORAIRE':
      week = state.planning.find(item => item.week === first_day_format(state.date))
      empty = week.horaires.filter(item =>
        (item.heure === true) ||
        (item.id !== action.value)
      )
      return {
        ...state,
        planning: (empty.length === 0)
          ? state.planning.filter(item => item.week !== first_day_format(state.date))
          : state.planning.map(item => {
              return (item.week === first_day_format(state.date))
                ? {
                    ...item,
                    horaires: item.horaires.filter(item => item.id !== action.value)
                  }
                : item
            })
      }
    case 'EDIT_PLANNING_EQUIPE':
      return {
        ...state,
        planning: state.planning.map(item => {
          return (item.week === first_day_format(state.date))
            ? {
                ...item,
                horaires: item.horaires.map(item => {
                  return (item.id === action.value.horaire)
                    ? {
                        ...item,
                        equipe: (item.equipe.includes(action.value.user))
                          ? item.equipe
                          : [
                              ...item.equipe,
                              action.value.user
                            ]
                      }
                    : item
                })
              }
            : item
        })
      }
    case 'REMOVE_PLANNING_USER':
      return {
        ...state,
        planning: state.planning.map(item => {
          return {
            ...item,
            horaires: item.horaires.map(item => {
              return {
                ...item,
                equipe: item.equipe.filter(item => item.id !== action.value)
              }
            })
          }
        })
      }
    case 'CLEAR_PLANNING_EQUIPE':
      return {
        ...state,
        planning: state.planning.map(item => {
          return (item.week === first_day_format(state.date))
            ? {
                ...item,
                horaires: item.horaires.map(item => {
                  return (item.id === action.value)
                    ? {
                        ...item,
                        equipe: []
                      }
                    : item
                })
              }
            : item
        })
      }
    case 'EDIT_PLANNING_HORAIRE':
      const fin = action.value.endDate
      const debut = action.value.startDate
      const end = (CheckTimestamp(fin))
        ? dayjs.unix(fin)
        : dayjs(fin)
      const start = (CheckTimestamp(debut))
        ? dayjs.unix(debut)
        : dayjs(debut)
      return {
        ...state,
        users: [],
        planning: state.planning.map(item => {
          return (item.week === first_day_format(state.date))
            ? {
                ...item,
                horaires: item.horaires.map(item => {
                  return (item.id === action.value.id)
                    ? {
                        ...item,
                        endDate: end.toDate(),
                        startDate: start.toDate()
                      }
                    : item
                })
              }
            : item
        })
      }
    case 'MODIFY_HEURE_PLANNING':
      const same = (first_day_format(dayjs.unix(action.ancien.debut)) === first_day_format(dayjs.unix(action.value.debut)))
      return {
        ...state,
        event: (same)
          ? state.event
          : {
              id: '',
              read: false
            },
        planning: (action.value.nombre === 0)
          ? (same)
            ? state.planning.map(item => {
                return (item.week === first_day_format(dayjs.unix(action.value.debut)))
                  ? {
                      ...item,
                      horaires: item.horaires.map(item => {
                        return (item.id === action.value.id)
                          ? {
                              ...item,
                              sens: action.value.sens,
                              equipe: [action.value.user],
                              endDate: dayjs.unix(action.value.fin).toDate(),
                              startDate: dayjs.unix(action.value.debut).toDate()
                            }
                          : item
                      })
                    }
                  : item
              })
            : (state.planning.find(item => item.week === first_day_format(dayjs.unix(action.value.debut))) === undefined)
                ? [
                    ...state.planning.map(item => {
                      return (item.week === first_day_format(dayjs.unix(action.ancien.debut)))
                        ? {
                            ...item,
                            horaires: item.horaires.filter(object => object.id !== action.value.id)
                          }
                        : item
                    }),
                    {
                      id: get_id(),
                      week: first_day_format(dayjs.unix(action.value.debut)),
                      horaires: [
                        {
                          heure: true,
                          id: action.value.id,
                          sens: action.value.sens,
                          equipe: [action.value.user],
                          endDate: dayjs.unix(action.value.fin).toDate(),
                          startDate: dayjs.unix(action.value.debut).toDate()
                        }
                      ]
                    }
                  ]
                : state.planning.map(item => {
                    return (item.week === first_day_format(dayjs.unix(action.ancien.debut)))
                      ? {
                          ...item,
                          horaires: item.horaires.filter(object => object.id !== action.value.id)
                        }
                      : (item.week === first_day_format(dayjs.unix(action.value.debut)))
                        ? {
                            ...item,
                            horaires: [
                              ...item.horaires,
                              {
                                heure: true,
                                id: action.value.id,
                                sens: action.value.sens,
                                equipe: [action.value.user],
                                endDate: dayjs.unix(action.value.fin).toDate(),
                                startDate: dayjs.unix(action.value.debut).toDate()
                              }
                            ]
                          }
                        : item
                  })
          : state.planning
      }
    case 'REMOVE_HEURE_PLANNING':
      return {
        ...state,
        planning: (action.ancien.nombre === 0)
          ? state.planning.map(item => {
              return (item.week === first_day_format(dayjs.unix(action.ancien.debut)))
                ? {
                    ...item,
                    horaires: item.horaires.filter(item => item.id !== action.value)
                  }
                : item
            })
          : state.planning
      }
    case 'EDIT_PLANNING_HORAIRE_FORM':
      week = state.planning.find(item => item.week === first_day_format(state.date))
      empty = week.horaires.filter(item =>
        (item.heure === true) ||
        (item.id !== action.value.id)
      )
      return (state.planning.find(item => item.week === action.value.week) !== undefined)
        ? (action.value.week === first_day_format(state.date))
          ? {
              ...state,
              planning: state.planning.map(item => {
                return (item.week === action.value.week)
                  ? {
                      ...item,
                      horaires: item.horaires.map(item => {
                        return (item.id === action.value.id)
                          ? {
                              ...item,
                              equipe: action.value.equipe,
                              endDate: action.value.endDate,
                              startDate: action.value.startDate
                            }
                          : item
                      })
                    }
                  : item
              })
            }
          : {
              ...state,
              event: {
                id: '',
                read: false
              },
              planning: (empty.length === 0)
                ? state.planning.filter(item => item.week !== first_day_format(state.date)).map(item => {
                    return (item.week === action.value.week)
                      ? {
                          ...item,
                          horaires: [
                            ...item.horaires,
                            {
                              id: action.value.id,
                              equipe: action.value.equipe,
                              endDate: action.value.endDate,
                              startDate: action.value.startDate
                            }
                          ]
                        }
                      : item
                  })
                : state.planning.map(item => {
                    return (item.week === first_day_format(state.date))
                      ? {
                          ...item,
                          horaires: item.horaires.filter(object => object.id !== action.value.id)
                        }
                      : (item.week === action.value.week)
                        ? {
                            ...item,
                            horaires: [
                              ...item.horaires,
                              {
                                id: action.value.id,
                                equipe: action.value.equipe,
                                endDate: action.value.endDate,
                                startDate: action.value.startDate
                              }
                            ]
                          }
                        : item
                  })
            }
        : {
            ...state,
            event: {
              id: '',
              read: false
            },
            planning: (empty.length === 0)
              ? [
                  ...state.planning.filter(item => item.week !== first_day_format(state.date)),
                  {
                    week: action.value.week,
                    id: action.value.semaine,
                    horaires: [
                      {
                        id: action.value.id,
                        equipe: action.value.equipe,
                        endDate: action.value.endDate,
                        startDate: action.value.startDate
                      }
                    ]
                  }
                ]
              : [
                  ...state.planning.map(item => {
                    return (item.week === first_day_format(state.date))
                      ? {
                          ...item,
                          horaires: item.horaires.filter(object => object.id !== action.value.id)
                        }
                      : item
                  }),
                  {
                    week: action.value.week,
                    id: action.value.semaine,
                    horaires: [
                      {
                        id: action.value.id,
                        equipe: action.value.equipe,
                        endDate: action.value.endDate,
                        startDate: action.value.startDate
                      }
                    ]
                  }
                ]
          }
    default:
      return state
  }
}

export default planning
