import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateFin = ({ item }) => {
  return (
    <TableCellDate
      value={item.fin}
      full={(item.nombre === 0)}
    />
  )
}

export default TableCellDateFin
