import React from 'react'
import Reveal from './Reveal'
import styles from './Animation.module.css'

const Animation = (props) => {
  return (
    <div className={styles.container}>
      <Reveal {...props}/>
    </div>
  )
}

export default Animation
