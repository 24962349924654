import React from 'react'
import InputTextField from './InputTextField'

const InputSetting = ({
  error,
  value,
  Commit,
  Update,
  values
}) => {
  return (
    <InputTextField
      error={error}
      value={value}
      setValue={Update}
      label={values.item}
      placeholder={values.placeholder}
      helperText={
        (error)
          ? values.invalid
          : null
      }
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          Commit()
        }
      }}
    />
  )
}

export default InputSetting
