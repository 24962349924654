import TableCellActionsUsers from '../Components/Includes/Table/TableCellActionsUsers'
import {
  mail
} from './Includes/Mail'
import {
  actions
} from './Includes/Actions'
import {
  MODAL_OFFICES
} from '../Redux/Actions/Offices'

export const properties = {
  type: {
    id: "offices",
    groupe: "users"
  },
  sort: {
    by: "mail",
    order: "asc"
  },
  columns: [
    {
      ...mail
    },
    {
      ...actions,
      mobile: true,
      action: MODAL_OFFICES,
      component: TableCellActionsUsers
    }
  ]
}
