import React from 'react'
import Titre from '../Misc/Titre'
import Container from './Container'
import {
  Toolbar
} from '@mui/material'

const Card = ({
  title,
  children
}) => {
  return (
    <Container>
      <Toolbar>
        <Titre>
          {title}
        </Titre>
      </Toolbar>
      {children}
    </Container>
  )
}

export default Card
