import React from 'react'
import Video from '../Includes/Misc/Video'
import styles from './AideVideo.module.css'
import {
  Paper
} from '@mui/material'

const AideVideo = ({ video }) => {
  return (
    <Paper>
      <div className={styles.container}>
        <Video
          width={640}
          height={353}
          video={video}
        />
      </div>
    </Paper>
  )
}

export default AideVideo
