import React from 'react'
import Button from './Button'

const ButtonIconEye = (props) => {
  return (
    <Button
      icon="eye"
      {...props}
    />
  )
}

export default ButtonIconEye
