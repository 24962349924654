import React from 'react'
import TableCellField from './TableCellField'
import ActionSheet from '../Action/ActionSheet'
import ButtonDownload from '../Button/ButtonDownload'
import TableCellContainer from './TableCellContainer'
import {
  saveAs
} from 'file-saver'
import {
  useSelector
} from 'react-redux'

const TableCellActionsFactures = ({ item }) => {
  const device = useSelector(state => state.device)
  const Download = (item) => {
    saveAs(item.url, item.id + '.pdf')
  }
  return (device.mobile)
    ? <TableCellField>
        <ActionSheet
          options={[
            {
              id: 0,
              label: 'Télécharger',
              action: () => Download(item)
            }
          ]}
        />
      </TableCellField>
    : <TableCellContainer width={1}>
        <ButtonDownload
          color="green"
          onClick={() => Download(item)}
        />
      </TableCellContainer>
}

export default TableCellActionsFactures
