import React from 'react'
import Button from './Button'

const ButtonPaginationLast = (props) => {
  return (
    <Button
      icon="LastPage"
      title="Dernière page"
      {...props}
    />
  )
}

export default ButtonPaginationLast
