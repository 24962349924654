import {
  nombre
} from '../Includes/Nombre'
import {
  fonction
} from '../Includes/Fonction'

export const properties = {
  type: {
    id: "creneau",
    groupe: "legendes"
  },
  sort: {
    by: "",
    order: ""
  },
  columns: [
    {
      ...fonction
    },
    {
      ...nombre,
      right: true,
      mobile: true
    }
  ]
}
