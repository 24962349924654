import React from 'react'
import Button from './Button'

const ButtonPaginationFirst = (props) => {
  return (
    <Button
      icon="FirstPage"
      title="Première page"
      {...props}
    />
  )
}

export default ButtonPaginationFirst
