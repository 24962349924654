import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellMail = ({
  item,
  type,
  label,
  device
}) => {
  const value = item.mail
  const object = {
    value: value,
    empty: (value === '')
  }
  return (
    (device.mobile) &&
    (type.id !== 'offices')
  )
    ? <TableCellMobile
        label={label}
        {...object}
      />
    : <TableCellDesktop
        max={250}
        {...object}
      />
}

export default TableCellMail
