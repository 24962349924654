import React from 'react'
import ButtonAdd from '../Includes/Button/ButtonAdd'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  CREATE_OFFICE
} from '../../Redux/Actions/Offices'

const OfficesToolbarContent = () => {
  const dispatch = useDispatch()
  const disabled = useSelector(state => state.offices.disabled)
  return (
    <ButtonAdd
      disabled={disabled}
      mobile="Nouveau compte office"
      onClick={() => dispatch(CREATE_OFFICE())}
    />
  )
}

export default OfficesToolbarContent
