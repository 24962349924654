import React from 'react'
import Scheduler from '../Includes/Planning/Scheduler'
import ToolbarComponent from '../Includes/Toolbar/ToolbarComponent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  MonthCell,
  ScaleCell,
  AppointmentComponentAbsence
} from '../Includes/Event/Event'
import {
  get_array_holidays
} from '../../Functions/Absences'
import {
  RootDateComponent
} from '../Includes/Toolbar/Toolbar'
import {
  ABSENCE,
  SET_DATE_ABSENCES
} from '../../Redux/Actions/Absences'

const AbsencesPlanningScheduler = React.memo(({ absences }) => {
  const dispatch = useDispatch()
  const date = useSelector(state => state.absences.date)
  const filter = useSelector(state => state.filters.filter)
  const data = absences.filter(item => filter.indexOf(item.user) < 0)
  const holidays = get_array_holidays(date, 'month')
  const Date = (date) => {
    dispatch(ABSENCE(''))
    dispatch(SET_DATE_ABSENCES(date))
  }
  return (
    <Scheduler
      data={data}
      date={date}
      view="Month"
      horaires={false}
      draggable={false}
      functions={{
        Date: Date
      }}
      components={{
        Toolbar: ToolbarComponent,
        Navigator: RootDateComponent,
        Appointment: AppointmentComponentAbsence
      }}
      cells={{
        dayScaleCellComponent: (props) =>
          <ScaleCell
            month={true}
            {...props}
          />,
        timeTableCellComponent: (props) =>
          <MonthCell
            holidays={holidays}
            {...props}
          />
      }}
    />
  )
})

export default AbsencesPlanningScheduler
