import React from 'react'
import InputCode from '../Includes/Input/InputCode'
import LoginButton from '../Includes/Login/LoginButton'
import {
  useDispatch
} from 'react-redux'
import {
  CheckCode
} from '../../Functions/Check'
import {
  PENDING
} from '../../Redux/Actions/Login'
import {
  USER_TOKEN
} from '../../Redux/Actions/Signin'

const ForgotCode = ({
  error,
  state,
  Update,
  UpdateError
}) => {
  const dispatch = useDispatch()
  const Code = (value) => {
    Update('code', value)
    UpdateError('code', (
      (value !== '') &&
      (!CheckCode(value))
    ))
  }
  const Commit = () => {
    if (CheckCode(state.code)) {
      dispatch(PENDING(true))
      UpdateError('code', false)
      dispatch(USER_TOKEN(
        state.mail,
        state.code,
        () => Update('step', 3)
      ))
    } else {
      UpdateError('code', !CheckCode(state.code))
    }
  }
  return (
    <>
      <InputCode
        setValue={Code}
        error={error.code}
        value={state.code}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            Commit()
          }
        }}
      />
      <LoginButton
        label="Continuer"
        onClick={() => Commit()}
      />
    </>
  )
}

export default ForgotCode
