import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const ADD_INDEMNITE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_INDEMNITE',
      value: value
    })
  }
}

export const INIT_INDEMNITES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_INDEMNITES',
      value: value
    })
  }
}

export const MODIFY_INDEMNITE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'MODIFY_INDEMNITE',
      value: value
    })
  }
}

export const REMOVE_INDEMNITE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_INDEMNITE',
      value: value
    })
  }
}

export const MODAL_INDEMNITES = (id, open) => {
  return (dispatch) => {
    dispatch({
      type: 'MODAL_INDEMNITES',
      open: open,
      id: id
    })
  }
}

export const STATUS_INDEMNITE = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: 'STATUS_INDEMNITE',
      value: value,
      id: id
    })
  }
}

export const REMOVE_INDEMNITES_USER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_INDEMNITES_USER',
      value: value
    })
  }
}

export const DELETE_INDEMNITE = (id) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      id: id,
      action: 'delete-indemnite'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(REMOVE_INDEMNITE(id))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const EDIT_INDEMNITE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'edit-indemnite'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(MODIFY_INDEMNITE(value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CREATE_INDEMNITE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'create-indemnite'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(ADD_INDEMNITE(value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_INDEMNITE = (id, value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      id: id,
      value: value,
      action: 'update-indemnite'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(STATUS_INDEMNITE(id, value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
