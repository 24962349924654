import React from 'react'
import styles from './HamburgerContent.module.css'

const HamburgerContent = ({ children }) => {
  return (
    <div className={styles.list}>
      {children}
    </div>
  )
}

export default HamburgerContent
