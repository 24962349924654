import React from 'react'
import ButtonAdd from '../Includes/Button/ButtonAdd'
import {
  useDispatch
} from 'react-redux'
import {
  MODAL_HEURES
} from '../../Redux/Actions/Heures'

const HeuresToolbarContent = () => {
  const dispatch = useDispatch()
  return (
    <>
      <ButtonAdd
        post={true}
        mobile="Début/fin"
        title="Heure avec début/fin"
        onClick={() => dispatch(MODAL_HEURES('', 'create', false))}
      />
      <ButtonAdd
        mobile="Simple"
        title="Heure simple"
        onClick={() => dispatch(MODAL_HEURES('', 'create', true))}
      />
    </>
  )
}

export default HeuresToolbarContent
