import React from 'react'
import Root from '../Includes/Wrapper/Root'
import HeureModal from '../Heures/HeureModal'
import PlanningContent from './PlanningContent'
import AbsenceModal from '../Absences/AbsenceModal'
import CreneauModal from '../Includes/Creneau/CreneauModal'
import {
  useSelector
} from 'react-redux'

const Planning = () => {
  const user = useSelector(state => state.login.user)
  const equipe = useSelector(state => state.equipe.equipe)
  return (
    <Root container={true}>
      {
        (equipe.length > 0)
          ? <>
              <HeureModal/>
              <AbsenceModal/>
            </>
          : null
      }
      {
        (user.profil === 'administrateur')
          ? <CreneauModal/>
          : null
      }
      <PlanningContent/>
    </Root>
  )
}

export default Planning
