import React from 'react'
import ButtonAdmin from './ButtonAdmin'

const ButtonAdminBeta = (props) => {
  return (
    <ButtonAdmin
      icon="Code"
      color="red"
      title="Beta"
      {...props}
    />
  )
}

export default ButtonAdminBeta
