import React from 'react'
import Button from './Button'

const ButtonDownload = (props) => {
  return (
    <Button
      icon="GetApp"
      title="Télécharger"
      {...props}
    />
  )
}

export default ButtonDownload
