import clsx from 'clsx'
import React from 'react'
import styles from './Button.module.css'
import ButtonCircleAdd from '../Button/ButtonCircleAdd'
import {
  useSelector
} from 'react-redux'

const ButtonSliderAdd = (props) => {
  const device = useSelector(state => state.device)
  return (
    <ButtonCircleAdd
      color="blue"
      className={
        clsx({
          [styles.left]: device.desktop,
          [styles.right]: device.mobile
        })
      }
      {...props}
    />
  )
}

export default ButtonSliderAdd
