import clsx from 'clsx'
import React from 'react'
import Planning from '../Hooks/Planning'
import UserColor from '../User/UserColor'
import dayjs from '../../../Libraries/dayjs'
import styles from './LegendeCreneauContent.module.css'
import LegendeCreneauHeure from './LegendeCreneauHeure'
import ButtonIconRemove from '../Button/ButtonIconRemove'
import ButtonIconAbsence from '../Button/ButtonIconAbsence'
import LegendeCreneauAbsence from './LegendeCreneauAbsence'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Warning
} from '@mui/icons-material'
import {
  sort_absent
} from '../../../Functions/Sort'
import {
  start_case,
  upper_case
} from '../../../Functions/Strings'
import {
  get_duree_absence
} from '../../../Functions/Absences'
import {
  MODAL_ABSENCES
} from '../../../Redux/Actions/Absences'
import {
  DELETE_PLANNING_EQUIPE
} from '../../../Redux/Actions/Planning'
import {
  DEL_CURRENT_EQUIPE
} from '../../../Redux/Actions/Semaines'

const LegendeCreneauContent = ({
  data,
  liste
}) => {
  const planning = Planning()
  const dispatch = useDispatch()
  const event = useSelector(state => state.planning.event)
  return (
    <div className="semaines-form-equipe">
      {
        sort_absent(liste).map(item => {
          return (
            <UserColor
              key={item.id}
              color={item.color}
              className={
                clsx({
                  [styles.color]: true,
                  'absent-form': (
                    (item.absent) ||
                    (item.heure)
                  )
                })
              }
            >
              <span
                className={
                  (event.read)
                    ? (data.heure)
                      ? styles.name
                      : styles.long
                    : (item.absent)
                      ? styles.absent
                      : styles.name
                }
              >
                {
                  (data.heure)
                    ? <span className={styles.heure}>
                        <i className="icon heures"/>
                      </span>
                    : (item.absent)
                      ? <Warning className={styles.warning}/>
                      : null
                }
                <span>{start_case(item.prenom)} </span>
                <span>{upper_case(item.nom)}</span>
              </span>
              {
                (!event.read)
                  ? <>
                      {
                        (
                          (!item.absent) &&
                          (planning)
                        )
                          ? <span className={styles.action}>
                              <ButtonIconAbsence
                                small={true}
                                title="Absent"
                                inherit={true}
                                onClick={() => {
                                  dispatch(MODAL_ABSENCES('', 'create', {
                                    salarie: item.id,
                                    jour: [
                                      dayjs(data.startDate).startOf('day'),
                                      dayjs(data.endDate).startOf('day')
                                    ],
                                    longueur: get_duree_absence({
                                      debut: {
                                        heures: dayjs(data.startDate).format('H'),
                                        minutes: dayjs(data.startDate).format('m')
                                      },
                                      fin: {
                                        heures: dayjs(data.endDate).format('H'),
                                        minutes: dayjs(data.endDate).format('m')
                                      }
                                    })
                                  }))
                                }}
                              />
                            </span>
                          : (
                              (item.absent) &&
                              (planning)
                            )
                              ? <LegendeCreneauAbsence item={item}/>
                              : null
                      }
                      <span
                        className={
                          (
                            (item.absent) &&
                            (item.status === 3)
                          )
                            ? styles.close
                            : styles.action
                        }
                      >
                        <ButtonIconRemove
                          small={true}
                          inherit={true}
                          onClick={() => {
                            const value = {
                              user: item.id,
                              horaire: event.id
                            }
                            if (planning) {
                              dispatch(DELETE_PLANNING_EQUIPE(value))
                            } else {
                              dispatch(DEL_CURRENT_EQUIPE(value))
                            }
                          }}
                        />
                      </span>
                    </>
                  : (event.heure)
                    ? (
                        (data.heure) &&
                        (planning)
                      )
                        ? <LegendeCreneauHeure data={data}/>
                        : null
                    : null
              }
            </UserColor>
          )
        })
      }
    </div>
  )
}

export default LegendeCreneauContent
