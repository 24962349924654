import TableCellDie from '../Components/Includes/Table/TableCellDie'
import TableCellBeta from '../Components/Includes/Table/TableCellBeta'
import TableCellPrix from '../Components/Includes/Table/TableCellPrix'
import TableCellType from '../Components/Includes/Table/TableCellType'
import TableCellPaiement from '../Components/Includes/Table/TableCellPaiement'
import TableCellSemaines from '../Components/Includes/Table/TableCellSemaines'
import TableCellAbonnement from '../Components/Includes/Table/TableCellAbonnement'
import TableCellUtilisateurs from '../Components/Includes/Table/TableCellUtilisateurs'
import TableCellDatePlanning from '../Components/Includes/Table/TableCellDatePlanning'
import TableCellDateEngagement from '../Components/Includes/Table/TableCellDateEngagement'
import TableCellDateInscription from '../Components/Includes/Table/TableCellDateInscription'
import TableCellCheckboxCompanies from '../Components/Includes/Table/TableCellCheckboxCompanies'
import {
  nom
} from './Includes/Nom'
import {
  code
} from './Includes/Code'
import {
  mail
} from './Includes/Mail'
import {
  prenom
} from './Includes/Prenom'
import {
  companie
} from './Includes/Companie'
import {
  engagement
} from './Includes/Engagement'

export const properties = {
  type: {
    id: "companies",
    groupe: "admin",
    pagination: "large"
  },
  sort: {
    by: "companie",
    order: "asc"
  },
  columns: [
    {
      id: "checkbox",
      component: TableCellCheckboxCompanies
    },
    {
      ...companie
    },
    {
      id: "abonnement",
      sort: true,
      padding: true,
      label: "Abonnement",
      component: TableCellAbonnement
    },
    {
      id: "prix",
      sort: true,
      padding: true,
      label: "Prix",
      component: TableCellPrix
    },
    {
      ...code
    },
    {
      id: "type",
      sort: true,
      mobile: true,
      padding: true,
      label: "Type",
      component: TableCellType
    },
    {
      id: "users",
      sort: true,
      mobile: true,
      padding: true,
      label: "Utilisateurs",
      component: TableCellUtilisateurs
    },
    {
      id: "semaines",
      sort: true,
      mobile: true,
      padding: true,
      label: "Semaines",
      component: TableCellSemaines
    },
    {
      id: "planning",
      sort: true,
      mobile: true,
      padding: true,
      label: "Planning",
      component: TableCellDatePlanning
    },
    {
      id: "date",
      sort: true,
      mobile: true,
      padding: true,
      label: "Inscription",
      component: TableCellDateInscription
    },
    {
      ...engagement,
      component: TableCellDateEngagement
    },
    {
      ...prenom,
      mobile: true
    },
    {
      ...nom,
      mobile: true
    },
    {
      ...mail
    },
    {
      id: "die",
      sort: true,
      mobile: true,
      padding: true,
      label: "Statut",
      component: TableCellDie
    },
    {
      id: "beta",
      sort: true,
      mobile: true,
      padding: true,
      label: "Beta",
      component: TableCellBeta
    },
    {
      id: "last",
      sort: true,
      mobile: true,
      padding: true,
      label: "Dernier paiement",
      component: TableCellPaiement
    }
  ]
}
