import clsx from 'clsx'
import React from 'react'
import Word from '../Includes/Misc/Word'
import styles from './AboutContent.module.css'
import Brice from '../../Images/Photos/brice.jpg'
import Thibaut from '../../Images/Photos/thibaut.jpg'
import {
  useSelector
} from 'react-redux'
import {
  landing
} from '../../Variables/Landing'

const AboutContent = ({ signature }) => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        clsx({
          [styles.mobile]: device.mobile,
          [styles.paper]: (signature !== undefined)
        })
      }
    >
      <div className={styles.photos}>
        <div>
          <img
            alt="Brice"
            src={Brice}
            className={styles.image}
          />
          <label>{landing.legendes.brice}</label>
        </div>
        <div>
          <img
            alt="Thibaut"
            src={Thibaut}
            className={styles.image}
          />
          <label>{landing.legendes.thibaut}</label>
        </div>
      </div>
      <div className={styles.texte}>
        {
          landing.about.map((item, index) => {
            return (
              <div key={index}>
                <Word color={true}>
                  {item}
                </Word>
              </div>
            )
          })
        }
        {
          (signature)
            ? <div className={styles.signature}>Brice & Thibaut</div>
            : null
        }
      </div>
    </div>
  )
}

export default AboutContent
