import React from 'react'
import Texte from '../Includes/Texte'
import Feature from '../Includes/Feature'
import styles from './Features.module.css'
import Container from '../Includes/Container'
import {
  landing
} from '../../Variables/Landing'

const Features = () => {
  return (
    <Container>
      <div className={styles.container}>
        <Texte
          full={true}
          titre="Économisez #du temps.#"
          description="Centralisez toute la partie planning au même endroit, pour tout le monde."
        />
        <div className={styles.margin}/>
        {
          landing.features.map((item, index) => {
            return (
              <Feature
                key={index}
                item={item}
              />
            )
          })
        }
      </div>
    </Container>
  )
}

export default Features
