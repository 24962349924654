import TableCellMotif from '../../Components/Includes/Table/TableCellMotif'

export const motif = {
  id: "motif",
  sort: true,
  mobile: true,
  padding: true,
  label: "Motif",
  component: TableCellMotif
}
