import React from 'react'
import Empty from '../../Includes/Misc/Empty'
import TableData from '../../Includes/Table/TableData'
import CompaniesMobileTitre from './CompaniesMobileTitre'
import SearchMobile from '../../Includes/Search/SearchMobile'
import CompaniesToolbarMobile from './CompaniesToolbarMobile'
import CompaniesToolbarDesktop from './CompaniesToolbarDesktop'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  All
} from '../../../Functions/Table'
import {
  properties
} from '../../../Settings/Companies'
import {
  variables
} from '../../../Variables/Variables'
import {
  SEARCH_COMPANIE,
  SELECTED_COMPANIES
} from '../../../Redux/Actions/Companies'

const CompaniesContent = ({ data }) => {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const device = useSelector(state => state.device)
  const search = useSelector(state => state.companies.search)
  const loading = useSelector(state => state.companies.loading)
  const selected = useSelector(state => state.companies.selected)
  const companies = useSelector(state => state.companies.companies)
  const Select = (event) => {
    All(dispatch, SELECTED_COMPANIES, data.map(item => item.id), event)
  }
  return (companies.length > 0)
    ? <>
        {
          (device.mobile)
            ? <CompaniesToolbarMobile setPage={setPage}/>
            : null
        }
        <Paper>
          {
            (device.mobile)
              ? <SearchMobile
                  data={data}
                  search={search}
                  onChange={Select}
                  selected={selected}
                  setSearch={(value) => dispatch(SEARCH_COMPANIE(value))}
                />
              : <CompaniesToolbarDesktop data={data}/>
          }
          {
            (data.length > 0)
              ? <TableData
                  data={data}
                  page={page}
                  All={Select}
                  setPage={setPage}
                  count={data.length}
                  selected={selected}
                  border={device.mobile}
                  titre={CompaniesMobileTitre}
                  {...properties}
                />
              : null
          }
        </Paper>
      </>
    : (!loading)
      ? <Empty texte={variables.empty.companies}/>
      : null
}

export default CompaniesContent
