import clsx from 'clsx'
import React from 'react'
import styles from './LoginWarning.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  landing
} from '../../Variables/Landing'
import {
  config
} from '../../Variables/Variables'

const LoginWarning = () => {
  const beta = (config.beta === '1')
  const device = useSelector(state => state.device)
  return (config.id !== 'prod')
    ? <Paper
        className={
          clsx({
            [styles.paper]: true,
            [styles.mobile]: device.mobile
          })
        }
      >
        <div
          className={
            clsx(
              styles.content,
              styles['content-' + config.id]
            )
          }
        >
          <div>Vous êtes sur l'environnement {config.env} de {landing.nom}.</div>
          <div>
            <span>Cet environnement est strictement reservé </span>
            {
              (beta)
                ? <span>aux utilisateurs déclarés.</span>
                : <span>à notre utilisation.</span>
            }
          </div>
          {
            (beta)
              ? <div>En cas de doute, demandez à votre manager.</div>
              : <div>Il ne contient que des données factices.</div>
          }
        </div>
      </Paper>
    : null
}

export default LoginWarning
