import React from 'react'
import ButtonDownload from '../Button/ButtonDownload'
import TableCellContainer from './TableCellContainer'
import {
  useDispatch
} from 'react-redux'
import {
  format_export
} from '../../../Functions/Dates'
import {
  DOWNLOAD_EXPORT
} from '../../../Redux/Actions/Comptabilite'

const TableCellActionsDownload = ({ item }) => {
  const dispatch = useDispatch()
  return (
    <TableCellContainer width={1}>
      <ButtonDownload
        color="green"
        onClick={() => {
          dispatch(DOWNLOAD_EXPORT(
            item.id,
            format_export(item.debut) + '_' + format_export(item.fin)
          ))
        }}
      />
    </TableCellContainer>
  )
}

export default TableCellActionsDownload
