import React from 'react'
import InputTextField from './InputTextField'

const InputMotif = ({
  error,
  ...others
}) => {
  return (
    <InputTextField
      label="Motif"
      error={error}
      helperText={
        (error)
          ? "Ce motif est invalide"
          : null
      }
      {...others}
    />
  )
}

export default InputMotif
