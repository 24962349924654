import React from 'react'
import ButtonSliderDel from '../Button/ButtonSliderDel'

const SliderButtonDel = ({
  Update,
  UpdateError
}) => {
  return (
    <ButtonSliderDel
      onClick={() => {
        Update('total', 1)
        UpdateError('slider', false)
      }}
    />
  )
}

export default SliderButtonDel
