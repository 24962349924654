import React from 'react'
import TableData from '../Includes/Table/TableData'
import styles from './ComptabilitePrimes.module.css'
import {
  properties
} from '../../Settings/Primes'

const ComptabilitePrimes = ({
  Edit,
  Delete,
  primes
}) => {
  return (
    <TableData
      data={primes}
      pagination={(primes.length > 0)}
      functions={{
        edit: Edit,
        delete: Delete
      }}
      {...properties}
    >
      {
        (primes.length === 0)
          ? <caption className={styles.caption}>Vous n'avez affecté aucune prime à vos salariés.</caption>
          : null
      }
    </TableData>
  )
}

export default ComptabilitePrimes
