import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'
import {
  start_case
} from '../../../Functions/Strings'

const SelectProfil = (props) => {
  const profils = useSelector(state => state.compte.profils)
  return (
    <SelectInput
      label="Profil"
      options={profils.map(item => {
        return {
          value: item,
          label: start_case(item)
        }
      })}
      {...props}
    />
  )
}

export default SelectProfil
