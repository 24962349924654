import React from 'react'
import Slide from './Slide'
import {
  landing
} from '../../Variables/Landing'

const Reveal = ({
  children,
  disabled
}) => {
  return (
    (disabled) ||
    (!Array.isArray(children))
  )
    ? children
    : children.map((item, index) => {
        return (
          <Slide
            key={index}
            direction={landing.directions[index % landing.directions.length]}
          >
            {item}
          </Slide>
        )
      })
}

export default Reveal
