import TableCellCode from '../../Components/Includes/Table/TableCellCode'

export const code = {
  id: "code",
  sort: true,
  mobile: true,
  padding: true,
  label: "Code",
  component: TableCellCode
}
