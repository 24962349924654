import React from 'react'
import {
  Toolbar
} from '@devexpress/dx-react-scheduler-material-ui'

const ToolbarComponent = ({ children }) => {
  return (
    <Toolbar.Root className="toolbar">
      {children}
    </Toolbar.Root>
  )
}

export default ToolbarComponent
