import React from 'react'
import Planning from '../Hooks/Planning'
import styles from './Legende.module.css'
import dayjs from '../../../Libraries/dayjs'
import {
  DateRange
} from '@mui/icons-material'
import {
  start_case
} from '../../../Functions/Strings'

const LegendeDate = ({ item }) => {
  const planning = Planning()
  return (
    <div className={styles.row}>
      <DateRange
        fontSize="small"
        className={styles.icone}
      />
      {
        (item.creneaux)
          ? (planning)
            ? dayjs(item.creneaux[0].start).format('DD/MM/YYYY')
            : start_case(dayjs(item.creneaux[0].start).format('dddd'))
          : (item.debut)
            ? dayjs(item.debut).format('DD/MM/YYYY')
            : dayjs(item.startDate).format('DD/MM/YYYY')
      }
    </div>
  )
}

export default LegendeDate
