import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateFull = ({
  item,
  label
}) => {
  return (
    <TableCellDate
      full={true}
      label={label}
      value={item.date}
    />
  )
}

export default TableCellDateFull
