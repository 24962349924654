import React from 'react'
import Button from './Button'

const ButtonIconAbsence = (props) => {
  return (
    <Button
      icon="absences"
      {...props}
    />
  )
}

export default ButtonIconAbsence
