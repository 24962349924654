import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import {
  upper_case
} from '../../../Functions/Strings'

const TableCellNom = ({
  item,
  label,
  device
}) => {
  const value = upper_case(item.nom)
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={150}
        value={value}
      />
}

export default TableCellNom
