import React from 'react'
import HamburgerDrawer from './HamburgerDrawer'
import HamburgerContent from './HamburgerContent'
import HamburgerLogoutButton from './HamburgerLogoutButton'
import {
  useSelector
} from 'react-redux'
import {
  List
} from '@mui/material'

const HamburgerContainer = ({ children }) => {
  const device = useSelector(state => state.device)
  return (
    <HamburgerDrawer>
      <HamburgerContent>
        <List>
          {children}
          {
            (device.mobile)
              ? <HamburgerLogoutButton/>
              : null
          }
        </List>
      </HamburgerContent>
    </HamburgerDrawer>
  )
}

export default HamburgerContainer
