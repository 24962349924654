import React from 'react'
import {
  messages
} from '../../Variables/Messages'

const Empty = () => {
  return (
    <div className="not-found-empty">
      <div>{messages.error.die}</div>
    </div>
  )
}

export default Empty
