import clsx from 'clsx'
import React from 'react'
import styles from './Titre.module.css'
import Word from '../../Components/Includes/Misc/Word'

const Titre = ({
  full,
  children,
  className
}) => {
  return (
    <h2
      className={
        clsx({
          [styles.full]: full,
          [styles.titre]: true,
          [className]: (className !== undefined)
        })
      }
    >
      <Word color={true}>
        {children}
      </Word>
    </h2>
  )
}

export default Titre
