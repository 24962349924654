import React from 'react'
import Button from './Button'

const ButtonCircleDel = (props) => {
  return (
    <Button
      icon="RemoveCircleOutline"
      {...props}
    />
  )
}

export default ButtonCircleDel
