import React from 'react'
import Scheduler from '../Includes/Planning/Scheduler'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  DayCell,
  ScaleCell,
  EmptyCell,
  AppointmentComponentRead,
  AppointmentComponentAdmin
} from '../Includes/Event/Event'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  get_ouverture
} from '../../Functions/Ouverture'
import {
  SET_DATE_SEMAINES,
  EDIT_CURRENT_HORAIRE
} from '../../Redux/Actions/Semaines'

const SemainesScheduler = React.memo(() => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const data = useSelector(state => state.semaines.data)
  const date = useSelector(state => state.semaines.date)
  const view = useSelector(state => state.planning.view)
  const working = useSelector(state => state.semaines.working)
  const ouverture = useSelector(state => state.ouverture.ouverture)
  const Date = (date) => {
    dispatch(SET_DATE_SEMAINES(date))
  }
  const Change = ({ changed }) => {
    if (changed) {
      dispatch(EDIT_CURRENT_HORAIRE(changed))
    }
  }
  return (
    <Scheduler
      data={data}
      date={date}
      view={view}
      horaires={true}
      draggable={(
        (device.desktop) &&
        (
          (working.edit) ||
          (working.new)
        )
      )}
      functions={{
        Date: Date,
        Change: Change
      }}
      components={{
        Appointment: (working.read)
          ? AppointmentComponentRead
          : AppointmentComponentAdmin
      }}
      cells={{
        dayScaleCellComponent: ScaleCell,
        timeTableCellComponent: (props) =>
          <DayCell
            open={isDifferent([ouverture], [get_ouverture()])}
            {...props}
          />,
        dayScaleEmptyCellComponent: () =>
          <EmptyCell
            enable={(
              (working.edit) ||
              (working.new)
            )}
          />
      }}
    />
  )
})

export default SemainesScheduler
