import React from 'react'
import TableCellTitreMobile from './TableCellTitreMobile'

const TableCellTitreCode = ({ item }) => {
  return (
    <TableCellTitreMobile
      value={item.nom}
      red={(item.companie !== '')}
    />
  )
}

export default TableCellTitreCode
