import React from 'react'

const UserReset = ({
  mot,
  name
}) => {
  return (
    <span>
      <span className="block">Voulez-vous vraiment réinitialiser le mot de passe de <span className="reset-name">{name}</span> ?</span>
      <span className="block">Son nouveau mot de passe {mot} sera envoyé par mail.</span>
    </span>
  )
}

export default UserReset
