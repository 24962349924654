import clsx from 'clsx'
import React from 'react'
import styles from './Signin.module.css'
import Abonnements from '../Abonnements/Abonnements'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Button
} from '@mui/material'
import {
  SIGNIN_ABONNEMENT
} from '../../Redux/Actions/Signin'

const SigninAbonnement = ({ setStep }) => {
  const dispatch = useDispatch()
  const [error, setError] = React.useState(false)
  const device = useSelector(state => state.device)
  const selected = useSelector(state => state.signin.abonnement)
  const abonnements = useSelector(state => state.landing.abonnements)
  return (
    <div
      className={
        clsx({
          [styles.container]: true,
          [styles.mobile]: device.mobile
        })
      }
    >
      <Abonnements
        setError={setError}
        mobile={device.mobile}
        abonnements={abonnements}
        className={styles.abonnements}
        selected={selected.unique_id_abonnement}
        Select={(item) => {
          setError(false)
          dispatch(SIGNIN_ABONNEMENT(item))
        }}
      />
      {
        (error)
          ? <div className="error-abonnement">Merci de choisir un abonnement</div>
          : null
      }
      <div className="signin-button">
        <Button
          color="secondary"
          variant="contained"
          className="button-back"
          onClick={() => setStep(1)}
        >
          Retour
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (selected.unique_id_abonnement !== undefined) {
              setStep(3)
            } else {
              setError(true)
            }
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
  )
}

export default SigninAbonnement
