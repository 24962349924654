import React from 'react'
import EquipeToolbarContent from './EquipeToolbarContent'

const EquipeToolbarMobile = () => {
  return (
    <div className="toolbar-actions-mobile">
      <EquipeToolbarContent/>
    </div>
  )
}

export default EquipeToolbarMobile
