import clsx from 'clsx'
import React from 'react'
import styles from './Question.module.css'
import {
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material'
import {
  ExpandMore
} from '@mui/icons-material'

const Question = ({ item }) => {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <Paper
      elevation={0}
      className={styles.paper}
      onClick={() => setExpanded(!expanded)}
    >
      <Accordion
        expanded={expanded}
        className={styles.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          className={
            clsx({
              [styles.summary]: true,
              [styles.expanded]: expanded
            })
          }
        >
          {item.question}
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          {item.answer}
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

export default Question
