import {
  USER_MAIL,
  USER_LOGOUT
} from './Login'
import {
  SET_MAIL
} from './Equipe'
import {
  SET_STRIPE,
  SET_ABONNEMENT
} from './Stripe'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SET_SURVOL = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_SURVOL',
      value: value
    })
  }
}

export const INIT_COMPTE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_COMPTE',
      value: value
    })
  }
}

export const SET_MAXIMUM = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_MAXIMUM',
      value: value
    })
  }
}

export const UPDATE_MAIL = (value, callback, success) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch, state) => {
    const data = JSON.stringify({
      mail: value,
      action: 'update-mail'
    })
    api(data)
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          const user = state().login.user.unique_id_user
          dispatch(SET_MAIL(value, user))
          dispatch(USER_MAIL(value))
          success()
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}

export const CHANGE_ABONNEMENT = (abonnement, callback) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      abonnement: abonnement,
      action: 'update-abonnement'
    })
    api(data, undefined, true)
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(SET_ABONNEMENT(data.data.abonnement))
          dispatch(SET_MAXIMUM(data.data.maximum))
          dispatch(SET_STRIPE(data.data.stripe))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_PASSWORD = (value, callback, success) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      password: value,
      action: 'update-password'
    })
    api(data)
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(USER_LOGOUT(false))
          success()
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}

export const DELETE_COMPANIE = (texte, callback, success) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      texte: texte,
      action: 'delete-companie'
    })
    api(data)
      .then(data => {
        callback()
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(USER_LOGOUT(false))
          success()
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        callback()
        update_toast(toast, undefined, 'error')
      })
  }
}
