import clsx from 'clsx'
import React from 'react'
import Card from '../Includes/Card'
import Image from '../Includes/Image'
import Texte from '../Includes/Texte'
import styles from './Explain.module.css'
import Container from '../Includes/Container'
import Source from '../../Images/Landing/creneau.png'
import {
  useSelector
} from 'react-redux'
import {
  landing
} from '../../Variables/Landing'

const Explain = () => {
  const device = useSelector(state => state.device)
  return (
    <Container reverse={device.mobile}>
      <Image
        width={40}
        mobile={80}
        src={Source}
        shadow={true}
        alt="Créneau"
      />
      <Texte
        right={true}
        titre="Le meilleur #pour vous.#"
        description="Il est important pour nous de proposer des solutions adaptées à vos besoins, et à vos budgets."
      >
        <div
          className={
            clsx({
              [styles.container]: true,
              [styles.mobile]: device.mobile
            })
          }
        >
          {
            landing.explain.map((item, index) => {
              return (
                <Card
                  key={index}
                  item={item}
                />
              )
            })
          }
        </div>
      </Texte>
    </Container>
  )
}

export default Explain
