import React from 'react'
import {
  loadStripe
} from '@stripe/stripe-js'
import {
  Elements
} from '@stripe/react-stripe-js'
import {
  variables
} from '../../../Variables/Variables'

const stripe = loadStripe(variables.stripe.public)

const Stripe = ({ children }) => {
  return (
    <Elements stripe={stripe}>
      {children}
    </Elements>
  )
}

export default Stripe
