import React from 'react'
import CodesContentTable from './CodesContentTable'
import CodesToolbarMobile from './CodesToolbarMobile'
import CodesToolbarDesktop from './CodesToolbarDesktop'
import SearchMobile from '../../Includes/Search/SearchMobile'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  All
} from '../../../Functions/Table'
import {
  SEARCH_CODE,
  SELECTED_CODES
} from '../../../Redux/Actions/Codes'

const CodesContent = ({ data }) => {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const device = useSelector(state => state.device)
  const codes = useSelector(state => state.codes.codes)
  const search = useSelector(state => state.codes.search)
  const selected = useSelector(state => state.codes.selected)
  const Select = (event) => {
    All(dispatch, SELECTED_CODES, data.filter(item => item.companie === '').map(item => item.id), event)
  }
  return (device.mobile)
    ? <>
        <CodesToolbarMobile setPage={setPage}/>
        {
          (codes.length > 0)
            ? <Paper>
                <SearchMobile
                  search={search}
                  onChange={Select}
                  selected={selected}
                  data={data.filter(item => item.companie === '')}
                  setSearch={(value) => dispatch(SEARCH_CODE(value))}
                />
                <CodesContentTable
                  data={data}
                  page={page}
                  Select={Select}
                  setPage={setPage}
                />
              </Paper>
            : null
        }
      </>
    : <Paper>
        <CodesToolbarDesktop data={data}/>
        <CodesContentTable
          data={data}
          page={page}
          Select={Select}
          setPage={setPage}
        />
      </Paper>
}

export default CodesContent
