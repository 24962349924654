import React from 'react'
import LogoModal from './LogoModal'
import Secure from '../../Images/Stripe/stripe.png'
import {
  config
} from '../../Variables/Variables'

const Logo = () => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      {
        (config.test)
          ? <LogoModal
              open={open}
              setOpen={setOpen}
            />
          : null
      }
      <div className="stripe-payment">
        <img
          width={100}
          height={35}
          src={Secure}
          alt="Stripe"
          onClick={() => setOpen(config.test)}
          className={
            (config.test)
              ? 'img'
              : undefined
          }
        />
      </div>
    </>
  )
}

export default Logo
