import React from 'react'
import TableCellDesktop from './TableCellDesktop'
import {
  convert_heure
} from '../../../Functions/Heures'

const TableCellLegendeHeures = ({ item }) => {
  const value = item.heures
  return (
    <TableCellDesktop
      width={35}
      right={true}
      value={convert_heure(value * 60, false)}
      style={{
        paddingLeft: 0
      }}
    />
  )
}

export default TableCellLegendeHeures
