import React from 'react'
import Bulle from '../Wrapper/Bulle'
import styles from './Button.module.css'
import {
  useSelector
} from 'react-redux'
import {
  Button,
  IconButton
} from '@mui/material'
import {
  AddBox,
  PostAdd
} from '@mui/icons-material'

const ButtonAdd = ({
  post,
  title,
  mobile,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (device.mobile)
    ? <Button
        color="primary"
        variant="contained"
        className={styles.mobile}
        startIcon={
          (post)
            ? <PostAdd/>
            : <AddBox/>
        }
        {...others}
      >
        {mobile}
      </Button>
    : <Bulle
        arrow={true}
        title={title}
        placement="top"
      >
        <IconButton
          color="primary"
          className={
            (post)
              ? undefined
              : styles.desktop
          }
          {...others}
        >
          {
            (post)
              ? <PostAdd/>
              : <AddBox/>
          }
        </IconButton>
      </Bulle>
}

export default ButtonAdd
