import Absences from './Absences'
import {
  useSelector
} from 'react-redux'
import {
  useLocation
} from 'react-router-dom'

const Planning = () => {
  const absences = Absences()
  const location = useLocation()
  const user = useSelector(state => state.login.user)
  return (
    (
      (user.profil === 'office') &&
      (
        (location.pathname === '/') ||
        (location.pathname === '/accueil')
      )
    ) ||
    (
      (user.profil !== 'office') &&
      (
        (absences) ||
        (location.pathname === '/planning')
      )
    )
  )
}

export default Planning
