import React from 'react'
import InputPayment from './InputPayment'
import {
  CardExpiryElement
} from '@stripe/react-stripe-js'

const InputExpire = ({
  error,
  message,
  ...others
}) => {
  return (
    <InputPayment
      name="exp"
      error={error}
      label="Date d'expiration"
      component={CardExpiryElement}
      helperText={
        (error)
          ? message
          : null
      }
      {...others}
    />
  )
}

export default InputExpire
