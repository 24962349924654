import React from 'react'
import ButtonFilter from '../Button/ButtonFilter'
import {
  useDispatch
} from 'react-redux'
import {
  SET_FILTERS_TARGET_RESET
} from '../../../Redux/Actions/Filters'
import {
  TARGET
} from '../../../Redux/Actions/Notifications'

const FilterReset = ({
  target,
  callback,
  notification
}) => {
  const dispatch = useDispatch()
  return (
    <ButtonFilter
      off={true}
      onClick={() => {
        dispatch(
          (notification)
            ? TARGET('')
            : SET_FILTERS_TARGET_RESET(target)
        )
        if (callback) {
          callback()
        }
      }}
    />
  )
}

export default FilterReset
