import React from 'react'
import styles from './Empty.module.css'

const Empty = ({ texte }) => {
  return (
    <div className={styles.content}>{texte}</div>
  )
}

export default Empty
