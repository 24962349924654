import React from 'react'
import InputPayment from './InputPayment'
import {
  CardNumberElement
} from '@stripe/react-stripe-js'

const InputCard = ({
  error,
  message,
  ...others
}) => {
  return (
    <InputPayment
      name="card"
      error={error}
      label="Carte de crédit"
      component={CardNumberElement}
      helperText={
        (error)
          ? message
          : null
      }
      {...others}
    />
  )
}

export default InputCard
