import TableCellSolde from '../Components/Includes/Table/TableCellSolde'
import TableCellRestant from '../Components/Includes/Table/TableCellRestant'

export const properties = {
  type: {
    id: "conges",
    groupe: "divers"
  },
  sort: {
    by: "solde",
    order: "desc"
  },
  columns: [
    {
      id: "solde",
      sort: false,
      center: true,
      mobile: true,
      padding: true,
      label: "Solde",
      component: TableCellSolde
    },
    {
      id: "restant",
      sort: false,
      center: true,
      mobile: true,
      padding: true,
      label: "Restant(s)",
      component: TableCellRestant
    }
  ]
}
