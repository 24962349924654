import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellFacture = ({
  item,
  label,
  device
}) => {
  const value = item.id
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={200}
        value={value}
      />
}

export default TableCellFacture
