export const colonnes = [
  {
    user: "Salarié",
    fonction: "Fonction",
    heures: "Heures",
    supp: "Supp",
    conges: "Absences",
    ik: "IK"
  },
  {
    week: "Semaine",
    heures: "Heures",
    supp: "Supp"
  },
  {
    debut: "Début",
    fin: "Fin",
    duree: "Durée",
    nombre: "Nombre",
    motif: "Motif"
  },
  {
    date: "Date",
    nombre: "Nombre"
  },
  {
    user: "Salarié"
  },
  {
    type: "Type",
    debut: "Début",
    fin: "Fin",
    duree: "Durée",
    nombre: "Nombre",
    motif: "Motif"
  },
  {
    user: "Salarié",
    montant: "Montant",
    motif: "Motif"
  }
]
