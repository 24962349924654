import React from 'react'
import Button from './Button'

const ButtonRename = (props) => {
  return (
    <Button
      title="Renommer"
      icon="TextFields"
      {...props}
    />
  )
}

export default ButtonRename
