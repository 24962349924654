const initial = {
  fonctions: [],
  modal: {
    id: '',
    name: '',
    open: ''
  }
}

function fonctions(state = initial, action) {
  switch (action.type) {
    case 'ADD_FONCTION':
      return {
        ...state,
        fonctions: [
          ...state.fonctions,
          action.value
        ]
      }
    case 'DEL_FONCTION':
      return {
        ...state,
        fonctions: state.fonctions.filter(item => item.id !== action.value)
      }
    case 'EDIT_FONCTION':
      return {
        ...state,
        fonctions: state.fonctions.map(item => {
          return (item.id === action.value.id)
            ? action.value
            : item
        })
      }
    case 'INIT_FONCTIONS':
      return {
        ...state,
        fonctions: action.value
      }
    case 'MODAL_FONCTIONS':
      return {
        ...state,
        modal: {
          id: action.id,
          name: action.name,
          open: action.open
        }
      }
    default:
      return state
  }
}

export default fonctions
