import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  print_error
} from '../../Functions/Toast'

export const TARGET = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'TARGET',
      value: value
    })
  }
}

export const INIT_NOTIFICATIONS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_NOTIFICATIONS',
      value: value
    })
  }
}

export const OPEN_NOTIFICATIONS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'OPEN_NOTIFICATIONS',
      value: value
    })
  }
}

export const REMOVE_NOTIFICATIONS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_NOTIFICATIONS',
      value: value
    })
  }
}

export const SELECTED_NOTIFICATIONS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SELECTED_NOTIFICATIONS',
      value: value
    })
  }
}

export const REMOVE_NOTIFICATIONS_USER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_NOTIFICATIONS_USER',
      value: value
    })
  }
}

export const READ_NOTIFICATIONS = (value, user, read) => {
  return (dispatch) => {
    dispatch({
      type: 'READ_NOTIFICATIONS',
      value: value,
      user: user,
      read: read
    })
  }
}

export const GET_NOTIFICATIONS = () => {
  return (dispatch) => {
    const data = JSON.stringify({
      action: 'notifications'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(INIT_NOTIFICATIONS(data.response))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const DELETE_NOTIFICATIONS = (value) => {
  return (dispatch) => {
    dispatch(REMOVE_NOTIFICATIONS(value))
    const data = JSON.stringify({
      value: value,
      action: 'delete-notifications'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const NOTIFICATIONS = (value, user, read) => {
  return (dispatch) => {
    dispatch(READ_NOTIFICATIONS(value, user, read))
    const data = JSON.stringify({
      read: read,
      value: value,
      action: 'read-notifications'
    })
    api(data)
      .then(data => {
        if (data.status !== 'OK') {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}
