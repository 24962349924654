import clsx from 'clsx'
import React from 'react'
import Icone from './Icone'
import styles from './Feature.module.css'
import {
  useSelector
} from 'react-redux'

const Feature = ({ item }) => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        clsx({
          [styles.container]: true,
          [styles.mobile]: device.mobile
        })
      }
    >
      <div className={styles.card}>
        <Icone icon={item.icon}/>
        <span className={styles.text}>
          <span className={styles.title}>
            {item.title}
          </span>
          <p className={styles.description}>
            {item.description}
          </p>
        </span>
      </div>
    </div>
  )
}

export default Feature
