import clsx from 'clsx'
import React from 'react'
import styles from './Button.module.css'
import * as IconComponent from '@mui/icons-material'

const ButtonIcon = ({
  icon,
  small,
  ...others
}) => {
  const Icon = IconComponent[icon]
  return (icon !== icon.toLowerCase())
    ? <Icon {...others}/>
    : <i
        className={
          clsx({
            'icon': true,
            [icon]: true,
            [styles.small]: small
          })
        }
      />
}

export default ButtonIcon
