import clsx from 'clsx'
import React from 'react'
import UserColor from '../Includes/User/UserColor'
import styles from './PlanningUserMobile.module.css'
import ButtonCircleAdd from '../Includes/Button/ButtonCircleAdd'
import ButtonCircleDel from '../Includes/Button/ButtonCircleDel'
import {
  useSelector
} from 'react-redux'
import {
  Warning
} from '@mui/icons-material'
import {
  start_case,
  upper_case
} from '../../Functions/Strings'

const PlanningUserMobile = ({
  data,
  item,
  inner,
  state,
  Update
}) => {
  const event = useSelector(state => state.planning.event)
  return (
    <UserColor
      key={item.id}
      color={
        (inner)
          ? item.color
          : {
              r: 242,
              g: 242,
              b: 242
            }
      }
      className={
        clsx({
          [styles.color]: true,
          'inner-content-users': inner,
          'outer-content-users': !inner,
          'absent-form': (
            (item.absent) ||
            (item.heure)
          )
        })
      }
    >
      <span
        className={
          (event.read)
            ? (data.heure)
              ? styles.name
              : styles.long
            : styles.name
        }
      >
        {
          (data.heure)
            ? <span className={styles.heure}>
                <i className="icon heures"/>
              </span>
            : (item.absent)
              ? <Warning
                  className={
                    clsx(
                      styles.warning,
                      'warning-absent'
                    )
                  }
                />
              : null
        }
        <span>{start_case(item.prenom)} </span>
        <span>{upper_case(item.nom)}</span>
      </span>
      {
        (
          (!data.heure) &&
          (!event.read)
        )
          ? (inner)
            ? <ButtonCircleDel
                inherit={true}
                onClick={() => {
                  Update('users', state.users.filter(object => object.id !== item.id))
                  Update('excludes', [
                    ...state.excludes,
                    item
                  ])
                }}
              />
            : <ButtonCircleAdd
                onClick={() => {
                  Update('excludes', state.excludes.filter(object => object.id !== item.id))
                  Update('users', [
                    ...state.users,
                    item
                  ])
                }}
              />
          : null
      }
    </UserColor>
  )
}

export default PlanningUserMobile
