import React from 'react'
import ButtonEdit from '../Button/ButtonEdit'
import ButtonDelete from '../Button/ButtonDelete'
import TableCellContainer from './TableCellContainer'

const TableCellActionsPrimes = ({
  item,
  functions
}) => {
  return (
    <TableCellContainer width={2}>
      <ButtonEdit
        color="green"
        onClick={() => functions.edit(item.id)}
      />
      <ButtonDelete
        color="red"
        onClick={() => functions.delete(item.id)}
      />
    </TableCellContainer>
  )
}

export default TableCellActionsPrimes
