import React from 'react'
import TableCellDesktop from './TableCellDesktop'

const TableCellContainer = ({
  width,
  children
}) => {
  return (
    <TableCellDesktop
      center={true}
      width={(width * 48)}
    >
      {children}
    </TableCellDesktop>
  )
}

export default TableCellContainer
