import {
  USER_LOGOUT
} from './Login'
import {
  api
} from '../../API/api'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const ADD_FONCTION = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_FONCTION',
      value: value
    })
  }
}

export const DEL_FONCTION = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DEL_FONCTION',
      value: value
    })
  }
}

export const EDIT_FONCTION = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EDIT_FONCTION',
      value: value
    })
  }
}

export const INIT_FONCTIONS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_FONCTIONS',
      value: value
    })
  }
}

export const MODAL_FONCTIONS = (id, open, name) => {
  return (dispatch) => {
    dispatch({
      type: 'MODAL_FONCTIONS',
      name: name,
      open: open,
      id: id
    })
  }
}

export const REMOVE_FONCTION = (id) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      id: id,
      action: 'delete-fonction'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          update_toast(toast, data.response, 'success')
          dispatch(DEL_FONCTION(id))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const FONCTION = (value, action) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: action
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          if (action === 'create-fonction') {
            dispatch(ADD_FONCTION(value))
          } else {
            dispatch(EDIT_FONCTION(value))
          }
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}
