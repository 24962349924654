import React from 'react'
import TableCellRed from './TableCellRed'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'

const TableCellConges = ({
  item,
  label
}) => {
  const device = useSelector(state => state.device)
  const value = parseFloat(item)
  const conges = (value < 0)
    ? <TableCellRed value={value}/>
    : value
  const object = {
    value: conges,
    empty: (conges === 0)
  }
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        {...object}
      />
    : <TableCellDesktop
        max={75}
        {...object}
      />
}

export default TableCellConges
