import clsx from 'clsx'
import React from 'react'
import styles from './TablePagination.module.css'
import TablePaginationActions from './TablePaginationActions'
import {
  useSelector
} from 'react-redux'
import {
  TablePagination as Pagination
} from '@mui/material'

const TablePagination = ({
  onPageChange,
  ...others
}) => {
  const device = useSelector(state => state.device)
  const Change = (event, page) => {
    onPageChange(page)
  }
  return (
    <Pagination
      colSpan={3}
      component="div"
      onPageChange={Change}
      ActionsComponent={TablePaginationActions}
      className={
        clsx({
          [styles.pagination]: device.mobile
        })
      }
      {...others}
    />
  )
}

export default TablePagination
