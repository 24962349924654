import clsx from 'clsx'
import Card from '../Stripe/Card'
import SigninForm from './SigninForm'
import SigninSuccess from './SigninSuccess'
import Root from '../Includes/Wrapper/Root'
import SigninProgress from './SigninProgress'
import Stripe from '../Includes/Wrapper/Stripe'
import SigninAbonnement from './SigninAbonnement'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  SIGNIN_ABONNEMENT
} from '../../Redux/Actions/Signin'
import {
  GET_LANDING
} from '../../Redux/Actions/Landing'

const Signin = () => {
  const dispatch = useDispatch()
  const [step, setStep] = React.useState(1)
  const device = useSelector(state => state.device)
  useEffect(() => {
    dispatch(GET_LANDING())
    return () => {
      dispatch(SIGNIN_ABONNEMENT(''))
    }
    // eslint-disable-next-line
  }, [])
  return (
    <Root
      className={
        clsx({
          'signin-content': true,
          'signin-content-mobile': device.mobile
        })
      }
    >
      <Paper className="signin-paper">
        <SigninProgress step={step}/>
        {
          (step === 1)
            ? <SigninForm setStep={setStep}/>
            : (step === 2)
              ? <SigninAbonnement setStep={setStep}/>
              : (step === 3)
                ? <Stripe>
                    <Card setStep={setStep}/>
                  </Stripe>
                : <SigninSuccess/>
        }
      </Paper>
    </Root>
  )
}

export default Signin
