import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellEssai = ({
  item,
  label,
  device
}) => {
  const value = (item.essai === 1)
    ? 'Avec'
    : 'Sans'
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={75}
        value={value}
      />
}

export default TableCellEssai
