import React from 'react'
import SelectInput from '../Select/SelectInput'
import {
  useSelector
} from 'react-redux'
import {
  sort_name
} from '../../../Functions/Sort'
import {
  get_user_long
} from '../../../Functions/Strings'

const FilterSalarie = (props) => {
  const equipe = useSelector(state => state.equipe.equipe)
  return (
    <SelectInput
      filter={true}
      label="Salarié"
      options={sort_name(equipe).map(item => {
        return {
          value: item.id,
          label: get_user_long(item)
        }
      })}
      {...props}
    />
  )
}

export default FilterSalarie
