import clsx from 'clsx'
import React from 'react'
import styles from './Word.module.css'
import reactStringReplace from 'react-string-replace'
import {
  regex
} from '../../../Variables/Regex'

const Word = ({
  bold,
  color,
  children
}) => {
  return (
    <>
      {
        reactStringReplace(children, regex.span, (item, index) => {
          return (
            <span
              key={index}
              className={
                clsx({
                  [styles.bold]: bold,
                  [styles.color]: color
                })
              }
            >
              {item}
            </span>
          )
        })
      }
    </>
  )
}

export default Word
