import clsx from 'clsx'
import React from 'react'
import styles from './Dialog.module.css'
import {
  DialogTitle
} from '@mui/material'

const Title = ({
  red,
  children,
  ...others
}) => {
  return (
    <DialogTitle
      className={
        clsx({
          [styles.red]: red,
          [styles.title]: true,
          [styles.center]: true
        })
      }
      {...others}
    >
      {children}
    </DialogTitle>
  )
}

export default Title
