import clsx from 'clsx'
import React from 'react'
import styles from './Abonnements.module.css'
import Abonnement from '../Includes/Abonnement/Abonnement'
import {
  useSelector
} from 'react-redux'

const Abonnements = ({
  mobile,
  current,
  favorite,
  selected,
  className,
  abonnements,
  ...others
}) => {
  const users = useSelector(state => state.equipe.equipe.length)
  const semaines = useSelector(state => state.semaines.semaines.length)
  return (
    <div
      className={
        clsx({
          [styles.mobile]: mobile,
          [styles.container]: true,
          [styles.abonnements]: true,
          [className]: (className !== undefined)
        })
      }
    >
      {
        abonnements.filter(item => item.unique_id_abonnement !== current).map((item, index) => {
          const maximum = {
            users: (
              (item.users > 0) &&
              (item.users < users)
            ),
            semaines: (
              (item.semaines > 0) &&
              (item.semaines < semaines)
            )
          }
          const error = (
            (current !== undefined) &&
            (maximum.users || maximum.semaines)
          )
          return (
            <Abonnement
              key={index}
              error={error}
              mobile={mobile}
              current={current}
              abonnement={item}
              favorite={(favorite === item.unique_id_abonnement)}
              selected={(selected === item.unique_id_abonnement)}
              content={
                (error)
                  ? <div className={styles.content}>
                      <div>
                        <b>Vous ne pouvez pas souscrire à cet abonnement.</b>
                      </div>
                      {
                        (maximum.users)
                          ? <div>Il ne comprend que {item.users} utilisateurs, et vous en avez {users}.</div>
                          : null
                      }
                      {
                        (maximum.semaines)
                          ? <div>Il ne comprend que {item.semaines} semaines types, et vous en avez {semaines}.</div>
                          : null
                      }
                    </div>
                  : null
              }
              {...others}
            />
          )
        })
      }
    </div>
  )
}

export default Abonnements
