import clsx from 'clsx'
import React from 'react'
import styles from './HamburgerDrawer.module.css'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Drawer
} from '@mui/material'
import {
  MENU
} from '../../Redux/Actions/Menu'

const HamburgerDrawer = ({ children }) => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const menu = useSelector(state => state.menu.menu)
  const survol = useSelector(state => state.compte.survol)
  const Open = () => {
    dispatch(MENU(true))
  }
  const Close = () => {
    dispatch(MENU(false))
  }
  return (
    <Drawer
      open={menu}
      anchor="left"
      onClose={Close}
      variant="permanent"
      classes={{
        paper: clsx({
          [styles.open]: menu,
          [styles.close]: !menu,
          [styles.mobile]: device.mobile
        })
      }}
      onMouseEnter={() => {
        if (
          (survol) &&
          (device.desktop)
        ) {
          Open()
        }
      }}
      onMouseLeave={() => {
        if (
          (survol) &&
          (device.desktop)
        ) {
          Close()
        }
      }}
      className={
        clsx({
          'app-bar': true,
          [styles.open]: menu,
          [styles.close]: !menu,
          [styles.drawer]: true,
          [styles.mobile]: device.mobile
        })
      }
    >
      {children}
    </Drawer>
  )
}

export default HamburgerDrawer
