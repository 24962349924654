import {
  useLocation
} from 'react-router-dom'

const Absences = () => {
  const location = useLocation()
  return (location.pathname === '/absences-planning')
}

export default Absences
