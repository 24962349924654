import TableCellEnable from '../Components/Includes/Table/TableCellEnable'
import TableCellLogged from '../Components/Includes/Table/TableCellLogged'
import TableCellDateLogin from '../Components/Includes/Table/TableCellDateLogin'
import TableCellCheckboxUsers from '../Components/Includes/Table/TableCellCheckboxUsers'
import {
  nom
} from './Includes/Nom'
import {
  mail
} from './Includes/Mail'
import {
  prenom
} from './Includes/Prenom'
import {
  profil
} from './Includes/Profil'
import {
  companie
} from './Includes/Companie'
import {
  fonction
} from './Includes/Fonction'

export const properties = {
  type: {
    id: "users",
    groupe: "admin",
    pagination: "large"
  },
  sort: {
    by: "nom",
    order: "asc"
  },
  columns: [
    {
      id: "checkbox",
      component: TableCellCheckboxUsers
    },
    {
      ...prenom
    },
    {
      ...nom
    },
    {
      ...companie,
      mobile: true
    },
    {
      ...fonction
    },
    {
      ...profil
    },
    {
      ...mail
    },
    {
      id: "enable",
      sort: true,
      mobile: true,
      padding: true,
      label: "Statut",
      component: TableCellEnable
    },
    {
      id: "logged",
      sort: true,
      mobile: true,
      padding: true,
      label: "Connecté",
      component: TableCellLogged
    },
    {
      id: "login",
      sort: true,
      mobile: true,
      padding: true,
      label: "Dernière connexion",
      component: TableCellDateLogin
    }
  ]
}
