import clsx from 'clsx'
import Partners from './Partners'
import Inscription from './Inscription'
import Container from '../Includes/Container'
import styles from './Presentation.module.css'
import React, {
  useEffect
} from 'react'
import {
  useSelector
} from 'react-redux'
import TextTransition, {
  presets
} from 'react-text-transition'
import {
  landing
} from '../../Variables/Landing'

const Presentation = () => {
  const [index, setIndex] = React.useState(0)
  const device = useSelector(state => state.device)
  const types = useSelector(state => state.landing.types)
  useEffect(() => {
    const interval = setInterval(() => setIndex(index => index + 1), landing.speed)
    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <Container>
      <div
        className={
          clsx({
            [styles.form]: true,
            [styles.colonne]: true,
            [styles.mobile]: device.mobile,
            [styles.desktop]: device.desktop
          })
        }
      >
        <h1 className={styles.titre}>
          <span>Gestion de planning pour </span>
          <TextTransition
            inline={true}
            direction="down"
            noOverflow={true}
            className={styles.target}
            springConfig={presets.default}
            text={"votre " + Object.values(types)[index % Object.values(types).length] + "."}
          />
        </h1>
        <p className={styles.paragraphe}>{landing.nom} vous permet de gérer facilement et rapidement vos plannings, congés, bulletins de paie, et tellement plus encore...</p>
        <Inscription/>
      </div>
      <Partners/>
    </Container>
  )
}

export default Presentation
