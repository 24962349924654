export const SORT_SALARIES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SORT_SALARIES',
      value: value
    })
  }
}

export const SORT_FONCTIONS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SORT_FONCTIONS',
      value: value
    })
  }
}
