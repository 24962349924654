import clsx from 'clsx'
import React from 'react'
import styles from './TableMobileRow.module.css'
import colors from './TableCellColors.module.css'
import {
  TableRow
} from '@mui/material'

const TableMobileRowTitre = ({
  item,
  type,
  children
}) => {
  return (
    <TableRow
      className={
        clsx({
          [styles.titre]: true,
          [colors.ko]: ((type.groupe === 'demandes') && (item.status === 0)),
          [colors.ok]: ((type.groupe === 'demandes') && (item.status === 1))
        })
      }
    >
      {children}
    </TableRow>
  )
}

export default TableMobileRowTitre
