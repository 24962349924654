import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellDie = ({
  item,
  label,
  device
}) => {
  const value = (item.die > 0)
    ? <span className="die-companie">Désactivée</span>
    : 'Activée'
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        value={value}
      />
    : <TableCellDesktop
        max={75}
        value={value}
      />
}

export default TableCellDie
