import dayjs from '../../Libraries/dayjs'
import HeureModalContent from './HeureModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  sort_name
} from '../../Functions/Sort'
import {
  CheckLong
} from '../../Functions/Check'
import {
  format_nombre
} from '../../Functions/Heures'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  get_id,
} from '../../Functions/Functions'
import {
  initial
} from '../../Variables/Variables'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  EDIT_HEURE,
  CREATE_HEURE,
  MODAL_HEURES
} from '../../Redux/Actions/Heures'

const HeureModal = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const modal = useSelector(state => state.heures.modal)
  const equipe = useSelector(state => state.equipe.equipe)
  const heures = useSelector(state => state.heures.heures)
  const filters = useSelector(state => state.filters.filters)
  const params = useSelector(state => state.settings.settings)
  const [error, setError] = React.useState({
    motif: false,
    nombre: false,
    picker: false,
    slider: false
  })
  const [state, setState] = React.useState({
    sens: 0,
    total: 1,
    picker: false,
    values: undefined,
    motif: initial.heure.motif,
    texte: initial.heure.texte,
    nombre: initial.heure.nombre,
    recuperation: initial.heure.recuperation,
    heures: format_nombre(initial.heure.nombre, 'heure'),
    minutes: format_nombre(initial.heure.nombre, 'minute'),
    am: [
      initial.creneau.am.debut.heure + (initial.creneau.am.debut.minute / 60),
      initial.creneau.am.fin.heure + (initial.creneau.am.fin.minute / 60)
    ],
    pm: [
      initial.creneau.pm.debut.heure + (initial.creneau.pm.debut.minute / 60),
      initial.creneau.pm.fin.heure + (initial.creneau.pm.fin.minute / 60)
    ],
    date: (
      (modal.data !== undefined) &&
      (modal.data.jour !== undefined)
    )
      ? modal.data.jour
      : initial.heure.date,
    salarie: (modal.open === 'edit')
      ? heures.find(item => item.id === modal.id).user
      : (user.profil === 'administrateur')
        ? (filters.heures.salarie !== -1)
          ? filters.heures.salarie
          : sort_name(equipe)[0].id
        : user.unique_id_user
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (
      (!error.nombre) &&
      (!error.picker) &&
      (!error.slider) &&
      (
        (state.motif !== 'autre') ||
        (
          (state.motif === 'autre') &&
          CheckLong(state.texte)
        )
      )
    ) {
      const value = {
        sens: state.sens,
        motif: state.motif,
        user: state.salarie,
        recuperation: state.recuperation,
        nombre: (modal.nombre)
          ? state.nombre
          : 0,
        id: (modal.id === '')
          ? get_id()
          : modal.id,
        demande: (modal.id === '')
          ? dayjs().unix()
          : state.values.demande,
        fin: (modal.nombre)
          ? state.date.unix()
          : parseInt(state.date.unix()) + (parseFloat(state.am[1]) * 3600),
        debut: (modal.nombre)
          ? state.date.unix()
          : parseInt(state.date.unix()) + (parseFloat(state.am[0]) * 3600),
        texte: (state.motif === 'autre')
          ? state.texte
          : '',
        status: (modal.id === '')
          ? (
              (user.profil === 'administrateur') ||
              (params.find(item => item.id === 'validation').value === 0)
            ) ? 1 : 2
          : state.values.status
      }
      if (modal.id !== '') {
        if (isDifferent([value], [state.values])) {
          dispatch(EDIT_HEURE(value))
        }
      } else {
        dispatch(CREATE_HEURE(
          (state.total === 1)
            ? [value]
            : [
                value,
                {
                  ...value,
                  id: get_id(),
                  fin: parseInt(state.date.unix()) + (parseFloat(state.pm[1]) * 3600),
                  debut: parseInt(state.date.unix()) + (parseFloat(state.pm[0]) * 3600)
                }
              ]
        ))
      }
      dispatch(MODAL_HEURES('', ''))
    } else {
      UpdateError('motif', !CheckLong(state.texte))
    }
  }
  useEffect(() => {
    if (modal.open !== '') {
      Update('total', 1)
      UpdateError('motif', false)
      UpdateError('nombre', false)
      UpdateError('picker', false)
      if (modal.id === '') {
        Update('sens', initial.heure.sens)
        Update('motif', initial.heure.motif)
        Update('texte', initial.heure.texte)
        Update('nombre', initial.heure.nombre)
        Update('recuperation', initial.heure.recuperation)
        Update('heures', format_nombre(initial.heure.nombre, 'heure'))
        Update('minutes', format_nombre(initial.heure.nombre, 'minute'))
        Update('am', [
          initial.creneau.am.debut.heure + (initial.creneau.am.debut.minute / 60),
          initial.creneau.am.fin.heure + (initial.creneau.am.fin.minute / 60)
        ])
        Update('pm', [
          initial.creneau.pm.debut.heure + (initial.creneau.pm.debut.minute / 60),
          initial.creneau.pm.fin.heure + (initial.creneau.pm.fin.minute / 60)
        ])
        Update('date', (
          (
            (modal.data !== undefined) &&
            (modal.data.jour !== undefined)
          )
            ? modal.data.jour
            : initial.heure.date
        ))
        Update('salarie', (
          (user.profil === 'administrateur')
            ? (filters.heures.salarie !== -1)
              ? filters.heures.salarie
              : sort_name(equipe)[0].id
            : user.unique_id_user
        ))
      } else {
        const heure = heures.find(item => item.id === modal.id)
        Update('values', {
          ...heure
        })
      }
    }
    // eslint-disable-next-line
  }, [modal])
  useEffect(() => {
    if (state.values !== undefined) {
      Update('sens', state.values.sens)
      Update('motif', state.values.motif)
      Update('texte', state.values.texte)
      Update('salarie', state.values.user)
      Update('nombre', state.values.nombre)
      Update('recuperation', state.values.recuperation)
      Update('date', dayjs.unix(state.values.debut).startOf('day'))
      Update('heures', format_nombre(state.values.nombre, 'heure'))
      Update('minutes', format_nombre(state.values.nombre, 'minute'))
      Update('am', [
        dayjs.unix(state.values.debut).hour() + (dayjs.unix(state.values.debut).minute() / 60),
        dayjs.unix(state.values.fin).hour() + (dayjs.unix(state.values.fin).minute() / 60)
      ])
    }
    // eslint-disable-next-line
  }, [state.values])
  return (
    <Dialog
      maxWidth="md"
      fullWidth={!modal.nombre}
      open={(
        (modal.open === 'create') ||
        (modal.open === 'edit')
      )}
      className={
        (device.mobile)
          ? 'dialog-heure-mobile'
          : 'dialog-heure'
      }
    >
      <DialogTitle>
        {
          (modal.id === '')
            ? "Nouvelle heure"
            : "Modifier l'heure"
        }
      </DialogTitle>
      <DialogContent>
        <HeureModalContent
          error={error}
          state={state}
          Commit={Commit}
          Update={Update}
          UpdateError={UpdateError}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => dispatch(MODAL_HEURES('', ''))
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default HeureModal
