import Planning from '../Hooks/Planning'
import dayjs from '../../../Libraries/dayjs'
import {
  useSelector
} from 'react-redux'
import {
  get_time
} from '../../../Functions/Heures'
import {
  isDifferent
} from '../../../Functions/Compare'
import {
  upper_first
} from '../../../Functions/Strings'
import {
  get_ouverture,
  get_open_ouverture
} from '../../../Functions/Ouverture'

const Ouverture = () => {
  const planning = Planning()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const type = useSelector(state => state.compte.type)
  const view = useSelector(state => state.planning.view)
  const settings = useSelector(state => state.settings.variables)
  const ouverture = useSelector(state => state.ouverture.ouverture)
  const day = {
    planning: useSelector(state => state.planning.date),
    semaines: useSelector(state => state.semaines.date)
  }
  const date = (planning)
    ? day.planning
    : day.semaines
  const total = get_time(
    (view === 'Week')
      ? get_open_ouverture(ouverture)
      : get_open_ouverture(ouverture, dayjs(date).weekday())
  )
  if (isDifferent([ouverture], [get_ouverture()])) {
    return (device.mobile)
      ? <div className="paper-toolbar-bottom">
          <div>
            <i className="icon horloge"/>
            {total}
          </div>
          <div className="bottom-help today-day"><span/>Aujourd'hui</div>
          <div className="bottom-help holiday-day"><span/>Jour férié</div>
        </div>
      : <div className="paper-toolbar-bottom">
          {upper_first(settings.companies.libelle[type]) + " sera " + settings.companies.open[type] + " durant " + total}
          {
            (view === 'Week')
              ? " cette semaine"
              : " aujourd'hui"
          }
          <div className="separate-bottom"/>
          <div className="bottom-help today-day"><span/>Aujourd'hui</div>
          <div className="bottom-help holiday-day"><span/>Jour férié</div>
        </div>
  } else {
    return (
      (device.desktop) &&
      (user.profil === 'administrateur')
    )
      ? <div className="paper-toolbar-bottom">
          <span className="toolbar-helper">Veuillez renseigner les horaires d'ouverture dans les paramètres</span>
        </div>
      : null
  }
}

export default Ouverture
