import React from 'react'
import Provider from './Provider'
import {
  useSelector
} from 'react-redux'
import {
  DatePicker as PickerDate
} from '@mui/x-date-pickers/DatePicker'
import {
  MobileDatePicker as PickerDateMobile
} from '@mui/x-date-pickers/MobileDatePicker'

const DatePicker = ({
  mobile,
  ...others
}) => {
  const device = useSelector(state => state.device)
  const object = {
    label: 'Date',
    format: 'DD/MM/YYYY'
  }
  return (
    <Provider>
      {
        (mobile || device.mobile)
          ? <PickerDateMobile
              className="date-picker-mobile"
              {...object}
              {...others}
            />
          : <PickerDate
              className="date-picker"
              {...object}
              {...others}
            />
      }
    </Provider>
  )
}

export default DatePicker
