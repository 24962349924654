import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateLogin = ({
  item,
  label
}) => {
  return (
    <TableCellDate
      min={160}
      full={true}
      label={label}
      value={item.login}
    />
  )
}

export default TableCellDateLogin
