import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'

const SelectSens = (props) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <SelectInput
      label="Sens"
      options={settings.heure.sens.map((item, index) => {
        return {
          value: index,
          label: item
        }
      })}
      {...props}
    />
  )
}

export default SelectSens
