import dayjs from '../../Libraries/dayjs'
import IndemniteModalContent from './IndemniteModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  sort_name
} from '../../Functions/Sort'
import {
  CheckInt
} from '../../Functions/Check'
import {
  isDifferent
} from '../../Functions/Compare'
import {
  get_id
} from '../../Functions/Functions'
import {
  initial
} from '../../Variables/Variables'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  EDIT_INDEMNITE,
  CREATE_INDEMNITE,
  MODAL_INDEMNITES
} from '../../Redux/Actions/Indemnites'

const IndemniteModal = () => {
  const dispatch = useDispatch()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.login.user)
  const equipe = useSelector(state => state.equipe.equipe)
  const modal = useSelector(state => state.indemnites.modal)
  const filters = useSelector(state => state.filters.filters)
  const params = useSelector(state => state.settings.settings)
  const settings = useSelector(state => state.settings.variables)
  const indemnites = useSelector(state => state.indemnites.indemnites)
  const [state, setState] = React.useState({
    error: false,
    picker: false,
    values: undefined,
    date: initial.indemnite.date,
    nombre: initial.indemnite.nombre,
    salarie: (modal.open === 'edit')
      ? indemnites.find(item => item.id === modal.id).user
      : (user.profil === 'administrateur')
        ? (filters.indemnites.salarie !== -1)
          ? filters.indemnites.salarie
          : sort_name(equipe)[0].id
        : user.unique_id_user
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (!state.error) {
      const value = {
        user: state.salarie,
        date: state.date.unix(),
        nombre: parseInt(state.nombre),
        id: (modal.id === '')
          ? get_id()
          : modal.id,
        demande: (modal.id === '')
          ? dayjs().unix()
          : state.values.demande,
        status: (modal.id === '')
          ? (
              (user.profil === 'administrateur') ||
              (params.find(item => item.id === 'validation').value === 0)
            ) ? 1 : 2
          : state.values.status
      }
      if (modal.id !== '') {
        if (isDifferent([value], [state.values])) {
          dispatch(EDIT_INDEMNITE(value))
        }
      } else {
        dispatch(CREATE_INDEMNITE(value))
      }
      dispatch(MODAL_INDEMNITES('', ''))
    } else {
      Update('error', (
        (parseInt(state.nombre) < settings.minimum.indemnite) ||
        (parseInt(state.nombre) > settings.maximum.indemnite) ||
        (!CheckInt(state.nombre))
      ))
    }
  }
  useEffect(() => {
    if (modal.open !== '') {
      Update('error', false)
      if (modal.id === '') {
        Update('date', initial.indemnite.date)
        Update('nombre', initial.indemnite.nombre)
        Update('salarie', (
          (user.profil === 'administrateur')
            ? (filters.indemnites.salarie !== -1)
              ? filters.indemnites.salarie
              : sort_name(equipe)[0].id
            : user.unique_id_user
        ))
      } else {
        const indemnite = indemnites.find(item => item.id === modal.id)
        Update('values', {
          ...indemnite
        })
      }
    }
    // eslint-disable-next-line
  }, [modal])
  useEffect(() => {
    if (state.values !== undefined) {
      Update('salarie', state.values.user)
      Update('nombre', state.values.nombre)
      Update('date', dayjs.unix(state.values.date))
    }
    // eslint-disable-next-line
  }, [state.values])
  return (
    <Dialog
      open={(
        (modal.open === 'create') ||
        (modal.open === 'edit')
      )}
      className={
        (device.mobile)
          ? 'dialog-indemnite-mobile'
          : 'dialog-indemnite'
      }
    >
      <DialogTitle>
        {
          (modal.id === '')
            ? "Nouvelle indemnité"
            : "Modifier l'indemnité"
        }
      </DialogTitle>
      <DialogContent>
        <IndemniteModalContent
          state={state}
          Commit={Commit}
          Update={Update}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => dispatch(MODAL_INDEMNITES('', ''))
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default IndemniteModal
