import React from 'react'
import Button from './Button'

const ButtonToday = (props) => {
  return (
    <Button
      icon="Today"
      title="Aujourd'hui"
      {...props}
    />
  )
}

export default ButtonToday
