import React from 'react'
import {
  Switch as Input
} from '@mui/material'

const Switch = (props) => {
  return (
    <Input
      color="secondary"
      {...props}
    />
  )
}

export default Switch
