import React from 'react'
import ButtonAdminBlock from '../../Includes/Button/ButtonAdminBlock'
import ButtonAdminUnblock from '../../Includes/Button/ButtonAdminUnblock'
import ButtonAdminDisconnect from '../../Includes/Button/ButtonAdminDisconnect'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  BLOCK_USER,
  MODAL_USERS,
  DISCONNECT_USER
} from '../../../Redux/Actions/Users'

const UsersToolbarButtons = () => {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.users.selected)
  const Texte = (action) => {
    const texte = (selected.length > 1)
      ? "les utilisateurs sélectionnés"
      : "l'utilisateur sélectionné"
    switch (action) {
      case 'block':
        return (
          <span>Voulez-vous vraiment <b>bloquer</b> {texte} ?</span>
        )
      case 'unblock':
        return (
          <span>Voulez-vous vraiment <b>débloquer</b> {texte} ?</span>
        )
      case 'disconnect':
        return (
          <span>Voulez-vous vraiment <b>déconnecter</b> {texte} ?</span>
        )
      default:
      break
    }
  }
  return (
    <>
      <ButtonAdminUnblock
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_USERS(
            true,
            Texte('unblock'),
            () => dispatch(BLOCK_USER(selected, 0))
          ))
        }}
      />
      <ButtonAdminBlock
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_USERS(
            true,
            Texte('block'),
            () => dispatch(BLOCK_USER(selected, 1))
          ))
        }}
      />
      <ButtonAdminDisconnect
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_USERS(
            true,
            Texte('disconnect'),
            () => dispatch(DISCONNECT_USER(selected))
          ))
        }}
      />
    </>
  )
}

export default UsersToolbarButtons
