import clsx from 'clsx'
import React from 'react'
import styles from './Partners.module.css'
import Phoenix from '../../Images/Partners/phoenix.png'
import Capunipharm from '../../Images/Partners/capunipharm.png'
import Vetinthemoun from '../../Images/Partners/vetinthemoun.png'
import {
  useSelector
} from 'react-redux'
import {
  landing
} from '../../Variables/Landing'

const Partners = () => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        clsx({
          [styles.colonne]: true,
          [styles.mobile]: device.mobile
        })
      }
    >
      <p className={styles.texte}>Ils utilisent {landing.nom}</p>
      <div className={styles.container}>
        <img
          src={Phoenix}
          alt="Phoenix"
          className={styles.image}
        />
        <img
          src={Capunipharm}
          alt="Cap'unipharm"
          className={styles.image}
        />
        <img
          src={Vetinthemoun}
          alt="Vet'in the Moun"
          className={styles.image}
        />
      </div>
    </div>
  )
}

export default Partners
