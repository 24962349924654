import {
  check_token
} from './token'
import {
  messages
} from '../Variables/Messages'
import {
  variables
} from '../Variables/Variables'

const url = {
  api: variables.url.server + variables.path.api + variables.page.api,
  user: variables.url.server + variables.path.api + variables.page.user,
  stripe: variables.url.server + variables.path.api + variables.page.stripe,
  planning: variables.url.server + variables.path.api + variables.page.planning
}

export async function user(data) {
  let headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')
  const config = {
    body: data,
    method: 'POST',
    headers: headers
  }
  const response = await fetch(url.user, config)
  return await response.json()
}

export async function planning(data) {
  const check = await check_token()
  if (check) {
    let headers = new Headers()
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'))
    const config = {
      body: data,
      method: 'POST',
      headers: headers
    }
    const response = await fetch(url.planning, config)
    return await response.json()
  } else {
    return messages.error.access
  }
}

export async function api(data, file, stripe = false) {
  const check = await check_token()
  if (check) {
    let headers = new Headers()
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'))
    const config = {
      body: data,
      method: 'POST',
      headers: headers
    }
    const target = (stripe)
      ? url.stripe
      : url.api
    const response = await fetch(target, config)
    return (file === undefined)
      ? await response.json()
      : await response.blob()
  } else {
    return messages.error.access
  }
}
