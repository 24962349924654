import dayjs from '../../Libraries/dayjs'
import React, {
  useEffect
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  start_case,
  get_user_long
} from '../../Functions/Strings'

const AccueilDate = () => {
  const [date, setDate] = React.useState(dayjs())
  const user = useSelector(state => state.login.user)
  useEffect(() => {
    const timer = setInterval(() => setDate(dayjs()), 1000)
    return () => {
      clearInterval(timer)
    }
  })
  return (
    <div className="accueil-date">
      <div>
        Bienvenue {get_user_long(user)}
      </div>
      <div>
        {start_case(date.format('dddd D MMMM YYYY HH:mm:ss'))}
      </div>
    </div>
  )
}

export default AccueilDate
