import React from 'react'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'
import {
  get_valeur
} from '../../../Functions/Settings'

const TableCellSettingsValeur = ({ item }) => {
  const ouverture = useSelector(state => state.ouverture.ouverture)
  return (
    <TableCellDesktop
      max={200}
      value={get_valeur(item, ouverture)}
    />
  )
}

export default TableCellSettingsValeur
