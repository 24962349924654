import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellLogged = ({
  item,
  label,
  device
}) => {
  const value = item.logged
  const object = {
    value: 'Oui',
    empty: (value !== 1)
  }
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        {...object}
      />
    : <TableCellDesktop
        max={75}
        {...object}
      />
}

export default TableCellLogged
