import FilterAdminContent from './FilterAdminContent'
import React, {
  useEffect
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Dialog/Dialog'

const FilterAdminComponent = ({
  open,
  label,
  Commit,
  target,
  values,
  setOpen
}) => {
  const [state, setState] = React.useState({})
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  useEffect(() => {
    if (open) {
      setState(values)
    }
    // eslint-disable-next-line
  }, [open])
  return (
    <Dialog open={open}>
      <DialogTitle>Filtrer les {label}</DialogTitle>
      <DialogContent>
        <FilterAdminContent
          state={state}
          target={target}
          Update={Update}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            onClick: () => {
              if (
                (state.date === null) &&
                (state.planning > 0)
              ) {
                Update('error', true)
              } else {
                setOpen(false)
                Commit(state)
              }
            }
          }
        ]}
      />
    </Dialog>
  )
}

export default FilterAdminComponent
