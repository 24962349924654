import clsx from 'clsx'
import React from 'react'
import styles from './Event.module.css'
import EventAppointmentBase from './EventAppointmentBase'
import {
  useSelector
} from 'react-redux'

const EventAppointment = (props) => {
  const { data, children } = props
  const [hover, setHover] = React.useState(false)
  const target = useSelector(state => state.planning.event)
  return (
    <EventAppointmentBase
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={
        clsx(
          styles.appointment,
          (data.heure)
            ? styles.heure
            : styles.event,
          (
            (hover) ||
            (data.id === target.id)
          )
            ? styles.hover
            : styles.normal
        )
      }
      {...props}
    >
      {children}
    </EventAppointmentBase>
  )
}

export default EventAppointment
