import React from 'react'
import Checkbox from '../Misc/Checkbox'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'

const SelectAbonnements = ({
  value,
  setValue,
  ...others
}) => {
  const abonnements = useSelector(state => state.landing.abonnements)
  return (
    <SelectInput
      value={value}
      multiple={true}
      label="Abonnements"
      renderValue={(array) => abonnements.filter(item => array.includes(item.unique_id_abonnement)).map(item => item.name).join(', ')}
      onChange={(event) => {
        if (event.target.value.length >= 1) {
          setValue(event.target.value)
        }
      }}
      options={abonnements.map(item => {
        return {
          content: <Checkbox checked={(value.includes(item.unique_id_abonnement))}/>,
          value: item.unique_id_abonnement,
          label: item.name
        }
      })}
      {...others}
    />
  )
}

export default SelectAbonnements
