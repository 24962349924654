import clsx from 'clsx'
import React from 'react'
import styles from './LegendeAccordion.module.css'
import {
  Paper,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion
} from '@mui/material'
import {
  Warning,
  ExpandMore
} from '@mui/icons-material'

const Accordion = ({
  open,
  children,
  ...others
}) => {
  return (open)
    ? <MuiAccordion
        expanded={true}
        defaultExpanded={true}
        className={styles.accordion}
      >
        {children}
      </MuiAccordion>
    : <MuiAccordion {...others}>
        {children}
      </MuiAccordion>
}

const LegendeAccordion = ({
  open,
  titre,
  warning,
  children
}) => {
  const [expanded, setExpanded] = React.useState(open || false)
  return (
    <Paper>
      <Accordion
        open={open}
        onChange={(event, value) => setExpanded(value)}
      >
        <AccordionSummary
          expandIcon={(!open) && <ExpandMore/>}
          className={
            clsx({
              [styles.open]: open,
              [styles.summary]: true,
              [styles.expanded]: expanded
            })
          }
        >
          <Typography className={styles.titre}>
            {
              (warning)
                ? <Warning className={styles.warning}/>
                : null
            }
            {titre}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

export default LegendeAccordion
