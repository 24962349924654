import React from 'react'
import ButtonAdd from '../Includes/Button/ButtonAdd'
import {
  useDispatch
} from 'react-redux'
import {
  MODAL_FONCTIONS
} from '../../Redux/Actions/Fonctions'

const FonctionsToolbarContent = () => {
  const dispatch = useDispatch()
  return (
    <ButtonAdd
      mobile="Nouvelle fonction"
      onClick={() => dispatch(MODAL_FONCTIONS('', 'create'))}
    />
  )
}

export default FonctionsToolbarContent
