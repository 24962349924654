import React from 'react'
import Titre from '../../Components/Includes/Misc/Titre'
import {
  useLocation
} from 'react-router-dom'
import {
  routes
} from '../../Variables/Routes'

const BarTitleMobile = () => {
  const location = useLocation()
  const page = routes.find(item => item.path === location.pathname.replace('/', ''))
  return (
    <Titre>
      <span className="navbar-title">
        {
          (page)
            ? page.name
            : null
        }
      </span>
    </Titre>
  )
}

export default BarTitleMobile
