import React from 'react'
import Checkbox from '../Misc/Checkbox'
import styles from './TableCell.module.css'
import {
  useSelector
} from 'react-redux'
import {
  TableCell
} from '@mui/material'

const TableCellCheckbox = ({
  enable,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (
    <TableCell
      padding="checkbox"
      className={styles.center}
      style={{
        paddingRight: 0,
        width: (device.mobile) ? undefined : 30,
        paddingTop: (device.mobile) ? 0 : undefined,
        paddingBottom: (device.mobile) ? 0 : undefined
      }}
    >
      {
        (enable !== false)
          ? <Checkbox {...others}/>
          : <span className={styles.empty}/>
      }
    </TableCell>
  )
}

export default TableCellCheckbox
