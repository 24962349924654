import React from 'react'
import TableRender from './TableRender'
import TableDesktopRow from './TableDesktopRow'

const TableDesktop = ({
  selected,
  ...others
}) => {
  return (
    <TableDesktopRow
      selected={selected}
      {...others}
    >
      <TableRender {...others}/>
    </TableDesktopRow>
  )
}

export default TableDesktop
