import AideContactContent from './AideContactContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch
} from 'react-redux'
import {
  CheckMessage
} from '../../Functions/Check'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  CONTACT
} from '../../Redux/Actions/Contact'

const AideContact = ({
  open,
  setOpen
}) => {
  const dispatch = useDispatch()
  const [error, setError] = React.useState({
    sujet: false,
    texte: false
  })
  const [state, setState] = React.useState({
    sujet: '',
    texte: ''
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (
      CheckMessage(state.texte) &&
      (state.sujet !== '')
    ) {
      dispatch(CONTACT(state.sujet, state.texte))
      setOpen(false)
    } else {
      UpdateError('sujet', (state.sujet === ''))
      UpdateError('texte', !CheckMessage(state.texte))
    }
  }
  useEffect(() => {
    if (open) {
      Update('sujet', '')
      Update('texte', '')
      UpdateError('sujet', false)
      UpdateError('texte', false)
    }
  }, [open])
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Contactez-nous</DialogTitle>
      <DialogContent>
        <AideContactContent
          error={error}
          state={state}
          Update={Update}
          UpdateError={UpdateError}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default AideContact
