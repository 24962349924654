import React from 'react'
import ToolbarMail from '../../Includes/Toolbar/ToolbarMail'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  variables
} from '../../../Variables/Variables'
import {
  PLAQUETTE,
  SEND_PLAQUETTE
} from '../../../Redux/Actions/Plaquette'

const PlaquetteToolbar = () => {
  const dispatch = useDispatch()
  const mails = useSelector(state => state.plaquette.mails)
  const pending = useSelector(state => state.plaquette.pending)
  const Commit = () => {
    dispatch(PLAQUETTE('pending', true))
    dispatch(SEND_PLAQUETTE(
      mails,
      () => {
        dispatch(PLAQUETTE('input', ''))
        dispatch(PLAQUETTE('mails', []))
        dispatch(PLAQUETTE('error', false))
        dispatch(PLAQUETTE('pending', false))
      }
    ))
  }
  return (
    <ToolbarMail
      mails={mails}
      onClick={() => Commit()}
      titre="Envoyer une plaquette"
      disabled={(
        (pending) ||
        (mails.length === 0) ||
        (mails.length > variables.mail.maximum)
      )}
    />
  )
}

export default PlaquetteToolbar
