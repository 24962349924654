import clsx from 'clsx'
import React from 'react'
import styles from './Page.module.css'

const Page = ({
  column,
  children
}) => {
  return (
    <div
      className={
        clsx(
          styles.page,
          (column)
            ? styles.column
            : styles.row
        )
      }
    >
      {children}
    </div>
  )
}

export default Page
