import React from 'react'
import InputTextField from './InputTextField'

const InputPlaquette = ({
  error,
  ...others
}) => {
  return (
    <InputTextField
      error={error}
      label="Destinataires"
      helperText={
        (error)
          ? "Au moins une adresse mail est invalide"
          : null
      }
      {...others}
    />
  )
}

export default InputPlaquette
