import React from 'react'
import CardContent from './CardContent'
import CardContentBottom from './CardContentBottom'
import Abonnement from '../Includes/Abonnement/Abonnement'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Container
} from '@mui/material'
import {
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  check_input
} from '../../Functions/Stripe'
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from '@stripe/react-stripe-js'
import {
  messages
} from '../../Variables/Messages'
import {
  get_id
} from '../../Functions/Functions'
import {
  initial
} from '../../Variables/Variables'
import {
  SIGNIN_UPDATE,
  CREATE_COMPANIE
} from '../../Redux/Actions/Signin'

const Card = ({ setStep }) => {
  const toast = get_id(6)
  const stripe = useStripe()
  const dispatch = useDispatch()
  const elements = useElements()
  const device = useSelector(state => state.device)
  const user = useSelector(state => state.signin.user)
  const abonnement = useSelector(state => state.signin.abonnement)
  const [error, setError] = React.useState({
    cvc: false,
    card: false,
    code: false,
    expire: false,
    conditions: false,
    confidentialite: false
  })
  const [state, setState] = React.useState({
    code: '',
    essai: 0,
    coupon: '',
    free: false,
    lock: false,
    promotion: 0,
    engagement: 0,
    pending: false,
    conditions: false,
    cvc: initial.stripe,
    card: initial.stripe,
    expire: initial.stripe,
    confidentialite: false,
    days: abonnement.free_days
  })
  const [success, setSuccess] = React.useState({
    cvc: false,
    card: false,
    expire: false
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateSuccess = (item, value) => {
    setSuccess(current => ({
      ...current,
      [item]: value
    }))
  }
  const Error = (message = messages.stripe.error) => {
    Disable(false)
    update_toast(toast, message, 'error')
  }
  const Success = () => {
    Disable(false)
    update_toast(toast, messages.stripe.success, 'success')
    setStep(4)
  }
  const Disable = (value) => {
    Update('pending', value)
    const cvc = elements.getElement(CardCvcElement)
    const card = elements.getElement(CardNumberElement)
    const expire = elements.getElement(CardExpiryElement)
    cvc.update({
      disabled: value
    })
    card.update({
      disabled: value
    })
    expire.update({
      disabled: value
    })
  }
  const Submit = async(event) => {
    event.preventDefault()
    if (
      (state.confidentialite) &&
      (state.conditions) &&
      (success.expire) &&
      (success.card) &&
      (success.cvc)
    ) {
      Disable(true)
      UpdateError('cvc', false)
      UpdateError('card', false)
      UpdateError('expire', false)
      UpdateError('conditions', false)
      UpdateError('confidentialite', false)
      print_loading(toast)
      if (
        (!elements) ||
        (!stripe)
      ) {
        return
      }
      stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement)
      })
        .then(data => {
          if (
            (data.paymentMethod) &&
            (data.paymentMethod.id !== '')
          ) {
            const payment = data.paymentMethod.id
            dispatch(CREATE_COMPANIE(
              {
                companie: user,
                subscription: {
                  payment: payment,
                  code: state.code,
                  coupon: state.coupon,
                  abonnement: abonnement.unique_id_abonnement
                }
              },
              () => Success(),
              (response, data) => {
                if (data !== '') {
                  dispatch(SIGNIN_UPDATE('token', data))
                }
                Error(response)
              }
            ))
          } else {
            Error()
          }
        })
        .catch(() => {
          Error()
        })
    } else {
      UpdateSuccess('cvc', check_input(state.cvc))
      UpdateSuccess('card', check_input(state.card))
      UpdateSuccess('expire', check_input(state.expire))
      UpdateError('confidentialite', !state.confidentialite)
      UpdateError('conditions', !state.conditions)
      if (state.cvc.empty === true) {
        UpdateError('cvc', true)
        Update('cvc', {
          ...state.cvc,
          error: {
            ...state.cvc.error,
            message: messages.stripe.empty.cvc
          }
        })
      }
      if (state.card.empty === true) {
        UpdateError('card', true)
        Update('card', {
          ...state.card,
          error: {
            ...state.card.error,
            message: messages.stripe.empty.card
          }
        })
      }
      if (state.expire.empty === true) {
        UpdateError('expire', true)
        Update('expire', {
          ...state.expire,
          error: {
            ...state.expire.error,
            message: messages.stripe.empty.expire
          }
        })
      }
    }
  }
  return (
    <Container
      maxWidth="sm"
      className="stripe"
    >
      <div className="stripe-content">
        <Abonnement
          payment={true}
          days={state.days}
          free={state.free}
          mobile={device.mobile}
          abonnement={abonnement}
          promotion={state.promotion}
        />
        <CardContent
          error={error}
          state={state}
          Submit={Submit}
          Update={Update}
          UpdateError={UpdateError}
          UpdateSuccess={UpdateSuccess}
        />
      </div>
      <CardContentBottom
        error={error}
        state={state}
        Update={Update}
        setStep={setStep}
        UpdateError={UpdateError}
      />
    </Container>
  )
}

export default Card
