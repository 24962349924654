import dayjs from '../Libraries/dayjs'
import store from '../Redux/Store/Store'
import {
  format_date
} from './Dates'
import {
  isDifferent
} from './Compare'
import {
  get_id
} from './Functions'
import {
  ouverture,
  variables
} from '../Variables/Variables'

export function is_valid(object) {
  return (
    (Number.isInteger(object.h)) &&
    (Number.isInteger(object.m))
  )
}

export function is_not_empty(object) {
  return (
    (object.debut.h !== null) &&
    (object.debut.m !== null) &&
    (object.fin.h !== null) &&
    (object.fin.m !== null)
  )
}

export function get_ouverture() {
  let data = {}
  for (let i = 0; i < 7; i++) {
    data = {
      ...data,
      [dayjs().weekday(i).format('dddd')]: ouverture
    }
  }
  return data
}

export function get_open_ouverture(ouverture, jour) {
  let total = 0
  if (jour === undefined) {
    for (let i = 0; i < 7; i++) {
      total += get_open_day(i, ouverture)
    }
  } else {
    total = get_open_day(jour, ouverture)
  }
  return total
}

export function get_ouverture_semaine(ouverture) {
  let data = []
  for (let i = 0; i < 7; i++) {
    const day = dayjs().weekday(i).format('dddd')
    if (is_not_empty(ouverture[day].am)) {
      data.push(get_ouverture_semaine_day(ouverture[day].am, i))
    }
    if (is_not_empty(ouverture[day].pm)) {
      data.push(get_ouverture_semaine_day(ouverture[day].pm, i))
    }
  }
  return data
}

export function get_ouverture_semaine_day(object, day) {
  return {
    equipe: [],
    id: get_id(),
    ...format_date({
      jour: day,
      debut: {
        heure: object.debut.h,
        minute: object.debut.m
      },
      fin: {
        heure: object.fin.h,
        minute: object.fin.m
      }
    })
  }
}

export function get_open_day(jour, ouverture) {
  let object
  let nombre = 0
  const day = dayjs().weekday(jour).format('dddd')
  object = ouverture[day].am
  nombre += (is_not_empty(object))
    ? (
        ((object.fin.h * 60) + object.fin.m) -
        ((object.debut.h * 60) + object.debut.m)
      )
    : 0
  object = ouverture[day].pm
  nombre += (is_not_empty(object))
    ? (
        ((object.fin.h * 60) + object.fin.m) -
        ((object.debut.h * 60) + object.debut.m)
      )
    : 0
  return nombre
}

export function get_open_cell(object, start, end) {
  if (is_not_empty(object)) {
    const dates = {
      fin: dayjs(end),
      debut: dayjs(start)
    }
    const day = dates.debut.startOf('day')
    const debut = day
      .add(object.debut.h, 'hours')
      .add(object.debut.m, 'minutes')
    const fin = day
      .add(object.fin.h, 'hours')
      .add(object.fin.m, 'minutes')
    if (
      dates.debut.isSameOrAfter(debut) &&
      dates.fin.isSameOrBefore(fin)
    ) {
      return 'cell-day-open'
    } else if (
      fin.isAfter(dates.debut) &&
      fin.isBefore(dates.fin)
    ) {
      return 'cell-day-open-after'
    } else if (
      dates.fin.isAfter(debut) &&
      dates.fin.isBefore(fin)
    ) {
      return 'cell-day-open-before'
    }
  }
}

export function get_amplitude_ouverture(ouverture) {
  let object
  let minimum = []
  let maximum = []
  const show = store.getState().planning.show
  if (
    (show.ouverture) &&
    (isDifferent([ouverture], [get_ouverture()]))
  ) {
    for (let i = 0; i < 7; i++) {
      const day = dayjs().weekday(i).format('dddd')
      object = ouverture[day].am
      if (is_not_empty(object)) {
        minimum.push(object.debut.h + (object.debut.m / 60))
        maximum.push(object.fin.h + (object.fin.m / 60))
      }
      object = ouverture[day].pm
      if (is_not_empty(object)) {
        minimum.push(object.debut.h + (object.debut.m / 60))
        maximum.push(object.fin.h + (object.fin.m / 60))
      }
    }
    return {
      minimum: Math.min(...minimum),
      maximum: Math.max(...maximum)
    }
  } else {
    return {
      minimum: variables.date.debut.heure + (variables.date.debut.minute / 60),
      maximum: variables.date.fin.heure + (variables.date.fin.minute / 60)
    }
  }
}
