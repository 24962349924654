import _deburr from 'lodash/deburr'
import _toLower from 'lodash/toLower'
import _toUpper from 'lodash/toUpper'
import _capitalize from 'lodash/capitalize'

export function lower_case(string) {
  return _toLower(string)
}

export function upper_case(string) {
  return _toUpper(string)
}

export function upper_first(string) {
  return _capitalize(string)
}

export function sans_accents(string) {
  return _toLower(_deburr(string))
}

export function get_user_pdf(user) {
  return wrap_text(upper_case(user.prenom[0] + '. ' + user.nom))
}

export function get_user_long(user, reverse = false) {
  return (reverse)
    ? upper_case(user.nom) + ' ' + start_case(user.prenom)
    : start_case(user.prenom) + ' ' + upper_case(user.nom)
}

export function get_user_short(user, reverse = false) {
  return (reverse)
    ? upper_case(user.nom) + ' ' + start_case(user.prenom[0]) + '.'
    : start_case(user.prenom) + ' ' + upper_case(user.nom[0]) + '.'
}

export function wrap_text(string, max = 30) {
  return (string.length > max)
    ? string.substring(0, max) + '...'
    : string
}

export function start_case(string) {
  return (
    (string !== undefined) &&
    (string !== null) &&
    (string !== '')
  )
    ? string.split(' ').map(item => {
        return item.split('-').map(item => {
          return upper_first(item)
        }).join('-')
      }).join(' ')
    : ''
}

export function order_comparator(a, b, by) {
  const number = (
    (typeof(a[by]) === 'number') &&
    (typeof(b[by]) === 'number')
  )
  if (number) {
    if (b[by] < a[by]) {
      return -1
    } else if (b[by] > a[by]) {
      return 1
    } else {
      return 0
    }
  } else {
    if (lower_case(sans_accents(b[by])) < lower_case(sans_accents(a[by]))) {
      return -1
    } else if (lower_case(sans_accents(b[by])) > lower_case(sans_accents(a[by]))) {
      return 1
    } else {
      return 0
    }
  }
}
