import React from 'react'
import ButtonAdd from '../Includes/Button/ButtonAdd'
import ButtonInfo from '../Includes/Button/ButtonInfo'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  MODAL_ABSENCES
} from '../../Redux/Actions/Absences'

const AbsencesToolbarContent = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  return (
    <>
      {
        (user.profil === 'utilisateur')
          ? <ButtonInfo
              onClick={() => dispatch(MODAL_ABSENCES('', 'user'))}
            />
          : null
      }
      <ButtonAdd
        mobile="Nouvelle absence"
        onClick={() => dispatch(MODAL_ABSENCES('', 'create'))}
      />
    </>
  )
}

export default AbsencesToolbarContent
