import React from 'react'
import TableCellRed from './TableCellRed'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import {
  convert_heure
} from '../../../Functions/Heures'

const TableCellTemps = ({
  item,
  label,
  device
}) => {
  const value = item.temps
  const time = convert_heure(value)
  const temps = (value < 0)
    ? <TableCellRed value={time}/>
    : time
  const object = {
    value: temps,
    empty: (value === 0)
  }
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        {...object}
      />
    : <TableCellDesktop
        max={75}
        {...object}
      />
}

export default TableCellTemps
