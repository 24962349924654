import React from 'react'
import history from '../Includes/History'
import styles from './BarDesktop.module.css'
import BarTitleDesktop from './BarTitleDesktop'
import Badge from '../../Components/Includes/Wrapper/Badge'
import ButtonMenu from '../../Components/Includes/Button/ButtonMenu'
import ButtonLogout from '../../Components/Includes/Button/ButtonLogout'
import ButtonAccount from '../../Components/Includes/Button/ButtonAccount'
import ButtonNotifications from '../../Components/Includes/Button/ButtonNotifications'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'
import {
  MENU
} from '../../Redux/Actions/Menu'
import {
  USER_LOGOUT
} from '../../Redux/Actions/Login'
import {
  TARGET
} from '../../Redux/Actions/Notifications'

const BarDesktop = () => {
  const dispatch = useDispatch()
  const menu = useSelector(state => state.menu.menu)
  const user = useSelector(state => state.login.user)
  const survol = useSelector(state => state.compte.survol)
  const notifications = useSelector(state => state.notifications.notifications)
  const badge = notifications.filter(item => item.read.indexOf(user.unique_id_user) < 0).length
  const access = (
    (user.profil === 'administrateur') ||
    (user.profil === 'utilisateur')
  )
  return (
    <Toolbar className="bar-menu">
      {
        (
          (
            (survol === 0) &&
            (user.profil !== 'office')
          ) || (user.profil === 'gestionnaire')
        )
          ? <ButtonMenu
              edge="start"
              className={styles.hamburger}
              onClick={() => dispatch(MENU(!menu))}
            />
          : null
      }
      <>
        <BarTitleDesktop/>
        {
          (access)
            ? <>
                <Badge
                  content={badge}
                  style={{
                    top: 13,
                    right: 10,
                    padding: '0px 4px'
                  }}
                >
                  <ButtonNotifications
                    className={styles.notifications}
                    onClick={() => {
                      dispatch(TARGET(''))
                      history.push('/notifications')
                    }}
                  />
                </Badge>
                <ButtonAccount
                  className={styles.compte}
                  onClick={() => {
                    dispatch(TARGET(''))
                    history.push('/compte')
                  }}
                />
              </>
            : null
        }
        <ButtonLogout
          edge="start"
          className={styles.logout}
          onClick={() => {
            dispatch(TARGET(''))
            dispatch(USER_LOGOUT(true))
          }}
        />
      </>
    </Toolbar>
  )
}

export default BarDesktop
