export const regex = {
  bool: /^[0-1]$/,
  jour: /^[1-2]$/,
  radio: /^[0-9]$/,
  span: /#(.*?)#/g,
  code: /^[A-Z0-9]{8}$/,
  message: /^.{20,5000}$/,
  timestamp: /^[0-9]{10}$/,
  mois: /^([1-9]|1[012])$/,
  texte: /^\s*.{2,40}\s*$/,
  long: /^\s*.{2,100}\s*$/,
  commentaire: /^.{0,5000}$/,
  stripe: /^[A-Z0-9]{8,20}$/,
  random: /^[a-zA-Z0-9]{30}$/,
  conges: /^[-]?(?!0+\d)\d+(\.((5)))?$/,
  jwt: /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/,
  mail: /^(?=.{6,100}$)\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  int: {
    positive: /^(0|[1-9]\d*)$/,
    negative: /^[-]?(0|[1-9]\d*)$/
  },
  float: {
    positive: /^(?!0+\d)\d+(?:\.(?![1-9]*0+$)\d{1,2})?$/,
    negative: /^[-]?(?!0+\d)\d+(?:\.(?![1-9]*0+$)\d{1,2})?$/
  },
  password: {
    chiffre: /(?=.*[0-9])/,
    minuscule: /(?=.*[a-z])/,
    majuscule: /(?=.*[A-Z])/,
    special: /(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+=\-_?.,:;(){}[\]/|~'"])/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+=\-_?.,:;(){}[\]/|~'"])(?=.{8,32}$)/
  }
}
