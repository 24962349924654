const initial = {
  card: '',
  stripe: '',
  factures: '',
  abonnement: ''
}

function stripe(state = initial, action) {
  switch (action.type) {
    case 'SET_CARD':
      return {
        ...state,
        card: action.value
      }
    case 'SET_STRIPE':
      return {
        ...state,
        stripe: action.value
      }
    case 'SET_FACTURES':
      return {
        ...state,
        factures: action.value
      }
    case 'SET_ABONNEMENT':
      return {
        ...state,
        abonnement: action.value
      }
    default:
      return state
  }
}

export default stripe
