import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateDay = ({
  item,
  label
}) => {
  return (
    <TableCellDate
      label={label}
      value={item.date}
    />
  )
}

export default TableCellDateDay
