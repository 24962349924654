import {
  device as init
} from '../../Functions/Device'

const initial = init()

function device(state = initial, action) {
  switch (action.type) {
    case 'SET_DEVICE':
      return {
        ...state,
        size: action.value.size,
        mobile: action.value.mobile,
        desktop: action.value.desktop
      }
    default:
      return state
  }
}

export default device
