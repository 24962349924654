import React from 'react'
import Button from './Button'

const ButtonUnread = (props) => {
  return (
    <Button
      icon="Mail"
      title="Marquer comme non lu"
      {...props}
    />
  )
}

export default ButtonUnread
