import React from 'react'
import {
  landing
} from '../../Variables/Landing'
import {
  config,
  variables
} from '../../Variables/Variables'

const Maintenance = () => {
  return (
    <div className="maintenance">
      <div className="maintenance-logo">
        <img
          alt="logo"
          width="800"
          height="471"
          src={variables.url.server + variables.path.icon + 'planipo_text.png'}
        />
      </div>
      <div className="maintenance-texte">
        <div>Nous sommes désolés, {landing.nom} est actuellement en maintenance.</div>
        <div>Le service devrait être rétabli le :</div>
        <div>{config.fin.replace(' ', ' à ').replace(':', 'h')}</div>
      </div>
      <div className="maintenance-contact">
        <a href={"mailto:" + variables.mail.contact}>
          {variables.mail.contact}
        </a>
      </div>
    </div>
  )
}

export default Maintenance
