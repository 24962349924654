import React from 'react'
import Root from '../Includes/Wrapper/Root'
import SemaineRename from './SemaineRename'
import SemainesContent from './SemainesContent'
import CreneauModal from '../Includes/Creneau/CreneauModal'

const Semaines = () => {
  return (
    <Root container={true}>
      <CreneauModal/>
      <SemaineRename/>
      <SemainesContent/>
    </Root>
  )
}

export default Semaines
