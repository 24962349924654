import React from 'react'
import dayjs from '../../../Libraries/dayjs'
import Root from '../../Includes/Wrapper/Root'
import Alert from '../../Includes/Dialog/Alert'
import CompaniesContent from './CompaniesContent'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  lower_case,
  sans_accents
} from '../../../Functions/Strings'
import {
  MODAL_COMPANIES,
  SELECTED_COMPANIES
} from '../../../Redux/Actions/Companies'

const Companies = () => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.companies.modal)
  const search = useSelector(state => state.companies.search)
  const filters = useSelector(state => state.filters.filters)
  const companies = useSelector(state => state.companies.companies)
  const data = companies.filter(item =>
    (
      lower_case(sans_accents(item.companie)).includes(lower_case(sans_accents(search))) ||
      lower_case(sans_accents(item.prenom)).includes(lower_case(sans_accents(search))) ||
      lower_case(sans_accents(item.code)).includes(lower_case(sans_accents(search))) ||
      lower_case(sans_accents(item.mail)).includes(lower_case(sans_accents(search))) ||
      lower_case(sans_accents(item.nom)).includes(lower_case(sans_accents(search)))
    ) &&
    (
      ((item.abonnement === filters.companies.abonnement) || (parseInt(filters.companies.abonnement) === -1)) &&
      ((item.type === filters.companies.type) || (parseInt(filters.companies.type) === -1)) &&
      ((item.last === filters.companies.last) || (parseInt(filters.companies.last) === -1)) &&
      ((item.beta === filters.companies.beta) || (parseInt(filters.companies.beta) === -1)) &&
      (
        ((filters.companies.planning === 2) && (dayjs(item.planning).isSameOrBefore(filters.companies.date))) ||
        ((filters.companies.planning === 1) && (dayjs(item.planning).isSameOrAfter(filters.companies.date))) ||
        ((filters.companies.date === null) && (parseInt(filters.companies.planning) === -1)) ||
        ((filters.companies.planning === 0) && (item.planning === null))
      ) &&
      (
        ((item.engagement > 0) && (filters.companies.engagement === 1)) ||
        (item.engagement === filters.companies.engagement) ||
        (parseInt(filters.companies.engagement) === -1)
      ) &&
      (
        ((item.code === '') && (filters.companies.code === 0)) ||
        ((item.code !== '') && (filters.companies.code === 1)) ||
        (parseInt(filters.companies.code) === -1)
      ) &&
      (
        ((item.die > 0) && (filters.companies.status === 1)) ||
        (parseInt(filters.companies.status) === -1) ||
        (item.die === filters.companies.status)
      )
    )
  )
  return (
    <Root container={true}>
      {
        (companies.length > 0)
          ? <Alert
              open={modal.open}
              titre="Confirmation"
              Cancel={() => dispatch(MODAL_COMPANIES(false, '', undefined))}
              texte={<span className="block block-admin">{modal.texte}</span>}
              Commit={() => {
                dispatch(MODAL_COMPANIES(false, '', undefined))
                dispatch(SELECTED_COMPANIES([]))
                modal.action()
              }}
            />
          : null
      }
      <CompaniesContent data={data}/>
    </Root>
  )
}

export default Companies
