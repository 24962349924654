import React from 'react'
import Titre from '../Includes/Misc/Titre'
import AbsencesPlanningToolbarContent from './AbsencesPlanningToolbarContent'
import {
  Toolbar
} from '@mui/material'

const AbsencesPlanningToolbarDesktop = () => {
  return (
    <Toolbar className="paper-toolbar">
      <Titre>Absences</Titre>
      <AbsencesPlanningToolbarContent/>
    </Toolbar>
  )
}

export default AbsencesPlanningToolbarDesktop
