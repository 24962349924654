import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'
import {
  start_case
} from '../../../Functions/Strings'

const TableCellFonction = ({
  item,
  type,
  label,
  device
}) => {
  const user = useSelector(state => state.login.user)
  const value = start_case(item.metier || item.fonction)
  return (
    (user.profil === 'administrateur') ||
    (user.profil === 'gestionnaire')
  )
    ? (
        (type.id !== 'fonctions') &&
        (type.id !== 'creneau') &&
        (device.mobile)
      )
        ? <TableCellMobile
            label={label}
            value={value}
          />
        : <TableCellDesktop
            max={170}
            value={value}
          />
    : null
}

export default TableCellFonction
