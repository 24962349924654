import React from 'react'
import {
  useSelector
} from 'react-redux'

const FirstLoginContent = () => {
  const user = useSelector(state => state.login.user)
  return (
    <>
      <div className="first-login-welcome">Nous sommes très heureux de vous compter parmi nos utilisateurs !</div>
      {
        (user.profil === 'administrateur')
          ? <div className="first-login-admin-container">
              <div>Pour commencer, vous pouvez déclarer vos salariés dans le menu "<b>Équipe</b>".</div>
              <div>Vous pourrez ensuite créer vos semaines types et alimenter votre planning.</div>
            </div>
          : null
      }
      <div className="first-login-help">Nous avons mis à votre disposition des vidéos d'auto-formation dans le menu "<b>Aide</b>" afin que vous puissiez rapidement maîtriser l'outil !</div>
      <div className="first-login-bye">Bon visionnage, et à très bientôt !</div>
    </>
  )
}

export default FirstLoginContent
