import React from 'react'
import {
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  messages
} from '../../Variables/Messages'

const CompteDie = () => {
  const user = useSelector(state => state.login.user)
  return (
    <Paper className="paper-die">
      <div>
        {
          (user.profil === 'administrateur')
            ? <>
                <span>{messages.stripe.die.admin[0]}</span>
                <span>{messages.stripe.die.admin[1]}</span>
              </>
            : <>
                <span>{messages.stripe.die.user[0]}</span>
                <span>{messages.stripe.die.user[1]}</span>
              </>
        }
      </div>
    </Paper>
  )
}

export default CompteDie
