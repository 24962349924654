import React from 'react'
import Button from './Button'

const ButtonVoir = (props) => {
  return (
    <Button
      title="Voir"
      icon="Visibility"
      {...props}
    />
  )
}

export default ButtonVoir
