import clsx from 'clsx'
import React from 'react'
import styles from './LegendeSurvol.module.css'
import CreneauEquipe from '../Creneau/CreneauEquipe'
import {
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'

const LegendeSurvol = () => {
  const event = useSelector(state => state.planning.event)
  const users = useSelector(state => state.planning.users)
  return (
    (users.length > 0) &&
    (event.id === '')
  )
    ? <Paper
        className={
          clsx(
            'semaine-box',
            styles.container
          )
        }
      >
        <div className="appoint">
          <CreneauEquipe
            full={true}
            users={users}
          />
        </div>
      </Paper>
    : null
}

export default LegendeSurvol
