import React from 'react'
import Provider from './Provider'
import {
  useSelector
} from 'react-redux'
import {
  variables
} from '../../../Variables/Variables'
import {
  TimePicker as PickerTime
} from '@mui/x-date-pickers/TimePicker'
import {
  MobileTimePicker as PickerTimeMobile
} from '@mui/x-date-pickers/MobileTimePicker'

const HeurePicker = ({
  mobile,
  ...others
}) => {
  const device = useSelector(state => state.device)
  const object = {
    ampm: false,
    minutesStep: variables.heure.step
  }
  return (
    <Provider>
      {
        (mobile || device.mobile)
          ? <PickerTimeMobile
              className="heure-picker-mobile"
              {...object}
              {...others}
            />
          : <PickerTime
              className="heure-picker"
              {...object}
              {...others}
            />
      }
    </Provider>
  )
}

export default HeurePicker
