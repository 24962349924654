import TableCellFonction from '../../Components/Includes/Table/TableCellFonction'

export const fonction = {
  id: "fonction",
  sort: true,
  user: false,
  mobile: true,
  padding: true,
  label: "Fonction",
  component: TableCellFonction
}
