import React from 'react'
import TableCellTitreMobile from './TableCellTitreMobile'

const TableCellTitreCompanie = ({ item }) => {
  return (
    <TableCellTitreMobile
      red={(item.die > 0)}
      value={item.companie}
    />
  )
}

export default TableCellTitreCompanie
