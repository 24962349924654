import clsx from 'clsx'
import React from 'react'
import TableRender from './TableRender'
import styles from './TableMobileRow.module.css'
import colors from './TableCellColors.module.css'
import TableMobileRowContent from './TableMobileRowContent'

const TableMobileRow = ({
  item,
  type,
  columns,
  ...others
}) => {
  return (
    <TableMobileRowContent
      className={
        clsx({
          [styles.row]: true,
          [colors.ko]: (
            (item.status === 0) &&
            (type.groupe === 'demandes')
          ),
          [colors.ok]: (
            (item.status === 1) &&
            (type.groupe === 'demandes')
          )
        })
      }
      {...others}
    >
      <TableRender
        item={item}
        type={type}
        columns={columns}
      />
    </TableMobileRowContent>
  )
}

export default TableMobileRow
