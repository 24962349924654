import TableCellActionsFonctions from '../Components/Includes/Table/TableCellActionsFonctions'
import {
  actions
} from './Includes/Actions'
import {
  fonction
} from './Includes/Fonction'

export const properties = {
  type: {
    id: "fonctions",
    groupe: "divers"
  },
  sort: {
    by: "fonction",
    order: "asc"
  },
  columns: [
    {
      ...fonction
    },
    {
      ...actions,
      mobile: true,
      component: TableCellActionsFonctions
    }
  ]
}
