import {
  user
} from '../../API/api'
import {
  print_error
} from '../../Functions/Toast'

export const SET_TYPES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_TYPES',
      value: value
    })
  }
}

export const SET_PROFILS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_PROFILS',
      value: value
    })
  }
}

export const SET_ABONNEMENTS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ABONNEMENTS',
      value: value
    })
  }
}

export const GET_LANDING = (gestionnaire = false) => {
  return (dispatch) => {
    const value = (gestionnaire === true)
      ? {
          gestionnaire: 1
        }
      : {}
    const data = JSON.stringify({
      ...value,
      action: 'landing'
    })
    user(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(SET_TYPES(data.response.types))
          dispatch(SET_PROFILS(data.response.profils))
          dispatch(SET_ABONNEMENTS(data.response.abonnements))
        } else {
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}
