import React from 'react'
import styles from './Hamburger.module.css'
import {
  ListItemText
} from '@mui/material'

const HamburgerTexte = React.memo(({ texte }) => {
  return (
    <ListItemText
      primary={texte}
      className={styles.texte}
    />
  )
})

export default HamburgerTexte
