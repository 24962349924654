import React from 'react'
import styles from './TableCell.module.css'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'
import {
  sort_name_by_id
} from '../../../Functions/Sort'
import {
  get_user_long
} from '../../../Functions/Strings'

const TableCellSalaries = ({ item }) => {
  const equipe = useSelector(state => state.equipe.equipe)
  return (
    <TableCellDesktop max={150}>
      {
        sort_name_by_id(item.users).map(object => {
          const user = equipe.find(salarie => salarie.id === object)
          return (
            <div
              key={user.id}
              className={styles.cell}
            >
              {get_user_long(user)}
            </div>
          )
        })
      }
    </TableCellDesktop>
  )
}

export default TableCellSalaries
