import React from 'react'
import EquipeColor from './EquipeColor'
import Form from '../Includes/Wrapper/Form'
import InputNom from '../Includes/Input/InputNom'
import InputMail from '../Includes/Input/InputMail'
import styles from './EquipeModalContent.module.css'
import InputTemps from '../Includes/Input/InputTemps'
import InputConges from '../Includes/Input/InputConges'
import InputHeures from '../Includes/Input/InputHeures'
import InputPrenom from '../Includes/Input/InputPrenom'
import SelectProfil from '../Includes/Select/SelectProfil'
import SelectFonction from '../Includes/Select/SelectFonction'
import {
  useSelector
} from 'react-redux'
import {
  CheckInt,
  CheckMail,
  CheckFloat,
  CheckTexte,
  CheckConges
} from '../../Functions/Check'

const EquipeModalContent = ({
  error,
  state,
  Update,
  UpdateError
}) => {
  const settings = useSelector(state => state.settings.variables)
  const Nom = (value) => {
    Update('nom', value)
    UpdateError('nom', (
      (value !== '') &&
      (!CheckTexte(value))
    ))
  }
  const Mail = (value) => {
    Update('mail', value)
    UpdateError('mail', (
      (value !== '') &&
      (!CheckMail(value))
    ))
  }
  const Prenom = (value) => {
    Update('prenom', value)
    UpdateError('prenom', (
      (value !== '') &&
      (!CheckTexte(value))
    ))
  }
  const Heures = (value) => {
    Update('heures', value)
    UpdateError('heures', (
      (parseFloat(value) < settings.minimum.heures) ||
      (parseFloat(value) > settings.maximum.heures) ||
      (
        (value !== '') &&
        (!CheckFloat(value))
      )
    ))
  }
  const Temps = (value) => {
    Update('temps', value)
    UpdateError('temps', (
      (parseInt(value) < settings.minimum.temps) ||
      (parseInt(value) > settings.maximum.temps) ||
      (
        (value !== '') &&
        (!CheckInt(value, false))
      )
    ))
  }
  const Done = (value) => {
    Update('done', value)
    UpdateError('done', (
      (parseFloat(value) < settings.minimum.done) ||
      (parseFloat(value) > settings.maximum.done) ||
      (
        (value !== '') &&
        (!CheckConges(value))
      )
    ))
  }
  const Current = (value) => {
    Update('current', value)
    UpdateError('current', (
      (parseFloat(value) < settings.minimum.current) ||
      (parseFloat(value) > settings.maximum.current) ||
      (
        (value !== '') &&
        (!CheckConges(value))
      )
    ))
  }
  return (
    <Form>
      <InputPrenom
        setValue={Prenom}
        error={error.prenom}
        value={state.prenom}
      />
      <InputNom
        setValue={Nom}
        uppercase={true}
        error={error.nom}
        value={state.nom}
      />
      <SelectProfil
        value={state.profil}
        setValue={(value) => Update('profil', value)}
      />
      <SelectFonction
        value={state.fonction}
        setValue={(value) => Update('fonction', value)}
      />
      <InputMail
        setValue={Mail}
        error={error.mail}
        value={state.mail}
      />
      <div className={styles.picker}>
        <EquipeColor
          color={state.color}
          setColor={(value) => Update('color', value)}
        />
      </div>
      <InputHeures
        setValue={Heures}
        error={error.heures}
        value={state.heures}
      />
      <InputTemps
        setValue={Temps}
        error={error.temps}
        value={state.temps}
      />
      <InputConges
        year="done"
        setValue={Done}
        error={error.done}
        value={state.done}
      />
      <InputConges
        year="current"
        setValue={Current}
        error={error.current}
        value={state.current}
      />
    </Form>
  )
}

export default EquipeModalContent
