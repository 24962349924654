import React from 'react'
import Button from './Button'

const ButtonCircleClose = (props) => {
  return (
    <Button
      icon="HighlightOff"
      {...props}
    />
  )
}

export default ButtonCircleClose
