import {
  useLocation
} from 'react-router-dom'

const Semaines = () => {
  const location = useLocation()
  return (location.pathname === '/semaines')
}

export default Semaines
