import React from 'react'
import SettingModal from './SettingModal'
import ButtonEdit from '../Includes/Button/ButtonEdit'
import SettingOuvertureModal from './SettingOuvertureModal'

const SettingsTableEdit = ({ item }) => {
  const [open, setOpen] = React.useState('')
  const [values, setValues] = React.useState({})
  return (
    <>
      <SettingModal
        values={values}
        setOpen={() => setOpen('')}
        open={(open === 'setting')}
      />
      <SettingOuvertureModal
        setOpen={() => setOpen('')}
        open={(open === 'ouverture')}
      />
      <ButtonEdit
        onClick={() => {
          if (item.id === 'ouverture') {
            setOpen('ouverture')
          } else {
            setValues(item)
            setOpen('setting')
          }
        }}
      />
    </>
  )
}

export default SettingsTableEdit
