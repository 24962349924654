import React from 'react'
import Button from './Button'
import styles from './Button.module.css'

const ButtonPassword = ({
  error,
  visible,
  ...others
}) => {
  return (
    <Button
      icon={
        (visible)
          ? 'Visibility'
          : 'VisibilityOff'
      }
      className={
        (error)
          ? styles.red
          : undefined
      }
      {...others}
    />
  )
}

export default ButtonPassword
