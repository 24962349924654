import dayjs from '../../Libraries/dayjs'

const initial = {
  absence: '',
  absences: [],
  date: dayjs(),
  modal: {
    id: '',
    open: '',
    data: {}
  }
}

function absences(state = initial, action) {
  switch (action.type) {
    case 'ABSENCE':
      return {
        ...state,
        absence: action.value
      }
    case 'ADD_ABSENCE':
      return {
        ...state,
        absences: [
          ...state.absences,
          action.value
        ]
      }
    case 'INIT_ABSENCES':
      return {
        ...state,
        absences: action.value
      }
    case 'MODAL_ABSENCES':
      return {
        ...state,
        modal: {
          id: action.id,
          data: action.data,
          open: action.open
        }
      }
    case 'MODIFY_ABSENCE':
      return {
        ...state,
        absences: state.absences.map(item => {
          return (item.id === action.value.id)
            ? {
                ...item,
                fin: action.value.fin,
                user: action.value.user,
                texte: action.value.texte,
                duree: action.value.duree,
                motif: action.value.motif,
                debut: action.value.debut,
                status: action.value.status,
                nombre: action.value.nombre
              }
            : item
        })
      }
    case 'REMOVE_ABSENCE':
      return {
        ...state,
        absences: state.absences.filter(item => item.id !== action.value)
      }
    case 'STATUS_ABSENCE':
      return (action.id === undefined)
        ? {
            ...state,
            absences: state.absences.map(item => {
              return (item.id === action.value.id)
                ? {
                    ...item,
                    status: 3,
                    fin: action.value.fin,
                    debut: action.value.debut,
                    nombre: action.value.nombre
                  }
                : item
            })
          }
        : {
            ...state,
            absences: state.absences.map(item => {
              return (item.id === action.id)
                ? {
                    ...item,
                    status: action.value
                  }
                : item
            })
          }
    case 'SET_DATE_ABSENCES':
      return {
        ...state,
        date: dayjs(action.value)
      }
    case 'REMOVE_ABSENCES_USER':
      return {
        ...state,
        absences: state.absences.filter(item => item.user !== action.value)
      }
    default:
      return state
  }
}

export default absences
