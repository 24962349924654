import clsx from 'clsx'
import React from 'react'
import Absences from '../Hooks/Absences'
import styles from './TableCell.module.css'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'
import {
  get_user_long
} from '../../../Functions/Strings'

const TableCellSalarie = ({ item }) => {
  const absences = Absences()
  const fonctions = useSelector(state => state.fonctions.fonctions)
  return (
    <TableCellDesktop
      max={100}
      style={{
        paddingLeft: 0,
        paddingRight: (absences) ? undefined : 0
      }}
    >
      {get_user_long(item)}
      <div
        className={
          clsx(
            styles.cell,
            styles.italic,
            styles.fonction
          )
        }
      >
        {fonctions.find(object => object.id === item.fonction).fonction}
      </div>
    </TableCellDesktop>
  )
}

export default TableCellSalarie
