import {
  config,
  variables
} from '../Variables/Variables'

export function open_link(target) {
  window.open(target)
}

export function random_number(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function offer_time(days) {
  const month = 30
  if (days === 1) {
    return '1 jour offert'
  } else if (days <= (month - 1)) {
    return days + ' jours offerts'
  } else if (days === month) {
    return '1 mois offert'
  } else {
    const nombre = Math.floor(days / month)
    const s = (nombre > 1) ? 's' : ''
    return nombre + ' mois offert' + s
  }
}

export function get_id(length) {
  let result = ''
  const chiffres = '0123456789'
  const lettres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const characters = chiffres + lettres
  length = (length === undefined) ? 29 : length - 1
  const first = lettres.charAt(Math.floor(Math.random() * lettres.length))
  const array = [ ...Array(length).keys() ]
  result = first
  array.forEach(() => {
     result += characters.charAt(Math.floor(Math.random() * characters.length))
  })
  return result
}

export function redirection(beta, full = true) {
  if (
    (config.beta === '1') ||
    (config.prod === '1')
  ) {
    const path = (full)
      ? window.location.pathname
      : ''
    if (
      (beta) &&                                                   // la companie est en version beta dans la BDD
      (config.beta !== '1') &&                                    // mais ils ne sont pas sur l'environnement beta
      (variables.url.beta.indexOf(window.location.origin) < 0)    // et l'url ne correspond pas aux urls beta
    ) {
      return (window.location.origin.includes('www'))             // si l'utilisateur utilise www devant le nom de domaine
        ? variables.url.beta[1] + path
        : variables.url.beta[0] + path
    } else if (
      (!beta) &&                                                  // la companie est en version prod dans la BDD
      (config.prod !== '1') &&                                    // mais ils ne sont pas sur l'environnement prod
      (variables.url.prod.indexOf(window.location.origin) < 0)    // et l'url ne correspond pas aux urls prod
    ) {
      return (window.location.origin.includes('www'))             // si l'utilisateur utilise www devant le nom de domaine
        ? variables.url.prod[1] + path
        : variables.url.prod[0] + path
    } else {
      return null
    }
  } else {
    return null
  }
}
