import React from 'react'
import SelectInput from '../Select/SelectInput'
import {
  useSelector
} from 'react-redux'

const FilterMotif = ({
  setting,
  ...others
}) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <SelectInput
      label="Motif"
      filter={true}
      options={Object.entries(settings[setting].motifs).map(([index, item]) => {
        return {
          value: index,
          label: item
        }
      })}
      {...others}
    />
  )
}

export default FilterMotif
