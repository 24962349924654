const initial = {
  companie: '',
  settings: [],
  variables: []
}

function settings(state = initial, action) {
  switch (action.type) {
    case 'EDIT_SETTING':
      return {
        ...state,
        settings: state.settings.map(item => {
          return (item.id === action.value.id)
            ? {
                ...item,
                value: action.value.value
              }
            : item
        })
      }
    case 'SET_COMPANIE':
      return {
        ...state,
        companie: action.value
      }
    case 'INIT_SETTINGS':
      return {
        ...state,
        settings: action.value
      }
    case 'SET_VARIABLES':
      return {
        ...state,
        variables: action.value
      }
    default:
      return state
  }
}

export default settings
