import React from 'react'
import {
  useSelector
} from 'react-redux'
import {
  Warning
} from '@mui/icons-material'

const ComptabiliteExplain = ({ intersection }) => {
  const settings = useSelector(state => state.settings.settings)
  const comptable = settings.find(item => item.id === 'comptable')
  const detail = settings.find(item => item.id === 'detail')
  return (settings.length > 0)
    ? <>
        <div>
          <span>Le détail de l'export comptable est </span>
          {
            (detail.value)
              ? "activé"
              : "désactivé"
          }
          <span>, pour </span>
          {
            (detail.value)
              ? "le désactiver"
              : "l'activer"
          }
          <span>, rendez vous dans les paramètres.</span>
        </div>
        {
          (comptable.value === '')
            ? <div>Pour pouvoir envoyer votre export au comptable, vous devez renseigner son adresse mail dans les paramètres.</div>
            : null
        }
        {
          (intersection)
            ? <div className="error-intersection">
                <Warning/>
                <div>Les dates sélectionnées chevauchent au moins un de vos précédents exports (liste ci-dessous)</div>
              </div>
            : null
        }
      </>
    : null
}

export default ComptabiliteExplain
