import React from 'react'
import InputPayment from './InputPayment'
import {
  CardCvcElement
} from '@stripe/react-stripe-js'

const InputCVC = ({
  error,
  message,
  ...others
}) => {
  return (
    <InputPayment
      name="cvc"
      label="CVC"
      error={error}
      component={CardCvcElement}
      helperText={
        (error)
          ? message
          : null
      }
      {...others}
    />
  )
}

export default InputCVC
