import React from 'react'
import styles from './BarTitle.module.css'
import {
  Typography
} from '@mui/material'

const BarTitle = ({ children }) => {
  return (
    <Typography
      variant="h6"
      className={styles.title}
    >
      {children}
    </Typography>
  )
}

export default BarTitle
