import clsx from 'clsx'
import styles from '../Styles/Mail.module.css'
import InputPlaquette from '../../Includes/Input/InputPlaquette'
import ButtonAddCircle from '../../Includes/Button/ButtonAddCircle'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Paper
} from '@mui/material'
import {
  CheckMail
} from '../../../Functions/Check'
import {
  PLAQUETTE
} from '../../../Redux/Actions/Plaquette'

const PlaquetteUsers = () => {
  const dispatch = useDispatch()
  const [separate, setSeparate] = React.useState('')
  const error = useSelector(state => state.plaquette.error)
  const input = useSelector(state => state.plaquette.input)
  const mails = useSelector(state => state.plaquette.mails)
  const disabled = (
    (error) ||
    (input === '')
  )
  const Add = () => {
    if (!disabled) {
      dispatch(PLAQUETTE('input', ''))
      dispatch(PLAQUETTE('mails', [
        ...mails,
        ...input.split(separate).filter(item => mails.indexOf(item) < 0)
      ]))
    }
  }
  useEffect(() => {
    let nombre = 0
    const between = (input.includes(';')) ? ';' : ' '
    input.split(between).forEach(item => {
      nombre += (CheckMail(item)) ? 0 : 1
    })
    setSeparate(between)
    dispatch(PLAQUETTE('error', (
      (input !== '') &&
      (nombre > 0)
    )))
    // eslint-disable-next-line
  }, [input])
  return (
    <Paper className={styles.paper}>
      <div className={styles.container}>
        <InputPlaquette
          error={error}
          value={input}
          setValue={(value) => dispatch(PLAQUETTE('input', value))}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              Add()
            }
          }}
        />
        <ButtonAddCircle
          disabled={disabled}
          onClick={() => Add()}
          className={
            clsx({
              [styles.error]: error,
              [styles.action]: true
            })
          }
          color={
            (disabled)
              ? "grey"
              : "blue"
          }
        />
      </div>
    </Paper>
  )
}

export default PlaquetteUsers
