import React from 'react'
import ButtonIcon from './ButtonIcon'
import {
  useSelector
} from 'react-redux'
import {
  Button
} from '@mui/material'

const ButtonCompte = ({
  icon,
  title,
  ...others
}) => {
  const device = useSelector(state => state.device)
  return (
    <Button
      variant="contained"
      {...others}
    >
      {
        (device.desktop)
          ? title
          : <ButtonIcon icon={icon}/>
      }
    </Button>
  )
}

export default ButtonCompte
