import React from 'react'
import SelectInput from './SelectInput'
import {
  useSelector
} from 'react-redux'

const SelectCompanie = (props) => {
  const companies = useSelector(state => state.companies.companies)
  return (
    <SelectInput
      label="Entreprise"
      options={companies.map(item => {
        return {
          value: item.id,
          label: item.companie
        }
      })}
      {...props}
    />
  )
}

export default SelectCompanie
