import dayjs from '../../Libraries/dayjs'
import {
  USER_LOGOUT
} from './Login'
import {
  EDIT_EQUIPE
} from './Equipe'
import {
  STATUS_HEURE
} from './Heures'
import {
  STATUS_ABSENCE
} from './Absences'
import {
  saveAs
} from 'file-saver'
import {
  STATUS_INDEMNITE
} from './Indemnites'
import {
  api
} from '../../API/api'
import {
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id
} from '../../Functions/Functions'

export const SUPP = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SUPP',
      value: value
    })
  }
}

export const EXPORTS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'EXPORTS',
      value: value
    })
  }
}

export const SET_PDF = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_PDF',
      value: value
    })
  }
}

export const ADD_EXPORT = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_EXPORT',
      value: value
    })
  }
}

export const SET_PRIMES = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_PRIMES',
      value: value
    })
  }
}

export const COMPTABILITE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'COMPTABILITE',
      value: value
    })
  }
}

export const INIT_EXPORTS = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'INIT_EXPORTS',
      value: value
    })
  }
}

export const REMOVE_PRIMES_USER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_PRIMES_USER',
      value: value
    })
  }
}

export const SELECTED_COMPTABILITE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SELECTED_COMPTABILITE',
      value: value
    })
  }
}

export const SAVE_PRIMES = (value) => {
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'save-primes'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(SET_PRIMES(value))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const DOWNLOAD_EXPORT = (id, name) => {
  return (dispatch) => {
    const data = JSON.stringify({
      id: id,
      action: 'download-export'
    })
    api(data, true)
      .then(data => {
        if (data.status !== 'KO') {
          saveAs(data, name + '.pdf')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const SEND_MAIL_COMPTABLE = (value) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      value: value,
      action: 'send-comptable'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(ADD_EXPORT({
            id: value.id,
            date: value.date,
            fin: dayjs(value.fin, 'DD/MM/YYYY').unix(),
            debut: dayjs(value.debut, 'DD/MM/YYYY').unix()
          }))
          update_toast(toast, data.response, 'success')
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        update_toast(toast, undefined, 'error')
      })
  }
}

export const UPDATE_CLOSE = (type, value, equipe) => {
  return (dispatch) => {
    if (value.length > 0) {
      const data = JSON.stringify({
        id: value,
        action: 'close-' + type
      })
      api(data)
        .then(data => {
          if (data.status === 'OK') {
            switch (type) {
              case 'heure':
                value.forEach(item => dispatch(STATUS_HEURE(item, 3)))
              break
              case 'absence':
                value.forEach(item => dispatch(STATUS_ABSENCE(item, 3)))
              break
              case 'indemnite':
                value.forEach(item => dispatch(STATUS_INDEMNITE(item, 3)))
              break
              default:
              break
            }
            if (equipe !== undefined) {
              dispatch(EDIT_EQUIPE(equipe))
            }
          } else {
            if (data.data === 'die') {
              dispatch(USER_LOGOUT(false))
            }
            print_error(data.response)
          }
        })
        .catch(() => {
          print_error()
        })
    } else if (equipe !== undefined) {
      dispatch(EDIT_EQUIPE(equipe))
    }
  }
}
