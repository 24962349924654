import React from 'react'
import dayjs from '../../Libraries/dayjs'
import Circle from '../Includes/Accueil/Circle'
import {
  useSelector
} from 'react-redux'
import {
  get_time
} from '../../Functions/Heures'

const AccueilTravail = ({ time }) => {
  let supp = 0
  let nombre = 0
  const fin = (time === 'week')
    ? dayjs().endOf('week')
    : dayjs().endOf('month')
  const debut = (time === 'week')
    ? dayjs().startOf('week')
    : dayjs().startOf('month')
  const user = useSelector(state => state.login.user)
  const heures = useSelector(state => state.heures.heures)
  const absences = useSelector(state => state.absences.absences)
  const planning = useSelector(state => state.planning.planning)
  const data = planning.map(item => item.horaires).flat().filter(item =>
    (item.equipe.includes(user.unique_id_user)) &&
    (dayjs(item.startDate).isSameOrAfter(debut)) &&
    (dayjs(item.endDate).isSameOrBefore(fin))
  )
  data.forEach(item => {
    const end = dayjs(item.endDate)
    const start = dayjs(item.startDate)
    const duration = dayjs.duration(end.diff(start))
    const absence = absences.filter(object =>
      (object.user === user.unique_id_user) &&
      (start.unix() >= object.debut) &&
      (end.unix() <= object.fin) &&
      (object.status === 1)
    )
    nombre += (absence.length === 0)
      ? duration.asHours()
      : 0
  })
  heures.filter(item =>
    (item.user === user.unique_id_user) &&
    (item.date >= debut.unix()) &&
    (item.date <= fin.unix()) &&
    (item.recuperation === 1) &&
    (item.status === 1) &&
    (item.nombre > 0)
  ).forEach(item => {
    supp += item.nombre
  })
  return (
    <Circle
      value={get_time((nombre * 60) + supp)}
      title={
        (time === 'week')
          ? 'Heures travaillées cette semaine'
          : 'Heures travaillées ce mois-ci'
      }
    />
  )
}

export default AccueilTravail
