import React from 'react'
import SelectInput from './SelectInput'

const SelectSetting = ({
  value,
  Update,
  values
}) => {
  return (
    <SelectInput
      value={value}
      setValue={Update}
      label={values.item}
      options={[
        {
          value: 1,
          label: 'Activer'
        },
        {
          value: 0,
          label: 'Désactiver'
        }
      ]}
    />
  )
}

export default SelectSetting
