import React from 'react'
import styles from './Input.module.css'
import InputStripe from './InputStripe'
import InputTextField from './InputTextField'

const InputPayment = ({
  component,
  ...others
}) => {
  return (
    <InputTextField
      fullWidth={true}
      className={styles.stripe}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        inputComponent: InputStripe,
        inputProps: {
          component: component
        }
      }}
      {...others}
    />
  )
}

export default InputPayment
