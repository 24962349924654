import history from '../../Navigation/Includes/History'
import {
  GET_DATA
} from './Data'
import {
  READY
} from './Menu'
import {
  GET_CODES
} from './Codes'
import {
  GET_USERS
} from './Users'
import {
  SET_CARD,
  SET_STRIPE,
  SET_FACTURES,
  SET_ABONNEMENT
} from './Stripe'
import {
  GET_LANDING
} from './Landing'
import {
  GET_PLANNING
} from './Planning'
import {
  GET_SEMAINES
} from './Semaines'
import {
  SET_COMPANIE,
  SET_VARIABLES
} from './Settings'
import {
  GET_COMPANIES
} from './Companies'
import {
  user
} from '../../API/api'
import {
  save_token,
  delete_token
} from '../../API/token'
import {
  GET_NOTIFICATIONS
} from './Notifications'
import {
  print_info,
  print_error,
  update_toast,
  print_loading
} from '../../Functions/Toast'
import {
  get_id,
  redirection
} from '../../Functions/Functions'

export const LOGOUT = () => {
  return (dispatch) => {
    dispatch({
      type: 'LOGOUT'
    })
  }
}

export const DIE = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'DIE',
      value: value
    })
  }
}

export const LOGIN = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'LOGIN',
      value: value
    })
  }
}

export const FIRST_LOGIN = () => {
  return (dispatch) => {
    dispatch({
      type: 'FIRST_LOGIN'
    })
  }
}

export const PENDING = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'PENDING',
      value: value
    })
  }
}

export const USER_MAIL = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'USER_MAIL',
      value: value
    })
  }
}

export const SUCCESS_LOGIN = (profil) => {
  return (dispatch) => {
    if (
      (profil === 'administrateur') ||
      (profil === 'utilisateur')
    ) {
      dispatch(GET_NOTIFICATIONS())
    }
    if (profil === 'administrateur') {
      dispatch(GET_SEMAINES())
    }
    if (profil !== 'gestionnaire') {
      dispatch(GET_DATA())
      dispatch(GET_PLANNING())
    } else {
      dispatch(GET_LANDING(true))
      dispatch(GET_COMPANIES())
      dispatch(GET_USERS())
      dispatch(GET_CODES())
    }
  }
}

export const USER_LOGOUT = (info) => {
  return (dispatch, state) => {
    const data = JSON.stringify({
      unique_id_user: state().login.user.unique_id_user,
      action: 'logout'
    })
    delete_token()
    dispatch(LOGOUT())
    dispatch(READY(true))
    history.push('/login')
    user(data)
      .then(data => {
        if (data.status === 'OK') {
          if (info) {
            print_info(data.response)
          }
        } else {
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}

export const USER_LOGIN = (value, success) => {
  const toast = get_id(6)
  print_loading(toast)
  return (dispatch) => {
    const data = JSON.stringify({
      login: value.login,
      password: value.password,
      action: 'login'
    })
    user(data)
      .then(data => {
        if (data.status === 'OK') {
          dispatch(PENDING(false))
          dispatch(LOGIN(data.data))
          save_token('token', data.response.jwt)
          save_token('token_refresh', data.response.refresh)
          dispatch(SET_ABONNEMENT(data.data.abonnement))
          dispatch(SET_VARIABLES(data.data.settings))
          dispatch(SET_COMPANIE(data.data.companie))
          dispatch(SET_FACTURES(data.data.factures))
          dispatch(SUCCESS_LOGIN(data.data.profil))
          dispatch(SET_STRIPE(data.data.stripe))
          dispatch(SET_CARD(data.data.card))
          dispatch(DIE(data.data.die))
          success(data.data.die, redirection(data.data.beta, false))
          update_toast(toast, data.response.message, 'info')
        } else {
          dispatch(PENDING(false))
          update_toast(toast, data.response, 'error')
        }
      })
      .catch(() => {
        dispatch(PENDING(false))
        update_toast(toast, undefined, 'error')
      })
  }
}
