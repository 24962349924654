import React from 'react'
import Button from './Button'

const ButtonIconRemove = (props) => {
  return (
    <Button
      icon="remove"
      title="Supprimer"
      {...props}
    />
  )
}

export default ButtonIconRemove
