import React from 'react'
import Titre from '../Includes/Misc/Titre'
import Root from '../Includes/Wrapper/Root'
import TableData from '../Includes/Table/TableData'
import {
  useSelector
} from 'react-redux'
import {
  Paper,
  Toolbar
} from '@mui/material'
import {
  properties
} from '../../Settings/Settings'

const Settings = () => {
  const settings = useSelector(state => state.settings.settings)
  return (
    <Root container={true}>
      {
        (settings.length > 0)
          ? <Paper>
              <Toolbar>
                <Titre>Paramètres</Titre>
              </Toolbar>
              <TableData
                data={settings}
                {...properties}
              />
            </Paper>
          : null
      }
    </Root>
  )
}

export default Settings
