import React from 'react'
import TableCellDesktop from './TableCellDesktop'
import {
  useSelector
} from 'react-redux'

const TableCellComptabilite = ({
  item,
  value
}) => {
  const dates = useSelector(state => state.comptabilite.dates)
  return (
    <TableCellDesktop
      max={75}
      value={(value || item)}
      empty={(
        (item === 0) ||
        (dates[0] === null)
      )}
    />
  )
}

export default TableCellComptabilite
