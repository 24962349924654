import React from 'react'
import ButtonAdmin from './ButtonAdmin'

const ButtonAdminDownload = (props) => {
  return (
    <ButtonAdmin
      icon="GetApp"
      color="green"
      title="Télécharger"
      {...props}
    />
  )
}

export default ButtonAdminDownload
