import React from 'react'
import styles from './Input.module.css'
import InputTextField from './InputTextField'
import {
  Autocomplete
} from '@mui/material'
import {
  Clear
} from '@mui/icons-material'
import {
  sort_name
} from '../../../Functions/Sort'
import {
  get_user_long
} from '../../../Functions/Strings'

const InputAutocomplete = ({
  error,
  label,
  value,
  options,
  className,
  ...others
}) => {
  return (
    <Autocomplete
      multiple={true}
      value={sort_name(value)}
      options={sort_name(options)}
      filterSelectedOptions={true}
      getOptionLabel={(item) => get_user_long(item)}
      clearIcon={
        <Clear
          fontSize="small"
          className={styles.clear}
        />
      }
      renderInput={(props) => {
        return (
          <InputTextField
            error={error}
            label={label}
            margin="normal"
            fullWidth={true}
            placeholder={label}
            className={className}
            {...props}
          />
        )
      }}
      {...others}
    />
  )
}

export default InputAutocomplete
