import React from 'react'
import TableData from '../Table/TableData'
import LegendeAccordion from './LegendeAccordion'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SORT_FONCTIONS
} from '../../../Redux/Actions/Sort'
import {
  SET_FILTER_ALL
} from '../../../Redux/Actions/Filters'
import {
  properties
} from '../../../Settings/Legende/Fonctions'

const LegendeFonctions = () => {
  const dispatch = useDispatch()
  const sort = useSelector(state => state.sort.fonctions)
  const equipe = useSelector(state => state.equipe.equipe)
  const filter = useSelector(state => state.filters.filter)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const selected = equipe.filter(item => filter.indexOf(item.id) < 0).map(item => item.fonction)
  const data = fonctions.filter(item => equipe.map(item => item.fonction).includes(item.id)).map(item => {
    const users = equipe.filter(object => object.fonction === item.id)
    const array = users.filter(object => filter.indexOf(object.id) < 0).length
    return {
      ...item,
      selected: (array === users.length)
        ? 2
        : (
            (array > 0) &&
            (array !== users.length)
          ) ? 1 : 0
    }
  })
  const Select = () => {
    dispatch(SET_FILTER_ALL(
      (filter.length === 0)
        ? equipe.map(item => item.id)
        : []
    ))
  }
  return (
    <LegendeAccordion titre="Fonctions">
      <TableData
        all={true}
        data={data}
        sort={sort}
        All={Select}
        bottom={false}
        pagination={false}
        selected={selected}
        count={equipe.length}
        request={(value) => dispatch(SORT_FONCTIONS(value))}
        {...properties}
      />
    </LegendeAccordion>
  )
}

export default LegendeFonctions
