import React from 'react'
import Button from './Button'

const ButtonIconCheck = (props) => {
  return (
    <Button
      icon="action-check"
      title="Enregistrer"
      {...props}
    />
  )
}

export default ButtonIconCheck
