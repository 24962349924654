import React from 'react'
import CodesMobileTitre from './CodesMobileTitre'
import TableData from '../../Includes/Table/TableData'
import {
  useSelector
} from 'react-redux'
import {
  properties
} from '../../../Settings/Codes'

const CodesContentTable = ({
  data,
  page,
  Select,
  setPage
}) => {
  const device = useSelector(state => state.device)
  const selected = useSelector(state => state.codes.selected)
  return (data.length > 0)
    ? <TableData
        data={data}
        page={page}
        All={Select}
        setPage={setPage}
        selected={selected}
        border={device.mobile}
        titre={CodesMobileTitre}
        count={data.filter(item => item.companie === '').length}
        {...properties}
      />
    : null
}

export default CodesContentTable
