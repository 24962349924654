import React from 'react'
import TableCellComptabilite from './TableCellComptabilite'

const TableCellComptabiliteConges = ({ item }) => {
  return (
    <TableCellComptabilite item={item.conges}/>
  )
}

export default TableCellComptabiliteConges
