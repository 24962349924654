import React from 'react'
import Titre from '../Includes/Misc/Titre'
import FonctionsToolbarContent from './FonctionsToolbarContent'
import {
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'

const FonctionsToolbarDesktop = () => {
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const maximum = useSelector(state => state.compte.maximum.fonctions)
  return (
    <Toolbar>
      <Titre>Fonctions</Titre>
      {
        (
          (maximum !== -1) &&
          (fonctions.length < maximum)
        )
          ? <FonctionsToolbarContent/>
          : null
      }
    </Toolbar>
  )
}

export default FonctionsToolbarDesktop
