import React from 'react'
import Button from './Button'

const ButtonClose = (props) => {
  return (
    <Button
      icon="Close"
      title="Fermer"
      {...props}
    />
  )
}

export default ButtonClose
