import React from 'react'
import Button from './Button'

const ButtonPaginationPrevious = (props) => {
  return (
    <Button
      title="Page précédente"
      icon="KeyboardArrowLeft"
      {...props}
    />
  )
}

export default ButtonPaginationPrevious
