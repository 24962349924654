import React from 'react'
import Bulle from '../Includes/Wrapper/Bulle'
import Checkbox from '../Includes/Misc/Checkbox'
import styles from './ComptabiliteModalSave.module.css'
import {
  FormGroup,
  FormControlLabel
} from '@mui/material'

const ComptabiliteModalSave = ({
  checked,
  setChecked
}) => {
  return (
    <Bulle
      arrow={true}
      placement="left"
      title="Si vous sauvegardez votre saisie, elle vous sera automatiquement proposée lors du prochain export"
    >
      <FormGroup className={styles.checkbox}>
        <FormControlLabel
          label="Enregistrer"
          control={
            <Checkbox
              checked={checked}
              onClick={() => setChecked(!checked)}
            />
          }
        />
      </FormGroup>
    </Bulle>
  )
}

export default ComptabiliteModalSave
