import React from 'react'
import ButtonAdd from '../Includes/Button/ButtonAdd'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  MODAL_ABSENCES
} from '../../Redux/Actions/Absences'

const AbsencesPlanningToolbarContent = () => {
  const dispatch = useDispatch()
  const date = useSelector(state => state.absences.date)
  return (
    <ButtonAdd
      mobile="Nouvelle absence"
      onClick={() => dispatch(MODAL_ABSENCES('', 'create', {
        jour: [
          date.startOf('day'),
          date.startOf('day')
        ]
      }))}
    />
  )
}

export default AbsencesPlanningToolbarContent
