import React from 'react'
import LegendeDate from '../Legende/LegendeDate'
import TableCellDesktop from './TableCellDesktop'
import LegendeHeure from '../Legende/LegendeHeure'

const TableCellLegendeVides = ({ item }) => {
  return (
    <TableCellDesktop max={200}>
      <LegendeDate item={item}/>
      <LegendeHeure item={item}/>
    </TableCellDesktop>
  )
}

export default TableCellLegendeVides
