import React from 'react'
import Titre from '../Includes/Misc/Titre'
import EquipeToolbarContent from './EquipeToolbarContent'
import {
  useSelector
} from 'react-redux'
import {
  Toolbar
} from '@mui/material'

const EquipeToolbarDesktop = () => {
  const equipe = useSelector(state => state.equipe.equipe)
  const maximum = useSelector(state => state.compte.maximum.users)
  return (
    <Toolbar>
      <Titre>Équipe</Titre>
      {
        (
          (maximum !== -1) &&
          (equipe.length < maximum)
        )
          ? <EquipeToolbarContent/>
          : null
      }
    </Toolbar>
  )
}

export default EquipeToolbarDesktop
