import React from 'react'
import ReactPlayer from 'react-player'
import {
  useSelector
} from 'react-redux'
import {
  variables
} from '../../../Variables/Variables'

const Video = ({
  video,
  width,
  height,
  ...others
}) => {
  const device = useSelector(state => state.device)
  const url = variables.url.server + variables.path.videos + video
  return (
    <ReactPlayer
      controls={true}
      url={url + "_vtt.mp4"}
      height={height + "px"}
      width={
        (device.mobile)
          ? "100%"
          : width + "px"
      }
      {...others}
    />
  )
}

export default Video
