import React from 'react'
import Button from './Button'

const ButtonReset = (props) => {
  return (
    <Button
      icon="Refresh"
      title="Réinitialiser"
      {...props}
    />
  )
}

export default ButtonReset
