import React from 'react'
import ButtonAdminBeta from '../../Includes/Button/ButtonAdminBeta'
import ButtonAdminBlock from '../../Includes/Button/ButtonAdminBlock'
import ButtonAdminUnblock from '../../Includes/Button/ButtonAdminUnblock'
import ButtonAdminProduction from '../../Includes/Button/ButtonAdminProduction'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  BETA_COMPANIE,
  BLOCK_COMPANIE,
  MODAL_COMPANIES
} from '../../../Redux/Actions/Companies'

const CompaniesToolbarButtons = () => {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.companies.selected)
  const Texte = (action) => {
    const texte = (selected.length > 1)
      ? "les entreprises sélectionnées"
      : "l'entreprise sélectionnée"
    switch (action) {
      case 'block':
        return (
          <span>Voulez-vous vraiment <b>bloquer</b> {texte} ?</span>
        )
      case 'unblock':
        return (
          <span>Voulez-vous vraiment <b>débloquer</b> {texte} ?</span>
        )
      case 'beta':
        return (
          <span>Voulez-vous vraiment basculer {texte} sur la version <b>beta</b> ?</span>
        )
      case 'production':
        return (
          <span>Voulez-vous vraiment basculer {texte} sur la version de <b>production</b> ?</span>
        )
      default:
      break
    }
  }
  return (
    <>
      <ButtonAdminUnblock
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_COMPANIES(
            true,
            Texte('unblock'),
            () => dispatch(BLOCK_COMPANIE(selected, 0))
          ))
        }}
      />
      <ButtonAdminBlock
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_COMPANIES(
            true,
            Texte('block'),
            () => dispatch(BLOCK_COMPANIE(selected, 1))
          ))
        }}
      />
      <ButtonAdminProduction
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_COMPANIES(
            true,
            Texte('production'),
            () => dispatch(BETA_COMPANIE(selected, 0))
          ))
        }}
      />
      <ButtonAdminBeta
        disabled={(selected.length === 0)}
        onClick={() => {
          dispatch(MODAL_COMPANIES(
            true,
            Texte('beta'),
            () => dispatch(BETA_COMPANIE(selected, 1))
          ))
        }}
      />
    </>
  )
}

export default CompaniesToolbarButtons
