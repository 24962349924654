import TableCellDuree from '../Components/Includes/Table/TableCellDuree'
import TableCellPeriode from '../Components/Includes/Table/TableCellPeriode'
import TableCellActionsDemandes from '../Components/Includes/Table/TableCellActionsDemandes'
import {
  fin
} from './Includes/Fin'
import {
  nom
} from './Includes/Nom'
import {
  debut
} from './Includes/Debut'
import {
  motif
} from './Includes/Motif'
import {
  prenom
} from './Includes/Prenom'
import {
  status
} from './Includes/Status'
import {
  actions
} from './Includes/Actions'
import {
  demande
} from './Includes/Demande'
import {
  fonction
} from './Includes/Fonction'
import {
  MODAL_ABSENCES,
  UPDATE_ABSENCE
} from '../Redux/Actions/Absences'

export const properties = {
  type: {
    id: "absences",
    groupe: "demandes"
  },
  sort: {
    by: "demande",
    order: "desc"
  },
  columns: [
    {
      ...prenom
    },
    {
      ...nom
    },
    {
      ...fonction
    },
    {
      ...demande
    },
    {
      ...debut
    },
    {
      ...fin
    },
    {
      ...motif,
      target: "absence"
    },
    {
      id: "duree",
      sort: true,
      padding: true,
      label: "Période",
      component: TableCellPeriode
    },
    {
      id: "nombre",
      sort: true,
      padding: true,
      label: "Durée",
      component: TableCellDuree
    },
    {
      ...status,
      action: UPDATE_ABSENCE
    },
    {
      ...actions,
      action: MODAL_ABSENCES,
      component: TableCellActionsDemandes
    }
  ]
}
