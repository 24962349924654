import React from 'react'
import ButtonSliderAdd from '../Button/ButtonSliderAdd'

const SliderButtonAdd = ({
  total,
  Update
}) => {
  return (
    <ButtonSliderAdd
      onClick={() => Update('total', 2)}
      style={{
        visibility: (total === 1)
          ? 'visible'
          : 'hidden'
      }}
    />
  )
}

export default SliderButtonAdd
