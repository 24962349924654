import React from 'react'
import InputTextField from './InputTextField'
import ButtonPassword from '../Button/ButtonPassword'
import {
  InputAdornment
} from '@mui/material'
import {
  CheckPasswordChiffre,
  CheckPasswordSpecial,
  CheckPasswordMinuscule,
  CheckPasswordMajuscule
} from '../../../Functions/Check'
import {
  variables
} from '../../../Variables/Variables'

const InputPassword = ({
  error,
  value,
  confirmation,
  ...others
}) => {
  const [visible, setVisible] = React.useState(false)
  return (
    <InputTextField
      error={error}
      value={value}
      type={
        (visible)
          ? 'text'
          : 'password'
      }
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <ButtonPassword
              error={error}
              visible={visible}
              onClick={() => setVisible(!visible)}
            />
          </InputAdornment>
      }}
      helperText={
        (error)
          ? (confirmation)
            ? "Les mots de passes doivent être identiques"
            : (!CheckPasswordMinuscule(value))
              ? "Le mot de passe doit contenir au moins une lettre minuscule"
              : (!CheckPasswordMajuscule(value))
                ? "Le mot de passe doit contenir au moins une lettre majuscule"
                : (!CheckPasswordChiffre(value))
                  ? "Le mot de passe doit contenir au moins un chiffre"
                  : (!CheckPasswordSpecial(value))
                    ? "Le mot de passe doit contenir au moins un caractère spécial"
                    : (value.length < variables.taille.password.minimum)
                      ? "Le mot de passe doit contenir au moins " + variables.taille.password.minimum + " caractères"
                      : (value.length > variables.taille.password.maximum)
                        ? "Le mot de passe ne doit pas dépasser " + variables.taille.password.maximum + " caractères"
                        : null
          : null
      }
      {...others}
    />
  )
}

export default InputPassword
