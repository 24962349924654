import React from 'react'
import Alert from '../Dialog/Alert'
import ButtonIconTrash from '../Button/ButtonIconTrash'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  first_day_format
} from '../../../Functions/Dates'
import {
  SET_EVENT,
  DELETE_PLANNING,
  DELETE_PLANNING_DAY
} from '../../../Redux/Actions/Planning'

const ToolbarRootDelete = ({ vide }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const user = useSelector(state => state.login.user)
  const date = useSelector(state => state.planning.date)
  const view = useSelector(state => state.planning.view)
  const texte = (view === 'Week')
    ? 'semaine'
    : 'journée'
  return (
    (vide) &&
    (user.profil === 'administrateur')
  )
    ? <>
        <Alert
          open={open}
          titre="Confirmation"
          Cancel={() => setOpen(false)}
          texte={
            <span>
              <span className="block">Voulez-vous vraiment vider la {texte} ?</span>
              <span className="block">Tous les créneaux seront perdus...</span>
            </span>
          }
          Commit={() => {
            setOpen(false)
            dispatch(SET_EVENT({
              id: '',
              read: false
            }))
            dispatch(
              (view === 'Week')
                ? DELETE_PLANNING(first_day_format(date))
                : DELETE_PLANNING_DAY({
                    week: first_day_format(date),
                    day: date.format('YYYY-MM-DD')
                  })
            )
          }}
        />
        <ButtonIconTrash
          onClick={() => setOpen(true)}
          title={
            (view === 'Week')
              ? "Vider la semaine"
              : "Vider la journée"
          }
        />
      </>
    : null
}

export default ToolbarRootDelete
