import React from 'react'
import Button from './Button'

const ButtonRead = (props) => {
  return (
    <Button
      icon="Drafts"
      title="Marquer comme lu"
      {...props}
    />
  )
}

export default ButtonRead
