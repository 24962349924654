import React from 'react'
import TableCellMobile from './TableCellMobile'
import TableCellDesktop from './TableCellDesktop'

const TableCellCompanie = ({
  item,
  label,
  device
}) => {
  const value = item.companie
  const object = {
    value: value,
    empty: (value === '')
  }
  return (device.mobile)
    ? <TableCellMobile
        label={label}
        {...object}
      />
    : <TableCellDesktop
        max={200}
        {...object}
      />
}

export default TableCellCompanie
