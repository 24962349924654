import OfficeModalContent from './OfficeModalContent'
import React, {
  useEffect
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  CheckPassword
} from '../../Functions/Check'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'
import {
  MODAL_OFFICES,
  UPDATE_OFFICE
} from '../../Redux/Actions/Offices'

const OfficeModal = () => {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.offices.modal)
  const [error, setError] = React.useState({
    password: false,
    confirmation: false
  })
  const [state, setState] = React.useState({
    password: '',
    confirmation: ''
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const UpdateError = (item, value) => {
    setError(current => ({
      ...current,
      [item]: value
    }))
  }
  const Commit = () => {
    if (
      CheckPassword(state.password) &&
      (state.password === state.confirmation)
    ) {
      dispatch(MODAL_OFFICES('', ''))
      dispatch(UPDATE_OFFICE(modal.id, state.password))
    } else {
      UpdateError('password', !CheckPassword(state.password))
      UpdateError('confirmation', (
        (state.confirmation === '') ||
        (state.password !== state.confirmation)
      ))
    }
  }
  useEffect(() => {
    if (modal.open !== '') {
      Update('password', '')
      Update('confirmation', '')
      UpdateError('password', false)
      UpdateError('confirmation', false)
    }
    // eslint-disable-next-line
  }, [modal])
  return (
    <Dialog open={(modal.open === 'edit')}>
      <DialogTitle>Mot de passe</DialogTitle>
      <DialogContent>
        <OfficeModalContent
          error={error}
          state={state}
          Update={Update}
          UpdateError={UpdateError}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => dispatch(MODAL_OFFICES('', ''))
          },
          {
            onClick: () => Commit()
          }
        ]}
      />
    </Dialog>
  )
}

export default OfficeModal
