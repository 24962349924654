import {
  READY
} from './Menu'
import {
  DIE,
  LOGIN,
  USER_LOGOUT,
  SUCCESS_LOGIN
} from './Login'
import {
  SET_CARD,
  SET_STRIPE,
  SET_FACTURES,
  SET_ABONNEMENT
} from './Stripe'
import {
  SET_COMPANIE,
  SET_VARIABLES
} from './Settings'
import {
  api
} from '../../API/api'
import {
  print_error
} from '../../Functions/Toast'
import {
  redirection
} from '../../Functions/Functions'

export const USER_BACK = () => {
  return (dispatch) => {
    const data = JSON.stringify({
      action: 'user'
    })
    api(data)
      .then(data => {
        if (data.status === 'OK') {
          const url = redirection(data.response.beta)
          if (url !== null) {
            window.location.assign(url)
          }
          dispatch(LOGIN(data.response))
          dispatch(DIE(data.response.die))
          dispatch(SET_CARD(data.response.card))
          dispatch(SET_STRIPE(data.response.stripe))
          dispatch(SUCCESS_LOGIN(data.response.profil))
          dispatch(SET_COMPANIE(data.response.companie))
          dispatch(SET_FACTURES(data.response.factures))
          dispatch(SET_VARIABLES(data.response.settings))
          dispatch(SET_ABONNEMENT(data.response.abonnement))
          dispatch(READY(true))
        } else {
          if (data.data === 'die') {
            dispatch(USER_LOGOUT(false))
          }
          print_error(data.response)
        }
      })
      .catch(() => {
        print_error()
      })
  }
}
