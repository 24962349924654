import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDatePlanning = ({
  item,
  label
}) => {
  return (
    <TableCellDate
      label={label}
      value={item.planning}
    />
  )
}

export default TableCellDatePlanning
