import clsx from 'clsx'
import React from 'react'
import AideVideo from './AideVideo'
import styles from './Aide.module.css'
import AideContact from './AideContact'
import Titre from '../Includes/Misc/Titre'
import Root from '../Includes/Wrapper/Root'
import SelectVideo from '../Includes/Select/SelectVideo'
import {
  useSelector
} from 'react-redux'
import {
  Paper,
  Button,
  Toolbar
} from '@mui/material'
import {
  landing
} from '../../Variables/Landing'
import {
  open_link
} from '../../Functions/Functions'
import {
  aide
} from '../../Variables/Variables'
import {
  ReactComponent as DiscordIcon
} from '../../Images/Social/discord.svg'
import {
  ReactComponent as FacebookIcon
} from '../../Images/Social/facebook.svg'

const Aide = () => {
  const [open, setOpen] = React.useState(false)
  const [current, setCurrent] = React.useState(0)
  const device = useSelector(state => state.device)
  return (
    <Root
      container={true}
      className={
        clsx({
          'page-container-aide': true,
          [styles.mobile]: device.mobile,
          'page-container-aide-mobile': device.mobile
        })
      }
    >
      <Paper>
        <AideContact
          open={open}
          setOpen={setOpen}
        />
        <Toolbar className="toolbar-aide">
          <Titre className={styles.title}>Vous ne trouvez pas ce que vous cherchez, vous rencontrez un problème ?</Titre>
          <div className="contact-items">
            {
              (landing.social.facebook !== '')
                ? <Button
                    color="aide"
                    variant="contained"
                    onClick={() => open_link(landing.social.facebook)}
                  >
                    <FacebookIcon className="contact-item"/>
                  </Button>
                : null
            }
            {
              (landing.social.discord !== '')
                ? <Button
                    color="aide"
                    variant="contained"
                    onClick={() => open_link(landing.social.discord)}
                  >
                    <DiscordIcon className="contact-item"/>
                  </Button>
                : null
            }
            <Button
              color="aide"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              <i className="icon message contact-item"/>
            </Button>
          </div>
        </Toolbar>
      </Paper>
      {
        (device.desktop)
          ? <Paper className={styles.margin}>
              <div className="aide-navigation">
                {
                  aide.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => setCurrent(index)}
                        className={
                          clsx({
                            'aide-item': true,
                            'aide-current': (index === current)
                          })
                        }
                      >
                        <i
                          className={
                            clsx(
                              'icon',
                              item.icon
                            )
                          }
                        />
                        <span>{item.label}</span>
                      </div>
                    )
                  })
                }
              </div>
            </Paper>
          : null
      }
      <Paper className={styles.margin}>
        {
          (device.mobile)
            ? <SelectVideo
                value={current}
                setValue={setCurrent}
                className={styles.select}
              />
            : null
        }
        <AideVideo video={aide[current].icon}/>
      </Paper>
    </Root>
  )
}

export default Aide
