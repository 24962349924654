import EventDrag from './EventDrag'
import EventDayCell from './EventDayCell'
import EventEmptyCell from './EventEmptyCell'
import EventMonthCell from './EventMonthCell'
import EventScaleCell from './EventScaleCell'
import EventScaleCellDate from './EventScaleCellDate'
import EventToolbarMobile from './EventToolbarMobile'
import EventSwitcherComponent from './EventSwitcherComponent'
import EventAppointmentComponentRead from './EventAppointmentComponentRead'
import EventAppointmentComponentAdmin from './EventAppointmentComponentAdmin'
import EventAppointmentComponentDraft from './EventAppointmentComponentDraft'
import EventAppointmentComponentHeure from './EventAppointmentComponentHeure'
import EventAppointmentComponentSource from './EventAppointmentComponentSource'
import EventAppointmentComponentAbsence from './EventAppointmentComponentAbsence'

export const Drag = (props) => <EventDrag {...props}/>
export const DayCell = (props) => <EventDayCell {...props}/>
export const EmptyCell = (props) => <EventEmptyCell {...props}/>
export const MonthCell = (props) => <EventMonthCell {...props}/>
export const ScaleCell = (props) => <EventScaleCell {...props}/>
export const ScaleCellDate = (props) => <EventScaleCellDate {...props}/>
export const ToolbarMobile = (props) => <EventToolbarMobile {...props}/>
export const SwitcherComponent = (props) => <EventSwitcherComponent {...props}/>
export const AppointmentComponentRead = (props) => <EventAppointmentComponentRead {...props}/>
export const AppointmentComponentDraft = (props) => <EventAppointmentComponentDraft {...props}/>
export const AppointmentComponentSource = (props) => <EventAppointmentComponentSource {...props}/>
export const AppointmentComponentAbsence = (props) => <EventAppointmentComponentAbsence {...props}/>
export const AppointmentComponentAdmin = (props) => (props.data.heure)
  ? <EventAppointmentComponentHeure {...props}/>
  : <EventAppointmentComponentAdmin {...props}/>
export const AppointmentComponentUser = (props) => (props.data.heure)
  ? <EventAppointmentComponentHeure {...props}/>
  : <EventAppointmentComponentRead {...props}/>
