export const SET_FILTER = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_FILTER',
      value: value
    })
  }
}

export const SET_FILTER_ALL = (value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_FILTER_ALL',
      value: value
    })
  }
}

export const SET_FILTERS_TARGET_RESET = (page) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_FILTERS_TARGET_RESET',
      page: page
    })
  }
}

export const SET_FILTERS_TARGET = (page, value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_FILTERS_TARGET',
      value: value,
      page: page
    })
  }
}

export const SET_FILTERS = (page, filtre, value) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_FILTERS',
      filtre: filtre,
      value: value,
      page: page
    })
  }
}
