import TableCellCheckboxFonctions from '../../Components/Includes/Table/TableCellCheckboxFonctions'
import {
  fonction
} from '../Includes/Fonction'

export const properties = {
  type: {
    id: "fonctions",
    groupe: "legendes"
  },
  columns: [
    {
      id: "checkbox",
      component: TableCellCheckboxFonctions
    },
    {
      ...fonction
    }
  ]
}
