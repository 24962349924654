import React from 'react'
import InputTextField from './InputTextField'

const InputCoupon = ({
  error,
  ...others
}) => {
  return (
    <InputTextField
      size="small"
      error={error}
      fullWidth={true}
      name="reduction"
      label="Coupon de réduction"
      className="stripe-element stripe-reduction"
      helperText={
        (error)
          ? "Ce coupon est invalide"
          : null
      }
      {...others}
    />
  )
}

export default InputCoupon
