import React from 'react'
import InputTextField from './InputTextField'
import {
  useSelector
} from 'react-redux'

const InputValeur = ({
  type,
  error,
  ...others
}) => {
  const settings = useSelector(state => state.settings.variables)
  return (
    <InputTextField
      error={error}
      label={settings.codes.types[type].label}
      helperText={
        (error)
          ? settings.codes.types[type].error + ' doit être compris' + ((settings.codes.types[type].genre) ? 'e' : '') + ' entre ' + settings.codes.types[type].minimum + ' et ' + settings.codes.types[type].maximum
          : null
      }
      {...others}
    />
  )
}

export default InputValeur
