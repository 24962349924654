import TableCellEssai from '../Components/Includes/Table/TableCellEssai'
import TableCellValeur from '../Components/Includes/Table/TableCellValeur'
import TableCellCodeNom from '../Components/Includes/Table/TableCellCodeNom'
import TableCellDateFull from '../Components/Includes/Table/TableCellDateFull'
import TableCellAbonnements from '../Components/Includes/Table/TableCellAbonnements'
import TableCellCheckboxCodes from '../Components/Includes/Table/TableCellCheckboxCodes'
import TableCellCodeEngagement from '../Components/Includes/Table/TableCellCodeEngagement'
import {
  nom
} from './Includes/Nom'
import {
  code
} from './Includes/Code'
import {
  date
} from './Includes/Date'
import {
  valeur
} from './Includes/Valeur'
import {
  companie
} from './Includes/Companie'
import {
  engagement
} from './Includes/Engagement'

export const properties = {
  type: {
    id: "codes",
    groupe: "admin",
    pagination: "large"
  },
  sort: {
    by: "nom",
    order: "asc"
  },
  columns: [
    {
      id: "checkbox",
      component: TableCellCheckboxCodes
    },
    {
      ...nom,
      component: TableCellCodeNom
    },
    {
      ...code
    },
    {
      ...date,
      mobile: true,
      component: TableCellDateFull
    },
    {
      ...companie,
      mobile: true
    },
    {
      ...valeur,
      component: TableCellValeur
    },
    {
      id: "essai",
      sort: true,
      mobile: true,
      padding: true,
      label: "Essai",
      component: TableCellEssai
    },
    {
      ...engagement,
      component: TableCellCodeEngagement
    },
    {
      id: "abonnements",
      sort: false,
      mobile: true,
      padding: true,
      label: "Abonnements",
      component: TableCellAbonnements
    }
  ]
}
