import React from 'react'
import SelectInput from './SelectInput'

const SelectMotif = ({
  array,
  ...others
}) => {
  return (
    <SelectInput
      label="Motif"
      options={Object.entries(array.motifs).map(([index, item]) => {
        return {
          value: index,
          label: item
        }
      })}
      {...others}
    />
  )
}

export default SelectMotif
