import React from 'react'
import TableCellDate from './TableCellDate'

const TableCellDateNotification = ({
  item,
  device
}) => {
  return (
    <TableCellDate
      full={true}
      width={120}
      desktop={true}
      value={item.date}
      style={{
        paddingLeft: (device.mobile) ? 0 : undefined,
        paddingRight: (device.mobile) ? 0 : undefined
      }}
    />
  )
}

export default TableCellDateNotification
