import React from 'react'
import InputTextField from './InputTextField'

const InputSujet = ({
  error,
  ...others
}) => {
  return (
    <InputTextField
      error={error}
      label="Sujet"
      helperText={
        (error)
          ? "Ce sujet est invalide"
          : null
      }
      {...others}
    />
  )
}

export default InputSujet
