import React from 'react'
import ActionSheet from './ActionSheet'
import Condition from '../Condition/Demandes'
import {
  useDispatch,
  useSelector
} from 'react-redux'

const ActionSheetDemandes = ({
  item,
  modal,
  update
}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.login.user)
  const condition = Condition(item)
  return (
    <ActionSheet
      condition={condition}
      options={[
        {
          id: 0,
          label: 'Refuser',
          action: () => dispatch(update(item.id, 0)),
          condition: (
            (item.status === 2) &&
            (user.profil === 'administrateur')
          )
        },
        {
          id: 1,
          label: 'Accepter',
          action: () => dispatch(update(item.id, 1)),
          condition: (
            (item.status === 2) &&
            (user.profil === 'administrateur')
          )
        },
        {
          id: 2,
          label: 'Modifier',
          action: () => dispatch(modal(item.id, 'edit', (item.nombre > 0)))
        },
        {
          id: 3,
          label: 'Supprimer',
          action: () => dispatch(modal(item.id, 'delete'))
        }
      ]}
    />
  )
}

export default ActionSheetDemandes
