import React from 'react'
import ButtonLeft from '../Includes/Button/ButtonLeft'
import styles from './SemainesSchedulerDate.module.css'
import ButtonRight from '../Includes/Button/ButtonRight'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  is_last_day,
  is_first_day
} from '../../Functions/Dates'
import {
  SET_DATE_SEMAINES
} from '../../Redux/Actions/Semaines'

const SemainesSchedulerDate = () => {
  const dispatch = useDispatch()
  const date = useSelector(state => state.semaines.date)
  return (
    <>
      <ButtonLeft
        disabled={(is_first_day(date))}
        onClick={() => dispatch(SET_DATE_SEMAINES(date.subtract(1, 'days')))}
        title={
          (is_first_day(date))
            ? undefined
            : 'Jour précédent'
        }
        className={
          (is_first_day(date))
            ? styles.disabled
            : styles.color
        }
      />
      <ButtonRight
        disabled={(is_last_day(date))}
        onClick={() => dispatch(SET_DATE_SEMAINES(date.add(1, 'days')))}
        title={
          (is_last_day(date))
            ? undefined
            : 'Jour suivant'
        }
        className={
          (is_last_day(date))
            ? styles.disabled
            : styles.color
        }
      />
    </>
  )
}

export default SemainesSchedulerDate
