import React from 'react'
import Form from '../../Includes/Wrapper/Form'
import InputNom from '../../Includes/Input/InputNom'
import SelectType from '../../Includes/Select/SelectType'
import InputValeur from '../../Includes/Input/InputValeur'
import SelectEssai from '../../Includes/Select/SelectEssai'
import SelectNombre from '../../Includes/Select/SelectNombre'
import SelectEngagement from '../../Includes/Select/SelectEngagement'
import SelectAbonnements from '../../Includes/Select/SelectAbonnements'
import {
  useSelector
} from 'react-redux'
import {
  CheckLong
} from '../../../Functions/Check'

const CodeModalContent = ({
  error,
  state,
  Commit,
  Update,
  UpdateError
}) => {
  const settings = useSelector(state => state.settings.variables)
  const Nom = (value) => {
    Update('nom', value)
    UpdateError('nom', (
      (value !== '') &&
      (!CheckLong(value))
    ))
  }
  const Valeur = (value) => {
    Update('valeur', value)
    UpdateError('valeur', (
      (value > settings.codes.types[state.type].maximum) ||
      (value < settings.codes.types[state.type].minimum) ||
      (!state.condition.test(value))
    ))
  }
  return (
    <Form>
      <InputNom
        setValue={Nom}
        error={error.nom}
        value={state.nom}
      />
      <SelectNombre
        value={state.nombre}
        setValue={(value) => Update('nombre', value)}
      />
      <SelectType
        value={state.type}
        setValue={(value) => Update('type', value)}
      />
      <InputValeur
        setValue={Valeur}
        type={state.type}
        error={error.valeur}
        value={state.valeur}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            Commit()
          }
        }}
      />
      <SelectAbonnements
        value={state.abonnements}
        setValue={(value) => Update('abonnements', value)}
      />
      <SelectEngagement
        value={state.engagement}
        setValue={(value) => Update('engagement', value)}
      />
      <SelectEssai
        value={state.essai}
        disabled={state.disabled}
        setValue={(value) => Update('essai', value)}
      />
    </Form>
  )
}

export default CodeModalContent
