import React from 'react'
import ToolbarOpen from './ToolbarOpen'
import Absences from '../Hooks/Absences'
import styles from './Toolbar.module.css'
import ButtonLeft from '../Button/ButtonLeft'
import ButtonRight from '../Button/ButtonRight'
import {
  TodayButtonComponent
} from './Toolbar'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  ABSENCE,
  SET_DATE_ABSENCES
} from '../../../Redux/Actions/Absences'
import {
  SET_EVENT,
  UPDATE_PLANNING
} from '../../../Redux/Actions/Planning'

const ToolbarDate = ({
  rootRef,
  onNavigate,
  navigatorText,
  onVisibilityToggle
}) => {
  const absences = Absences()
  const dispatch = useDispatch()
  const view = useSelector(state => state.planning.view)
  const date = {
    absences: useSelector(state => state.absences.date),
    planning: useSelector(state => state.planning.date)
  }
  const week = (absences)
    ? ''
    : ' (S' + date.planning.week() + ')'
  return (
    <div
      ref={rootRef}
      className="navigate"
    >
      <TodayButtonComponent/>
      <ButtonLeft
        className={styles.color}
        title={
          (absences)
            ? 'Mois précédent'
            : (view === 'Day')
              ? 'Jour précédent'
              : 'Semaine précédente'
        }
        onClick={() => {
          if (absences) {
            dispatch(ABSENCE(''))
            dispatch(SET_DATE_ABSENCES(date.absences.subtract(1, 'month')))
          } else {
            dispatch(SET_EVENT({
              id: '',
              read: false
            }))
            onNavigate('back')
            dispatch(UPDATE_PLANNING())
          }
        }}
      />
      <ButtonRight
        className={styles.color}
        title={
          (absences)
            ? 'Mois suivant'
            : (view === 'Day')
              ? 'Jour suivant'
              : 'Semaine suivante'
        }
        onClick={() => {
          if (absences) {
            dispatch(ABSENCE(''))
            dispatch(SET_DATE_ABSENCES(date.absences.add(1, 'month')))
          } else {
            dispatch(SET_EVENT({
              id: '',
              read: false
            }))
            onNavigate('forward')
            dispatch(UPDATE_PLANNING())
          }
        }}
      />
      <ToolbarOpen
        text={navigatorText + week}
        onVisibilityToggle={onVisibilityToggle}
      />
    </div>
  )
}

export default ToolbarDate
