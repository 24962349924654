import React from 'react'
import Button from './Button'

const ButtonIconClose = (props) => {
  return (
    <Button
      title="Quitter"
      icon="action-close"
      {...props}
    />
  )
}

export default ButtonIconClose
