import clsx from 'clsx'
import CompteModalContent from './CompteModalContent'
import React, {
  useEffect
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  CheckComment
} from '../../Functions/Check'
import {
  config
} from '../../Variables/Variables'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Includes/Dialog/Dialog'

const CompteModal = ({
  open,
  Commit,
  setOpen
}) => {
  const device = useSelector(state => state.device)
  const type = useSelector(state => state.compte.type)
  const settings = useSelector(state => state.settings.variables)
  const [state, setState] = React.useState({
    error: false,
    commentaire: ''
  })
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  const Delete = () => {
    if (CheckComment(state.commentaire)) {
      Update('error', false)
      Commit(state.commentaire)
    } else {
      Update('error', !CheckComment(state.commentaire))
    }
  }
  useEffect(() => {
    if (open) {
      Update('error', false)
      Update('commentaire', '')
    }
    // eslint-disable-next-line
  }, [open])
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      className={
        clsx({
          'dialog-compte': true,
          'dialog-compte-mobile': device.mobile
        })
      }
    >
      <DialogTitle red={true}>
        Voulez-vous vraiment supprimer {settings.companies.libelle[type]} ?
      </DialogTitle>
      <DialogContent>
        <CompteModalContent
          state={state}
          Update={Update}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            hidden: config.test,
            onClick: () => Delete()
          }
        ]}
      />
    </Dialog>
  )
}

export default CompteModal
