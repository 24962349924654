import styles from './ActionSheet.module.css'
import ButtonMore from '../Button/ButtonMore'
import SelectInput from '../Select/SelectInput'
import React, {
  useRef
} from 'react'

const ActionSheet = ({
  options,
  condition
}) => {
  const ref = useRef()
  const [open, setOpen] = React.useState(false)
  return (condition !== false)
    ? <>
        <ButtonMore
          ref={ref}
          small={true}
          className={styles.card}
          onClick={() => setOpen(true)}
        />
        <SelectInput
          value=""
          open={open}
          hidden={true}
          onClose={() => setOpen(false)}
          MenuProps={{
            anchorEl: ref.current,
            anchorReference: 'anchorEl'
          }}
          setValue={(value) => {
            const selected = options.find(item => item.id === value)
            selected.action()
            setOpen(false)
          }}
          options={options.filter(item => item.condition !== false).map(item => {
            return {
              value: item.id,
              label: item.label
            }
          })}
        />
      </>
    : <div className={styles.hidden}/>
}

export default ActionSheet
