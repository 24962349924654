import React from 'react'
import Logo from './Logo'
import styles from './CardForm.module.css'
import InputCVC from '../Includes/Input/InputCVC'
import InputCard from '../Includes/Input/InputCard'
import CardFormContainer from './CardFormContainer'
import InputExpire from '../Includes/Input/InputExpire'
import {
  check_input
} from '../../Functions/Stripe'

const CardForm = ({
  error,
  state,
  Update,
  UpdateError,
  UpdateSuccess
}) => {
  const CVC = (value) => {
    Update('cvc', value)
    UpdateSuccess('cvc', check_input(value))
    UpdateError('cvc', (value.error !== undefined))
  }
  const Card = (value) => {
    Update('card', value)
    UpdateSuccess('card', check_input(value))
    UpdateError('card', (value.error !== undefined))
  }
  const Expire = (value) => {
    Update('expire', value)
    UpdateSuccess('expire', check_input(value))
    UpdateError('expire', (value.error !== undefined))
  }
  return (
    <div className={styles.container}>
      <CardFormContainer>
        <InputCard
          onChange={Card}
          error={error.card}
          disabled={state.pending}
          message={
            (state.card.error)
              ? state.card.error.message
              : ''
          }
        />
      </CardFormContainer>
      <CardFormContainer>
        <InputExpire
          onChange={Expire}
          error={error.expire}
          disabled={state.pending}
          message={
            (state.expire.error)
              ? state.expire.error.message
              : ''
          }
        />
      </CardFormContainer>
      <CardFormContainer>
        <InputCVC
          onChange={CVC}
          error={error.cvc}
          disabled={state.pending}
          message={
            (state.cvc.error)
              ? state.cvc.error.message
              : ''
          }
        />
      </CardFormContainer>
      <Logo/>
    </div>
  )
}

export default CardForm
