import clsx from 'clsx'
import React from 'react'
import styles from './Container.module.css'
import {
  useSelector
} from 'react-redux'

const Container = ({
  column,
  reverse,
  children
}) => {
  const device = useSelector(state => state.device)
  return (
    <div
      className={
        clsx({
          [styles.column]: column,
          [styles.container]: true,
          [styles.reverse]: reverse,
          [styles.mobile]: device.mobile,
          [styles.desktop]: device.desktop
        })
      }
    >
      {children}
    </div>
  )
}

export default Container
