import FilterMobileContent from './FilterMobileContent'
import React, {
  useEffect
} from 'react'
import {
  useSelector
} from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '../Dialog/Dialog'

const FilterMobile = ({
  open,
  page,
  label,
  Commit,
  setOpen,
  setting
}) => {
  const [state, setState] = React.useState({})
  const [validation, setValidation] = React.useState(true)
  const filters = useSelector(state => state.filters.filters)
  const Update = (item, value) => {
    setState(current => ({
      ...current,
      [item]: value
    }))
  }
  useEffect(() => {
    if (open) {
      setValidation(true)
      setState(filters[page])
    }
    // eslint-disable-next-line
  }, [open])
  return (
    <Dialog open={open}>
      <DialogTitle>
        Filtrer les {label}
      </DialogTitle>
      <DialogContent>
        <FilterMobileContent
          page={page}
          state={state}
          Update={Update}
          setting={setting}
          validation={validation}
          setValidation={setValidation}
        />
      </DialogContent>
      <DialogActions
        buttons={[
          {
            onClick: () => setOpen(false)
          },
          {
            onClick: () => {
              if (validation) {
                setOpen(false)
                Commit(state)
              }
            }
          }
        ]}
      />
    </Dialog>
  )
}

export default FilterMobile
