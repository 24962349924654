import React from 'react'
import TableData from '../Table/TableData'
import LegendeAccordion from './LegendeAccordion'
import {
  useSelector
} from 'react-redux'
import {
  sort_name
} from '../../../Functions/Sort'
import {
  properties
} from '../../../Settings/Legende/Erreurs'

const LegendeErreurs = ({ data }) => {
  const equipe = useSelector(state => state.equipe.equipe)
  const fonctions = useSelector(state => state.fonctions.fonctions)
  const erreurs = data.map(item => {
    const user = equipe.find(object => object.id === item.user)
    const fonction = fonctions.find(object => object.id === user.fonction)
    return {
      ...item,
      nom: user.nom,
      color: user.color,
      prenom: user.prenom,
      fonction: fonction.fonction
    }
  })
  return (
    <LegendeAccordion
      warning={true}
      titre={"Conflits (" + erreurs.length + ")"}
    >
      <TableData
        all={true}
        head={false}
        border={true}
        random={true}
        bottom={false}
        pagination={false}
        data={sort_name(erreurs)}
        {...properties}
      />
    </LegendeAccordion>
  )
}

export default LegendeErreurs
