import clsx from 'clsx'
import React from 'react'
import styles from './Event.module.css'
import CreneauContent from '../Creneau/CreneauContent'
import EventAppointmentBase from './EventAppointmentBase'

const EventAppointmentComponentDraft = (props) => {
  const { data } = props
  return (
    <EventAppointmentBase
      className={
        clsx(
          styles.hover,
          styles.appointment,
          (data.heure)
            ? styles.heure
            : styles.event
        )
      }
      {...props}
    >
      <CreneauContent data={data}/>
    </EventAppointmentBase>
  )
}

export default EventAppointmentComponentDraft
