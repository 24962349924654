import React, {
  useEffect
} from 'react'
import {
  useDispatch
} from 'react-redux'
import {
  device
} from './Functions/Device'
import {
  SET_DEVICE
} from './Redux/Actions/Device'

const Container = ({ children }) => {
  const dispatch = useDispatch()
  const Resize = () => {
    dispatch(SET_DEVICE(device()))
  }
  useEffect(() => {
    Resize()
    window.addEventListener('resize', Resize)
    return () => {
      window.removeEventListener('resize', Resize)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      {children}
    </>
  )
}

export default Container
